/* eslint-disable prefer-destructuring */
import { Container } from "@mui/material";
import NoToolWarning from "apps/jtm/components/tag/NoToolWarning";
import { getCreateOptions } from "libs/business/apollo/createOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Wizard, { WizardChild } from "libs/ui/components/surfaces/wizard/Wizard";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { CREATE_TAG, GET_ALL_TAGS } from "../../business/tag/queries";
import { NoConsentActiveProvider } from "../../components/tag/NoConsentActiveHook";
import StepFour from "../../components/tag/StepFour";
import StepOne from "../../components/tag/StepOne";
import StepThree from "../../components/tag/StepThree";
import StepTwo from "../../components/tag/StepTwo";

const schemaStepOne = yup.object({
  name: yup.string().required().min(4).max(256, "The name must be shorter than 256 characters."),
  toolId: yup.string().required(),
});

const schemaStepTwo = yup.object({
  template: yup.string().required(),
});

const schemaStepThree = yup.object({});

const schemaStepFour = yup.object({
  triggerId: yup.number().required(),
});

/**
 * The TagNew component
 * @returns a TagNew component
 */
const TagNew = (): JSX.Element => {
  const localStorageDataName = "tag_creation_wizard_data";
  const [createTag, mutationState] = useMutationWrapper(CREATE_TAG);
  const navigate = useNavigate();

  const formSubmitHandler: SubmitHandler<any> = (submitData: any) => {
    submitData.pluginID = submitData.tool.pluginID;
    submitData.arraylistVariables = submitData.arraylistVariables || null;
    submitData.hitType = submitData.hitType || null;
    createTag(getCreateOptions("Tag", "JTM", submitData, GET_ALL_TAGS, navigate));
    localStorage.removeItem(localStorageDataName);
  };

  if (mutationState.loading) return <PageLoadingSpinner />;

  return (
    <NoConsentActiveProvider>
      <Container>
        <NoToolWarning>
          <Wizard handleFinish={formSubmitHandler} localStorageDataName={localStorageDataName}>
            <WizardChild label="Tag and Tool" schema={schemaStepOne}>
              <StepOne />
            </WizardChild>
            <WizardChild label="Select Template" schema={schemaStepTwo}>
              <StepTwo />
            </WizardChild>
            <WizardChild label="Configure Template" schema={schemaStepThree}>
              <StepThree />
            </WizardChild>
            <WizardChild label="Select Trigger" schema={schemaStepFour}>
              <StepFour />
            </WizardChild>
          </Wizard>
        </NoToolWarning>
      </Container>
    </NoConsentActiveProvider>
  );
};

export default TagNew;
