import { Container } from "@mui/material";
import { useActiveContainer } from "apps/admin/components/container/ContainerContext";
import { GET_ALL_VERSIONS, GetAllVersionsQueryDefinition } from "apps/jtm/business/version/queries";
import QuickContainerVersionCard from "apps/jtm/components/version/QuickContainerVersionCard";
import SystemUpdateDialogProvider from "apps/jtm/components/version/SystemUpdatesPopUp";
import VersionGrid from "apps/jtm/components/version/VersionGrid";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { deepCopyObjectWithoutFunction } from "libs/business/utils/objectFunctions";
import { dateFormatter } from "libs/business/utils/timeTransformation";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import Page from "libs/ui/components/surfaces/page/Page";
import { useLocation } from "react-router-dom";
import { PublishDialogProvider } from "../../components/version/PublishDialog";

const filterVersionDataOnContainer = (
  versionData: GetAllVersionsQueryDefinition["dataType"][],
  containerIDs: number[],
) => {
  const filteredVersionData = versionData.filter((version: any) => {
    return containerIDs.includes(version.container_id);
  });
  return filteredVersionData;
};

const prepareContainerVersions = (versionData: any) => {
  const containerVersions: any = {};
  versionData.forEach((version: any) => {
    containerVersions[version.container_id] = containerVersions[version.container_id] || {};
    containerVersions[version.container_id].container_name = version.container.name;
    let versionUsername = "-";
    if (version.updated_by?.email) {
      versionUsername = version.updated_by?.email;
    }
    if (version.updated_by?.username) {
      versionUsername = version.updated_by?.username;
    }

    if (version.edit === 1) {
      containerVersions[version.container_id].currentVersion = {
        container_id: version.container_id,
        container_name: version.container.name,
        container_url: version.container.live_url,
        test_url: version.container.test_url,
        number: version.number,
        id: version.id,
        updated_on: dateFormatter(version.edit_at),
        updated_by: versionUsername,
      };
    }
    if (version.public_environments.map((env: any) => env.id).includes(1)) {
      containerVersions[version.container_id].publishedVersion = {
        number: version.number,
        published_on: dateFormatter(version.edit_at),
        created_on: dateFormatter(version.create_at),
        description: version.description,
        published_by: versionUsername,
      };
    }
  });
  return containerVersions;
};

const Versions = (): JSX.Element => {
  const location: any = useLocation();
  const initModalOpen = location?.state?.initModalOpen;

  let containerVersions: any = {};
  const activeContainerIds = useActiveContainer();

  const getAllVersionsResponse = useSimpleFetching<GetAllVersionsQueryDefinition>({
    query: GET_ALL_VERSIONS,
    context: "JTM",
  });

  if (getAllVersionsResponse === false) {
    return <PageLoadingSpinner />;
  }

  let versionData = deepCopyObjectWithoutFunction(getAllVersionsResponse.deploymentVersions);

  versionData = filterVersionDataOnContainer(versionData, activeContainerIds);
  containerVersions = prepareContainerVersions(versionData);

  return (
    <SystemUpdateDialogProvider>
      <PublishDialogProvider initModalOpen={!!initModalOpen}>
        <Container>
          <Page title="Container overview">
            <div style={{ minHeight: 240 }}>
              {activeContainerIds.length ? (
                <QuickContainerVersionCard data={containerVersions} />
              ) : (
                <AlertBox type="warning" message="Please select a container!" />
              )}
            </div>
          </Page>
          <Page title="All versions" notFirst>
            <VersionGrid rowsData={versionData} />
          </Page>
        </Container>
      </PublishDialogProvider>
    </SystemUpdateDialogProvider>
  );
};

export default Versions;
