import { FallbackProps } from "react-error-boundary";
import oops from "assets/images/custom/oops.png";
import colorStyles from "assets/styles/colorPalette.module.scss";

/**
 * The GlobalErrorPage component
 * @returns a GlobalErrorPage component
 */
const GlobalErrorPage = ({ error, resetErrorBoundary }: FallbackProps): JSX.Element => {
  return (
    <div
      role="alert"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        width: "100%",
        height: "100vh",
      }}>
      <img alt="oops" src={oops} style={{ height: "300px" }} />
      <p style={{ fontFamily: "Barlow" }}>Something went wrong!</p>
      <pre>{error.message}</pre>
      <button
        type="button"
        onClick={resetErrorBoundary}
        style={{
          fontFamily: "Barlow",
          padding: "10px",
          backgroundColor: colorStyles.primaryMain,
          borderRadius: "4px",
          border: "none",
          color: "white",
          cursor: "pointer",
        }}>
        Try again
      </button>
    </div>
  );
};

export default GlobalErrorPage;
