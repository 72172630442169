import config, { IGroupNavElement, IMainNavElement } from "../../../conf/sidebar_config";
import { IRouteInfo } from "./business";
import GroupNavElement from "./GroupNavElement";

import classes from "./sidebar.module.scss";

interface ISubNavProps {
  activeRouteInfo: IRouteInfo;
}
/**
 * The SubNav component
 * @param {ISubNavProps} props
 * @returns a SubNav component
 */
const SubNav = ({ activeRouteInfo }: ISubNavProps): JSX.Element | null => {
  const mainNavElement = config[activeRouteInfo.mainNavIndex];
  if (!mainNavElement.subNav) {
    return null;
  }
  return (
    <div>
      <nav className={classes.subNav}>
        <ul>{getGroupsDOM(mainNavElement, activeRouteInfo)}</ul>
      </nav>
    </div>
  );
};
export default SubNav;

const getGroupsDOM = (mainNavElement: IMainNavElement, activeRouteInfo: IRouteInfo) => {
  if (!mainNavElement.subNav) {
    return null;
  }
  return mainNavElement.subNav.map((subNavGroup: IGroupNavElement, groupNavIndex: number) => {
    const groupKey = subNavGroup.groupname + groupNavIndex.toString();
    return (
      <GroupNavElement
        key={groupKey}
        subNavGroup={subNavGroup}
        activeRouteInfo={activeRouteInfo}
        groupNavIndex={groupNavIndex}
      />
    );
  });
};
