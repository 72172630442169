import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_PREVIEW = gql`
  mutation updatePreview($containerIds: [Int!]!) {
    updatePreview(containerIds: $containerIds) {
      id
      create_at
      edit_at
      number
      edit
      create_by
      updated_by {
        id
        username
        email
      }
      container_id
      container {
        id
        name
        short
        live_url
        test_url
        hash
        file_hash
        languages
      }
      description
      public_environments {
        id
        name
      }
    }
  }
`;

export const PUBLISH = gql`
  mutation publish($environmentIds: [Int!]!, $versionId: Int!, $description: String!) {
    publish(environmentIds: $environmentIds, versionId: $versionId, description: $description) {
      id
      create_at
      edit_at
      number
      edit
      create_by
      updated_by {
        id
        username
        email
      }
      container_id
      container {
        id
        name
        short
        live_url
        test_url
        hash
        file_hash
        languages
      }
      description
      public_environments {
        id
        name
      }
    }
  }
`;
