import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

/**
 * The IsNegativeInfo component
 * @returns a IsNegativeInfo component
 */
const IsNegativeInfo = (): JSX.Element => {
  return (
    <Typography>
      <FontAwesomeIcon icon={faInfoCircle} /> Stream was not executed! See Trigger for details.
    </Typography>
  );
};

export default IsNegativeInfo;
