import { gql } from "@apollo/client";

const GET_CATEGORIES = gql`
  query Categories {
    Categories {
      id
      categoryid
      textcontent_textcontentid
      text_content {
        text
      }
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation AddCategory($text: String!) {
    addCategory(text: $text) {
      id
      categoryid
      text_content {
        text
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($updateCategoryId: Int!, $text: String!) {
    updateCategory(id: $updateCategoryId, text: $text) {
      id
      categoryid
      textcontent_textcontentid
      text_content {
        text
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($deleteCategoryId: Int!) {
    deleteCategory(id: $deleteCategoryId)
  }
`;

export default GET_CATEGORIES;
