import { Container } from "@mui/material";
import CLIENT from "libs/business/apollo";
import UserService from "libs/business/authentication/UserService";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import getRoutePath from "libs/business/routes/routes";
import BasicGrid from "libs/ui/components/datagrid/BasicGrid";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";
import {
  DELETE_CONTAINER,
  DeleteContainerQueryDefinition,
  GET_ALL_CONTAINERS,
  GetAllContainerQueryDefinition,
} from "../../business/container/queries";

const ContainerGrid = (): JSX.Element => {
  const [deleteContainer] = useMutationWrapper<DeleteContainerQueryDefinition>(DELETE_CONTAINER);

  const allContainers = useSimpleFetching<GetAllContainerQueryDefinition>({
    query: GET_ALL_CONTAINERS,
    context: "ADMIN",
  });

  const accessGroup = UserService.getAccessGroup();
  const userCanDelete = accessGroup === 1;
  const userCanEdit = accessGroup <= 3;

  if (allContainers === false) return <PageLoadingSpinner />;

  return (
    <Container>
      <Page
        title="Tags"
        newButtonConfig={{
          instanceName: "Container",
          newInstanceLink: getRoutePath("admin", "container_new"),
          accessGroups: 1,
        }}>
        <BasicGrid
          columnConfig={[
            {
              field: "name",
              headerName: "Name",
              minWidth: 250,
              flex: 1,
            },
            {
              field: "short",
              headerName: "Short",
              width: 230,
            },
            {
              field: "live_url",
              headerName: "Live Url",
              width: 250,
            },
            {
              field: "test_url",
              headerName: "Test Url",
              width: 250,
            },
          ]}
          actionConfig={{
            editLink: {
              service: "admin",
              routeName: "container_id",
            },
            context: "ADMIN",
            deleteFunction: deleteContainer,
            onDeleteCompleted: () => CLIENT.resetStore(),
            copy: false,
            log: false,
            settings: {
              editable: userCanEdit,
              deletable: userCanDelete,
              copyable: false,
              hasLog: false,
            },
          }}
          rowsData={allContainers.containers}
        />
      </Page>
      {/* TODO update right permission number */}
    </Container>
  );
};

export default ContainerGrid;
