import { Box } from "@mui/material";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import SelectWrapper from "libs/ui/components/input/SelectWrapper";

const operators = [
  { key: "eq", value: "equal" },
  { key: "neq", value: "not-equal" },
  { key: "bg", value: "bigger" },
  { key: "lo", value: "lower" },
  { key: "bgeq", value: "bigger-equal" },
  { key: "loeq", value: "lower-equal" },
  { key: "co", value: "contains" },
  { key: "bw", value: "begins-with" },
  { key: "ew", value: "ends-with" },
  { key: "reg", value: "regex" },
];

interface IOperatorSelectProps {
  selected: string;
  name: string;
  readOnly: boolean;
  required?: boolean;
  fixedWidth?: boolean;
}

export default function OperatorSelect({
  selected,
  name,
  readOnly = false,
  required = false,
  fixedWidth = true,
}: IOperatorSelectProps) {
  const { setMaintained } = useMaintained();
  let selectedValue = "";

  operators.forEach((element) => {
    if (element.key === selected) {
      selectedValue = element.key;
    }
  });

  const onChange = (newValue: string | number) => {
    if (selected !== newValue.toString()) {
      setMaintained(false);
    }
  };

  return (
    <Box sx={fixedWidth ? { width: "130px" } : {}}>
      <SelectWrapper
        label={`Operator ${required ? "*" : ""}`}
        name={name}
        value={selectedValue}
        options={operators}
        style={{ margin: 0 }}
        readonly={readOnly}
        onChange={onChange}
      />
    </Box>
  );
}
