import { faExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Tooltip } from "@mui/material";
import colorStyles from "assets/styles/colorPalette.module.scss";
import { ReactNode } from "react";
import AlertBox from "../surfaces/alertbox/alertBox";

interface WarningBadgeProps {
  children: ReactNode;
  tooltipMessage: string;
  show: boolean;
}
/**
 * The WarningBadge component
 * @param {WarningBadgeProps} props
 * @returns a WarningBadge component
 */
const WarningBadge = ({ children, tooltipMessage, show }: WarningBadgeProps): JSX.Element => {
  if (!show) return <div>{children}</div>;
  return (
    <Badge
      sx={{ cursor: "pointer", "& .MuiBadge-badge": { padding: 0 } }}
      color="warning"
      component="div"
      badgeContent={
        <Tooltip
          title={<AlertBox type="warning" message={tooltipMessage} />}
          placement="top-end"
          componentsProps={{
            tooltip: { style: { backgroundColor: "transparent", padding: 0, maxWidth: "400px" } },
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}>
            <FontAwesomeIcon icon={faExclamation} color={colorStyles.orange9} />
          </div>
        </Tooltip>
      }>
      {children}
    </Badge>
  );
};

export default WarningBadge;
