import { AccessGroups } from "libs/business/authentication/UserStorageManager";
import ErrorPage from "libs/business/error/ErrorPage";
import { useNavigate } from "react-router-dom";
import UserService from "../../../libs/business/authentication/UserService";

interface RequiresAccessGroupWrapperProps {
  accessGroups: AccessGroups[] | AccessGroups;
  hideChildren?: boolean;
  children: JSX.Element;
}
/**
 * The RequireAccessGroupWrapper component
 * @param {AccessGroups[] | AccessGroups} props.accessGroups - if passing a number it will be checked, if the user accessGroup is equal or lower. If an array is passed it is checked if the access group of the user is included.
 * @param {boolean} props.hideChildren - if this prop is true the children will be hidden and no error page is returned
 * @returns the children with right permissions, otherwise it will return the error page.
 */
const RequiresAccessGroupWrapper = ({
  accessGroups,
  hideChildren = false,
  children,
}: RequiresAccessGroupWrapperProps): JSX.Element => {
  const userAccessGroup = UserService.getAccessGroup();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  let hasPermission = false;

  if (typeof accessGroups === "number") {
    hasPermission = userAccessGroup <= accessGroups;
  } else {
    hasPermission = accessGroups.includes(userAccessGroup);
  }

  if (!hasPermission && hideChildren) {
    return <div className="noAccess" />;
  }

  if (!hasPermission) {
    return (
      <ErrorPage
        variant="no-access"
        description="You do not have the rights to see this page!"
        buttonText="Go Back"
        onButtonClick={navigateBack}
      />
    );
  }

  return children;
};

export default RequiresAccessGroupWrapper;
