import { gql } from "@apollo/client";

export const GET_REQUEST_ROWS = gql`
  query findRequestRows($sessionID: String!, $limit: Int!, $cursorId: Int!) {
    findRequestRows(sessionId: $sessionID, limit: $limit, cursorId: $cursorId) {
      requestRows {
        id
        index
        source
        type
        tool_id
        image
        state
        time
        description
        details
        error
        negative
      }
    }
  }
`;

export const SUBSCRIBTION_TO_NEW_ROWS = gql`
  subscription subscribeToNewRows($sessionId: String!) {
    subscribeToNewRows(sessionId: $sessionId) {
      id
      index
      source
      type
      tool_id
      image
      state
      time
      description
      details
      error
      negative
    }
  }
`;

export const FIND_REQUEST_DETAIL = gql`
  query findRequestDetail($id: Int!, $index: Int!) {
    findRequestDetail(id: $id, index: $index)
  }
`;

export const GET_DEBUG_SESSION_BY_ID = gql`
  query getDebugSessionById($sessionId: String!) {
    getDebugSessionById(sessionId: $sessionId)
  }
`;

export default GET_REQUEST_ROWS;
