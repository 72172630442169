import { Paper, Typography } from "@mui/material";
import CompareSimpleInput from "../CompareSimpleInput";
import CompareFilterOnly from "./tag/CompareFilterOnly";
import ComparePlaceholders, {
  PlaceholderChanges,
  ArrayListVariables,
} from "./tag/ComparePlaceholders";

interface TagData {
  trigger?: {
    name: string;
  };
  hitType?: string;
  placeholder?: PlaceholderChanges;
  no_consent_placeholder?: PlaceholderChanges;
  arraylist_variables?: ArrayListVariables;
}

interface CompareTagProps {
  old_tagData: TagData;
  new_tagData: TagData;
  tagID: number;
}
/**
 * The CompareTag component
 * @param {CompareTagProps} props
 * @returns a CompareTag component
 */
const CompareTag = ({ old_tagData, new_tagData, tagID }: CompareTagProps): JSX.Element => {
  const {
    trigger: oldTrigger,
    hitType: oldHitType,
    placeholder: oldPlaceholder,
    no_consent_placeholder: oldNoConsentPlaceholder,
    arraylist_variables: oldArrayListVariables,
  } = old_tagData;
  const {
    trigger: newTrigger,
    hitType: newHitType,
    placeholder: newPlaceholder,
    no_consent_placeholder: newNoConsentPlaceholder,
    arraylist_variables: newArrayListVariables,
  } = new_tagData;

  const triggerShouldBeRendered =
    typeof oldTrigger !== "undefined" &&
    typeof newTrigger !== "undefined" &&
    JSON.stringify(oldTrigger) !== JSON.stringify(newTrigger);

  const hitTypeShouldBeRendered =
    typeof oldHitType !== "undefined" &&
    typeof newHitType !== "undefined" &&
    oldHitType !== newHitType;

  const placeholderShouldBeRendered =
    typeof oldPlaceholder !== "undefined" &&
    typeof newPlaceholder !== "undefined" &&
    JSON.stringify(oldPlaceholder) !== JSON.stringify(newPlaceholder);

  const noConsentPlaceholderShouldBeRendered =
    typeof oldNoConsentPlaceholder !== "undefined" &&
    typeof newNoConsentPlaceholder !== "undefined" &&
    JSON.stringify(oldNoConsentPlaceholder) !== JSON.stringify(newNoConsentPlaceholder);

  const arrayListVariablesWhichShouldBeRenderedSeparately = Object.keys(
    oldArrayListVariables || {},
  ).filter((key) => oldPlaceholder?.[key] === undefined);

  const arrayListVariablesShouldBeRendered =
    arrayListVariablesWhichShouldBeRenderedSeparately.length > 0;

  return (
    <>
      {triggerShouldBeRendered && (
        <CompareSimpleInput
          inputName="trigger"
          leftLabel="Old Trigger Name"
          rightLabel="New Trigger Name"
          leftValue={oldTrigger.name}
          rightValue={newTrigger.name}
        />
      )}
      {hitTypeShouldBeRendered && (
        <CompareSimpleInput
          inputName="hitType"
          leftLabel="Old Hit Type"
          rightLabel="New Hit Type"
          leftValue={oldHitType}
          rightValue={newHitType}
        />
      )}
      {arrayListVariablesShouldBeRendered &&
        arrayListVariablesWhichShouldBeRenderedSeparately.map((shortId) => {
          return (
            <CompareFilterOnly
              key={shortId}
              shortId={shortId}
              oldArrayListVariables={oldArrayListVariables}
              newArrayListVariables={newArrayListVariables}
            />
          );
        })}
      {placeholderShouldBeRendered && (
        <>
          <Typography variant="pageTitle" sx={{ mt: 2 }}>
            Placeholder
          </Typography>
          <Paper variant="page">
            <ComparePlaceholders
              leftPlaceholderChanges={oldPlaceholder}
              rightPlaceholderChanges={newPlaceholder}
              leftArrayListVariables={oldArrayListVariables}
              rightArrayListVariables={newArrayListVariables}
              tagId={tagID}
            />
          </Paper>
        </>
      )}
      {noConsentPlaceholderShouldBeRendered && (
        <>
          <Typography variant="pageTitle" sx={{ mt: 2 }}>
            No Consent Placeholder
          </Typography>
          <Paper variant="page">
            <ComparePlaceholders
              leftPlaceholderChanges={oldNoConsentPlaceholder}
              rightPlaceholderChanges={newNoConsentPlaceholder}
              tagId={tagID}
            />
          </Paper>
        </>
      )}
    </>
  );
};

export default CompareTag;
