import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { KeyValuePair } from "libs/model/types";
import { useState } from "react";
import { get, useFormContext } from "react-hook-form";

export interface SelectSearchOption extends KeyValuePair<JSX.Element> {
  optionLabel: string;
}

interface SelectWithSearchProps {
  inputName: string;
  instanceName: string;
  options: SelectSearchOption[];
  limitTags?: number;
  fullWidth?: boolean;
  onChange?: (newValue: KeyValuePair["key"] | undefined) => void;
  defaultValue?: SelectSearchOption["key"];
  shouldUnregister?: boolean;
  helperText?: string;
}

const SelectWithSearch = ({
  inputName,
  options,
  instanceName,
  limitTags = -1,
  fullWidth = false,
  defaultValue,
  onChange,
  helperText = "",
}: SelectWithSearchProps) => {
  const instanceNameToLowerCase = instanceName.toLowerCase();
  const formContext = useFormContext();
  const [uiValue, setUiValue] = useState(defaultValue);

  const findOptionValue = (optionKey: SelectSearchOption["key"]) =>
    options.find((option) => option.key === optionKey)?.value || <span>Not Found</span>;

  const findOptionLabel = (optionKey: SelectSearchOption["key"]) =>
    options.find((option) => option.key === optionKey)?.optionLabel || "";

  const renderOption = (props: any, option: SelectSearchOption["key"]) => {
    return (
      <Box {...props} key={option}>
        {findOptionValue(option)}
      </Box>
    );
  };

  const formRegisterValues = formContext.register(inputName, {
    value: defaultValue,
  });

  const nameErrors = get(formContext.formState.errors, inputName);

  return (
    <Autocomplete
      {...formRegisterValues}
      value={uiValue}
      limitTags={limitTags}
      id={inputName}
      options={options.map((option) => option.key)}
      renderOption={renderOption}
      getOptionLabel={findOptionLabel}
      onChange={(e, value) => {
        formContext.setValue(inputName, value || undefined);
        setUiValue(value || undefined);
        if (onChange) onChange(value || undefined);
        if (value) formContext.clearErrors(inputName);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            "data-cy": inputName,
          }}
          error={!!nameErrors}
          label={instanceName}
          placeholder={`Search for ${instanceNameToLowerCase}`}
          helperText={nameErrors?.message ?? helperText}
        />
      )}
      sx={{ width: fullWidth ? "100%" : "auto" }}
    />
  );
};

export default SelectWithSearch;
