import CodeEditor from "../../input/editor/CodeEditor";
import CompareSimpleInput from "../CompareSimpleInput";

interface FunctionData {
  __typename?: string;
  id?: number;
  short_id?: string;
  name?: string;
  desc?: string;
  value?: string;
  maintained?: number;
  pluginID?: string;
}

interface ICompareFunctionProps {
  old_functionData: FunctionData;
  new_functionData: FunctionData;
}
/**
 * The CompareFunction component
 * @param {ICompareFunctionProps} props
 * @returns a CompareFunction component
 */
const CompareFunction = ({
  old_functionData: { name: old_name, value: old_value, desc: old_desc },
  new_functionData: { name: new_name, value: new_value, desc: new_desc },
}: ICompareFunctionProps): JSX.Element | null => {
  const nameShouldBeRendered =
    typeof old_name !== "undefined" && typeof new_name !== "undefined" && old_name !== new_name;

  const valueShouldBeRendered =
    typeof old_value !== "undefined" && typeof new_value !== "undefined" && old_value !== new_value;

  const descShouldBeRendered =
    typeof old_desc !== "undefined" && typeof new_desc !== "undefined" && old_desc !== new_desc;

  return (
    <>
      {nameShouldBeRendered && (
        <CompareSimpleInput
          inputName="name"
          leftLabel="Old Name"
          rightLabel="New Name"
          leftValue={old_name}
          rightValue={new_name}
        />
      )}
      {descShouldBeRendered && (
        <CompareSimpleInput
          inputName="desc"
          leftLabel="Old Description"
          rightLabel="New Description"
          leftValue={old_desc}
          rightValue={new_desc}
        />
      )}
      {valueShouldBeRendered && (
        <CodeEditor
          label="Javascript Code"
          name="value"
          contentOld={decodeURIComponent(old_value)}
          content={decodeURIComponent(new_value)}
          readOnly
        />
      )}
    </>
  );
};

export default CompareFunction;
