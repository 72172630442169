import { Paper } from "@mui/material";
import CompareBoolean from "../CompareBoolean";
import CompareConstant from "./constant/CompareConstant";
import CompareSimpleInput from "../CompareSimpleInput";
import { LogConstantData } from "./constant/types";

interface AccountConstantData {
  name?: string;
  value?: LogConstantData;
  maintained?: number;
  pii?: boolean;
  group?: string;
}

interface CompareAccountConstantProps {
  old_variableData: AccountConstantData;
  new_variableData: AccountConstantData;
}
/**
 * The CompareAccountConstant component
 * @param {CompareAccountConstantProps} props
 * @returns a CompareAccountConstant component
 */
const CompareAccountConstant = ({
  old_variableData: { name: old_name, group: old_group, pii: old_pii, value: old_value },
  new_variableData: { name: new_name, group: new_group, pii: new_pii, value: new_value },
}: CompareAccountConstantProps): JSX.Element => {
  const nameShouldBeRendered =
    typeof old_name !== "undefined" && typeof new_name !== "undefined" && old_name !== new_name;

  const groupShouldBeRendered =
    typeof old_group !== "undefined" && typeof new_group !== "undefined" && old_group !== new_group;

  const piiShouldBeRendered =
    typeof old_pii !== "undefined" && typeof new_pii !== "undefined" && old_pii !== new_pii;

  const valueShouldBeRendered =
    typeof old_value !== "undefined" && typeof new_value !== "undefined" && old_value !== new_value;

  return (
    <>
      {nameShouldBeRendered && (
        <CompareSimpleInput
          inputName="name"
          leftLabel="Old Name"
          rightLabel="New Name"
          leftValue={old_name}
          rightValue={new_name}
        />
      )}
      {groupShouldBeRendered && (
        <CompareSimpleInput
          inputName="group"
          leftLabel="Old Group"
          rightLabel="New Group"
          leftValue={old_group}
          rightValue={new_group}
        />
      )}
      {piiShouldBeRendered && (
        <CompareBoolean
          inputName="pii"
          leftLabel="Old Personal Identifiable Information"
          rightLabel="New Personal Identifiable Information"
          leftValue={old_pii}
          rightValue={new_pii}
        />
      )}
      {valueShouldBeRendered && (
        <Paper variant="outlined" sx={{ mt: 3, p: 2 }}>
          <CompareConstant leftValue={old_value} rightValue={new_value} />
        </Paper>
      )}
    </>
  );
};

export default CompareAccountConstant;
