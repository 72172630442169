import { ApolloCache, ApolloError, FetchResult } from "@apollo/client";
import { DocumentNode } from "graphql";
import CLIENT from "libs/business/apollo";
import getRoutePath from "libs/business/routes/routes";
import { NavigateFunction } from "react-router-dom";
import TService from "../../model/TService";
import { notifyError, notifySuccess } from "../notification/notification";

type TRefetchQuerie = {
  query: DocumentNode;
  context: {
    clientName: string;
  };
};

// eslint-disable-next-line import/prefer-default-export
export const getCreateOptions = (
  elementTypeCapitalCase: string,
  clientName: string,
  submitData: any,
  GET_ALL_QUERY: DocumentNode,
  navigate: NavigateFunction,
  optionals?: {
    refetchQueriesArg?: TRefetchQuerie[];
    customOnComplete?: (response: any) => void;
    customNavigationPath?: string;
  },
) => {
  const elementTypeLowerCase = elementTypeCapitalCase.toLowerCase(); // eg. function
  const elementListTypeLowerCase = `${elementTypeLowerCase}s`; // eg. functions
  const elementCreateType = `create${elementTypeCapitalCase}`; //  eg. createFunction
  let refetchQueries: TRefetchQuerie[] = [];
  if (optionals?.refetchQueriesArg) {
    refetchQueries = optionals.refetchQueriesArg;
  }
  return {
    variables: submitData,
    context: { clientName },
    onCompleted: (response: any) => {
      const newPath = getRoutePath(
        clientName.toLowerCase() as TService,
        `${elementTypeLowerCase}_id`,
        {
          id: response[elementCreateType].id,
        },
      );
      if (optionals?.customOnComplete) optionals.customOnComplete(response);
      navigate(optionals?.customNavigationPath || newPath, { replace: true });
      notifySuccess(`${elementTypeCapitalCase} created`);
    },
    update(cache: ApolloCache<any>, response: FetchResult) {
      if (!response.data) {
        return;
      }
      const queryCache = CLIENT.readQuery({
        query: GET_ALL_QUERY,
      });
      if (!queryCache) {
        return;
      }
      CLIENT.writeQuery({
        query: GET_ALL_QUERY,
        data: {
          [elementListTypeLowerCase]: [
            response.data[elementCreateType],
            ...queryCache[elementListTypeLowerCase],
          ],
        },
      });
    },
    refetchQueries,
    onError: (error: ApolloError) => {
      notifyError(error.message);
    },
  };
};

export const getCreateOptionsWithShortIDValidation = (
  elementTypeCapitalCase: string,
  clientName: string,
  submitData: any,
  GET_ALL_QUERY: DocumentNode,
  GET_SHORT_IDS_QUERY: DocumentNode,
  navigate: NavigateFunction,
) => {
  const elementTypeLowerCase = elementTypeCapitalCase.toLowerCase(); // eg. function
  const elementListTypeLowerCase = `${elementTypeLowerCase}s`; // eg. functions
  const elementCreateType = `create${elementTypeCapitalCase}`; //  eg. createFunction
  return {
    variables: submitData,
    context: { clientName },
    onCompleted: (response: any) => {
      const newPath = getRoutePath(
        clientName.toLowerCase() as TService,
        `${elementTypeLowerCase}_id`,
        {
          id: response[elementCreateType].id,
        },
      );
      navigate(newPath, { replace: true });
      notifySuccess(`${elementTypeCapitalCase} created`);
    },
    update(cache: ApolloCache<any>, response: FetchResult) {
      if (!response.data) {
        return;
      }
      const allQueryCache = CLIENT.readQuery({
        query: GET_ALL_QUERY,
      });
      const shortIDQueryCache = CLIENT.readQuery({
        query: GET_SHORT_IDS_QUERY,
      });
      if (allQueryCache) {
        CLIENT.writeQuery({
          query: GET_ALL_QUERY,
          data: {
            [elementListTypeLowerCase]: [
              response.data[elementCreateType],
              ...allQueryCache[elementListTypeLowerCase],
            ],
          },
        });
      }
      if (shortIDQueryCache) {
        const shortIDType = `${elementTypeLowerCase}ShortIds`;
        const newShortID = response.data[elementCreateType].short_id;
        const newShortIDCache = {
          _typename: "ShortIds",
          shortIds: [newShortID, ...shortIDQueryCache[shortIDType].shortIds],
        };
        CLIENT.writeQuery({
          query: GET_SHORT_IDS_QUERY,
          data: {
            [shortIDType]: newShortIDCache,
          },
        });
      }
    },
    onError: (error: ApolloError) => {
      notifyError(error.message);
    },
  };
};
