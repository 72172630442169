import { GET_ALL_TOOL_NAMES } from "apps/jtm/business/tool/queries";
import CLIENT from "libs/business/apollo";
import * as yup from "yup";

export const CA_INPUT_NAME_CONTAINER_IDS = "containerIDs";
export const CA_INPUT_NAME_TOOL_ID = "pluginID";

export const CA_INPUT_NAME_NAME = "name";
export const CA_INPUT_NAME_TAG_TYPES_TO_GENERATE = "tagTypesToGenerate";
export const CA_INPUT_NAME_CREATE_TAGS = "createTags";
export const CA_INPUT_NAME_CONSTANTS = "constants";

const CA_STEP_ONE_SCHEMA = yup.object({
  [CA_INPUT_NAME_TOOL_ID]: yup
    .string()
    .required("You need to select a tool before you can continue."),
  [CA_INPUT_NAME_CONTAINER_IDS]: yup
    .array(yup.number())
    .min(1, "You must select at least one container!"),
});

const CA_STEP_TWP_SCHEMA = yup.object({
  [CA_INPUT_NAME_NAME]: yup
    .string()
    .required()
    .max(150, "The name must be shorter than 150 characters.")
    .test("Name Check", "This name is already in use!", async function test(value) {
      let allNames = [];
      try {
        const result = await CLIENT.query({
          query: GET_ALL_TOOL_NAMES,
          context: { clientName: "JTM" },
        });
        if (result.error) return true;

        allNames = result.data.tools.map((tool: any) => tool.name);
      } catch (error) {
        return true;
      }
      return !allNames.includes(value);
    }),
  [CA_INPUT_NAME_TAG_TYPES_TO_GENERATE]: yup.array().of(yup.string()),
  [CA_INPUT_NAME_CONSTANTS]: yup.object({}),
  [CA_INPUT_NAME_CREATE_TAGS]: yup.boolean(),
});

export const CA_FORM_SCHEMA = [CA_STEP_ONE_SCHEMA, CA_STEP_TWP_SCHEMA];
