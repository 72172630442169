import { GetLogByIdQueryDefinition, GET_LOG_BY_ID } from "apps/jtm/business/log/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import PopUpWindow from "libs/ui/components/feedback/modal/PopUpWindow";
import { FormProvider, useForm } from "react-hook-form";
import CompareChanges from "./CompareChanges";

interface ILogModalProps {
  open: boolean;
  handleClose: () => void;
  id: number;
}
/**
 * The LogModal component
 * @param {ILogModalProps} props
 * @returns a LogModal component
 */
const LogModal = ({ open, handleClose, id }: ILogModalProps): JSX.Element | null => {
  const methods = useForm();

  const getLogResponse = useSimpleFetching<GetLogByIdQueryDefinition>({
    query: GET_LOG_BY_ID,
    context: "JTM",
    queryArguments: { id },
  });

  if (getLogResponse === false) return <PageLoadingSpinner />;

  const logData = getLogResponse.changeLog;

  return (
    <PopUpWindow
      open={open}
      handleClose={handleClose}
      title={`${logData.element}: ${logData.name}`}>
      <FormProvider {...methods}>
        <form>
          {/* {showReleaseLogs && (
            <Page title="Update Information">
              <ReleaseLogs releaseLog={logData.release_log} />
            </Page>
          )} */}
          <CompareChanges logData={logData} />
        </form>
      </FormProvider>
    </PopUpWindow>
  );
};
export default LogModal;
