import { Container, Typography } from "@mui/material";
import { GET_USED_LANGUAGES } from "apps/cmp/business/languages/queries";
import {
  ConfigTextContenti18n,
  GetTranslationsQueryDefinition,
  GET_TRANSLATIONS,
  UPDATE_TRANSLATIONS,
} from "apps/cmp/business/translations/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import MultiSelectWrapper from "libs/ui/components/input/MultiSelectWrapper";
import Page from "libs/ui/components/surfaces/page/Page";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { emptyTranslationPopUpConfig } from "../../components/translation/business";
import scss from "../../components/translation/Translation.module.scss";
import TranslationField from "../../components/translation/TranslationField";
import TranslationPopUp from "../../components/translation/TranslationPopUp";
import {
  FetchedLanguage,
  TranslationCollection,
  TranslationFormData,
  TranslationPopUpConfig,
} from "../../components/translation/types";

/**
 * The Translation component
 * @returns a Translation component
 */
const Translation = (): JSX.Element => {
  // HOOKS
  const [selectedLanguages, setSelectedLanguages] = useState<number[]>([]);
  const methods = useForm();

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpConfig, setPopUpConfig] = useState<TranslationPopUpConfig>(
    emptyTranslationPopUpConfig,
  );

  // QUERIES
  const getLanguagesQueryState = useSimpleFetching({
    query: GET_USED_LANGUAGES,
    context: "CMP",
  });

  const getTranslationsQueryState = useSimpleFetching<GetTranslationsQueryDefinition>({
    query: GET_TRANSLATIONS,
    context: "CMP",
    queryArguments: {
      languages:
        getLanguagesQueryState === false
          ? []
          : getLanguagesQueryState.getUsedLanguages.map(
              (translation: { id: number }) => translation.id,
            ),
    },
  });

  const [updateTranslation] = useMutationWrapper(UPDATE_TRANSLATIONS);

  // VARIABLES
  const multiSelectName = "selectedLanguages";

  const openPopUp = (config: TranslationPopUpConfig) => {
    setPopUpConfig(config);
    setPopUpOpen(true);
  };

  const onSelectionChange = (data: number[]) => {
    setSelectedLanguages(data);
  };

  const renderTableHeader = () => {
    return (
      <div className={`${scss.gridContainer} ${scss.gridHead}`}>
        <Typography variant="h4">Default (English)</Typography>
        {fetchedLanguages
          .filter((language: any) => selectedLanguages.includes(language.id))
          .map((language: any) => {
            return (
              <Typography key={language.id} variant="h4" textAlign="center">
                {language?.text_content?.text}
              </Typography>
            );
          })}
      </div>
    );
  };

  const renderTable = () => {
    return fetchedTranslations.map((translation: TranslationCollection) => {
      return (
        <TranslationField
          key={translation.id}
          onClick={openPopUp}
          languagesToTranslate={fetchedLanguages
            .filter((language: any) => selectedLanguages.includes(language.id))
            .map((language: any) => {
              return {
                id: language.id,
                name: language?.text_content?.text,
                iso: language?.iso,
              };
            })}
          translationCollection={translation}
        />
      );
    });
  };

  const formSubmitHandler = (data: TranslationFormData) => {
    delete data[multiSelectName]; // delete form values of MultiSelect

    const records = [];
    for (const languageId in data) {
      if (Object.prototype.hasOwnProperty.call(data, languageId)) {
        const language = data[languageId];
        for (const textContentId in language) {
          if (Object.prototype.hasOwnProperty.call(language, textContentId)) {
            const translation = language[textContentId];
            records.push(translation);
          }
        }
      }
    }
    updateTranslation({
      variables: { translations: records },
      context: { clientName: "CMP" },
      onCompleted: () => {
        notifySuccess("Update Successful!");
      },
      onError: (error) => {
        notifyError(error.message);
      },
    });
  };

  if (getLanguagesQueryState === false || getTranslationsQueryState === false) {
    return <PageLoadingSpinner />;
  }

  const fetchedLanguages: FetchedLanguage[] = getLanguagesQueryState.getUsedLanguages;
  const fetchedTranslations =
    getTranslationsQueryState.getAllTextContentWithTranslationsByLanguages;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Page title="Translation">
          <MultiSelectWrapper
            name={multiSelectName}
            label="Languages"
            options={fetchedLanguages.map((language: any) => {
              return { id: language?.id, name: language?.text_content?.text };
            })}
            value={selectedLanguages}
            onChange={onSelectionChange}
            maxSelection={3}
          />
          <TranslationPopUp
            open={popUpOpen}
            config={popUpConfig}
            onClose={() => {
              setPopUpOpen(false);
            }}
          />
          {renderTableHeader()}
          {renderTable()}
        </Page>
        <Container>
          <SaveCancelBar />
        </Container>
      </form>
    </FormProvider>
  );
};

export default Translation;
