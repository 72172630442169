import { Typography } from "@mui/material";
import ExcludeTrigger from "./ExludeTrigger";
import style from "./Trigger.module.scss";
import { TriggerCondition } from "./types";

interface Trigger {
  name: string;
  conditions: TriggerCondition[][];
}
interface IExcludeTriggersProps {
  triggers: Trigger[];
}

export default function ExcludeTriggers({ triggers }: IExcludeTriggersProps) {
  const excludeTriggers = triggers.map((excludeTrigger) => {
    return (
      <ExcludeTrigger
        key={excludeTrigger.name}
        name={excludeTrigger.name}
        conditions={excludeTrigger.conditions}
      />
    );
  });
  return (
    <div className={style.excludeTriggers}>
      <Typography className={style.excludeTriggersTitle}>Exclude Triggers</Typography>
      {excludeTriggers}
    </div>
  );
}
