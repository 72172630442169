import { IdAndName } from "libs/model/types";
import { RowData } from "../SessionRow/type";
import { ConnectorFilterElement } from "./FilterElements/ConnectorFilter";
import { RowFilterConfig } from "./types";

const DO_NOT_FILTER = true;

const filterRowData = (
  rowData: RowData[],
  filterConfig: RowFilterConfig,
  availableConnectors: ConnectorFilterElement[],
): RowData[] => {
  const filter = new RowFilter(rowData, filterConfig, availableConnectors);
  return filter.apply();
};

class RowFilter {
  private rowData: RowData[];

  private filterConfig: RowFilterConfig;

  private availableConnectors: ConnectorFilterElement[];

  constructor(
    rowData: RowData[],
    filterConfig: RowFilterConfig,
    availableConnectors: ConnectorFilterElement[],
  ) {
    this.rowData = rowData;
    this.filterConfig = filterConfig;
    this.availableConnectors = availableConnectors;
  }

  apply() {
    this.filterErrors();
    this.filterInfos();
    this.filterSource();
    this.filterNegatives();
    this.filterConnectors();
    this.filterDescription();
    this.sortByTime();
    return this.rowData;
  }

  private filterErrors() {
    this.rowData = this.rowData.filter((row) =>
      this.filterConfig.showErrorsOnly ? row.error : DO_NOT_FILTER,
    );
  }

  private filterInfos() {
    this.rowData = this.rowData.filter((row) =>
      this.filterConfig.showInfo ? DO_NOT_FILTER : row.type !== "info",
    );
  }

  private filterSource() {
    this.rowData = this.rowData.filter((row) =>
      this.filterConfig.source ? this.filterConfig.source === row.source : DO_NOT_FILTER,
    );
  }

  private filterNegatives() {
    this.rowData = this.rowData.filter((row) =>
      this.filterConfig.showNegatives ? DO_NOT_FILTER : row.negative === false,
    );
  }

  private sortByTime() {
    this.rowData = this.rowData.sort((e1, e2) => {
      if (e1.time === e2.time) return 0;
      return e1.time > e2.time ? -1 : 1;
    });
  }

  private filterConnectors() {
    this.rowData = this.rowData.filter((row) => {
      const connectorFilterExists = this.filterConfig.connectorIds.length > 0;
      if (!connectorFilterExists) return DO_NOT_FILTER;

      const isTagType = row.type === "tag";
      if (isTagType) {
        const notExistingId = -1;
        let availableConnectorId = this.availableConnectors.find((connector) => {
          return connector.shortId === row.tool_id;
        })?.id;

        if (availableConnectorId === undefined) {
          availableConnectorId = notExistingId;
        }

        return this.filterConfig.connectorIds.includes(availableConnectorId);
      }
      return false;
    });
  }

  private filterDescription() {
    if (this.filterConfig.description !== "") {
      const desc = this.filterConfig.description.toLocaleLowerCase();
      this.rowData = this.rowData.filter((row) => {
        return (
          row.description.toLocaleLowerCase().includes(desc) ||
          row.state.toLocaleLowerCase().includes(desc)
        );
      });
    }
  }
}

export default filterRowData;
