import { OperationVariables, TypedDocumentNode, useQuery } from "@apollo/client";
import { ClientName } from "../apollo";
import { notifyError } from "../notification/notification";

interface OperationConfig<TVariables = OperationVariables> {
  clientName: ClientName;
  disableCache?: boolean;
  variables?: TVariables;
  customOnError?: (msg: string) => void;
}

/**
 * This Hook is a wrapper for the useQuery hook.
 * It should simplify the use and the error handling is pre-configured.
 */
function useTypedDocumentQueryWrapper<TData = any, TVariables = OperationVariables>(
  documentNode: TypedDocumentNode<TData, TVariables>,
  { clientName, variables, customOnError, disableCache = false }: OperationConfig<TVariables>,
) {
  const { data } = useQuery(documentNode, {
    context: { clientName },
    variables,
    onError: (e) => {
      if (customOnError) {
        customOnError(e.message);
      } else {
        notifyError(e.message);
        throw new Error(e.message);
      }
    },
    fetchPolicy: disableCache ? "no-cache" : undefined,
  });

  return data;
}

export default useTypedDocumentQueryWrapper;
