import { deepCopyObjectWithoutFunction } from "libs/business/utils/objectFunctions";
import SelectWrapper from "libs/ui/components/input/SelectWrapper";
import React, { useCallback } from "react";
import { Source } from "../../SessionRow/type";
import { RowFilterConfig } from "../types";

interface SourceFilterProps {
  filter: RowFilterConfig;
  setFilter: React.Dispatch<React.SetStateAction<RowFilterConfig>>;
}
/**
 * The SourceFilter component
 * @param {SourceFilterProps} props
 * @returns a SourceFilter component
 */
const SourceFilter = ({ filter, setFilter }: SourceFilterProps): JSX.Element => {
  const onSourceSelect = useCallback(
    (value: string | number) => {
      setFilter((currentFilter) => {
        const newFilter: RowFilterConfig = deepCopyObjectWithoutFunction(currentFilter);
        if (value === "all") {
          newFilter.source = undefined;
        } else {
          newFilter.source = value as Source;
        }
        return newFilter;
      });
    },
    [setFilter],
  );

  return (
    <SelectWrapper
      name="Source"
      label="Source Filter"
      size="medium"
      options={[
        { key: "backend", value: "Server-Side" },
        { key: "frontend", value: "Client-Side" },
        { key: "hybrid", value: "Hybrid" },
        { key: "rawdata", value: "Rawdata" },
        { key: "all", value: "All" },
      ]}
      onChange={onSourceSelect}
      style={{ margin: 0, width: "130px" }}
      value={filter.source || "all"}
    />
  );
};

export default SourceFilter;
