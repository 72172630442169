export const REFRESH_TOKEN_ERROR_MESSAGE = "Error while trying to refresh token";

class RefreshTokenError extends Error {
  constructor() {
    super(REFRESH_TOKEN_ERROR_MESSAGE);
    this.name = "RefreshTokenError";
  }
}

export default RefreshTokenError;
