import { GET_VARIABLE_TEMPLATE_FIELDS } from "apps/jtm/business/variable/queries";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { decodeHTMLEntities } from "libs/business/utils/stringManipulation";
import CheckboxWrapper from "libs/ui/components/input/CheckboxWrapper";
import SelectWrapper from "libs/ui/components/input/SelectWrapper";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";

interface IVariableTemplateProps {
  template: string;
  placeholder: { [key: string]: any } | string;
}

const VariableTemplateFields = ({ template, placeholder }: IVariableTemplateProps) => {
  const [getTemplateFields, queryState] = useQueryWrapper(GET_VARIABLE_TEMPLATE_FIELDS);

  let returnFields: any = [];

  if (!queryState.called) {
    getTemplateFields({
      variables: { pluginID: template },
      context: { clientName: "JTM" },
    });
  } else if (queryState.data) {
    const fields = {
      ...queryState.data.variableTemplateFields.fields,
    };

    returnFields = Object.keys(fields).map((key, index) => {
      const helperText = decodeHTMLEntities(fields[key].description);
      switch (fields[key].type) {
        case "SELECT": {
          let selectValue: string;
          if (typeof placeholder === "object") {
            selectValue = placeholder[key];
          } else {
            // eslint-disable-next-line prefer-destructuring
            selectValue = fields[key].value[0];
          }

          return (
            <SelectWrapper
              key={key}
              name={`placeholder.${key}`}
              label={fields[key].name}
              value={selectValue}
              helperText={helperText}
              options={fields[key].value.map((value: string) => {
                return {
                  key: value,
                  value,
                };
              })}
            />
          );
        }
        case "TEXT": {
          let value: string;
          if (typeof placeholder === "object") {
            value = placeholder[key];
          } else {
            value = fields[key].value ?? "";
          }
          return (
            <TextFieldWrapper
              key={key}
              name={`placeholder.${key}`}
              label={fields[key].name}
              value={value}
              helperText={helperText}
            />
          );
        }
        case "BOOL": {
          let value: boolean;
          if (typeof placeholder === "object") {
            value = placeholder[key];
          } else {
            // eslint-disable-next-line prefer-destructuring
            value = fields[key].value ?? false;
          }
          return (
            <CheckboxWrapper
              key={key}
              name={`placeholder.${key}`}
              label={fields[key].name}
              value={value}
              helperText={helperText}
            />
          );
        }
        default:
          return <div />;
      }
    });
  }

  return returnFields;
};

export default VariableTemplateFields;
