import { FIND_REQUEST_DETAIL } from "apps/monitor/business/queries";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { notifyError } from "libs/business/notification/notification";
import LoadComponentSpinner from "libs/ui/components/feedback/loading-spinner/LoadComponentSpinner";
import { DetailData } from "../type";
import ErrorDetails from "./ErrorDetails";
import TagDetails from "./TagDetails";

interface RowDetailsProps {
  rowId: number;
  rowIndex: number;
  isNegative: boolean;
}
/**
 * The RowDetails component
 * @param {RowDetailsProps} props
 * @returns a RowDetails component
 */
const RowDetails = ({ rowId, rowIndex, isNegative }: RowDetailsProps): JSX.Element => {
  const [getDetails, getDetailsQueryState] = useQueryWrapper(FIND_REQUEST_DETAIL);

  if (!getDetailsQueryState.called) {
    getDetails({ context: { clientName: "MONITOR" }, variables: { id: rowId, index: rowIndex } });
  }

  const queryFetchingNotCompleted = !getDetailsQueryState.called || getDetailsQueryState.loading;

  if (getDetailsQueryState.error) {
    notifyError(getDetailsQueryState.error.message);
    return <div />;
  }

  if (queryFetchingNotCompleted || !getDetailsQueryState.data) {
    return <LoadComponentSpinner />;
  }

  const data: DetailData = getDetailsQueryState.data.findRequestDetail;

  if (data.type === "error") return <ErrorDetails detailData={data} />;

  return <TagDetails detailData={data} isNegative={isNegative} />;
};

export default RowDetails;
