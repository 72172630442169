import ConditionLine from "./ConditionLine";
import style from "./Trigger.module.scss";
import { TriggerCondition } from "./types";

interface IConditionGroupProps {
  group: TriggerCondition[];
}

export default function ConditionGroup({ group }: IConditionGroupProps) {
  const groupDOM = group.map((line) => {
    return (
      <ConditionLine
        key={`${line.variable_value}${line.operator}${line.variable}`}
        variable={line.variable}
        operator={line.operator}
        conditionValue={line.value}
        variableValue={line.variable_value}
        executed={line.executed}
      />
    );
  });
  return (
    <div className={style.conditionGroup}>
      <div />
      <div className={style.lines}>{groupDOM}</div>
    </div>
  );
}
