import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup, IconButton, Stack, Typography } from "@mui/material";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";

import OperatorSelect from "./OperatorSelect";
import VariableSelect from "./VariableSelect";

export interface ICondition {
  variable: string;
  operator: string;
  value: string;
}

export interface IConditionProps {
  rootName: string;
  variable: string;
  operator: string;
  value: string;
  orButtonDisabled: boolean;
  isFirstAndCondition: boolean;
  addCondition: () => void;
  addOrCondition: () => void;
  removeCondition: () => void;
  readOnly?: boolean;
}

export default function Condition({
  rootName,
  variable,
  operator,
  value,
  orButtonDisabled,
  isFirstAndCondition,
  addCondition,
  addOrCondition,
  removeCondition,
  readOnly = false,
}: IConditionProps) {
  const { setMaintained } = useMaintained();

  const onValueChange = (newValue: string | number) => {
    if (value !== newValue) {
      setMaintained(false);
    }
  };

  return (
    <Stack direction="row" alignItems="start" flexWrap="wrap" gap={2} sx={{ flex: 1 }}>
      {!isFirstAndCondition && <Typography color="secondary.dark">AND</Typography>}
      <Box sx={{ flex: 1, minWidth: "max-content" }}>
        <VariableSelect
          selected={variable}
          name={`${rootName}.variable`}
          readOnly={readOnly}
          required
        />
      </Box>
      <Box sx={{ flex: 1, minWidth: "max-content" }}>
        <OperatorSelect
          selected={operator}
          name={`${rootName}.operator`}
          readOnly={readOnly}
          required
          fixedWidth={false}
        />
      </Box>

      <Box sx={{ flex: 1, minWidth: "max-content" }}>
        <TextFieldWrapper
          name={`${rootName}.value`}
          label="Value *"
          variant="outlined"
          size="small"
          value={value}
          style={{ margin: 0 }}
          readonly={readOnly}
          onChange={onValueChange}
        />
      </Box>
      {!readOnly && (
        <div style={{ minWidth: "174px" }}>
          <ButtonGroup variant="outlined" sx={{ mr: 2 }} color="primary">
            <Button onClick={addCondition} data-cy="addAndCondition">
              AND
            </Button>
            <Button disabled={orButtonDisabled} onClick={addOrCondition} data-cy="addOrCondition">
              OR
            </Button>
          </ButtonGroup>
          <IconButton
            sx={{
              borderRadius: "4px",
              marginLeft: 2,
            }}
            onClick={removeCondition}
            data-cy="deleteCondition">
            <FontAwesomeIcon icon={faTrashCan} size="sm" />
          </IconButton>
        </div>
      )}
    </Stack>
  );
}
