import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@mui/material";
import ConstantEditTable from "apps/jtm/components/constants/ConstantEditTable";
import { addMissingOptionsInXFromY, getAccounts, transformLogData } from "./business";
import { LogConstantData } from "./types";

interface CompareConstantProps {
  leftValue: LogConstantData;
  rightValue: LogConstantData;
}
/**
 * The CompareConstant component
 * @param {CompareConstantProps} props
 * @returns a CompareConstant component
 */
const CompareConstant = ({ leftValue, rightValue }: CompareConstantProps): JSX.Element => {
  const leftValueWithNewOptions = addMissingOptionsInXFromY(leftValue, rightValue);
  const rightValueWithNewOptions = addMissingOptionsInXFromY(rightValue, leftValueWithNewOptions);

  const transformedLeftValue = transformLogData(leftValueWithNewOptions);
  const transformedRightValue = transformLogData(rightValueWithNewOptions);

  const leftContainerIds = getAccounts(transformedLeftValue);
  const rightContainerIds = getAccounts(transformedRightValue);

  const environmentColumnsNeeded =
    leftValue?.options?.[0]?.environment !== undefined ||
    rightValue?.options?.[0]?.environment !== undefined;

  return (
    <Stack direction="row" spacing={4} alignItems="center" justifyContent="space-evenly">
      <ConstantEditTable
        constantData={transformedLeftValue}
        submitName="constants"
        selectedContainerIds={leftContainerIds}
        environmentColumnsNeeded={environmentColumnsNeeded}
        readonly
      />
      <FontAwesomeIcon icon={faArrowRight} />
      <ConstantEditTable
        constantData={transformedRightValue}
        submitName="constants"
        selectedContainerIds={rightContainerIds}
        environmentColumnsNeeded={environmentColumnsNeeded}
        readonly
      />
    </Stack>
  );
};

export default CompareConstant;
