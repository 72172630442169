import { useState, useEffect } from "react";

export const useLocalStorage = <T>(key: string, defaultValue: T | (() => T)) => {
  return useStorage(key, defaultValue, localStorage);
};

export const useSessionStorage = <T>(key: string, defaultValue: T | (() => T)) => {
  return useStorage(key, defaultValue, sessionStorage);
};

const useStorage = <T>(key: string, defaultValue: T | (() => T), storageObject: Storage) => {
  const [value, setValue] = useState<T>(() => {
    const jsonValue = storageObject.getItem(key);

    if (jsonValue !== null) {
      return JSON.parse(jsonValue);
    }

    if (typeof defaultValue === "function") {
      return (defaultValue as () => T)();
    }
    return defaultValue;
  });

  useEffect(() => {
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as [typeof value, typeof setValue];
};
