import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const NotificationContainer = (): JSX.Element => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export const notifySuccess = (message: string) => {
  toast.success(message);
};

export const notifyWarning = (message: string) => {
  toast.warn(message);
};

export const notifyInfo = (message: string) => {
  toast.info(message);
};

export const notifyError = (message: string) => {
  toast.error(message);
};

export default NotificationContainer;
