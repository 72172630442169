import JSONXMLBox from "../../../json_xml_box/JSONXMLBox";
import { TagDetailData } from "../../type";

interface ResponseTabContentProps {
  detailData: TagDetailData;
}
/**
 * The ResponseTabContent component
 * @param {ResponseTabContentProps} props
 * @returns a ResponseTabContent component
 */
const ResponseTabContent = ({ detailData }: ResponseTabContentProps): JSX.Element => {
  return <JSONXMLBox name="Response" elements={detailData.response?.elements || []} />;
};

export default ResponseTabContent;
