import useIsSetupInitialized from "libs/business/hooks/useIsSetupInitialized";
import { Navigate } from "react-router-dom";
import ContainerCreate from "../container/ContainerCreate";

export default function InitCustomer() {
  const isSetupInitialized = useIsSetupInitialized();

  if (isSetupInitialized) {
    return <Navigate to="/" />;
  }
  return <ContainerCreate isSetupInitialized={false} />;
}
