import { createTheme, experimental_sx as sx, Theme, ThemeOptions } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import colorStyles from "assets/styles/colorPalette.module.scss";

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: "Barlow",
    fontWeightRegular: 500,
    fontWeightMedium: 500,
    h1: {
      fontSize: 32,
      letterSpacing: 0.1,
      lineHeight: "38px",
    },
    h2: {
      fontSize: 24,
      letterSpacing: 0.1,
      lineHeight: "36px",
    },
    h3: {
      fontSize: 20,
      letterSpacing: 0.1,
      lineHeight: "30px",
    },
    h4: {
      fontSize: 16,
      letterSpacing: 0.1,
      lineHeight: "24px",
    },
    h5: {
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: "20px",
    },
    h6: {
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: "20px",
      fontWeight: 700,
    },
    body1: {
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: "20px",
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: "20px",
      fontWeight: 400,
    },
    pageTitle: {
      fontFamily: "Barlow",
      fontSize: 18,
      letterSpacing: 0.1,
      fontWeight: 300,
      lineHeight: "36px",
      color: colorStyles.grey1,
      marginLeft: "8px",
    },
    bodyRegular16: { fontFamily: "Barlow", fontWeight: 400, fontSize: 16, lineHeight: "24px" },
    bodyRegular14: { fontFamily: "Barlow", fontWeight: 400, fontSize: 14, lineHeight: "20px" },
    bodyRegular12: { fontFamily: "Barlow", fontWeight: 400, fontSize: 12, lineHeight: "18px" },
    bodyMedium16: { fontFamily: "Barlow", fontWeight: 500, fontSize: 16, lineHeight: "24px" },
    bodyMedium14: { fontFamily: "Barlow", fontWeight: 500, fontSize: 14, lineHeight: "20px" },
    bodyMedium12: { fontFamily: "Barlow", fontWeight: 500, fontSize: 12, lineHeight: "18px" },
    titleMedium: { fontFamily: "Barlow", fontWeight: "500", fontSize: "20px", lineHeight: "30px" },
  },
  palette: {
    background: {
      default: colorStyles.backgroundDefault,
      paper: colorStyles.backgroundPaper,
    },
    text: {
      primary: colorStyles.textPrimary,
      secondary: colorStyles.textSecondary,
      disabled: colorStyles.textDisabled,
    },
    primary: {
      main: colorStyles.primaryMain,
      dark: colorStyles.primaryDark,
      light: colorStyles.primaryLight,
      contrastText: colorStyles.primaryContrastText,
    },
    secondary: {
      main: colorStyles.secondaryMain,
      light: colorStyles.secondaryLight,
      dark: colorStyles.secondaryDark,
      contrastText: colorStyles.secondaryContrastText,
    },
    success: {
      main: colorStyles.successMain,
      light: colorStyles.successLight,
      dark: colorStyles.successDark,
      contrastText: colorStyles.successContrastText,
    },
    error: {
      main: colorStyles.errorMain,
      light: colorStyles.errorLight,
      dark: colorStyles.errorDark,
      contrastText: colorStyles.errorContrastText,
    },
    warning: {
      main: colorStyles.warningMain,
      light: colorStyles.warningLight,
      dark: colorStyles.warningDark,
      contrastText: colorStyles.warningContrastText,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: sx({
          textDecoration: "none",
          color: "text.primary",
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: sx({
          borderColor: colorStyles.grey3,
          border: "none",
        }),
        columnHeaders: {
          backgroundColor: colorStyles.grey3,
          borderRadius: 0,
        },
        main: {
          border: `1px solid ${colorStyles.grey3}`,
          borderRadius: 4,
        },
        footerContainer: {
          border: "none",
          paddingBottom: 0,
        },
        cell: {
          cursor: "pointer",
        },
        cellContent: {
          cursor: "text",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: "1rem",
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        media: sx({ objectFit: "contain", padding: "20px", boxSizing: "border-box" }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          pageTitle: "h2",
        },
      },
      styleOverrides: {
        subtitle2: {
          fontSize: "0.8rem",
          opacity: 0.6,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: sx({ bgcolor: "primary.contrastText", color: "primary.main", boxShadow: "none" }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: sx({
          borderRadius: "4px",
          fontWeight: 400,
          textTransform: "none",
          boxShadow: "none",
        }),
        outlinedPrimary: {
          ":hover": {
            backgroundColor: `${colorStyles.primaryMain}15`,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: sx({}),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: sx({
          // bgcolor: "primary.contrastText",
          borderRadius: "4px",
        }),
      },
    },
    MuiPaper: {
      variants: [
        {
          props: {
            variant: "page",
          },
          style: {
            padding: "30px",
            margin: "4px",
            boxShadow: "0px 1px 4px rgba(69, 90, 100, 0.2)",
          },
        },
      ],
      styleOverrides: {
        // root: sx({}),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          // marginBottom: "20px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: sx({ typography: "h3" }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colorStyles.grey3,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colorStyles.primaryMain,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (min-width: 1px)": {
            paddingLeft: 0,
            paddingRight: 0,
          },
          minWidth: "100%",
        },
      },
    },
  },
};

const theme: Theme = createTheme(themeOptions);

export default theme;

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    page: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    pageTitle: React.CSSProperties;
    bodyRegular16: React.CSSProperties;
    bodyRegular14: React.CSSProperties;
    bodyRegular12: React.CSSProperties;
    bodyMedium16: React.CSSProperties;
    bodyMedium14: React.CSSProperties;
    bodyMedium12: React.CSSProperties;
    titleMedium: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    pageTitle?: React.CSSProperties;
    bodyRegular16: React.CSSProperties;
    bodyRegular14: React.CSSProperties;
    bodyRegular12: React.CSSProperties;
    bodyMedium16: React.CSSProperties;
    bodyMedium14: React.CSSProperties;
    bodyMedium12: React.CSSProperties;
    titleMedium: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    pageTitle: true;
    bodyRegular16: true;
    bodyRegular14: true;
    bodyRegular12: true;
    bodyMedium16: true;
    bodyMedium14: true;
    bodyMedium12: true;
    titleMedium: true;
  }
}
