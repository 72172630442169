import { gql } from "@apollo/client";

export const GET_JUSTIFICATIONS = gql`
  query Justifications {
    Justifications {
      id
      justificationid
      textcontent_textcontentid
      text_content {
        text
      }
    }
  }
`;

export default GET_JUSTIFICATIONS;
