import { Stack } from "@mui/material";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import React, { useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import Condition from "./Condition";
import { AndConditions } from "./types";

interface TriggerAndConditionsProps {
  rootName: string;
  andConditions: AndConditions;
  addOrCondition: () => void;
  removeOrCondition: () => void;
  readOnly?: boolean;
}
/**
 * The TriggerConditions component
 * @param {TriggerAndConditionsProps} props
 * @returns a TriggerConditions component
 */
const TriggerAndConditions = ({
  rootName,
  andConditions,
  addOrCondition,
  removeOrCondition,
  readOnly = false,
}: TriggerAndConditionsProps): JSX.Element => {
  const { setMaintained } = useMaintained();
  const { control, clearErrors } = useFormContext();
  const { fields, insert, replace, remove } = useFieldArray({
    control,
    name: rootName,
  });

  const removeEntry = (index: number) => {
    remove(index);
    setMaintained(false);
  };

  const addEntry = (index: number) => {
    const newEntry = {
      value: "",
      variable: "",
      operator: "",
    };
    insert(index, newEntry);
    setMaintained(false);
    clearErrors(`${rootName}`);
  };

  useEffect(() => {
    replace(andConditions);
  }, []);

  return (
    <Stack spacing={2}>
      {fields.map((item: any, index) => {
        return (
          <Condition
            key={item.id}
            rootName={`${rootName}[${index}]`}
            variable={item.variable}
            operator={item.operator}
            value={item.value}
            orButtonDisabled={fields.length - 1 !== index}
            isFirstAndCondition={!index}
            addCondition={() => addEntry(index + 1)}
            removeCondition={
              fields.length === 1
                ? removeOrCondition
                : () => {
                    removeEntry(index);
                  }
            }
            addOrCondition={addOrCondition}
            readOnly={readOnly}
          />
        );
      })}
    </Stack>
  );
};

export default TriggerAndConditions;
