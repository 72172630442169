import { Box, Container } from "@mui/material";
import { VariableSelectionTab } from "apps/jtm/components/variable/VariableSelectionTab";
import TabContent from "libs/ui/components/surfaces/tabs/TabContent";
import TabWrapper from "libs/ui/components/surfaces/tabs/TabWrapper";
import VariableNews from "./VariableNew";

/**
 * The FunctionGrid component displays a grid containing all functions in the db.
 * @returns a FunctionGrid component
 */
const VariableSelection = (): JSX.Element => {
  return (
    <Container>
      <TabWrapper enableSearchParamsChanging>
        <TabContent label="Frontend">
          <Box sx={{ mt: 5 }}>
            <VariableSelectionTab type="FRONTENDJS" />
          </Box>
        </TabContent>
        <TabContent label="Backend">
          <Box sx={{ mt: 5 }}>
            <VariableSelectionTab type="BACKENDJS" />
          </Box>
        </TabContent>
        <TabContent label="Constant">
          <Box sx={{ mt: 5 }}>
            <VariableNews type="ACCOUNTCONSTANT" />
          </Box>
        </TabContent>
      </TabWrapper>
    </Container>
  );
};

export default VariableSelection;
