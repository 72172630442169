import { Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import ContainerFilter from "../input/ContainerFilter";

interface CustomToolBarProps {
  showContainerFilter?: boolean;
}

const CustomToolBar = ({ showContainerFilter = false }: CustomToolBarProps): JSX.Element => {
  return (
    <GridToolbarContainer sx={{ display: "flex", padding: 0, marginBottom: "16px" }}>
      {showContainerFilter && (
        <Box sx={{ mb: 0.5, mr: 2 }}>
          <ContainerFilter />
        </Box>
      )}
      <GridToolbarQuickFilter variant="outlined" size="small" sx={{ flex: 1 }} />
    </GridToolbarContainer>
  );
};

const getCustomToolBar = ({ showContainerFilter }: CustomToolBarProps) => {
  return {
    components: {
      Toolbar: () => <CustomToolBar showContainerFilter={showContainerFilter} />,
    },
    disableDensitySelector: true,
    disableColumnMenu: true,
    componentsProps: {
      toolbar: {
        showQuickFilter: true,
        quickFilterProps: { debounceMs: 300 },
      },
    },
  };
};

export default getCustomToolBar;
