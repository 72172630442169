import { GET_LOGS_BY_TYPE_AND_ID } from "apps/jtm/business/log/queries";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { notifyError } from "libs/business/notification/notification";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LogGrid from "./LogGrid";

/**
 * The SingleLogGrid component
 * @param {SingleLogGridProps} props
 * @returns a SingleLogGrid component
 */
const SingleLogGrid = (): JSX.Element => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [getLogs, { called, loading, data, error }] = useQueryWrapper(
    GET_LOGS_BY_TYPE_AND_ID,
    true,
  );
  const name = searchParams.get("name");
  const variableType = searchParams.get("variableType");

  if (!called) {
    if (urlParams.id && variableType)
      getLogs({
        variables: { elementID: parseInt(urlParams.id, 10), element: variableType },
        context: { clientName: "JTM" },
      });
    else notifyError("No ID or variableType provided");
  }

  if (error) {
    notifyError(error.message);
    navigate(-1);
  }
  if (loading || !data) return <PageLoadingSpinner />;

  return (
    <Page title={`Changelog: ${name}`}>
      <LogGrid rowData={data.changeLogsByElementTypeAndId} />
    </Page>
  );
};

export default SingleLogGrid;
