import { gql } from "@apollo/client";
import { BackendNexusContainerDetails } from "apps/admin/business/container/queries";
import { GQLTypeDefinition, ListResponse, SingleResponse } from "libs/model/types";

export interface CriticalUpdate {
  [pluginId: string]: {
    name: string;
    criticalLogs: {
      [version: string]: string;
    };
  };
}

export interface BackendNexusDeploymentVersions {
  id: number;
  create_at: string;
  edit_at: string;
  number: string;
  edit: number;
  create_by: number;
  description: string;
  container_id: number;
  container: BackendNexusContainerDetails;
  updated_by: {
    id: number;
    username: string;
    email: string;
  };
  public_environments: {
    id: number;
    name: string;
  };
  critical_updates: CriticalUpdate;
  classification: "normal" | "critical" | "emergency";
}

export interface GetAllVersionsQueryDefinition extends GQLTypeDefinition {
  dataType: Omit<BackendNexusDeploymentVersions, "container"> & {
    container: Pick<
      BackendNexusContainerDetails,
      "id" | "name" | "short" | "live_url" | "test_url"
    >;
  };
  response: ListResponse<"deploymentVersions", GetAllVersionsQueryDefinition["dataType"]>;
  context: "JTM";
}

export const GET_ALL_VERSIONS = gql`
  query deploymentVersions {
    deploymentVersions {
      id
      create_at
      edit_at
      number
      edit
      create_by
      description
      container_id
      container {
        id
        name
        short
        live_url
        test_url
      }
      updated_by {
        id
        username
        email
      }
      public_environments {
        id
        name
      }
      classification
    }
  }
`;

export const GET_VERSION_BY_ID = gql`
  query deploymentVersion($id: Int!) {
    deploymentVersion(id: $id) {
      id
      create_by
      create_at
      edit_at
      number
      edit
      container_id
      description
      public_environments {
        id
        name
      }
      classification
    }
  }
`;

export const GET_VERSION_CLASSIFICATION_BY_ID = gql`
  query deploymentVersion($id: Int!) {
    deploymentVersion(id: $id) {
      classification
    }
  }
`;

export interface GetCriticalUpdatesOfVersionQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusDeploymentVersions, "critical_updates">;
  response: SingleResponse<
    "deploymentVersion",
    GetCriticalUpdatesOfVersionQueryDefinition["dataType"]
  >;
  arguments: { id: number };
  context: "JTM";
}
export const GET_CRITICAL_UPDATES_OF_VERSION = gql`
  query deploymentVersion($id: Int!) {
    deploymentVersion(id: $id) {
      critical_updates
    }
  }
`;
