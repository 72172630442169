import { ApolloCache } from "@apollo/client";
import {
  CREATE_TRIGGER,
  GET_ALL_TRIGGERS,
  GET_TRIGGER_SHORT_IDS,
} from "apps/jtm/business/trigger/queries";
import { MaintainedProvider } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import TriggerForm from "apps/jtm/components/trigger/TriggerForm";
import { Trigger } from "apps/jtm/components/trigger/types";
import { getCreateOptionsWithShortIDValidation } from "libs/business/apollo/createOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { useNavigate } from "react-router-dom";

/**
 * The TriggerNew component
 * @returns a TriggerNew component
 */
const TriggerNew = (): JSX.Element => {
  const navigate = useNavigate();
  const [getTriggerShortIds, queryState] = useQueryWrapper(GET_TRIGGER_SHORT_IDS);
  const [createTrigger, mutationState] = useMutationWrapper(CREATE_TRIGGER);

  if (!queryState.called) {
    getTriggerShortIds({ context: { clientName: "JTM" } });
  }

  const triggerData: Trigger = {
    id: 0,
    short_id: "",
    pluginID: "",
    name: "",
    desc: "",
    maintained: 0,
    frontend_trigger_type: "STATE",
    condition: [],
  };

  const handleSubmit = (submitData: any) => {
    createTrigger({
      ...getCreateOptionsWithShortIDValidation(
        "Trigger",
        "JTM",
        submitData,
        GET_ALL_TRIGGERS,
        GET_TRIGGER_SHORT_IDS,
        navigate,
      ),
      update(cache: ApolloCache<any>) {
        cache.evict({ fieldName: "triggers" });
        cache.gc();
      },
    });
  };

  if (!queryState.data || mutationState.loading) return <PageLoadingSpinner />;

  return (
    <MaintainedProvider
      initialValue={false}
      elementName="trigger"
      formSubmitHandler={handleSubmit}
      shortIds={queryState.data.triggerShortIds.shortIds}>
      <TriggerForm mode="create" triggerData={triggerData} />
    </MaintainedProvider>
  );
};

export default TriggerNew;
