import { Grid, Stack } from "@mui/material";
import { useAssistantWizard } from "libs/ui/components/assistant-wizard/AssistantWizardContext";
import AssistantSummaryCard from "libs/ui/components/assistant-wizard/summary-page/AssistantSummaryCard";

import {
  GET_TAG_TYPE_DEFINITION_BY_PLUGINID,
  GET_TOOL_DEFINITION_BY_PLUGINID,
  GetTagTypesByPluginIdQueryDefinition,
  GetToolDefinitionByPluginIdQueryDefinition,
} from "apps/jtm/business/tool/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { notifyError } from "libs/business/notification/notification";
import { ContainerEnvironmentValues } from "libs/model/types";
import AssistantConnectorSummary from "libs/ui/components/assistant-wizard/summary-page/AssistantConnectorSummary";
import AssistantContainerSummary from "libs/ui/components/assistant-wizard/summary-page/AssistantContainerSummary";
import AssistantListValueSummary from "libs/ui/components/assistant-wizard/summary-page/AssistantListValueSummary";
import AssistantValueSummary from "libs/ui/components/assistant-wizard/summary-page/AssistantValueSummary";
import LoadComponentSpinner from "libs/ui/components/feedback/loading-spinner/LoadComponentSpinner";
import {
  updateWizardContextConstantsFromManifest,
  updateWizardContextTagTypesFromManifest,
} from "../business/onManifestChange";
import {
  CA_INPUT_NAME_CONSTANTS,
  CA_INPUT_NAME_CONTAINER_IDS,
  CA_INPUT_NAME_NAME,
  CA_INPUT_NAME_TAG_TYPES_TO_GENERATE,
  CA_INPUT_NAME_TOOL_ID,
} from "../conf/schema";
import { CaWizardContextType } from "../conf/types";

/**
 * The ConnectorAssistantStepThree component
 * @returns a ConnectorAssistantStepThree component
 */
const ConnectorAssistantStepThree = (): JSX.Element => {
  const wizardData = useAssistantWizard<CaWizardContextType>();

  const getTagTypesResponse = useSimpleFetching<GetTagTypesByPluginIdQueryDefinition>({
    query: GET_TAG_TYPE_DEFINITION_BY_PLUGINID,
    context: "JTM",
    queryArguments: { pluginID: wizardData.data[CA_INPUT_NAME_TOOL_ID] },
    customOnError: (e) => {
      notifyError(e);
    },
  });
  const getToolDefinitionResponse = useSimpleFetching<GetToolDefinitionByPluginIdQueryDefinition>({
    query: GET_TOOL_DEFINITION_BY_PLUGINID,
    context: "JTM",
    queryArguments: { pluginID: wizardData.data[CA_INPUT_NAME_TOOL_ID] },
    customOnError: (e) => {
      notifyError(e);
    },
  });

  if (!getTagTypesResponse || !getToolDefinitionResponse) {
    return <LoadComponentSpinner />;
  }

  const tagTypes = getTagTypesResponse.toolDefinition.tag_types;

  wizardData.data[CA_INPUT_NAME_CONSTANTS] = updateWizardContextConstantsFromManifest(
    wizardData.data[CA_INPUT_NAME_CONSTANTS],
    getToolDefinitionResponse.toolDefinition.constants,
  );

  wizardData.data[CA_INPUT_NAME_TAG_TYPES_TO_GENERATE] = updateWizardContextTagTypesFromManifest(
    wizardData.data[CA_INPUT_NAME_TAG_TYPES_TO_GENERATE],
    tagTypes,
  );

  const constantEntries: [string, ContainerEnvironmentValues<any>][] = Object.entries(
    wizardData.data[CA_INPUT_NAME_CONSTANTS],
  );

  const allTagsWillBeInstalled =
    wizardData.data[CA_INPUT_NAME_TAG_TYPES_TO_GENERATE]?.length ===
    Object.entries(tagTypes).filter(([, tagType]) => {
      return tagType.triggerId !== undefined;
    }).length;

  const tagTypeNames = allTagsWillBeInstalled
    ? undefined
    : wizardData.data[CA_INPUT_NAME_TAG_TYPES_TO_GENERATE]?.map(
        (templateId: string) => tagTypes[templateId].name,
      );

  return (
    <Stack gap={2}>
      <AssistantSummaryCard step={1}>
        <Grid direction="row" spacing={3} container>
          <Grid item xs={6}>
            <AssistantContainerSummary
              containerIDs={wizardData.data[CA_INPUT_NAME_CONTAINER_IDS]}
            />
          </Grid>
          <Grid item xs={6}>
            <AssistantConnectorSummary pluginID={wizardData.data[CA_INPUT_NAME_TOOL_ID]} />
          </Grid>
        </Grid>
      </AssistantSummaryCard>
      <AssistantSummaryCard step={2}>
        <Grid direction="row" spacing={3} container>
          <Grid item xs={6}>
            <AssistantValueSummary label="Name" value={wizardData.data[CA_INPUT_NAME_NAME]} />
          </Grid>
          <Grid item xs={6}>
            <AssistantListValueSummary
              label="Tags to be installed"
              values={tagTypeNames || []}
              emptyListMessage={allTagsWillBeInstalled ? "All" : "No tags selected"}
            />
          </Grid>
          {constantEntries.map(([pluginId, constant]) => {
            return (
              <Grid item xs={6} key={pluginId}>
                <AssistantValueSummary
                  key={pluginId}
                  label={constant.name || pluginId}
                  value={constant.value || "You have not defined a default value!"}
                />
              </Grid>
            );
          })}
        </Grid>
      </AssistantSummaryCard>
    </Stack>
  );
};

export default ConnectorAssistantStepThree;
