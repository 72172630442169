import { OperationVariables } from "@apollo/client";
import { DocumentNode } from "graphql";
import { GQLTypeDefinition } from "libs/model/types";
import useQueryWrapper from "../authentication/useQueryWrapper";
import { notifyError } from "../notification/notification";
import useCompareByValueEffect from "./useCompareByValueEffect";

export type Context = "JTM" | "ADMIN" | "CMP" | "MONITOR" | "SETUP";
interface FetchingConfigWithoutArguments<
  TQueryArguments extends any,
  TContext extends GQLTypeDefinition["context"],
> {
  query: DocumentNode;
  context: TContext;
  customOnError?: (msg: string) => void;
  disableCache?: boolean;
  queryArguments?: TQueryArguments;
}

interface FetchingConfigWithVariables<
  TQueryArguments extends OperationVariables,
  TContext extends GQLTypeDefinition["context"],
> extends FetchingConfigWithoutArguments<TQueryArguments, TContext> {
  queryArguments: TQueryArguments;
}

export type FetchingConfig<
  TQueryArguments,
  TContext extends GQLTypeDefinition["context"],
> = TQueryArguments extends OperationVariables
  ? FetchingConfigWithVariables<TQueryArguments, TContext>
  : FetchingConfigWithoutArguments<TQueryArguments, TContext>;

/**
 * This Hook can be used to simplify the fetching of gql queries (not mutations).
 * It is basically just a boilerplate code to avoid cluttering the react components.
 * It is configured, that the query will be automatically refetched, if the given variables are changed.
 * @param {FetchingConfig} param Configuration for a basic query call.
 * @returns
 */
function useSimpleFetching<TQueryDefinition extends GQLTypeDefinition>({
  query,
  context,
  queryArguments = undefined,
  customOnError,
  disableCache = false,
}: FetchingConfig<TQueryDefinition["arguments"], TQueryDefinition["context"]>):
  | false
  | TQueryDefinition["response"] {
  const [callQuery, queryState] = useQueryWrapper<TQueryDefinition>(query, disableCache);

  useCompareByValueEffect(() => {
    callQuery({ context: { clientName: context }, variables: queryArguments });
  }, [queryArguments]);

  if (!queryState.called) {
    callQuery({ context: { clientName: context }, variables: queryArguments });
  }

  if (queryState.error) {
    if (customOnError) {
      customOnError(queryState.error.message);
    } else {
      notifyError(queryState.error.message);
      throw new Error(queryState.error.message);
    }
  }

  if (queryState.loading || !queryState.data) {
    return false;
  }

  return queryState.data;
}

export default useSimpleFetching;
