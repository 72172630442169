import { useParams } from "react-router-dom";

const useParamsId = (name: string): number => {
  const params = useParams<string>();
  const id = params[name];
  let numberId: number;
  if (id === undefined) {
    numberId = 0;
  } else {
    numberId = parseInt(id, 10);
  }
  return Number.isNaN(numberId) ? 0 : numberId;
};
export default useParamsId;
