import { Typography } from "@mui/material";
import { typography } from "@mui/system";

interface AssistantValueSummaryProps {
  label: string;
  value: string | JSX.Element;
}
/**
 * The AssistantValueSummary component
 * @param {AssistantValueSummaryProps} props
 * @returns a AssistantValueSummary component
 */
const AssistantValueSummary = ({ label, value }: AssistantValueSummaryProps): JSX.Element => {
  return (
    <div>
      <Typography variant="bodyRegular14" component="p">
        {label}
      </Typography>
      {typeof value === "string" ? (
        <Typography variant="bodyMedium16" component="p" sx={{ overflowWrap: "break-word" }}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </div>
  );
};

export default AssistantValueSummary;
