import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";
import colorStyles from "assets/styles/colorPalette.module.scss";

interface HomeProps {
  children?: JSX.Element;
  icon: IconDefinition;
  title: string;
}
/**
 * The Home component
 * @param {HomeProps} props
 * @returns a Home component
 */
const HomeIcon = ({ children, icon, title }: HomeProps): JSX.Element => {
  return (
    <Stack alignItems="center" style={{ height: "100%" }} justifyContent="center">
      <Stack direction="row" spacing={2}>
        <Typography variant="h1" color={colorStyles.grey2}>
          <FontAwesomeIcon icon={icon} style={{ marginRight: "10px" }} />
          {title}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default HomeIcon;
