import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import ConstantsTabConfiguration from "apps/jtm/components/constants/ConstantsTabConfiguration";
import ContainerMultiSelect from "apps/admin/components/container/ContainerMultiSelect";
import Page from "libs/ui/components/surfaces/page/Page";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object({
  name: yup.string().required().min(4).max(256, "The name must be shorter than 256 characters."),
});

interface IFrontendPluginProps {
  id: number | string;
  frontendPluginData: any;
  formSubmitHandler: SubmitHandler<any>;
  selectedContainerIdsInit: number[];
}

const FrontendPlugin = ({
  id,
  frontendPluginData,
  formSubmitHandler,
  selectedContainerIdsInit,
}: IFrontendPluginProps): JSX.Element => {
  const methods = useForm<any>({ resolver: yupResolver(schema) });

  const [selectedContainerIds, setContainerSelection] =
    useState<number[]>(selectedContainerIdsInit);

  const handleMultiSelectChange = (newValues: number[]) => {
    setContainerSelection(newValues);
  };

  return (
    <Page title="Plugin">
      <FormProvider {...methods} key="frontendPluginForm">
        <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <TextFieldWrapper name="id" label="id" value={id} hidden maxLength={256} />
          <TextFieldWrapper
            name="pluginID"
            label="pluginID"
            value={frontendPluginData.pluginID}
            hidden
          />
          <TextFieldWrapper
            name="name"
            label="Name"
            value={frontendPluginData.name}
            maxLength={256}
          />
          <Box sx={{ mt: 2 }}>
            <ContainerMultiSelect
              idsOfSelectedContainers={selectedContainerIds}
              onChange={handleMultiSelectChange}
            />
          </Box>
          <Box sx={{ m: 2 }} />
          <Typography variant="h5">Configuration</Typography>
          <ConstantsTabConfiguration
            constantData={frontendPluginData.constants}
            submitName="constants"
            selectedContainerIds={selectedContainerIds}
          />
          <SaveCancelBar />
        </form>
      </FormProvider>
    </Page>
  );
};

export default FrontendPlugin;
