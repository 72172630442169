import CompareAccountConstant from "libs/ui/components/compareview/plugin_type/CompareAccountConstant";
import CompareCode from "libs/ui/components/compareview/plugin_type/CompareCode";
import CompareFrontendVariable from "libs/ui/components/compareview/plugin_type/CompareFrontendVariable";
import CompareFunction from "libs/ui/components/compareview/plugin_type/CompareFunction";
import CompareTag from "libs/ui/components/compareview/plugin_type/CompareTag";
import CompareTool from "libs/ui/components/compareview/plugin_type/CompareTool";
import CompareTrigger from "libs/ui/components/compareview/plugin_type/CompareTrigger";

export enum PluginType {
  FrontendVariable = "FRONTENDJS",
  BackendVariable = "BACKENDJS",
  Constant = "ACCOUNTCONSTANT",
  Tag = "Tag",
  Code = "Code",
  Function = "Function",
  State = "State",
  Trigger = "Trigger",
  Tool = "Tool",
}
interface Message {
  old: object;
  new: object;
}

interface IMessageTransformerProps {
  elementType: PluginType;
  message: Message;
  elementID: number;
}
/**
 * The MessageTransformer component transforms the changelog-message into a JSX element.
 * @param {IMessageTransformerProps} props
 * @returns a MessageTransformer component
 */
const MessageTransformer = ({
  elementType,
  message,
  elementID,
}: IMessageTransformerProps): JSX.Element => {
  switch (elementType) {
    case PluginType.Function:
      return <CompareFunction old_functionData={message.old} new_functionData={message.new} />;
    case PluginType.FrontendVariable:
      return (
        <CompareFrontendVariable
          old_variableData={message.old}
          new_variableData={message.new}
          // allowCompareEdit
        />
      );
    case PluginType.BackendVariable:
      return (
        <CompareFrontendVariable
          old_variableData={message.old}
          new_variableData={message.new}
          // allowCompareEdit
        />
      );
    case PluginType.Constant:
      return (
        <CompareAccountConstant old_variableData={message.old} new_variableData={message.new} />
      );

    case PluginType.State:
      return <CompareFunction old_functionData={message.old} new_functionData={message.new} />;
    case PluginType.Code:
      return <CompareCode old_codeData={message.old} new_codeData={message.new} />;
    case PluginType.Trigger:
      return <CompareTrigger old_triggerData={message.old} new_functionData={message.new} />;
    case PluginType.Tool:
      return <CompareTool old_toolData={message.old} new_toolData={message.new} />;
    case PluginType.Tag:
      return <CompareTag old_tagData={message.old} new_tagData={message.new} tagID={elementID} />;
    default:
      return <div>Element Type not found</div>;
  }
};

export default MessageTransformer;
