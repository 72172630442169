import { Typography } from "@mui/material";
import { GET_ALL_TRIGGERS, GetAllTriggersQueryDefinition } from "apps/jtm/business/trigger/queries";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import MultiSelectWithSearch from "libs/ui/components/search/MultiSelectWithSearch";
import { memo } from "react";

export interface IExcludeTriggerSelectProps {
  selectedTriggers: number[];
}

function ExcludeTriggerSelect({ selectedTriggers }: IExcludeTriggerSelectProps) {
  const { setMaintained } = useMaintained();
  const getAllTriggersResponse = useSimpleFetching<GetAllTriggersQueryDefinition>({
    query: GET_ALL_TRIGGERS,
    context: "JTM",
    queryArguments: { sortParam: "name", sortDirection: "asc" },
  });

  if (getAllTriggersResponse === false) {
    return <PageLoadingSpinner />;
  }

  const fetchedTriggers = getAllTriggersResponse.triggers;
  const excludeTriggers = [];

  for (const trigger of fetchedTriggers) {
    excludeTriggers.push({
      key: trigger.id,
      optionLabel: trigger.name,
      value: <Typography>{trigger.name}</Typography>,
    });
  }

  const onChange = (newValue: (number | string)[] | undefined) => {
    setMaintained(false);
  };

  return (
    <MultiSelectWithSearch
      inputName="excluded_triggers"
      instanceName="Exclude triggers"
      options={excludeTriggers}
      defaultValue={selectedTriggers}
      onChange={onChange}
    />
  );
}
export default memo(ExcludeTriggerSelect);
