import {
  CREATE_TOOL,
  GetToolByIdQueryDefinition,
  GET_ALL_TOOLS,
  GET_TOOL_DEFINITION_BY_PLUGINID,
} from "apps/jtm/business/tool/queries";
import Tool from "apps/jtm/components/tool/Tool";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCreateOptions } from "libs/business/apollo/createOptions";
import {
  removesEmptyOptionsOfCMPInfo,
  removesEmptyOptionsOfConstants,
} from "../../components/shared/preprocess_constant_values";
import { GET_ALL_TAGS } from "../../business/tag/queries";

const ToolCreate = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const pluginID = searchParams.get("pluginID") ?? "";

  const [getToolByPluginID, queryState] = useQueryWrapper(GET_TOOL_DEFINITION_BY_PLUGINID);

  const [createTool, mutationState] = useMutationWrapper(CREATE_TOOL);
  const navigate = useNavigate();

  const formSubmitHandler: SubmitHandler<any> = (submitData: any) => {
    submitData.constants = removesEmptyOptionsOfConstants(submitData.constants);
    submitData.cmpInformation = removesEmptyOptionsOfCMPInfo(submitData.cmpInformation);

    createTool(
      getCreateOptions("Tool", "JTM", submitData, GET_ALL_TOOLS, navigate, {
        refetchQueriesArg: [
          {
            query: GET_ALL_TAGS,
            context: { clientName: "JTM" },
          },
        ],
      }),
    );
  };

  if (!queryState.called) {
    getToolByPluginID({ variables: { pluginID }, context: { clientName: "JTM" } });
  }

  if (queryState.loading || !queryState.data || mutationState.loading)
    return <PageLoadingSpinner />;

  const toolData: GetToolByIdQueryDefinition["dataType"] = {
    id: -1,
    name: "",
    containerIDs: [],
    pluginID,
    constants: queryState.data.toolDefinition.constants,
    tool_definition: queryState.data.toolDefinition,
    create_at: "",
    edit_at: "",
    version: "",
    no_consent_mode: true,
    justification_id: 0,
    category_id: 0,
  };

  return <Tool toolData={toolData} formSubmitHandler={formSubmitHandler} />;
};

export default ToolCreate;
