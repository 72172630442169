import { gql } from "@apollo/client";
import { TVariableSources } from "apps/jtm/conf/variable_source";
import { GQLTypeDefinition, ListResponse, SingleResponse, SortQueryParams } from "libs/model/types";

export interface BackendNexusVariableType {
  id: number;
  status: number;
  mid: number;
  create_by: number;
  create_at: string;
  edit_by: number;
  edit_at: string;
  original: number;
  short_id: string;
  version: string;
  name: string;
  desc: string;
  source: TVariableSources;
  scope: string;
  group: string;
  personal_data: number;
  value: string;
  trigger_related: number;
  maintained: number;
  placeholder: any;
  pluginID: string;
}
export interface GetVariableByIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusVariableType,
    | "id"
    | "short_id"
    | "name"
    | "desc"
    | "source"
    | "scope"
    | "group"
    | "personal_data"
    | "value"
    | "trigger_related"
    | "maintained"
    | "placeholder"
    | "pluginID"
  >;
  response: SingleResponse<"variable", GetVariableByIdQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "JTM";
}

export const GET_VARIABLE_BY_ID = gql`
  query variable($id: Int!) {
    variable(id: $id) {
      id
      short_id
      name
      desc
      source
      scope
      group
      personal_data
      value
      trigger_related
      maintained
      placeholder
      pluginID
    }
  }
`;

export interface GetAllVariablesQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusVariableType,
    | "id"
    | "name"
    | "short_id"
    | "group"
    | "source"
    | "create_at"
    | "edit_at"
    | "pluginID"
    | "placeholder"
  >;
  response: ListResponse<"variables", GetAllVariablesQueryDefinition["dataType"]>;
  arguments?: SortQueryParams;
  context: "JTM";
}

export const GET_ALL_VARIABLES = gql`
  query variables($sortParam: String, $sortDirection: String) {
    variables(sortParam: $sortParam, sortDirection: $sortDirection) {
      id
      name
      short_id
      group
      source
      create_at
      edit_at
      pluginID
      placeholder
    }
  }
`;

export interface GetToolConstantsByTagOrToolQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusVariableType, "name" | "short_id" | "desc">;
  response: ListResponse<"toolConstants", GetToolConstantsByTagOrToolQueryDefinition["dataType"]>;
  arguments: { tagId: number; toolId: number };
  context: "JTM";
}

export const GET_TOOL_CONSTANTS_BY_TAG_OR_TOOL_ID = gql`
  query toolConstants($tagId: Int, $toolId: Int) {
    toolConstants(tagId: $tagId, toolId: $toolId) {
      name
      short_id
      desc
    }
  }
`;

export const COPY_VARIABLE = gql`
  mutation copyVariable($idToCopy: Int!, $newName: String!) {
    copyVariable(id_to_copy: $idToCopy, new_name: $newName) {
      id
      name
      short_id
      source
      create_at
      edit_at
      pluginID
    }
  }
`;

export const DELETE_VARIABLE = gql`
  mutation deleteVariable($id: Int!) {
    deleteVariable(id: $id) {
      ... on Variable {
        id
        name
      }
      ... on DependenciesOnVariable {
        dependentTriggers {
          id
          name
        }
        dependentTags {
          id
          name
        }
      }
    }
  }
`;

export const GET_VARIABLE_DEFAULT_VALUE = gql`
  query variableDefaultValues($pluginID: String!) {
    variableDefaultValues(pluginId: $pluginID) {
      short_id
      name
      desc
      group
      personal_data
      trigger_related
      value
      scope
      source
      maintained
    }
  }
`;

export const UPDATE_VARIABLE = gql`
  mutation updateVariable(
    $id: Int!
    $short_id: String
    $name: String!
    $desc: String
    $source: String!
    $scope: String
    $group: String
    $personal_data: Int
    $value: String
    $triggerRelated: Int
    $maintained: Int
    $placeholder: JSON
  ) {
    updateVariable(
      id: $id
      short_id: $short_id
      name: $name
      desc: $desc
      source: $source
      scope: $scope
      group: $group
      personal_data: $personal_data
      value: $value
      trigger_related: $triggerRelated
      maintained: $maintained
      placeholder: $placeholder
    ) {
      id
      short_id
      version
      name
      desc
      source
      scope
      group
      personal_data
      value
      trigger_related
      maintained
      placeholder
    }
  }
`;

export const CREATE_VARIABLE = gql`
  mutation createVariable(
    $short_id: String
    $name: String!
    $desc: String
    $source: String!
    $scope: String
    $group: String
    $personal_data: Int
    $value: String
    $triggerRelated: Int
    $maintained: Int
    $placeholder: JSON
    $pluginID: String
  ) {
    createVariable(
      short_id: $short_id
      name: $name
      desc: $desc
      source: $source
      scope: $scope
      group: $group
      personal_data: $personal_data
      value: $value
      trigger_related: $triggerRelated
      maintained: $maintained
      placeholder: $placeholder
      pluginID: $pluginID
    ) {
      id
      create_at
      edit_at
      short_id
      version
      name
      desc
      source
      scope
      group
      personal_data
      value
      trigger_related
      maintained
      placeholder
      pluginID
    }
  }
`;

export const GET_VARIABLE_TEMPLATE_FIELDS = gql`
  query variableTemplateFields($pluginID: String!) {
    variableTemplateFields(pluginID: $pluginID) {
      pluginID
      type
      fields
    }
  }
`;

export const GET_TEMPLATE_VARIABLES_BY_TYPE = gql`
  query templateVariables($type: String!) {
    templateVariables(type: $type) {
      pluginID
      image_path
      name
      type
    }
  }
`;

export const GET_VARIABLE_SHORT_IDS = gql`
  query variableShortIds {
    variableShortIds {
      shortIds
    }
  }
`;

export const GET_VARIABLES_DEPENDENCIES = gql`
  query getVariableDependencies($id: Int!) {
    getVariableDependencies(id: $id) {
      dependentTriggers {
        id
        create_by
        create_at
        edit_by
        edit_at
        short_id
        version
        name
        desc
        condition
        frontend_trigger_type
        selector
        action
        maintained
        pluginID
      }
      dependentTags {
        id
        create_by
        create_at
        edit_by
        edit_at
        name
        templateid
        sender
        placeholder
        no_consent_placeholder
        arraylist_variables
        type
        tool_id
        containerIDs
        placeholder_definition
      }
    }
  }
`;

export default GET_VARIABLE_BY_ID;
