import { Stack } from "@mui/material";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { useState } from "react";

interface IAutoGenerateProps {
  mode: string;
  name: string;
  short_id: string;
}

export const AutoGenerateNameAndID: React.FC<IAutoGenerateProps> = ({
  mode,
  short_id,
  name,
}: IAutoGenerateProps) => {
  const [shortID, setShortID] = useState<string>(short_id);

  const generateShortID = (value: string) => {
    const newShortID = generateIdOutOfName(value);
    setShortID(newShortID);
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <TextFieldWrapper
        name="name"
        label="Name"
        value={name}
        onChange={mode === "create" ? generateShortID : undefined}
        maxLength={256}
      />
      <TextFieldWrapper name="short_id" label="ID" value={shortID} disabled />
    </Stack>
  );
};

export const generateIdOutOfName = (name: string) => {
  let id = name.toLowerCase();
  id = id.replace(/([^a-zA-Z0-9'])+/gi, "_");
  return id;
};

export default AutoGenerateNameAndID;
