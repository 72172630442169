import RequiresAccessGroupWrapper from "apps/admin/components/RequiresAccessGroupWrapper";
import RouteInterface from "libs/business/routes/RouteInterface";
import CategoryManager from "../pages/category/CategoryManager";
import Consent from "../pages/consent/Consent";
import Consents from "../pages/consent/Consents";
import ConsentBar from "../pages/consentbar/Consentbar";
import Consentbars from "../pages/consentbar/Consentbars";
import LegalManagementDefaultPage from "../pages/home/LegalManagementDefaultPage";
import Translation from "../pages/translation/Translation";

export const cmpPrefix = "/app/cmp";

const routes: RouteInterface = {
  category_manager: {
    path: `/setting/categories`,
    element: (
      <RequiresAccessGroupWrapper accessGroups={[1, 2, 3, 4, 5, 6]}>
        <CategoryManager />
      </RequiresAccessGroupWrapper>
    ),
  },
  home: {
    path: `${cmpPrefix}`,
    element: <LegalManagementDefaultPage />,
  },
  consents: {
    path: `${cmpPrefix}/consent`,
    element: <Consents />,
  },
  consent: {
    path: `${cmpPrefix}/consent/:id`,
    element: <Consent />,
  },
  translation: {
    path: `${cmpPrefix}/translation`,
    element: <Translation />,
  },
  consentbars: {
    path: `${cmpPrefix}/consentbar`,
    element: <Consentbars />,
  },
  consentbar: {
    path: `${cmpPrefix}/consentbar/:id`,
    element: <ConsentBar />,
  },
};

export default routes;
