import WhiteListRoutes from "apps/admin/components/WhiteListRoutes";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { trackPageView } from "trackingcode";
import useIsWhiteListPage from "../../../apps/admin/hooks/useIsWhiteListPage";
import { refreshAuthToken } from "../apollo";
import useTriggerRerender from "../hooks/useTriggerRerender";
import TokenStorageManager from "./TokenStorageManager";

interface AuthProviderProps {
  children?: ReactNode;
}
/**
 * The AuthProvider component
 * @param {AuthProviderProps} props
 * @returns a AuthProvider component
 */
const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const isWhiteListPage = useIsWhiteListPage();
  const pathBeforeLogin = useRef("");
  const hostBeforeLogin = useRef("");
  const newlyEvaluateAuthentication = useTriggerRerender();
  const [initialTokenRefreshCompleted, setInitialTokenRefreshCompleted] = useState(false);

  useEffect(() => {
    const removeTokenChangeListener = TokenStorageManager.addOnTokenChangeListener(
      newlyEvaluateAuthentication,
    );

    if (!initialTokenRefreshCompleted) {
      refreshAuthToken()
        .catch(() => {})
        .finally(() => setInitialTokenRefreshCompleted(true));
    }
    return () => {
      removeTokenChangeListener();
    };
  }, []);

  const isAuthenticated = !!TokenStorageManager.getRefreshToken();

  if (initialTokenRefreshCompleted === false) {
    return (
      <div style={{ height: "100vh" }}>
        <PageLoadingSpinner />
      </div>
    );
  }

  if (isAuthenticated) {
    if (!isWhiteListPage) {
      trackPageView(); // track pageview on all non-white list pages
      return <div>{children}</div>;
    }

    if (hostBeforeLogin.current !== "") {
      return <Navigate to={pathBeforeLogin.current} />;
    }
    return <Navigate to="/" />;
  }

  if (!isWhiteListPage) {
    hostBeforeLogin.current = window.location.host;
    pathBeforeLogin.current = window.location.pathname;
    return <Navigate to="/login" />;
  }

  trackPageView(); // track pageview on all white list pages
  return <WhiteListRoutes />;
};

export default AuthProvider;
