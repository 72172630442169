import { Typography } from "@mui/material";
import prettifyXML from "libs/business/utils/prettifyXML";
import style from "../KeyValueGroup/KeyValueGroup.module.scss";
import KeyValueRow from "../KeyValueRow/KeyValueRow";

interface IJSONXMLBoxProps {
  name: string;
  elements: any[];
}

export default function JSONXMLBox({ name, elements }: IJSONXMLBoxProps) {
  let elementsDOM;
  if (typeof elements !== "undefined" && elements.length > 0) {
    const value = getFormattedValue(elements[0].data);

    elementsDOM = (
      <KeyValueRow key={`${name}-data`} title="data" value={value} success={undefined} />
    );
  } else {
    elementsDOM = <Typography component="pre">No data</Typography>;
  }

  return (
    <div>
      <Typography className={style.title}>{name}</Typography>
      <div className={style.list}>{elementsDOM}</div>
    </div>
  );
}

const getFormattedValue = (value: string) => {
  let jsonValue;
  let xmlValue;

  try {
    jsonValue = JSON.parse(value);
  } catch (e) {
    jsonValue = undefined;
    try {
      xmlValue = prettifyXML(value);
    } catch (e2) {
      xmlValue = undefined;
    }
  }
  if (jsonValue) {
    return JSON.stringify(jsonValue, null, 4);
  }
  if (xmlValue) {
    return xmlValue;
  }
  return value;
};
