import { useLazyQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import { GQLTypeDefinition } from "libs/model/types";

export default function useQueryWrapper<TQueryDefinition extends GQLTypeDefinition>(
  query: DocumentNode,
  disableCache?: boolean,
) {
  let options: any = {};
  if (disableCache) {
    options = {
      fetchPolicy: "no-cache",
    };
  }
  return useLazyQuery<TQueryDefinition["response"], TQueryDefinition["arguments"]>(query, options);
}
