import JSONXMLBox from "../../../json_xml_box/JSONXMLBox";
import { TagDetailData } from "../../type";

interface PayloadTabContentProps {
  detailData: TagDetailData;
}
/**
 * The PayloadTab component
 * @param {PayloadTabContentProps} props
 * @returns a PayloadTab component
 */
const PayloadTabContent = ({ detailData }: PayloadTabContentProps): JSX.Element => {
  return <JSONXMLBox name="Payload" elements={detailData.payload.elements || []} />;
};

export default PayloadTabContent;
