import { Button } from "@mui/material";
import { useMutation } from "@apollo/client";
import { PASSWORD_RESET } from "apps/admin/business/login/queries";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import LoginHeader from "./LoginHeader";

interface IFormProps {
  email: number;
}

const schema = yup.object({
  email: yup.string().required().email(),
});

export default function PasswordResetForm() {
  const methods = useForm<IFormProps>({ resolver: yupResolver(schema) });
  const [passwordReset, checkPasswordReset] = useMutation(PASSWORD_RESET);

  const formSubmitHandler: SubmitHandler<IFormProps> = (submitData: IFormProps) => {
    passwordReset({
      variables: submitData,
      context: { clientName: "ADMIN" },
      onCompleted: (data) => {
        notifySuccess("E-mail sent!");
      },
      onError: (error) => {
        notifyError(error.message);
      },
    });
  };

  return (
    <FormProvider {...methods} key="resetForm">
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <LoginHeader title="Password reset" description="Please tell us your e-mail address!" />
        <TextFieldWrapper name="email" label="E-mail" />
        <Button type="submit" variant="contained" fullWidth sx={{ mt: 3 }}>
          Send
        </Button>
      </form>
    </FormProvider>
  );
}
