import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import colorStyles from "assets/styles/colorPalette.module.scss";
import { ReactNode, useState } from "react";

interface DividerAccordionProps {
  title: string;
  children?: ReactNode;
  defaultOpen?: boolean;
}
/**
 * The DividerAccordion component
 * @param {DividerAccordionProps} props
 * @returns a DividerAccordion component
 */
const DividerAccordion = ({
  children,
  title,
  defaultOpen = false,
}: DividerAccordionProps): JSX.Element => {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ mt: 4, cursor: "pointer", color: colorStyles.grey2 }}
        spacing={1}
        onClick={() => setOpen((prevValue) => !prevValue)}>
        <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="xs" />
        <Typography>{title}</Typography>
        <div style={{ flex: 1, height: 1, background: colorStyles.grey2 }} />
      </Stack>
      <Collapse in={open}>{children}</Collapse>
    </>
  );
};

export default DividerAccordion;
