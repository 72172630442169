import { gql } from "@apollo/client";
import { GQLTypeDefinition, ListResponse, SingleResponse } from "libs/model/types";

export interface BackendNexusStateType {
  id: number;
  create_by: number;
  create_at: string;
  edit_by: number;
  edit_at: string;
  short_id: string;
  version: string;
  name: string;
  desc: string;
  value: string;
  maintained: number;
  pluginID: string;
}

export interface GetStateByIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusStateType,
    "id" | "short_id" | "name" | "desc" | "value" | "maintained" | "pluginID"
  >;
  response: SingleResponse<"state", GetStateByIdQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "JTM";
}

export const GET_STATE_BY_ID = gql`
  query state($id: Int!) {
    state(id: $id) {
      id
      short_id
      name
      desc
      value
      maintained
      pluginID
    }
  }
`;

export interface GetAllStatesQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusStateType,
    "id" | "name" | "create_at" | "edit_at" | "pluginID" | "short_id"
  >;
  response: ListResponse<"states", GetAllStatesQueryDefinition["dataType"]>;
  context: "JTM";
}
export const GET_ALL_STATES = gql`
  query states {
    states {
      id
      name
      create_at
      edit_at
      pluginID
      short_id
    }
  }
`;

export const COPY_STATE = gql`
  mutation copyState($idToCopy: Int!, $newName: String!) {
    copyState(id_to_copy: $idToCopy, new_name: $newName) {
      id
      name
      create_at
      edit_at
      pluginID
      short_id
    }
  }
`;

export const DELETE_STATE = gql`
  mutation deleteState($id: Int!) {
    deleteState(id: $id) {
      ... on State {
        id
        name
      }
      ... on DependenciesOnState {
        dependentTriggers {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_STATE = gql`
  mutation createState(
    $short_id: String!
    $name: String!
    $desc: String!
    $value: String!
    $maintained: Int!
  ) {
    createState(
      short_id: $short_id
      name: $name
      desc: $desc
      value: $value
      maintained: $maintained
    ) {
      id
      short_id
      name
      desc
      value
      maintained
      create_at
      edit_at
      pluginID
    }
  }
`;

export const UPDATE_STATE = gql`
  mutation updateState(
    $id: Int!
    $short_id: String!
    $name: String!
    $desc: String!
    $value: String!
    $maintained: Int!
  ) {
    updateState(
      id: $id
      short_id: $short_id
      name: $name
      desc: $desc
      value: $value
      maintained: $maintained
    ) {
      id
      short_id
      name
      desc
      value
      maintained
      create_at
      edit_at
      pluginID
    }
  }
`;

export const GET_STATE_DEFAULT_VALUES = gql`
  query stateDefaultValues($pluginID: String!) {
    stateDefaultValues(pluginId: $pluginID) {
      name
      short_id
      desc
      value
      maintained
    }
  }
`;

export const GET_STATE_SHORT_IDS = gql`
  query stateShortIds {
    stateShortIds {
      shortIds
    }
  }
`;

export const GET_STATE_DEPENDENCIES = gql`
  query getStateDependencies($id: Int!) {
    getStateDependencies(id: $id) {
      dependentTriggers {
        id
        name
      }
    }
  }
`;

export default GET_STATE_BY_ID;
