import {
  BackendNexusToolConstantDetails,
  BackendNexusToolDefinitionDetails,
} from "apps/jtm/business/tool/queries";
import { CaWizardContextType } from "../conf/types";
import { CA_INPUT_NAME_CONSTANTS, CA_INPUT_NAME_TAG_TYPES_TO_GENERATE } from "../conf/schema";

export const updateWizardContextConstantsFromManifest = (
  wizardConstants: CaWizardContextType["data"][typeof CA_INPUT_NAME_CONSTANTS],
  constants: BackendNexusToolConstantDetails[],
) => {
  const newConstants: CaWizardContextType["data"][typeof CA_INPUT_NAME_CONSTANTS] = {};

  constants.forEach((constant) => {
    const value = wizardConstants[constant.short_id]?.value;
    newConstants[constant.short_id] = {
      name: constant.name,
      optional: constant.optional,
      desc: constant.desc,
      value: value !== undefined ? value : constant.value.value,
      options: wizardConstants[constant.short_id]?.options || constant.value.options,
    };
  });

  return newConstants;
};

export const updateWizardContextTagTypesFromManifest = (
  wizardSelectedTagTypes: CaWizardContextType["data"][typeof CA_INPUT_NAME_TAG_TYPES_TO_GENERATE],
  manifestTagTypes: BackendNexusToolDefinitionDetails["tag_types"],
) => {
  return wizardSelectedTagTypes?.filter((tagType) => manifestTagTypes[tagType]);
};
