import RouteInterface from "libs/business/routes/RouteInterface";
import DNS from "../pages/dns/DNS";
import Home from "../pages/home/Home";
import Trackingcode from "../pages/trackingcode/Trackingcode";

export const setupPrefix = "/app/setup";

const routes: RouteInterface = {
  home: {
    path: `${setupPrefix}`,
    element: <Home />,
  },
  tracking_code: {
    path: `${setupPrefix}/trackingcode/:id`,
    element: <Trackingcode />,
  },
  dns: {
    path: `${setupPrefix}/dns/:id`,
    element: <DNS />,
    documentation: "0229b715-039e-4c32-9449-d723276e9815",
  },
};

export default routes;
