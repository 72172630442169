import { faDisplay, faServer, faText, faWandMagicSparkles } from "@fortawesome/pro-solid-svg-icons";
import colorStyles from "assets/styles/colorPalette.module.scss";

// check https://www.youtube.com/watch?v=jjMbPt_H3RQ why enums should be done like this in typescript
export const VARIABLE_SOURCES = {
  BACKEND: "BACKENDJS",
  FRONTENDJS: "FRONTENDJS",
  ENRICHMENT: "ENRICHMENT",
  ACCOUNTCONSTANT: "ACCOUNTCONSTANT",
} as const;
type ObjectValues<T> = T[keyof T];

export type TVariableSources = ObjectValues<typeof VARIABLE_SOURCES>;
export const VARIABLE_SOURCE_STYLE_CONFIG = {
  FRONTENDJS: {
    icon: faDisplay,
    color: colorStyles.jentisMidBlue,
  },
  BACKENDJS: {
    icon: faServer,
    color: colorStyles.jentisRed,
  },
  ACCOUNTCONSTANT: {
    icon: faText,
    color: colorStyles.jentisGreen,
  },
  ENRICHMENT: {
    icon: faWandMagicSparkles,
    color: colorStyles.jentisOrange,
  },
};

export default VARIABLE_SOURCE_STYLE_CONFIG;
