import { faFunction } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography } from "@mui/material";
import colorStyles from "assets/styles/colorPalette.module.scss";
import useParamsId from "libs/business/hooks/useParamId";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { useLocalStorage } from "libs/business/hooks/useStorage";
import { removeDuplicateObjectsFromArray } from "libs/business/utils/objectFunctions";
import LoadComponentSpinner from "libs/ui/components/feedback/loading-spinner/LoadComponentSpinner";
import ListSearchComponent from "libs/ui/components/search/ListSearchComponent";
import { useState } from "react";
import {
  GetAllFunctionsQueryDefinition,
  GET_ALL_FUNCTIONS,
} from "../../../business/function/queries";
import {
  GetAllVariablesQueryDefinition,
  GetToolConstantsByTagOrToolQueryDefinition,
  GET_ALL_VARIABLES,
  GET_TOOL_CONSTANTS_BY_TAG_OR_TOOL_ID,
} from "../../../business/variable/queries";
import { TVariableSources, VARIABLE_SOURCE_STYLE_CONFIG } from "../../../conf/variable_source";
import { IFunctionItem, ITextItem, IVariableItem } from "./FieldSelectionTypes";

interface IFieldSelectionMenuProps {
  onSelection: (item: IFunctionItem | IVariableItem | ITextItem) => void;
}

/**
 * The FieldSelectionMenu component
 * @param {IFieldSelectionMenuProps} props
 * @returns a FieldSelectionMenu component
 */
const FieldSelectionAddMenuContent = ({ onSelection }: IFieldSelectionMenuProps): JSX.Element => {
  const allToolConstantsVariables: any = {};
  const toolId = useLocalStorage<{ tool?: { id?: number } }>("tag_creation_wizard_data", {})[0]
    ?.tool?.id;
  const tagId: number = useParamsId("id");
  const isNewTag = tagId === 0;

  if (isNewTag) {
    allToolConstantsVariables.toolId = toolId;
  } else {
    allToolConstantsVariables.tagId = tagId;
  }

  const allVariablesData = useSimpleFetching<GetAllVariablesQueryDefinition>({
    query: GET_ALL_VARIABLES,
    context: "JTM",
  });

  const allFunctionsData = useSimpleFetching<GetAllFunctionsQueryDefinition>({
    query: GET_ALL_FUNCTIONS,
    context: "JTM",
  });

  const allToolConstants = useSimpleFetching<GetToolConstantsByTagOrToolQueryDefinition>({
    query: GET_TOOL_CONSTANTS_BY_TAG_OR_TOOL_ID,
    context: "JTM",
    queryArguments: allToolConstantsVariables,
  });

  const [value, setValue] = useState("");

  const handleSelection = (item: IFunctionItem | IVariableItem | ITextItem): void => {
    onSelection(item);
  };

  const insertAsText = () => {
    if (value) handleSelection({ name: value, type: "text" });
  };

  if (allVariablesData === false || allFunctionsData === false || allToolConstants === false) {
    return (
      <Box style={{ height: "454px", minWidth: "320px" }}>
        <LoadComponentSpinner />;
      </Box>
    );
  }

  const options = transformVariablesAndFunctionsToOptions(
    allVariablesData.variables,
    allFunctionsData.functions,
    allToolConstants.toolConstants,
  );

  const searchOptions = options
    .filter((option) => !(option.name === null || option.short_id === null)) // filter for faulty constants in db
    .map((option) => {
      return { key: option.name, value: option };
    });

  searchOptions.sort((a, b) => a.value.name.localeCompare(b.value.name));

  return (
    <ListSearchComponent
      placeholderText="Search for Element"
      options={searchOptions}
      onChange={setValue}
      actionButtonProps={{ name: "Insert as text", onClick: insertAsText }}
      renderInput={(option) => (
        <Box
          sx={{ cursor: "pointer", p: 1, ":hover": { background: "#eee" } }}
          component="li"
          onClick={() => handleSelection(option.value)}>
          <Stack direction="row" spacing={2} alignItems="center">
            {renderOptionIcon(option.value)}
            <Typography variant="body1">{option.value.name}</Typography>
          </Stack>
        </Box>
      )}
      sx={{ minWidth: "320px" }}
    />
  );
};

interface IVariable {
  name: string;
  short_id: string;
  source: TVariableSources;
}
interface IFunction {
  name: string;
  short_id: string;
}

interface IToolConstant {
  name: string;
  short_id: string;
  desc: string;
}

const transformVariablesAndFunctionsToOptions = (
  variables: IVariable[],
  functions: IFunction[],
  toolConstants: IToolConstant[],
) => {
  let options: (IFunctionItem | IVariableItem)[] = [];
  addToOptions(variables, options, "variable");
  addToOptions(toolConstants, options, "variable");
  addToOptions(functions, options, "function");

  options = removeDuplicateObjectsFromArray(options, "short_id");
  options = options.sort((option1, option2) => (option1.name > option2.name ? 1 : -1));
  return options;
};

const addToOptions = (
  list: IVariable[] | IToolConstant[] | IFunction[],
  options: (IFunctionItem | IVariableItem)[],
  type: "variable" | "function",
) => {
  for (const element of list) {
    let source: "BACKENDJS" | "FRONTENDJS" | "ENRICHMENT" | "ACCOUNTCONSTANT" | undefined;
    if (type === "variable") {
      if ((element as IVariable).source) {
        source = (element as IVariable).source;
      } else {
        source = "ACCOUNTCONSTANT";
      }
    }
    options.push({
      short_id: element.short_id,
      type,
      source,
      name: element.name,
    });
  }
};

export default FieldSelectionAddMenuContent;

const renderOptionIcon = (option: IFunctionItem | IVariableItem): JSX.Element => {
  let icon = faFunction;
  let color = colorStyles.jentisYellow;

  if (option.type === "variable" && option.source !== undefined && option.source !== null) {
    const type: TVariableSources = option.source;
    icon = VARIABLE_SOURCE_STYLE_CONFIG[type].icon;
    color = VARIABLE_SOURCE_STYLE_CONFIG[type].color;
  }
  return <FontAwesomeIcon icon={icon} color={color} style={{ width: "20px" }} />;
};
