import { gql } from "@apollo/client";

const GET_CONSENT_CONFIG = gql`
  query FindConfigs {
    findConfigs {
      id
      current
      updatetime
      templateid
      strict
      userlifetimedays
      service_contact
      accid
    }
  }
`;

export const SAVE_CONSENT_CONFIG = gql`
  mutation UpdateConfig($strict: Int!) {
    updateConfig(strict: $strict) {
      id
      current
      updatetime
      templateid
      strict
      userlifetimedays
      service_contact
      accid
    }
  }
`;

export default GET_CONSENT_CONFIG;
