import { Box } from "@mui/material";
import { GET_ALL_VARIABLES } from "apps/jtm/business/variable/queries";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import SelectWrapper from "libs/ui/components/input/SelectWrapper";
import { useState } from "react";

interface IVariableSelectProps {
  selected: string;
  name: string;
  readOnly?: boolean;
  required?: boolean;
}

export default function VariableSelect({
  selected,
  name,
  readOnly = false,
  required = false,
}: IVariableSelectProps) {
  const { setMaintained } = useMaintained();
  const [getVariables, checkGetVariables] = useQueryWrapper(GET_ALL_VARIABLES);
  const [allVariables, setAllVariables]: any[] = useState([]);

  if (!checkGetVariables.called) {
    getVariables({
      context: { clientName: "JTM" },
      variables: { sortParam: "name", sortDirection: "asc" },
      onCompleted: (data) => {
        const vars = [];
        for (const variable of data.variables) {
          vars.push({
            key: variable.short_id,
            value: variable.name,
          });
        }
        setAllVariables([...vars]);
      },
    });
  }

  if (!checkGetVariables.called || checkGetVariables.loading) {
    return <div />;
  }

  const onChange = (newValue: string | number) => {
    if (selected !== newValue.toString()) {
      setMaintained(false);
    }
  };

  return (
    <Box sx={{ flex: 1 }}>
      <SelectWrapper
        label={`Variable ${required ? "*" : ""}`}
        name={name}
        value={selected}
        options={allVariables}
        style={{ margin: 0 }}
        readonly={readOnly}
        onChange={onChange}
        disableSorting
      />
    </Box>
  );
}
