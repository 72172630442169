import { faClipboardList, faCloud, faDesktop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Stack, SxProps, Theme } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridEnrichedColDef,
  GridRenderCellParams,
  GridRowHeightParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { jtmPrefix } from "apps/jtm/conf/routes";
import UserService from "libs/business/authentication/UserService";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import dateFormatterDatagrid, { dateFormatter } from "libs/business/utils/timeTransformation";
import LinkStyledButton from "libs/ui/components/buttons/LinkStyledButton";
import getCustomToolBar from "libs/ui/components/datagrid/CustomToolBar";
import IRow from "libs/ui/components/datagrid/IRow";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { useCallback, useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { UPDATE_PREVIEW } from "../../business/output/queries";
import { usePublishDialog } from "./PublishDialog";
import { useSystemUpdateDialog } from "./SystemUpdatesPopUp";
import { getUpdatePreviewOptions, openPreviewMonitor } from "./update_preview";

interface IVersionGridProps {
  rowsData: IRow[];
}

const checkIfUpdatePreviewIsPossible = (editVersion: number) => {
  return editVersion === 1 && process.env.REACT_APP_ENVIRONMENT !== "dev";
};

const VersionGrid = ({ rowsData }: IVersionGridProps): JSX.Element => {
  const [updatePreview, mutationState] = useMutationWrapper(UPDATE_PREVIEW);
  const { handleOpenModal } = usePublishDialog();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const userAccessGroup = UserService.getAccessGroup();
  const systemUpdateDialog = useSystemUpdateDialog();

  const onPreview = useCallback(
    (params: GridRowParams<any>) => () => {
      const versionNumber = params.row.number;
      const containerURL = params.row.container.test_url || params.row.container.live_url;
      if (checkIfUpdatePreviewIsPossible(params.row.edit)) {
        updatePreview(
          getUpdatePreviewOptions([params.row.container_id], versionNumber, containerURL, true),
        );
      } else {
        openPreviewMonitor(versionNumber, containerURL, params.row.container_id);
      }
    },
    [],
  );

  const onShowLog = useCallback(
    (params: GridRowParams<any>) => () => {
      const versionData = params.row;
      navigate({
        pathname: `${jtmPrefix}/versions/log`,
        search: createSearchParams({
          versionId: versionData.id,
          containerName: versionData.container.name,
          versionNumber: versionData.number,
          editAt: dateFormatter(versionData.edit_at),
        }).toString(),
      });
    },
    [],
  );

  const onPublish = useCallback(
    (params: GridRowParams<any>) => () => {
      const versionData = params.row;
      handleOpenModal({
        containerName: versionData.container.name,
        versionNumber: versionData.number,
        versionId: versionData.id,
        classification: versionData.classification,
      });
    },
    [],
  );

  const getRowHeight = useCallback(({ model, densityFactor }: GridRowHeightParams) => {
    const gaps = 8 * (model.public_environments.length - 1);
    const chipHeight = 24;
    const isNotEdit = model.edit !== 1 ? 1 : 0;
    if (model.public_environments.length > 0)
      return (
        80 * densityFactor + (model.public_environments.length - 1 - isNotEdit) * chipHeight + gaps
      );
    return null;
  }, []);

  const ActionArrayDOM = (params: GridRowParams) => {
    const gridActionArray = [];
    const neededAccessGroup = [1, 2, 3, 4, 5, 6];
    if (neededAccessGroup.includes(userAccessGroup)) {
      gridActionArray.push(
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faDesktop} />}
          label="Preview"
          onClick={onPreview(params)}
          showInMenu
        />,
      );
    }
    gridActionArray.push(
      <GridActionsCellItem
        icon={<FontAwesomeIcon icon={faClipboardList} />}
        label="Log"
        onClick={onShowLog(params)}
        showInMenu
      />,
    );
    if (neededAccessGroup.includes(userAccessGroup)) {
      gridActionArray.push(
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faCloud} />}
          label="Publish"
          onClick={onPublish(params)}
          showInMenu
        />,
      );
    }
    return gridActionArray;
  };

  const columns: GridEnrichedColDef[] = [
    {
      field: "number",
      headerName: "Version",
      width: 120,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <>
            {params.value}
            <Stack gap={1} sx={{ py: 1, maxWidth: 100 }}>
              {params.row.edit === 1 && (
                <Chip label="current" color="success" size="small" sx={{ ml: 2 }} />
              )}
              {params.row.public_environments.map((env: any) => (
                <Chip label={env.name} key={env.name} color="warning" size="small" sx={{ ml: 2 }} />
              ))}
            </Stack>
          </>
        );
      },
      valueGetter: ({ value }) => parseInt(value, 10),
    },
    {
      field: "classification",
      headerName: "Critical Updates",
      width: 120,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.classification === "critical" || params.row.classification === "emergency") {
          return (
            <LinkStyledButton
              onClick={() => systemUpdateDialog.handleOpenModal(params.row.id)}
              tooltipText="This version includes critical system updates performed by JENTIS. Click to view updates.">
              critical updates
            </LinkStyledButton>
          );
        }
        return <div />;
      },
      valueGetter: ({ value }) =>
        value === "emergency" || value === "critical" ? "critical, emergency" : "normal",
    },
    {
      field: "container",
      headerName: "Container Name",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }) => value?.name || "-",
    },
    {
      field: "live_url",
      headerName: "URL",
      width: 200,
      valueGetter: ({ row }) => row?.container?.live_url || "-",
    },
    {
      field: "create_at",
      headerName: "Created On",
      width: 160,
      type: "dateTime",
      valueFormatter: dateFormatterDatagrid,
    },
    {
      field: "edit_at",
      headerName: "Last Update",
      width: 160,
      type: "dateTime",
      valueFormatter: dateFormatterDatagrid,
    },
    {
      field: "actions",
      type: "actions",
      width: 10,
      getActions: ActionArrayDOM,
    },
  ];

  const customToolBar = useMemo(() => getCustomToolBar({ showContainerFilter: true }), []);

  if (mutationState.loading) {
    return <PageLoadingSpinner />;
  }

  return (
    <DataGrid
      initialState={{
        sorting: { sortModel: [{ field: "number", sort: "desc" }] },
      }}
      getRowHeight={getRowHeight}
      rows={rowsData}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize: any) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 25, 50, 100]}
      disableSelectionOnClick
      autoHeight
      sx={sxForHighlightCriticalRows}
      getRowClassName={(params) => `${params.row.classification || ""}`}
      {...customToolBar}
    />
  );
};

export default VersionGrid;

export const sxForHighlightCriticalRows: SxProps<Theme> = {
  "& .critical": {
    bgcolor: "warning.light",
    "&:hover": {
      bgcolor: "warning.light",
    },
  },
  "& .emergency": {
    bgcolor: "warning.light",
    "&:hover": {
      bgcolor: "warning.light",
    },
  },
};
