import { Stack } from "@mui/material";
import CircularSpinner from "./CircularSpinner";

/**
 * The PageLoadingSpinner component
 * @returns a PageLoadingSpinner component
 */
const PageLoadingSpinner = (): JSX.Element => {
  return (
    <Stack
      sx={{ color: "primary.main", width: "100%", height: "100%" }}
      alignItems="center"
      justifyContent="center"
      data-cy="loadingSpinner">
      <CircularSpinner />
    </Stack>
  );
};

export default PageLoadingSpinner;
