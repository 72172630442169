import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import {
  getFirstKeyOfObject,
  getNextObjectProperty,
  getPreviousObjectProperty,
  isEmptyObject,
} from "libs/business/utils/objectFunctions";
import LoadingSpinner from "libs/ui/components/feedback/loading-spinner/LoadingSpinner";
import { useMemo } from "react";
import CurrentVersionCardContent from "./CurrentVersionCardContent";
import PublishedCardContent from "./PublishedCardContent";
import VersionCard from "./VersionCard";

const QuickContainerVersionCard = ({ data }: any): JSX.Element => {
  const firstContainerID = getFirstKeyOfObject(data);
  const [containerID, setContainerID] = usePropChangingState(firstContainerID);
  const cointainerData = useMemo(() => ({ ...data[containerID] }), [data[containerID]]);
  const [containerVersion, setContainerVersion] = usePropChangingState<any>(cointainerData);
  const title = `${containerVersion.container_name}`;

  const showArrows = () => {
    return Object.keys(data).length > 1;
  };

  const setNextContainer = () => {
    const nextContainer = getNextObjectProperty(data, containerID);
    setContainerID(nextContainer);
    setContainerVersion(data[nextContainer]);
  };

  const setPreviousContainer = () => {
    const previousContainer = getPreviousObjectProperty(data, containerID);
    setContainerID(previousContainer);
    setContainerVersion(data[previousContainer]);
  };
  if (isEmptyObject(containerVersion)) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Typography variant="h2" sx={{ p: "0 36px" }}>
        {title}
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              pt: 2,
              pb: 2,
              height: "calc(100% - 32px)",
            }}>
            <Stack sx={{ height: "100%", minWidth: "28px" }} justifyContent="center">
              <IconButton
                onClick={setPreviousContainer}
                sx={{ borderRadius: 0, display: showArrows() ? "" : "none" }}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </IconButton>
            </Stack>
            <VersionCard
              versionNumber={containerVersion?.publishedVersion?.number}
              cardTitle="Published Version">
              <PublishedCardContent data={containerVersion?.publishedVersion} />
            </VersionCard>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              pt: 2,
              pb: 2,
              height: "calc(100% - 32px)",
            }}>
            <VersionCard
              versionNumber={containerVersion?.currentVersion?.number}
              cardTitle="Current Version">
              <CurrentVersionCardContent data={containerVersion?.currentVersion} />
            </VersionCard>
            <Stack sx={{ height: "100%", minWidth: "28px" }} justifyContent="center">
              <IconButton
                onClick={setNextContainer}
                sx={{ borderRadius: 0, display: showArrows() ? "" : "none" }}>
                <FontAwesomeIcon icon={faAngleRight} />
              </IconButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default QuickContainerVersionCard;
