import Page from "../../surfaces/page/Page";
import CompareBoolean from "../CompareBoolean";
import CompareConstant from "./constant/CompareConstant";
import { LogConstantData } from "./constant/types";

interface ToolData {
  __typename?: string;
  no_consent_mode?: boolean;
  constants?: { [key: string]: LogConstantData };
}

interface CompareToolProps {
  old_toolData: ToolData;
  new_toolData: ToolData;
}
/**
 * The CompareTool component
 * @param {CompareToolProps} props
 * @returns a CompareTool component
 */
const CompareTool = ({ old_toolData, new_toolData }: CompareToolProps): JSX.Element => {
  const { no_consent_mode: oldNoConsentMode, constants: oldConstants } = old_toolData;
  const { no_consent_mode: newNoConsentMode, constants: newConstants } = new_toolData;

  const noConsentModeShouldBeRendered =
    typeof oldNoConsentMode !== "undefined" &&
    typeof newNoConsentMode !== "undefined" &&
    oldNoConsentMode !== newNoConsentMode;

  const constantsShouldBeRendered =
    typeof oldConstants !== "undefined" &&
    typeof newConstants !== "undefined" &&
    JSON.stringify(oldConstants) !== JSON.stringify(newConstants);

  return (
    <>
      {noConsentModeShouldBeRendered && (
        <CompareBoolean
          inputName="no_consent_mode"
          leftLabel="Old Essential Mode"
          rightLabel="New Essential Mode"
          leftValue={oldNoConsentMode}
          rightValue={newNoConsentMode}
        />
      )}
      {constantsShouldBeRendered && (
        <>
          {getUniqueConstantShortIds(oldConstants, newConstants).map((shortId) => {
            const emptyConstant: LogConstantData = { id: "", name: "", value: "" };
            const oldConstant = oldConstants[shortId] || emptyConstant;
            const newConstant = newConstants[shortId] || emptyConstant;

            return (
              <Page key={shortId} title={`${oldConstant.name || newConstant.name}`} notFirst>
                <CompareConstant leftValue={oldConstant} rightValue={newConstant} />
              </Page>
            );
          })}
        </>
      )}
    </>
  );
};

export default CompareTool;

const getUniqueConstantShortIds = (
  constants1: { [key: string]: LogConstantData },
  constants2: { [key: string]: LogConstantData },
): string[] => {
  const ids1 = Object.keys(constants1).map((key) => constants1[key].id);
  const ids2 = Object.keys(constants2).map((key) => constants2[key].id);
  const concatKeys = ids1.concat(ids2);
  const uniqueSet = new Set(concatKeys);
  return [...uniqueSet];
};
