import { gql } from "@apollo/client";
import { GQLTypeDefinition, SingleResponse } from "libs/model/types";

export interface BackendNexusContainerStatusType {
  containerId: number;
  dns: boolean;
  snippet: boolean;
}

export interface GetContainerStatusByIdQueryDefinition extends GQLTypeDefinition {
  dataType: BackendNexusContainerStatusType;
  response: SingleResponse<"getContainerStatus", GetContainerStatusByIdQueryDefinition["dataType"]>;
  arguments: { containerId: number };
  context: "SETUP";
}

export const GET_CONTAINER_STATUS_BY_ID = gql`
  query getContainerStatus($containerId: Int!) {
    getContainerStatus(containerId: $containerId) {
      containerId
      dns
      snippet
    }
  }
`;

export interface BackendNexusCustomerType {
  name: string;
  ipOfCluster: string;
  accountManager: string;
  email: string;
}

export interface GetCustomerQueryDefinition extends GQLTypeDefinition {
  dataType: BackendNexusCustomerType;
  response: SingleResponse<"getCustomer", GetCustomerQueryDefinition["dataType"]>;
  context: "SETUP";
}

export const GET_CUSTOMER = gql`
  query getCustomer {
    getCustomer {
      name
      ipOfCluster
      accountManager
      email
    }
  }
`;

export const GET_STATUS_PER_CONTAINER = gql`
  query getStatusPerContainer {
    getStatusPerContainer {
      containerStatuses {
        containerId
        dns
        snippet
      }
    }
  }
`;
