import { useRef, useEffect, useCallback } from "react";

const useTimeout = (callback: () => void, delayInMs: number) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<undefined | NodeJS.Timeout>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delayInMs);
  }, [delayInMs]);

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delayInMs, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
};

export default useTimeout;
