import * as yup from "yup";

export const getValidationSchema = (shortIds: string[] | undefined) => {
  const schemaObject: any = {
    name: yup.string().required().max(256, "The name must be shorter than 256 characters."),
    condition: yup.array().of(
      yup.array().of(
        yup.object().shape({
          variable: yup.string().required("Variable is required!"),
          operator: yup.string().required("Operator is required!"),
          value: yup.string().required("Value is required!"),
        }),
      ),
    ),
  };
  if (shortIds) {
    schemaObject.short_id = yup
      .string()
      .required()
      .notOneOf(shortIds, "This id is already in use. Choose another Name!");
  }
  return yup.object(schemaObject);
};

export default getValidationSchema;
