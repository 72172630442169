import { gql } from "@apollo/client";

export const GET_CONSENTBARS = gql`
  query Consentbars {
    Consentbars {
      id
      create_at
      create_by
      edit_at
      edit_by
      lib_js
      version
      current
      config_js
      pluginid
      name
      description
      url
      css
    }
  }
`;

export const GET_CONSENTBAR = gql`
  query Consentbar($consentbarId: Int!) {
    Consentbar(id: $consentbarId) {
      id
      create_at
      create_by
      edit_at
      edit_by
      lib_js
      version
      current
      config_js
      pluginid
      name
      description
      url
      css
    }
  }
`;

export const GET_CONSENTBAR_CONFIG = gql`
  query ConsentbarData($consentbarId: Int!) {
    ConsentbarData(consentbarID: $consentbarId) {
      id
      name
      config_js
      template_consentbar_selected {
        containerid
        config_json
      }
    }
  }
`;

export const GET_CONNECTED_CONSENTBAR_DATA = gql`
  query ActiveConsentbarData($consentbarId: Int!) {
    ActiveConsentbarData(consentbarID: $consentbarId) {
      id
      name
      template_consentbar_selected {
        id
        accid
        containerid
        config_json
        active
        changed
      }
    }
  }
`;

export const SAVE_CONSENTBAR_CONFIG = gql`
  mutation SaveConsentbarConfig($consentbarId: Int!, $containers: [Int!]!, $config: String!) {
    saveConsentbarConfig(consentbarID: $consentbarId, containers: $containers, config: $config) {
      id
      create_at
      create_by
      edit_at
      edit_by
      accid
      consentbar_consentbarid
      config_json
      active
      changed
      consentbar {
        id
        name
        config_js
      }
    }
  }
`;

export const SAVE_CONSENTBAR_CONNECTIONS = gql`
  mutation UpdateConsentbarConnections($containerids: [Int!]!, $consentbarConsentbarid: Int!) {
    updateConsentbarConnections(
      containerids: $containerids
      consentbar_consentbarid: $consentbarConsentbarid
    ) {
      id
      create_at
      create_by
      edit_at
      edit_by
      accid
      containerid
      consentbar_consentbarid
      config_json
      active
      changed
    }
  }
`;

export default GET_CONSENTBARS;
