import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { ContainerEnvironmentValues } from "libs/model/types";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import InputTable from "../../input/InputTable";
import AlertBox from "../../surfaces/alertbox/alertBox";

export interface FieldsDefaultValue {
  short_id: string;
  name: string;
  desc?: string;
  value: ContainerEnvironmentValues<string>;
}

interface PaginatedInputDialogProps {
  title?: string;
  subtitle?: string;
  open: boolean;
  onClose?: () => void;
  rootInputName: string;
  defaultValues: ContainerEnvironmentValues<string>;

  selectedContainerIds: number[];
}

/**
 * The PaginatedInputDialog component
 * @param {PaginatedInputDialogProps} props
 * @returns a PaginatedInputDialog component
 */
const PaginatedInputDialog = ({
  rootInputName,
  title,
  open,
  subtitle,
  onClose,
  defaultValues,
  selectedContainerIds,
}: PaginatedInputDialogProps): JSX.Element => {
  const formContext = useFormContext();

  const filterNotNullOptions = () => defaultValues.options?.filter((option) => option);

  const filteredDefaultOptions = filterNotNullOptions();

  useEffect(() => {
    if (filteredDefaultOptions) {
      formContext.setValue(`${rootInputName}.options`, []);

      filteredDefaultOptions.forEach((option) => {
        formContext.setValue(
          `${rootInputName}.options.${option.account}${option.environment}.value`,
          option.value,
        );
        if (option.environment !== undefined) {
          formContext.setValue(
            `${rootInputName}.options.${option.account}${option.environment}.environment`,
            parseInt(option.environment.toString(), 10),
          );
        }
        formContext.setValue(
          `${rootInputName}.options.${option.account}${option.environment}.account`,
          parseInt(option.account.toString(), 10),
        );
      });
    }
  }, [defaultValues, formContext, rootInputName]);

  return (
    <Dialog
      sx={{
        width: "100%",
        overflowY: "none",
        "& .MuiPaper-root": {
          maxWidth: "none",
        },
      }}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}>
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography sx={{ display: "flex", flexDirection: "column" }} variant="titleMedium">
          {title}
        </Typography>
        <IconButton onClick={onClose} sx={{ alignSelf: "flex-start" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography mb={2} variant="bodyRegular16" component="p">
          {subtitle}
        </Typography>
        <Box sx={{ mb: 2 }}>
          <AlertBox
            type="info"
            message="If a particular container requires a value that is different from the default value, then
          please add it to this table."
          />
        </Box>
        <InputTable rootInputName={rootInputName} selectedContainerIds={selectedContainerIds} />
        <Button variant="contained" onClick={onClose} sx={{ float: "right", mt: 2 }}>
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PaginatedInputDialog;
