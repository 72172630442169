import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack } from "@mui/material";
import { PASSWORD_CHANGE } from "apps/admin/business/login/queries";
import UserService from "libs/business/authentication/UserService";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import LoginHeader from "./LoginHeader";

interface IChangeParams {
  email: string;
  oldPassword?: string;
  newPassword: string;
  newPasswordAgain: string;
}

const schema = yup.object({
  newPassword: yup
    .string()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    ),
  newPasswordAgain: yup
    .string()
    .required()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export default function PasswordChangeForm() {
  const methods = useForm<IChangeParams>({ resolver: yupResolver(schema) });
  const [passwordChange] = useMutation(PASSWORD_CHANGE);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  let email = searchParams.get("email");
  const isPasswordReset = email && token;
  const user = UserService.getUser();

  if (!isPasswordReset && user.email) {
    email = user.email;
  }

  const formSubmitHandler: SubmitHandler<IChangeParams> = (submitData: IChangeParams) => {
    if (submitData.newPassword !== submitData.newPasswordAgain) {
      notifyError("Passwords do not match!");
    } else {
      let variables;
      if (isPasswordReset) {
        variables = {
          email,
          token,
          newPassword: submitData.newPassword,
        };
      } else {
        variables = {
          email,
          oldPassword: submitData.oldPassword,
          newPassword: submitData.newPassword,
        };
      }

      passwordChange({
        variables,
        context: { clientName: "ADMIN" },
        onCompleted: (data) => {
          notifySuccess("Password changed!");
          if (isPasswordReset) navigate("/");
        },
        onError: (error) => {
          if (error.message === "Passwords do not match") {
            notifyError("The old password is incorrect!");
          } else {
            notifyError(error.message);
          }
        },
      });
    }
  };

  const handleCancelClick = () => navigate("/");

  if (isPasswordReset) {
    return (
      <FormProvider {...methods} key="loginForm">
        <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <LoginHeader title="Welcome back" description="Please insert your new password!" />

          <TextFieldWrapper name="newPassword" label="New Password" type="password" required />

          <TextFieldWrapper
            name="newPasswordAgain"
            label="New Password Again"
            type="password"
            required
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
            <Button variant="outlined" color="primary" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Stack>
        </form>
      </FormProvider>
    );
  }

  return (
    <FormProvider {...methods} key="loginForm">
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <TextFieldWrapper name="oldPassword" label="Old Password" type="password" required />
        <TextFieldWrapper name="newPassword" label="New Password" type="password" required />
        <TextFieldWrapper
          name="newPasswordAgain"
          label="Confirm New Password"
          type="password"
          required
        />
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="contained" type="submit">
            Update Password
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}
