import { faArrowRight, faArrowRightRotate } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import SwitchWrapper from "../input/SwitchWrapper";

interface ICompareBooleanProps {
  inputName: string;
  leftLabel: string;
  rightLabel: string;
  leftValue: boolean;
  rightValue: boolean;
  allowCompareEdit?: boolean;
}
/**
 * The CompareBoolean component
 * @param {ICompareBooleanProps} props
 * @returns a CompareBoolean component
 */
const CompareBoolean = ({
  inputName,
  leftLabel,
  rightLabel,
  leftValue,
  rightValue,
  allowCompareEdit = false,
}: ICompareBooleanProps): JSX.Element => {
  const memoizedValue = useMemo(() => rightValue, []);
  const [rightVal, setRightVal] = useState<boolean | number>(rightValue);
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-evenly">
      <SwitchWrapper name={`left_${inputName}`} label={leftLabel} disabled value={leftValue} />

      {renderCopyArrow(allowCompareEdit, () => {
        setRightVal(leftValue);
      })}
      <SwitchWrapper
        name={inputName}
        label={rightLabel}
        disabled={!allowCompareEdit}
        value={!!rightVal}
        onChange={setRightVal}
      />
      {renderReset(allowCompareEdit, () => setRightVal(memoizedValue))}
    </Stack>
  );
};

export default CompareBoolean;

const renderCopyArrow = (show: boolean, onClick: () => void): JSX.Element | null => {
  if (show) {
    return (
      <Button style={{ marginTop: "8px" }} onClick={onClick}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    );
  }
  return <FontAwesomeIcon icon={faArrowRight} />;
};

const renderReset = (show: boolean, onClick: () => void): JSX.Element | null => {
  if (show) {
    return (
      <IconButton style={{ marginTop: "8px" }} onClick={onClick} size="small">
        <FontAwesomeIcon icon={faArrowRightRotate} />
      </IconButton>
    );
  }
  return null;
};
