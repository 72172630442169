import AutoGenerateNameAndID from "apps/jtm/components/shared/AutoGenerateNameAndID";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import StateSelect from "./StateSelect";
import { Trigger } from "./types";

interface TriggerDetailsProps {
  triggerData: Trigger;
  mode: "create" | "update";
}
/**
 * The TriggerDetails component
 * @param {TriggerDetailsProps} props
 * @returns a TriggerDetails component
 */
const TriggerDetails = ({ triggerData, mode }: TriggerDetailsProps): JSX.Element => {
  const { setMaintained } = useMaintained();

  const onDescChange = (value: string) => {
    if (value !== triggerData.desc) {
      setMaintained(false);
    }
  };

  return (
    <>
      <TextFieldWrapper name="id" label="id" value={triggerData.id} hidden />

      <AutoGenerateNameAndID mode={mode} short_id={triggerData.short_id} name={triggerData.name} />
      <TextFieldWrapper
        name="desc"
        label="Description"
        value={triggerData.desc}
        multiline
        onChange={onDescChange}
      />
      <StateSelect triggerData={triggerData} />
    </>
  );
};

export default TriggerDetails;
