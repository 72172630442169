import { GET_ALL_FRONTEND_PLUGIN_DEFINITION } from "apps/jtm/business/frontend_plugin/queries";
import PluginSelection from "apps/jtm/components/shared/plugin-selection/PluginSelection";
import getRoutePath from "libs/business/routes/routes";

const FrontendPluginSelection = (): JSX.Element => {
  return (
    <PluginSelection
      query={GET_ALL_FRONTEND_PLUGIN_DEFINITION}
      redirectLinkCard={getRoutePath("jtm", "plugin_new")}
    />
  );
};

export default FrontendPluginSelection;
