import { Box, Typography } from "@mui/material";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import CopyClipboardButton from "libs/ui/components/buttons/CopyClipboardButton";
import { FC } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const TrackingCodeSpa: FC = () => {
  const spaSnippet1 = "_jts.push({'track':'pageview'});";
  const spaSnippet2 = "_jts.push({'track':'submit'});";
  return (
    <>
      <AlertBox
        boxStyles={{ mt: "5px" }}
        type="info"
        message="After the basic setup, JENTIS will track a lot of data independently and send it to the TWIN-SERVER. Some additional data must be transfered manually. That your Single Page Application (SPA) work yo have to do the following steps. "
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "5px",
        }}>
        <Typography sx={{ alignSelf: "flex-end" }}>
          You should implement on every page the following statement to trigger a pageview:
        </Typography>
        <CopyClipboardButton textToCopy={spaSnippet1} buttonText="Copy" />
      </Box>
      <SyntaxHighlighter
        customStyle={{ borderRadius: "5px" }}
        wrapLongLines
        language="javascript"
        style={dark}>
        {spaSnippet1}
      </SyntaxHighlighter>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "5px",
        }}>
        <Typography variant="h4" component="h4" sx={{ alignSelf: "flex-end" }}>
          and after you pushed everything to our datalayer you need to submit.
        </Typography>
        <CopyClipboardButton textToCopy={spaSnippet2} buttonText="Copy" />
      </Box>
      <SyntaxHighlighter
        customStyle={{ borderRadius: "5px" }}
        wrapLongLines
        language="javascript"
        style={dark}>
        {spaSnippet2}
      </SyntaxHighlighter>
    </>
  );
};

export default TrackingCodeSpa;
