import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
import { Container } from "@mui/material";
import GET_CONSENTBARS from "apps/cmp/business/consentbars/queries";
import { cmpPrefix } from "apps/cmp/conf/routes";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { ICardListCard } from "libs/ui/components/surfaces/card-list/Card";
import CardList from "libs/ui/components/surfaces/card-list/CardList";
import md5 from "md5";

export default function Consentbars() {
  const getConsentbarsResponse = useSimpleFetching({ query: GET_CONSENTBARS, context: "CMP" });

  const consentbars: ICardListCard[] = [];

  if (getConsentbarsResponse === false) {
    return <PageLoadingSpinner />;
  }

  for (const consentbar of getConsentbarsResponse.Consentbars) {
    const image = md5(consentbar.pluginid);
    consentbars.push({
      title: consentbar.name,
      image: `https://pixelstudio.dev.jentis.com/api/images/${image}/medium`,
      link: `${cmpPrefix}/consentbar/${consentbar.id}`,
      menuItemConfigs: [],
    });
  }

  return (
    <Container>
      <CardList list={consentbars} />
    </Container>
  );
}
