import { DeleteContainerQueryDefinition } from "apps/admin/business/container/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import getRoutePath from "libs/business/routes/routes";
import BasicGrid from "libs/ui/components/datagrid/BasicGrid";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";
import {
  DELETE_USER_BY_ID,
  GetAllUsersQueryDefinition,
  GET_ALL_USER,
} from "../../business/user/queries";

const UserGrid = (): JSX.Element => {
  const getAllUserResponse = useSimpleFetching<GetAllUsersQueryDefinition>({
    query: GET_ALL_USER,
    context: "ADMIN",
  });
  const [deleteUser, deleteUserMutationState] =
    useMutationWrapper<DeleteContainerQueryDefinition>(DELETE_USER_BY_ID);

  if (getAllUserResponse === false) return <PageLoadingSpinner />;

  const isFaultyUser = (user: GetAllUsersQueryDefinition["dataType"]) => {
    if (user.group === null) return true;
    if (!user.email && !user.username) return true;
    return false;
  };

  const usersWithoutSuperadmin = getAllUserResponse.users.filter((user) => {
    if (isFaultyUser(user)) {
      if (!deleteUserMutationState.called) {
        deleteUser({
          variables: { id: user.id },
          context: { clientName: "ADMIN" },
        });
      }
      return false;
    }

    return user.group.id !== 1;
  });

  return (
    <Page
      title="Users"
      newButtonConfig={{
        instanceName: "User",
        newInstanceLink: getRoutePath("admin", "register"),
      }}>
      <BasicGrid
        columnConfig={[
          {
            field: "username",
            headerName: "Name",
            valueGetter: ({ row }) => row.username || row.email || "-",
            minWidth: 250,
            flex: 1,
          },
          {
            field: "group",
            headerName: "Permission Group",
            valueGetter: ({ row }) => row?.group?.name || "-",
            width: 250,
          },
        ]}
        actionConfig={{
          editLink: {
            service: "admin",
            routeName: "user_edit",
          },
          deleteFunction: deleteUser,
          copy: false,
          log: false,
          settings: {
            editable: true,
            deletable: true,
            copyable: false,
            hasLog: false,
          },
        }}
        rowsData={usersWithoutSuperadmin}
      />
    </Page>
  );
};

export default UserGrid;
