import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { KeyValuePair } from "libs/model/types";
import { get, useFormContext } from "react-hook-form";
import { SelectSearchOption } from "./SelectWithSearcht";

interface MultiSelectWithSearchProps {
  inputName: string;
  instanceName: string;
  options: SelectSearchOption[];
  limitTags?: number;
  fullWidth?: boolean;
  onChange?: (newValue: KeyValuePair["key"][] | undefined) => void;
  defaultValue?: SelectSearchOption["key"][];
  shouldUnregister?: boolean;
  helperText?: string;
}

const MultiSelectWithSearch = ({
  inputName,
  options,
  instanceName,
  limitTags = -1,
  fullWidth = false,
  defaultValue,
  onChange,
  helperText = "",
}: MultiSelectWithSearchProps) => {
  const instanceNameToLowerCase = instanceName.toLowerCase();
  const formContext = useFormContext();

  const findOptionValue = (optionKey: SelectSearchOption["key"]) =>
    options.find((option) => option.key === optionKey)?.value || <span>Not Found</span>;

  const findOptionLabel = (optionKey: SelectSearchOption["key"]) =>
    options.find((option) => option.key === optionKey)?.optionLabel || "Not Found";

  const renderOption = (props: any, option: SelectSearchOption["key"]) => {
    return <Box {...props}>{findOptionValue(option)}</Box>;
  };

  const formRegisterValues = formContext.register(inputName, {
    value: defaultValue,
  });

  const nameErrors = get(formContext.formState.errors, inputName);

  return (
    <Autocomplete
      {...formRegisterValues}
      limitTags={limitTags}
      multiple
      id={inputName}
      options={options.map((option) => option.key)}
      defaultValue={formContext.getValues(inputName) || defaultValue}
      renderOption={renderOption}
      getOptionLabel={findOptionLabel}
      onChange={(e, value) => {
        formContext.setValue(inputName, value || []);
        if (onChange) onChange(value || []);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            "data-cy": inputName,
          }}
          error={!!nameErrors}
          label={`Please select ${instanceNameToLowerCase}`}
          placeholder={`Search for ${instanceNameToLowerCase}`}
          helperText={nameErrors?.message ?? helperText}
        />
      )}
      sx={{ width: fullWidth ? "100%" : "auto" }}
    />
  );
};

export default MultiSelectWithSearch;
