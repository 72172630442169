import { Box } from "@mui/material";
import { GET_DEBUG_SESSION_BY_ID } from "apps/monitor/business/queries";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import LoadingSpinner from "libs/ui/components/feedback/loading-spinner/LoadingSpinner";
import { useState } from "react";
import { useParams } from "react-router-dom";

import InformationHeader from "../../components/Session/InformationHeader";
import SessionData from "../../components/Session/SessionData";

export default function Session() {
  const params = useParams<string>();
  const [subscriptionWasSuccessful, setSubscriptionWasSuccessful] = useState(false);

  const sessionId = params.id;

  if (!sessionId) {
    throw new Error("No Session ID given!");
  }
  const [getDebugSession, queryState] = useQueryWrapper(GET_DEBUG_SESSION_BY_ID);
  if (!queryState.called) {
    getDebugSession({ variables: { sessionId }, context: { clientName: "MONITOR" } });
  }
  if (queryState.loading || !queryState.data) {
    return <LoadingSpinner />;
  }

  const jtsVersion = queryState.data.getDebugSessionById.jts_version;
  const containerURL = queryState.data.getDebugSessionById.account_url;

  return (
    <>
      <InformationHeader jtsVersion={jtsVersion} containerURL={containerURL} />
      <Box sx={{ m: 2 }} />
      <SessionData
        sessionID={sessionId}
        subscriptionWasSuccessful={subscriptionWasSuccessful}
        setSubscriptionWasSuccessful={setSubscriptionWasSuccessful}
      />
    </>
  );
}
