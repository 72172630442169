import { Button, Tooltip, Typography } from "@mui/material";
import { ReactNode } from "react";

interface LinkStyledButtonProps {
  onClick: () => void;
  tooltipText?: string;
  children: ReactNode;
}
/**
 * The LinkStyledButton component
 * @param {LinkStyledButtonProps} props
 * @returns a LinkStyledButton component
 */
const LinkStyledButton = ({
  onClick,
  tooltipText,
  children,
}: LinkStyledButtonProps): JSX.Element => {
  return (
    <Button variant="text" onClick={onClick} style={{ background: "none" }}>
      <Tooltip title={tooltipText || ""}>
        <Typography color="primary" sx={{ textDecoration: "underline" }}>
          {children}
        </Typography>
      </Tooltip>
    </Button>
  );
};

export default LinkStyledButton;
