import adminRoutes from "../../../apps/admin/conf/routes";
import jtmRoutes from "../../../apps/jtm/conf/routes";
import cmpRoutes from "../../../apps/cmp/conf/routes";
import setupRoutes from "../../../apps/setup/conf/routes";
import TService from "../../model/TService";

export interface IRoutePathParams {
  service: TService;
  routeName: string;
  params?: object;
}

export default function getRoutePath(
  service: TService,
  routeName: string,
  params?: object,
): string {
  let path = "/";
  let route;
  switch (service) {
    case "admin":
      route = adminRoutes;
      break;
    case "jtm":
      route = jtmRoutes;
      break;
    case "cmp":
      route = cmpRoutes;
      break;
    case "setup":
      route = setupRoutes;
      break;
    default:
      return path;
  }
  if (route[routeName] === undefined) {
    throw new Error(`Route with name "${routeName}" doesn't exist`);
  }
  path = route[routeName].path;
  if (params !== undefined) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(params)) {
      const regex = new RegExp(`:${key}`);
      path = path.replace(regex, value);
    }
  }
  return path;
}
