import { ApolloCache, FetchResult } from "@apollo/client";
import { Container } from "@mui/material";
import {
  CodeUpdateArguments,
  GET_ALL_FRONTEND_CODES,
  GET_FRONTEND_CODE_BY_ID,
  UPDATE_FRONTEND_CODE,
} from "apps/jtm/business/frontend_code/queries";
import CLIENT from "libs/business/apollo";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { notifySuccess } from "libs/business/notification/notification";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import { FrontendCodeForm } from "../../components/code/FrontendCodeForm";

const Code = () => {
  const id = useParamsId("id");
  const [updateCode] = useMutationWrapper(UPDATE_FRONTEND_CODE);

  const formSubmitHandler: SubmitHandler<CodeUpdateArguments> = (submitData) => {
    updateCode({
      variables: submitData,
      context: { clientName: "JTM" },
      update(cache: ApolloCache<any>, response: FetchResult) {
        if (response.data) {
          const data = CLIENT.readQuery({
            query: GET_ALL_FRONTEND_CODES,
          });
          if (data) {
            const newArrayOfData = data.frontendCodes.map((item: any) => {
              if (response.data && item.id === response.data.updateFrontendCode.id) {
                return response.data.updateFrontendCode;
              }
              return item;
            });

            CLIENT.writeQuery({
              query: GET_ALL_FRONTEND_CODES,
              data: {
                frontendCodes: newArrayOfData,
              },
            });
          }
        }
      },
    });
    notifySuccess("Code updated!");
  };

  const getFrontendCodeResponse = useSimpleFetching({
    query: GET_FRONTEND_CODE_BY_ID,
    context: "JTM",
    queryArguments: { id },
    disableCache: true, // For some reason, I could not figure out, the refetch query in the ContainerGrid is not working in this case, therefore I disabled the cache here
  });

  if (getFrontendCodeResponse === false) {
    return <PageLoadingSpinner />;
  }

  const codeData = getFrontendCodeResponse.frontendCode;

  return (
    <Container>
      <FrontendCodeForm
        formSubmitHandler={formSubmitHandler}
        data={codeData}
        containerSelection={codeData.containerIds}
      />
    </Container>
  );
};

export default Code;
