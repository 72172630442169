import React, { CSSProperties, ReactNode } from "react";
import colorStyles from "assets/styles/colorPalette.module.scss";
import { Accordion } from "@mui/material";

const jentisColors = [
  colorStyles.jentisDarkBlue,
  colorStyles.jentisMidBlue,
  colorStyles.jentisLightBlue,
  colorStyles.jentisGreen,
  colorStyles.jentisDarkRed,
  colorStyles.jentisRed,
  colorStyles.jentisOrange,
  colorStyles.jentisYellow,
];

interface ColoredAccordionProps {
  index: number;
  style?: CSSProperties;
  children?: ReactNode;
  overrideColor?: string;
}
/**
 * The ColoredAccordion component
 * @param {ColoredAccordionProps} props
 * @returns a ColoredAccordion component
 */
const ColoredAccordion: React.FC<ColoredAccordionProps> = ({
  index,
  style,
  children,
  overrideColor,
}: ColoredAccordionProps): JSX.Element => {
  const borderColor = overrideColor || jentisColors[index % 8];
  return (
    <Accordion style={style} sx={{ borderLeft: `6px solid ${borderColor}` }}>
      {!!children && children}
    </Accordion>
  );
};

export default ColoredAccordion;
