import { useNavigate } from "react-router-dom";
import ErrorPage from "./ErrorPage";

function SiteNotFound() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
  };
  return (
    <ErrorPage
      variant="404"
      description="The page you are looking for was moved, removed, renamed, or might never exist!"
      buttonText="Go to the home page"
      onButtonClick={onClick}
    />
  );
}

export default SiteNotFound;
