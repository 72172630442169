import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC, useState } from "react";

interface IMaintainedDialogData {
  open: boolean;
}

const MaintainedDialog: FC<IMaintainedDialogData> = ({ open }: IMaintainedDialogData) => {
  const [maintainedPopupOpen, setMaintainedPopupOpen] = useState(open);
  const handleMaintainedPopupClose = () => {
    setMaintainedPopupOpen(false);
  };

  return (
    <Dialog
      open={maintainedPopupOpen}
      onClose={handleMaintainedPopupClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-cy="maintainedPopUp">
      <DialogTitle id="alert-dialog-title">
        Attention, you are editing a maintained by JENTIS element
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You are able to overwrite it. If you do so the element will be maintained by you and get
          no further system updates. However, you can reset the maintainablity status at any time.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleMaintainedPopupClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MaintainedDialog;
