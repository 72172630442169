import { ApolloCache, FetchResult } from "@apollo/client";
import { removesEmptyOptionsOfConstants } from "apps/jtm/components/shared/preprocess_constant_values";
import GET_FRONTEND_PLUGIN_BY_ID, {
  GET_ALL_FRONTEND_PLUGINS,
  UPDATE_FRONTEND_PLUGIN,
} from "apps/jtm/business/frontend_plugin/queries";
import FrontendPlugin from "apps/jtm/components/plugin/FrontendPlugin";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import useParamsId from "libs/business/hooks/useParamId";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CLIENT from "libs/business/apollo";

const FrontendPluginUpdate = (): JSX.Element => {
  let containerIDs: number[] = [];
  const id = useParamsId("id");
  const navigate = useNavigate();

  const [getFrontendPlugin, queryState] = useQueryWrapper(GET_FRONTEND_PLUGIN_BY_ID);

  const [updateFrontendPlugin, mutationState] = useMutationWrapper(UPDATE_FRONTEND_PLUGIN);

  const formSubmitHandler: SubmitHandler<any> = (submitData: any) => {
    submitData.constants = removesEmptyOptionsOfConstants(submitData.constants);
    updateFrontendPlugin({
      variables: submitData,
      context: { clientName: "JTM" },
      onCompleted: (response: any) => {
        notifySuccess("Frontend Plugin updated!");
      },
      update(cache: ApolloCache<any>, response: FetchResult) {
        if (response.data) {
          const data = CLIENT.readQuery({
            query: GET_ALL_FRONTEND_PLUGINS,
          });
          if (data) {
            const newArrayOfData = data.frontendPlugins.map((item: any) => {
              if (response.data && item.id === response.data.updateFrontendPlugin.id) {
                return response.data.updateFrontendPlugin;
              }
              return item;
            });

            CLIENT.writeQuery({
              query: GET_ALL_FRONTEND_PLUGINS,
              data: {
                frontendPlugins: newArrayOfData,
              },
            });
          }
        }
      },
      refetchQueries: [
        {
          query: GET_FRONTEND_PLUGIN_BY_ID,
          variables: { id },
          context: { clientName: "JTM" },
        },
      ],
    });
  };

  if (!queryState.called) {
    getFrontendPlugin({ variables: { id }, context: { clientName: "JTM" } });
  }

  if (queryState.loading || !queryState.data) return <PageLoadingSpinner />;

  if (queryState.error) {
    notifyError(queryState.error.message);
  }

  containerIDs = queryState.data.frontendPlugin.container.map((container: any) => container.id);

  return (
    <FrontendPlugin
      id={id}
      frontendPluginData={queryState.data.frontendPlugin}
      formSubmitHandler={formSubmitHandler}
      selectedContainerIdsInit={containerIDs}
    />
  );
};

export default FrontendPluginUpdate;
