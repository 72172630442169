import { Grid, Typography } from "@mui/material";
import {
  GET_TAG_TYPE_DEFINITION_BY_PLUGINID,
  GetTagTypesByPluginIdQueryDefinition,
} from "apps/jtm/business/tool/queries";
import { updateWizardContextTagTypesFromManifest } from "apps/jtm/pages/tool/assistant/business/onManifestChange";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { notifyError } from "libs/business/notification/notification";
import { useFormContext } from "react-hook-form";
import LoadComponentSpinner from "../../feedback/loading-spinner/LoadComponentSpinner";
import MultiSelectWithSearch from "../../search/MultiSelectWithSearch";

interface TemplateSelectionProps {
  pluginId: string;
  name: string;
  label: string;
  value: string[] | undefined;
}
/**
 * The TemplateSelection component
 * @param {TemplateSelectionProps} props
 * @returns a TemplateSelection component
 */
const TemplateSelection = ({
  pluginId,
  name,
  label,
  value,
}: TemplateSelectionProps): JSX.Element => {
  const getTagTypesResponse = useSimpleFetching<GetTagTypesByPluginIdQueryDefinition>({
    query: GET_TAG_TYPE_DEFINITION_BY_PLUGINID,
    context: "JTM",
    queryArguments: { pluginID: pluginId },
    customOnError: (e) => {
      notifyError(e);
    },
    disableCache: true,
  });
  const { setValue } = useFormContext();

  if (!getTagTypesResponse) return <LoadComponentSpinner />;

  const tagTypes = getTagTypesResponse.toolDefinition.tag_types;

  const options = Object.entries(tagTypes)
    .filter(([, tagType]) => {
      return tagType.triggerId !== undefined;
    })
    .sort(([, option1], [, option2]) =>
      option1.name.toLowerCase() > option2.name.toLowerCase() ? 1 : -1,
    )
    .map(([tagTypeShortId, tagType]) => ({
      key: tagTypeShortId,
      value: (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
              {tagType.name}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="caption"
              style={{ display: "inline-block", whiteSpace: "pre-line" }}>
              {tagType.description}
            </Typography>
          </Grid>
        </Grid>
      ),
      optionLabel: tagType.name,
    }));

  const updatedValue = updateWizardContextTagTypesFromManifest(value, tagTypes);

  if (updatedValue === undefined) {
    setValue(
      name,
      options.map((option) => option.key),
    );
  }

  return (
    <MultiSelectWithSearch
      inputName={name}
      instanceName={label}
      options={options}
      defaultValue={updatedValue || options.map((option) => option.key)}
    />
  );
};

export default TemplateSelection;
