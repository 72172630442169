/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RowData } from "apps/monitor/components/SessionRow/type";
import { useState } from "react";
import RowDetails from "./Details/RowDetails";
import RowIcon from "./RowIcon";
import RowSourceIcon from "./RowSourceIcon";
import style from "./SessionRow.module.scss";

interface ISessionRowProps {
  rowData: RowData;
  hasDetails?: boolean;
}

export default function SessionRow({ rowData, hasDetails = true }: ISessionRowProps) {
  // HOOKS
  const [detailsOpened, setDetailsOpened] = useState(false);

  const toggleOpenDetails = () => {
    if (hasDetails) setDetailsOpened((b) => !b);
  };

  const isNewClass = rowData.isNew ? style.newRow : "";
  const errorClass = rowData.error ? style.errorColor : "";
  const isNegativeClass = rowData.negative ? style.negativeColor : "";

  return (
    <div className={`${style.rowBorder} ${isNewClass}`}>
      <div
        className={`${style.sessionRow} ${isNewClass}`}
        onClick={toggleOpenDetails}
        style={{ cursor: "pointer" }}>
        <div
          className={`${style.sessionColumn} ${style.sessionColumnSource} ${errorClass} ${isNegativeClass}`}>
          <RowSourceIcon source={rowData.source} />
        </div>
        <div className={`${style.sessionColumn} ${style.sessionColumnType} ${style[rowData.type]}`}>
          <RowIcon rowData={rowData} />
        </div>
        <div className={`${style.sessionColumn} ${style.sessionColumnState}`}>{rowData.state}</div>
        <div className={`${style.sessionColumn} ${style.sessionColumnTime}`}>{rowData.time}</div>
        <div
          className={`${style.sessionColumn} ${style.sessionColumnDescription} ${errorClass} ${isNegativeClass}`}>
          {rowData.description}
        </div>
        <div className={`${style.sessionColumn} ${style.sessionColumnDetails} `}>
          {hasDetails && (
            <FontAwesomeIcon
              icon={faCaretRight}
              rotation={detailsOpened ? 90 : undefined}
              size="lg"
            />
          )}
        </div>
      </div>
      {detailsOpened && (
        <div className={style.detailRow}>
          <RowDetails rowId={rowData.id} rowIndex={rowData.index} isNegative={rowData.negative} />
        </div>
      )}
    </div>
  );
}
