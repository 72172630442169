import CardListCard, { ICardListCard } from "libs/ui/components/surfaces/card-list/Card";
import Grid from "@mui/material/Grid";

interface Props {
  list: ICardListCard[];
}

export default function CardList({ list }: Props) {
  const cardList = list.map((element: ICardListCard) => (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={element.link}>
      <CardListCard
        title={element.title}
        image={element.image}
        link={element.link}
        menuItemConfigs={element.menuItemConfigs}
        dataFields={element.dataFields}
      />
    </Grid>
  ));
  return (
    <div className="card-list">
      <Grid container spacing={4} alignContent="stretch">
        {cardList}
      </Grid>
    </div>
  );
}
