import { Grid, Typography } from "@mui/material";
import { GetAllTriggersQueryDefinition, GET_ALL_TRIGGERS } from "apps/jtm/business/trigger/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import LoadComponentSpinner from "libs/ui/components/feedback/loading-spinner/LoadComponentSpinner";
import SelectWithSearch, { SelectSearchOption } from "libs/ui/components/search/SelectWithSearch";

interface ITriggerProps {
  selectedTrigger: number | string | false;
  withBeforeBundle?: boolean;
}

const TriggerSelect = ({ selectedTrigger, withBeforeBundle }: ITriggerProps): JSX.Element => {
  const getAllTriggersResponse = useSimpleFetching<GetAllTriggersQueryDefinition>({
    query: GET_ALL_TRIGGERS,
    context: "JTM",
    queryArguments: { sortParam: "name", sortDirection: "asc", withBeforeBundle },
  });

  if (getAllTriggersResponse === false) return <LoadComponentSpinner />;

  const triggers: SelectSearchOption[] = getAllTriggersResponse.triggers.map((trigger) => ({
    key: trigger.id,
    value: <SelectOption name={trigger.name} description={trigger.desc} />,
    optionLabel: trigger.name,
  }));
  let defaultTrigger = selectedTrigger || undefined;
  if (selectedTrigger && typeof selectedTrigger === "string") {
    defaultTrigger = getAllTriggersResponse.triggers.find(
      (trigger) => trigger.short_id === selectedTrigger,
    )?.id;
  }
  return (
    <SelectWithSearch
      inputName="triggerId"
      instanceName="Trigger"
      defaultValue={defaultTrigger}
      options={triggers}
    />
  );
};

export default TriggerSelect;

export const SelectOption = ({
  name,
  description,
}: {
  name: string;
  description: string;
}): JSX.Element => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <Typography variant="body1">{name}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="caption" style={{ display: "inline-block", whiteSpace: "pre-line" }}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};
