import { Paper, Stack } from "@mui/material";
import ContainerMultiSelect from "apps/admin/components/container/ContainerMultiSelect";
import useIsFirstRender from "libs/business/hooks/useIsFirstRender";
import { useAssistantWizard } from "libs/ui/components/assistant-wizard/AssistantWizardContext";
import WarningBadge from "libs/ui/components/feedback/WarningBadge";
import ConnectorSelection from "libs/ui/components/input/connector/ConnectorSelection";
import TaskWrapper from "libs/ui/components/surfaces/helpers/TaskWrapper";
import { useFormContext } from "react-hook-form";
import { CaWizardContextType } from "../conf/types";
import { CA_INPUT_NAME_CONTAINER_IDS, CA_INPUT_NAME_TOOL_ID } from "../conf/schema";

/**
 * The ConnectorAssistantStepOne component
 * @returns a ConnectorAssistantStepOne component
 */
const ConnectorAssistantStepOne = (): JSX.Element => {
  const wizardData = useAssistantWizard<CaWizardContextType>();
  const isFirstRender = useIsFirstRender();

  const idsOfSelectedContainers = wizardData.data[CA_INPUT_NAME_CONTAINER_IDS];

  const { watch } = useFormContext();
  const watchTool = watch(CA_INPUT_NAME_TOOL_ID);
  const watchContainers = watch(CA_INPUT_NAME_CONTAINER_IDS);

  return (
    <Paper variant="page" style={{ overflow: "auto", maxHeight: "100%" }}>
      <Stack spacing={5}>
        <TaskWrapper
          taskDescription="Let's start with choosing the tool you want to install."
          done={isFirstRender ? !!wizardData.data[CA_INPUT_NAME_TOOL_ID] : !!watchTool}>
          <WarningBadge
            tooltipMessage="If you change the tool, you will lose any configurations you have made in the following steps."
            show={wizardData.highestVisited > 1}>
            <div style={{ width: "500px" }}>
              <ConnectorSelection
                defaultSelectValue={wizardData.data[CA_INPUT_NAME_TOOL_ID]}
                name={CA_INPUT_NAME_TOOL_ID}
              />
            </div>
          </WarningBadge>
        </TaskWrapper>
        <TaskWrapper
          taskDescription="Select all the containers where you want to install the tool."
          done={
            isFirstRender
              ? wizardData.data[CA_INPUT_NAME_CONTAINER_IDS]?.length > 0
              : watchContainers?.length > 0
          }>
          <ContainerMultiSelect
            idsOfSelectedContainers={idsOfSelectedContainers}
            inputName={CA_INPUT_NAME_CONTAINER_IDS}
          />
        </TaskWrapper>
      </Stack>
    </Paper>
  );
};

export default ConnectorAssistantStepOne;
