import { ICondition } from "apps/jtm/components/trigger/Condition";
import { IdAndName } from "libs/model/types";
import Page from "../../surfaces/page/Page";
import CompareMultiSelect from "../CompareMultiSelect";
import CompareSimpleInput from "../CompareSimpleInput";
import CompareTriggerConditions from "../CompareTriggerConditions";

interface TriggerData {
  __typename?: string;
  name?: string;
  desc?: string;
  condition?: ICondition[][];
  state?: {
    name: string;
  };
  frontend_trigger_type?: string;
  selector?: string;
  action?: string;
  excluded_triggers?: IdAndName[];
}

interface CompareTriggerProps {
  old_triggerData: TriggerData;
  new_functionData: TriggerData;
}
/**
 * The CompareTrigger component
 * @param {CompareTriggerProps} props
 * @returns a CompareTrigger component
 */
const CompareTrigger = ({
  old_triggerData: {
    name: old_name,
    desc: old_desc,
    condition: old_condition,
    state: old_state,
    frontend_trigger_type: old_frontend_trigger_type,
    selector: old_selector,
    action: old_action,
    excluded_triggers: old_triggerExclude,
  },
  new_functionData: {
    name: new_name,
    desc: new_desc,
    condition: new_condition,
    state: new_state,
    frontend_trigger_type: new_frontend_trigger_type,
    selector: new_selector,
    action: new_action,
    excluded_triggers: new_triggerExclude,
  },
}: CompareTriggerProps): JSX.Element => {
  const nameShouldBeRendered =
    typeof old_name !== "undefined" && typeof new_name !== "undefined" && old_name !== new_name;

  const descShouldBeRendered =
    typeof old_desc !== "undefined" && typeof new_desc !== "undefined" && old_desc !== new_desc;

  const stateShouldBeRendered =
    typeof old_state !== "undefined" && typeof new_state !== "undefined" && old_state !== new_state;

  const frontendTriggerTypeShouldBeRendered =
    typeof old_frontend_trigger_type !== "undefined" &&
    typeof new_frontend_trigger_type !== "undefined" &&
    old_frontend_trigger_type !== new_frontend_trigger_type;

  const selectorShouldBeRendered =
    typeof old_selector !== "undefined" &&
    typeof new_selector !== "undefined" &&
    old_selector !== new_selector;

  const actionShouldBeRendered =
    typeof old_action !== "undefined" &&
    typeof new_action !== "undefined" &&
    old_action !== new_action;

  const triggerExcludeShouldBeRendered =
    typeof old_triggerExclude !== "undefined" &&
    typeof new_triggerExclude !== "undefined" &&
    JSON.stringify(old_triggerExclude) !== JSON.stringify(new_triggerExclude);

  const conditionsShouldBeRendered =
    typeof old_condition !== "undefined" &&
    typeof new_condition !== "undefined" &&
    JSON.stringify(old_condition) !== JSON.stringify(new_condition);

  const detailsShouldBeRendered =
    nameShouldBeRendered ||
    descShouldBeRendered ||
    stateShouldBeRendered ||
    frontendTriggerTypeShouldBeRendered ||
    selectorShouldBeRendered ||
    actionShouldBeRendered;

  return (
    <>
      {detailsShouldBeRendered && (
        <Page title="Trigger Details">
          {nameShouldBeRendered && (
            <CompareSimpleInput
              inputName="name"
              leftLabel="Old Name"
              rightLabel="New Name"
              leftValue={old_name}
              rightValue={new_name}
            />
          )}
          {descShouldBeRendered && (
            <CompareSimpleInput
              inputName="desc"
              leftLabel="Old Description"
              rightLabel="New Description"
              leftValue={old_desc}
              rightValue={new_desc}
            />
          )}
          {stateShouldBeRendered && (
            <CompareSimpleInput
              inputName="state"
              leftLabel="Old State"
              rightLabel="New State"
              leftValue={old_state.name}
              rightValue={new_state.name}
            />
          )}
          {frontendTriggerTypeShouldBeRendered && (
            <CompareSimpleInput
              inputName="state"
              leftLabel="Old Frontend Trigger Type"
              rightLabel="New Frontend Trigger Type"
              leftValue={old_frontend_trigger_type}
              rightValue={new_frontend_trigger_type}
            />
          )}
          {selectorShouldBeRendered && (
            <CompareSimpleInput
              inputName="state"
              leftLabel="Old Selector"
              rightLabel="New Selector"
              leftValue={old_selector}
              rightValue={new_selector}
            />
          )}
          {actionShouldBeRendered && (
            <CompareSimpleInput
              inputName="state"
              leftLabel="Old State"
              rightLabel="New State"
              leftValue={old_action}
              rightValue={new_action}
            />
          )}
        </Page>
      )}
      {conditionsShouldBeRendered && (
        <Page title="Conditions" notFirst>
          <CompareTriggerConditions
            leftLabel="Old conditions"
            rightLabel="New conditions"
            leftCondition={old_condition}
            rightCondition={new_condition}
          />
        </Page>
      )}
      {triggerExcludeShouldBeRendered && (
        <Page title="Exclude Triggers" notFirst>
          <CompareMultiSelect
            inputName="trigger_exclude"
            leftLabel="Removed excluded triggers"
            rightLabel="New excluded triggers"
            leftValue={old_triggerExclude}
            rightValue={new_triggerExclude}
            hideArrow
          />
        </Page>
      )}
    </>
  );
};

export default CompareTrigger;
