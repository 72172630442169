import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography } from "@mui/material";
import TriggerOrConditions from "apps/jtm/components/trigger/TriggerOrConditions";
import { OrConditions } from "apps/jtm/components/trigger/types";

interface CompareTriggerConditionsProps {
  leftLabel: string;
  rightLabel: string;
  leftCondition: OrConditions;
  rightCondition: OrConditions;
}
/**
 * The CompareTriggerConditions component
 * @param {CompareTriggerConditionsProps} props
 * @returns a CompareTriggerConditions component
 */
const CompareTriggerConditions = ({
  leftCondition,
  rightCondition,
  leftLabel,
  rightLabel,
}: CompareTriggerConditionsProps): JSX.Element => {
  return (
    <Stack direction="row" spacing={4}>
      <Stack sx={{ width: "100%" }}>
        <Typography variant="caption" sx={{ mb: 2 }}>
          {leftLabel}
        </Typography>
        <TriggerOrConditions conditions={leftCondition} readOnly nameAppendix="-1" />
      </Stack>
      <Box alignSelf="center">
        <FontAwesomeIcon style={{ marginTop: "8px" }} icon={faArrowRight} />
      </Box>
      <Stack sx={{ width: "100%" }}>
        <Typography variant="caption" sx={{ mb: 2 }}>
          {rightLabel}
        </Typography>
        <TriggerOrConditions conditions={rightCondition} readOnly nameAppendix="-2" />
      </Stack>
    </Stack>
  );
};

export default CompareTriggerConditions;
