import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useContainer } from "apps/admin/components/container/ContainerContext";
import ContainerMultiSelect from "apps/admin/components/container/ContainerMultiSelect";
import { GET_ALL_FRONTEND_CODE_NAMES } from "apps/jtm/business/frontend_code/queries";
import useFetchNotOneOfList from "libs/business/hooks/useFetchNotOneOfList";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import CodeEditor from "libs/ui/components/input/editor/CodeEditor";
import MultiSelectWithSearch from "libs/ui/components/search/MultiSelectWithSearch";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import Page from "libs/ui/components/surfaces/page/Page";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import TabContent from "libs/ui/components/surfaces/tabs/TabContent";
import TabWrapper from "libs/ui/components/surfaces/tabs/TabWrapper";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import TriggerSelect from "../trigger/TriggerSelect";

export interface ICodeFormData {
  id: number;
  name: string;
  js: string;
  css?: string;
  order?: number;
  containerIds: number[];
  triggerId?: number | string;
  vendorId?: number;
}

interface ICodeFormProps {
  formSubmitHandler: any;
  data: ICodeFormData;
  containerSelection: number[];
}

export const FrontendCodeForm: React.FC<ICodeFormProps> = ({
  formSubmitHandler,
  data,
  containerSelection,
}: ICodeFormProps) => {
  const usedNames = useFetchNotOneOfList({
    query: GET_ALL_FRONTEND_CODE_NAMES,
    queryName: "frontendCodes",
    context: "JTM",
    queryProperty: "name",
    exclude: [data.name],
  });

  const schema = yup.object({
    name: yup
      .string()
      .required("Please enter a name.")
      .max(256, "The name must be shorter than 256 characters.")
      .notOneOf(usedNames, "This name is already in use!"),
    triggerId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required("Please select a trigger."),
    order: yup.number().min(0).max(2147483648).typeError("Please enter a number."),
  });

  const methods = useForm<ICodeFormData>({ resolver: yupResolver(schema) });

  return (
    <Container>
      <FormProvider {...methods} key="codeForm">
        <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <TextFieldWrapper name="id" label="id" value={data.id} hidden />
          <Page title="Basics">
            <Stack direction="row" spacing={2} mb={2}>
              <TextFieldWrapper
                name="name"
                label="Name"
                size="small"
                value={data.name}
                maxLength={256}
              />

              <TextFieldWrapper
                name="order"
                label="Order"
                value={data.order}
                type="number"
                size="small"
                inputMin={0}
              />
            </Stack>
            <ContainerMultiSelect
              idsOfSelectedContainers={containerSelection}
              inputName="containerIds"
            />
          </Page>
          <Page title="Code" notFirst>
            <TabWrapper>
              <TabContent label="JS">
                <Box padding="0 5px">
                  <AlertBox
                    type="info"
                    message="The javascript code will be automatically wrapped within a function signature ( function () { <your code> } )"
                    boxStyles={{ mt: 2 }}
                  />
                  <CodeEditor label="Javascript Code" name="js" content={data.js} />
                </Box>
              </TabContent>
              <TabContent label="CSS">
                <Box padding="0 5px">
                  <CodeEditor label="CSS Code" name="css" content={data.css || ""} />
                </Box>
              </TabContent>
            </TabWrapper>
          </Page>

          <Page title="Trigger" notFirst>
            <Box width="100%">
              <TriggerSelect selectedTrigger={data?.triggerId || false} withBeforeBundle />
            </Box>
          </Page>

          {/* <CheckboxWrapper
            label="Insert before mastertemplate"
            name="before_master"
            value={data.before_master === 1}
            asNumber
          /> */}
          <SaveCancelBar />
        </form>
      </FormProvider>
    </Container>
  );
};

export default FrontendCodeForm;
