import { TranslationCollection, TranslationPopUpConfig } from "./types";

export const emptyTranslationPopUpConfig: TranslationPopUpConfig = {
  languageId: 1,
  translationLanguageName: "English",
  translationLanguageIso: "en",
  translationCollectionId: 0,
  englishTextContent: "",
  translatedTextContent: "",
  translationId: null,
};

export const findTranslationInCollection = (
  translationCollection: TranslationCollection,
  languageId: number,
) => {
  return translationCollection.config_text_content_i18n.find(
    (config) => config.language_languageid === languageId,
  );
};
