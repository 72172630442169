const isNotDevEnv = process.env.REACT_APP_ENVIRONMENT !== "dev";
const DevEnv = {
  logWarning(value: any) {
    if (isNotDevEnv) return;
    console.warn(`[JENTIS DEV]: ${value}`);
  },

  logError(value: any) {
    if (isNotDevEnv) return;
    console.error(`[JENTIS DEV]: ${value}`);
  },

  log(value: any) {
    if (isNotDevEnv) return;
    console.log(`[JENTIS DEV]: ${value}`);
  },
};

export default DevEnv;
