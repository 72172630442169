function runTrackingCode() {
  const environment = process.env.REACT_APP_ENVIRONMENT === "dev" ? "stage" : "live";

  (function (sCDN, sCDNProject, sCDNWorkspace, sCDNVers) {
    if (
      window.localStorage !== null &&
      typeof window.localStorage === "object" &&
      typeof window.localStorage.getItem === "function" &&
      window.sessionStorage !== null &&
      typeof window.sessionStorage === "object" &&
      typeof window.sessionStorage.getItem === "function"
    ) {
      sCDNVers =
        window.sessionStorage.getItem("jts_preview_version") ||
        window.localStorage.getItem("jts_preview_version") ||
        sCDNVers;
    }

    window.jentis = window.jentis || {};
    window.jentis.config = window.jentis.config || {};
    window.jentis.config.frontend = window.jentis.config.frontend || {};
    window.jentis.config.frontend.cdnhost = `${sCDN}/get/${sCDNWorkspace}/web/${sCDNVers}/`;
    window.jentis.config.frontend.vers = sCDNVers;
    window.jentis.config.frontend.env = sCDNWorkspace;
    window.jentis.config.frontend.project = sCDNProject;
    // eslint-disable-next-line no-underscore-dangle
    window._jts = window._jts || [];
    const f = document.getElementsByTagName("script")[0];
    const j = document.createElement("script");
    j.async = true;
    j.src = `${window.jentis.config.frontend.cdnhost}2d23ag.js`;
    f.parentNode.insertBefore(j, f);
  })("https://kndmjh.jentis.com", "mouseflow-tracking", environment, "_");
}

export default runTrackingCode;

export function trackPageView() {
  /* eslint-disable */
  if (_jts !== undefined) {
    _jts.push({ track: "pageview" });
    _jts.push({ track: "submit" });
  }
  /* eslint-enable */
}
