import { gql } from "@apollo/client";
import { PluginType } from "apps/jtm/components/log/MessageTransformer";
import { GQLTypeDefinition, SingleResponse } from "libs/model/types";

export interface BackendNexusChangeLogType {
  id: number;
  status: number;
  mid: number;
  create_by: number;
  create_at: string;
  edit_by: number;
  edit_at: string;
  original: number;
  type: string;
  element: PluginType;
  name: string;
  action: string;
  message: any;
  user: string;
  userID: number;
  userdirectory: string;
  elementID: number;
}

export interface GetLogByIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusChangeLogType,
    "id" | "element" | "name" | "action" | "message" | "elementID"
  >;
  response: SingleResponse<"changeLog", GetLogByIdQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "JTM";
}

export const GET_LOG_BY_ID = gql`
  query changeLog($id: Int!) {
    changeLog(id: $id) {
      id
      element
      name
      action
      message
      elementID
    }
  }
`;

export const GET_LOGS_BY_VERSION_ID = gql`
  query changeLogs($versionID: Int!) {
    changeLogs(versionID: $versionID) {
      id
      create_at
      user
      name
      element
      action
    }
  }
`;

export const GET_LOGS_BY_TYPE_AND_ID = gql`
  query changeLogsByElementTypeAndId($element: String!, $elementID: Int!) {
    changeLogsByElementTypeAndId(element: $element, elementID: $elementID) {
      id
      create_at
      user
      name
      element
      action
    }
  }
`;

export interface GetIdNameMappingTableQueryDefinition extends GQLTypeDefinition {
  dataType: { [shortId: string]: string };
  response: SingleResponse<"idNameMappings", GetIdNameMappingTableQueryDefinition["dataType"]>;
  context: "JTM";
}
export const GET_ID_NAME_MAPPING_TABLE = gql`
  query idNameMappings {
    idNameMappings
  }
`;

export interface GetManifestIdNameMappingTableQueryDefinition extends GQLTypeDefinition {
  dataType: { [shortId: string]: string };
  response: SingleResponse<
    "manifestIdNameMappings",
    GetManifestIdNameMappingTableQueryDefinition["dataType"]
  >;
  arguments: { tagId: number };
  context: "JTM";
}

export const GET_MANIFEST_ID_NAME_MAPPING_TABLE = gql`
  query manifestIdNameMappings($tagId: Int!) {
    manifestIdNameMappings(tagId: $tagId)
  }
`;

export interface GetManifestToolPluginIdNameMappingsQueryDefinition extends GQLTypeDefinition {
  dataType: { [shortId: string]: string };
  response: SingleResponse<
    "manifestToolPluginIdNameMappings",
    GetManifestToolPluginIdNameMappingsQueryDefinition["dataType"]
  >;
  context: "JTM";
}
export const GET_MANIFEST_TOOL_PLUGINID_NAME_MAPPINGS = gql`
  query manifestToolPluginIdNameMappings {
    manifestToolPluginIdNameMappings
  }
`;
