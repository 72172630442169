const ErrorCodes = {
  BAD_TOKEN: "BAD_TOKEN",
  BAD_CREDENTIALS: "BAD_CREDENTIALS",
  TO_MANY_TRIES: "TO_MANY_TRIES",
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  UNAUTHORIZED: "UNAUTHORIZED",
  NO_TOKEN: "NO_TOKEN",
} as const;

type ObjectValues<T> = T[keyof T];
export type TErrorCodes = ObjectValues<typeof ErrorCodes>;

export default ErrorCodes;
