import { BackendNexusToolConstantDetails } from "apps/jtm/business/tool/queries";
import { deepCopyObjectWithoutFunction } from "libs/business/utils/objectFunctions";
import { ContainerEnvironmentValues, ContainerEnvironmentOptions } from "libs/model/types";

/**
 * removes  the empty constant options
 * @param constants
 * @returns
 */
export const removesEmptyOptionsOfConstants = (constants: {
  [key: string]: ContainerEnvironmentValues;
}) => {
  for (const constantKey in constants) {
    constants[constantKey].options = filterEmptyOptions(constants[constantKey].options);
  }
  return constants;
};

export const filterEmptyOptions = (options: ContainerEnvironmentOptions[] = []) => {
  return options.filter((option) => option?.value);
};

export const removesEmptyOptionsOfCMPInfo = (cmpInformations: [key: any]) => {
  for (const cmpInfoKey in cmpInformations) {
    const newOptions = [];
    for (const optionIndex in cmpInformations[cmpInfoKey].options) {
      const { value } = cmpInformations[cmpInfoKey].options[optionIndex];
      if (typeof value === "string") {
        if (value !== "") {
          newOptions.push(cmpInformations[cmpInfoKey].options[optionIndex]);
        }
      } else if (value.length !== 0) {
        newOptions.push(cmpInformations[cmpInfoKey].options[optionIndex]);
      }
    }
    cmpInformations[cmpInfoKey].options = newOptions;
  }
  return cmpInformations;
};

export const setShortIDForCustomConstants = (constants: BackendNexusToolConstantDetails[]) => {
  return constants.map((constant: any) => {
    const c = deepCopyObjectWithoutFunction(constant);
    if (!c.short_id) {
      c.short_id = c.name;
    }
    return c;
  });
};

export default removesEmptyOptionsOfConstants;
