import { Typography } from "@mui/material";
import Condition from "./Condition";
import style from "./Trigger.module.scss";
import TriggerHeader from "./TriggerHeader";
import { Trigger as ITrigger } from "./types";

interface ITriggerProps {
  trigger: ITrigger;
}

export default function Trigger({ trigger }: ITriggerProps) {
  return (
    <div className={style.trigger}>
      <Typography className={style.triggerName}>{trigger.name}</Typography>
      <TriggerHeader />
      <Condition condition={trigger.conditions} />
    </div>
  );
}
