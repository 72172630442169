import { Stack } from "@mui/material";
import CircularSpinner from "./CircularSpinner";

/**
 * The PageLoadingSpinner component
 * @returns a PageLoadingSpinner component
 */
const LoadComponentSpinner = (): JSX.Element => {
  return (
    <Stack sx={{ scale: "0.4" }} alignItems="center" justifyContent="center">
      <div style={{ padding: "8px" }}>
        <CircularSpinner />
      </div>
    </Stack>
  );
};

export default LoadComponentSpinner;
