import { Box, Stack } from "@mui/material";
import AutoGenerateNameAndID from "apps/jtm/components/shared/AutoGenerateNameAndID";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import MaintainedPage from "apps/jtm/components/shared/maintainedby/MaintainedPage";
import { GetVariableByIdQuery } from "gql/autogeneratedTypes";
import checkForNullOrUndefined from "libs/business/utils/checkForNullOrUndefined";
import CodeEditor from "libs/ui/components/input/editor/CodeEditor";
import SelectWrapper from "libs/ui/components/input/SelectWrapper";
import SwitchWrapper from "libs/ui/components/input/SwitchWrapper";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { IConstantData } from "../constants/ConstantContainerSetting";
import Constant from "./Constant";
import VariableTemplateFields from "./VariableTemplateFields";

interface IVariableFormProps {
  data: NonNullable<GetVariableByIdQuery["variable"]>;
  mode: "create" | "update";
}

export const VariableForm: React.FC<IVariableFormProps> = ({ data, mode }: IVariableFormProps) => {
  const { setMaintained, maintainedBy } = useMaintained();

  const formElements = getFormElementsForVariableType(data, setMaintained);

  return (
    <MaintainedPage
      title="Variable"
      showMaintainedInfo
      maintainedBy={maintainedBy}
      maintainedTypePlaceholder="variable">
      <TextFieldWrapper name="id" label="id" value={data.id} hidden />
      <TextFieldWrapper name="pluginID" label="pluginID" value={data.pluginID || ""} hidden />
      <TextFieldWrapper name="source" label="source" value={data.source || ""} hidden />

      <AutoGenerateNameAndID
        short_id={checkForNullOrUndefined(data.short_id)}
        name={checkForNullOrUndefined(data.name)}
        mode={mode}
      />
      <Stack direction="row" spacing={2} alignItems="center">
        {((data.placeholder && mode === "update") || !data.placeholder) && (
          <TextFieldWrapper name="group" label="Group" value={data.group ?? ""} />
        )}
        <Box sx={{ width: "100%", pt: 1 }}>
          <SwitchWrapper
            name="personal_data"
            label="Personal identifiable information"
            value={data.personal_data === 1}
            labelPlacement="start"
            asNumber
          />
        </Box>
      </Stack>
      {formElements}
    </MaintainedPage>
  );
};

const getFormElementsForVariableType = (
  variableData: IVariableFormProps["data"],
  setMaintained: (b: boolean) => void,
) => {
  if (variableData.placeholder) {
    return getFormElementsForTemplateVariable(variableData);
  }
  if (variableData.source === "FRONTENDJS") {
    return getFormElementsForFRONTENDJS(variableData, setMaintained);
  }
  if (variableData.source === "BACKENDJS") {
    return getFormElementsForBACKENDJS(variableData, setMaintained);
  }
  return getFormElementsForACCOUNTCONSTANT(variableData);
};

const getFormElementsForTemplateVariable = (variableData: IVariableFormProps["data"]) => {
  return [
    <VariableTemplateFields
      template={checkForNullOrUndefined(variableData.pluginID)}
      placeholder={variableData.placeholder}
    />,
  ];
};

const getFormElementsForFRONTENDJS = (
  variableData: IVariableFormProps["data"],
  setMaintained: (b: boolean) => void,
) => {
  const onScopeChange = (value: string | number) => {
    if (value !== variableData.scope) {
      setMaintained(false);
    }
  };
  const onEditorValueChange = (value: string) => {
    if (value !== variableData.value) {
      setMaintained(false);
    }
  };
  return [
    <SelectWrapper
      name="scope"
      label="Scope"
      value={variableData.scope || undefined}
      options={scopeOptions}
      onChange={onScopeChange}
    />,
    <CodeEditor
      label="Javascript Code"
      name="value"
      onChange={onEditorValueChange}
      content={variableData.value || ""}
    />,
  ];
};

const getFormElementsForBACKENDJS = (
  variableData: IVariableFormProps["data"],
  setMaintained: (b: boolean) => void,
) => {
  const onEditorValueChange = (value: string) => {
    const defaultData = variableData.value || "";
    if (value.replace(/[\r\n]/gm, "") !== defaultData.replace(/[\r\n]/gm, "")) {
      setMaintained(false);
    }
  };
  return [
    <CodeEditor
      label="Javascript Code"
      name="value"
      onChange={onEditorValueChange}
      content={variableData.value || ""}
    />,
  ];
};

const getFormElementsForACCOUNTCONSTANT = (variableData: IVariableFormProps["data"]) => {
  let value;
  if (!variableData.value) {
    value = {
      value: "",
      options: [],
    };
  } else {
    value = JSON.parse(variableData.value);
  }
  const constantData: IConstantData = {
    short_id: variableData.short_id || "singleConstant",
    name: "",
    value,
  };
  return [<Constant data={constantData} />];
};

export const scopeOptions = [
  {
    key: "USER",
    value: "User",
  },
  {
    key: "SESSION",
    value: "Session",
  },
  {
    key: "EVENT",
    value: "Event",
  },
];

export default VariableForm;
