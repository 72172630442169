const developServicePorts: { [key: string]: string } = {
  admin: "4001",
  cmp: "4002",
  jtm: "4003",
  setup: "4004",
  monitor: "4005",
};

export const getProductionServiceURL = (service: string, subscription?: boolean) => {
  if (subscription) {
    return `wss://${window.location.hostname}/subscription`;
  }
  return `${window.location.origin}/graphql/${service}`;
};

export const getDevelopServiceURL = (service: string, subscription?: boolean) => {
  if (subscription) {
    return `ws://localhost:${developServicePorts[service]}/subscription`;
  }
  return `http://localhost:${developServicePorts[service]}/graphql`;
};

export default getProductionServiceURL;
