import { MenuItem, Checkbox, Typography, Stack, FormControl, FormHelperText } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useController, useFormContext, get } from "react-hook-form";

interface ICheckboxWrapperProps {
  name: string;
  label: string;
  value?: boolean;
  required?: boolean;
  readonly?: boolean;
  hidden?: boolean;
  onChange?: Function;
  asNumber?: boolean;
  helperText?: string;
}

export interface Option {
  key: string;
  value: any;
}
/**
 * The InputWrapper component wraps a Mui TextField with a react-form-hook controller to us it in a context context.
 * @param {ICheckboxWrapperProps} props
 * @returns a InputWrapper component
 */
const CheckboxWrapper: FC<ICheckboxWrapperProps> = ({
  name,
  label,
  value = false,
  required = false,
  readonly = false,
  hidden = false,
  onChange,
  asNumber = false,
  helperText = "",
}: ICheckboxWrapperProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  let fieldDefaultValue: boolean | number = value;
  if (asNumber) {
    fieldDefaultValue = value ? 1 : 0;
  }

  const { field } = useController({
    control,
    name,
    defaultValue: fieldDefaultValue,
  });

  const nameErrors = get(errors, name);

  const [checkedState, setCheckedState] = React.useState(value);

  const changeValue = (checked: boolean): void => {
    let newValue: boolean | number = checked;
    if (asNumber) {
      newValue = checked ? 1 : 0;
    }
    setCheckedState(checked);
    setHookFormValue(newValue);
    runCustomOnChangeFunction(checked);
  };

  const setHookFormValue = (checked: boolean | number) => {
    field.onChange(checked);
  };

  const runCustomOnChangeFunction = (checked: boolean) => {
    if (typeof onChange !== "undefined") onChange(checked);
  };

  const finalHelperText = nameErrors?.message || helperText;

  return (
    // the hidden attribute is not working with TextFields in combination with react-hook-form

    <FormControl
      margin="normal"
      style={{ display: hidden ? "none" : "", width: "100%" }}
      error={!!nameErrors}>
      <Stack direction="row" alignItems="center">
        <Checkbox
          name={name}
          required={required}
          disabled={readonly}
          checked={checkedState}
          value={checkedState}
          onChange={(e) => changeValue(e.target.checked)}
          inputProps={{ "data-cy": name } as any}
        />
        <Typography data-cy={`${name}-label`}>{label}</Typography>
      </Stack>
      {!!finalHelperText && (
        <FormHelperText data-cy={`${name}-helperText`}>{finalHelperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckboxWrapper;
