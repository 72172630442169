import {
  IConstantOption,
  IConstantData,
} from "apps/jtm/components/constants/ConstantContainerSetting";
import { deepCopyObjectWithoutFunction } from "libs/business/utils/objectFunctions";
import { LogConstantData } from "./types";

/**
 *
 * @param X constant1 data
 * @param Y constant2 data
 * @returns adds the missing options (options which were deleted or added new) from constant2(Y) to the constant1(X).
 */
export const addMissingOptionsInXFromY = (
  X: LogConstantData,
  Y: LogConstantData,
): LogConstantData => {
  const newValue = deepCopyObjectWithoutFunction(X);

  Y?.options?.forEach((yOption, index) => {
    const yAccount = yOption.account;
    const yEnvironment = yOption.environment;
    const optionDoesNotExistInX = !X?.options?.find(
      (xOption) => xOption.account === yAccount && xOption.environment === yEnvironment,
    );

    if (optionDoesNotExistInX) {
      newValue.options = newValue.options || [];
      addEmptyOptionAtIndex(newValue.options, index, yAccount, yEnvironment);
    }
  });
  return newValue;
};

const addEmptyOptionAtIndex = (
  arr: IConstantOption[],
  index: number,
  account: number,
  environment: number | undefined,
) => {
  arr.splice(index, 0, { value: "", account, environment });
};

export const transformLogData = (data: LogConstantData): IConstantData => {
  return {
    short_id: data.id,
    name: data.name,
    value: {
      value: data.value,
      options: data.options,
    },
  };
};

export const getAccounts = (constant: IConstantData): number[] => {
  return [...new Set<number>(constant.value?.options?.map((option) => option.account) || [])];
};
