import { useEffect } from "react";

/**
 * This hook is extending the normal useEffect hook in a way that
 * object dependencies(which are normally compared by reference) are instead compared by value.
 * @param effect The logic which is usually in the useEffect hook
 * @param dependencies the dependencies which are usually given to the useEffect hook
 */
const useCompareByValueEffect = (effect: () => void, dependencies: React.DependencyList) => {
  useEffect(() => {
    effect();
  }, [JSON.stringify(dependencies)]);
};

export default useCompareByValueEffect;
