import { useFormContext } from "react-hook-form";

const useTranslationForm = () => {
  const { getValues, setValue } = useFormContext();

  const setTranslationValue = (
    languageId: number,
    translationCollectionId: number,
    value: string,
    translationId: number | null,
  ): void => {
    const fieldName = `${languageId}_.${translationCollectionId}_`;
    setValue(`${fieldName}.value`, value);
    setValue(`${fieldName}.id`, translationId);
    setValue(`${fieldName}.textContentID`, translationCollectionId);
    setValue(`${fieldName}.languageID`, languageId);
  };

  const getTranslationValue = (languageId: number, translationCollectionId: number) => {
    const valueName = `${languageId}_.${translationCollectionId}_.value`;
    return getValues(valueName);
  };

  return { getTranslationValue, setTranslationValue };
};

export default useTranslationForm;
