import { Button, Stack } from "@mui/material";
import RequiresAccessGroupWrapper from "apps/admin/components/RequiresAccessGroupWrapper";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ISaveCancelBarProps {
  saveHandler?: () => void;
  cancelHandler?: () => void;
  resetMaintainedHandler?: () => void;
  showResetButton?: boolean;
  mainButtonText?: string;
}
/**
 * The SaveCancelBar component
 * @param {ISaveCancelBarProps} props
 * @returns a SaveCancelBar component
 */
const SaveCancelBar: React.FC<ISaveCancelBarProps> = ({
  saveHandler,
  cancelHandler,
  resetMaintainedHandler,
  showResetButton,
  mainButtonText = "Save",
}: ISaveCancelBarProps) => {
  const navigate = useNavigate();

  const navigateBack = (): void => {
    navigate(-1);
  };

  const mainButtonType = typeof saveHandler === "undefined" ? "submit" : "button";

  const handleCancelClick = typeof cancelHandler === "undefined" ? navigateBack : cancelHandler;

  return (
    <RequiresAccessGroupWrapper accessGroups={[1, 2, 3, 4, 5, 6]} hideChildren>
      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
        {showResetButton && (
          <Button
            sx={{ marginRight: "auto" }}
            variant="outlined"
            color="primary"
            onClick={resetMaintainedHandler}
            data-cy="reset">
            Reset Maintained
          </Button>
        )}
        <Button variant="outlined" color="primary" onClick={handleCancelClick} data-cy="cancel">
          Cancel
        </Button>
        <Button variant="contained" onClick={saveHandler} type={mainButtonType} data-cy="submit">
          {mainButtonText}
        </Button>
      </Stack>
    </RequiresAccessGroupWrapper>
  );
};

export default SaveCancelBar;
