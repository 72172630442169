import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from "@mui/material";
import { CSSProperties, FC, useEffect, useState } from "react";
import { useController, useFormContext, get } from "react-hook-form";

interface ITextfieldWrapperProps {
  name: string;
  label: string;
  value?: string | number;
  options: Option[];
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  size?: "small" | "medium";
  helperText?: string;
  style?: CSSProperties;
  onChange?: (newValue: string | number) => void;
  sx?: SxProps<Theme>;
  disableSorting?: boolean;
}
export interface Option {
  key: string | number;
  value: any;
}
/**
 * The InputWrapper component wraps a Mui TextField with a react-form-hook controller to us it in a context context.
 * @param {ITextfieldWrapperProps} props
 * @returns a InputWrapper component
 */
const SelectWrapper: FC<ITextfieldWrapperProps> = ({
  name,
  label,
  value = "",
  options,
  required = false,
  readonly = false,
  disabled = false,
  hidden = false,
  size = "small",
  helperText = "",
  style,
  onChange,
  sx,
  disableSorting,
}: ITextfieldWrapperProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
    defaultValue: value,
  });

  const nameErrors = get(errors, name);
  const [uiElementValue, setUiElementValue] = useState(value);

  const changeValue = (changedValue: string | number): void => {
    setUiElementValue(changedValue);
    setHookFormValue(changedValue);
    runCustomOnChangeFunction(changedValue);
  };

  const setHookFormValue = (changedValue: string | number) => {
    field.onChange(changedValue);
  };

  const runCustomOnChangeFunction = (changedValue: string | number) => {
    if (typeof onChange !== "undefined") onChange(changedValue);
  };

  // for changes not made by a manual input
  useEffect(() => {
    changeValue(value);
  }, [value]);

  const finalHelperText = nameErrors?.message || helperText;

  if (!disableSorting)
    options.sort((option1: Option, option2: Option) => (option1.value > option2.value ? 1 : -1));

  return (
    // the hidden attribute is not working with TextFields in combination with react-hook-form
    <FormControl
      margin="normal"
      style={{ display: hidden ? "none" : "", width: "100%", ...style }}
      error={!!nameErrors}>
      <InputLabel
        id="SelectLabel"
        size={size === "medium" ? "normal" : size}
        data-cy={`${name}-label`}>
        {label}
      </InputLabel>
      <Select
        labelId="SelectLabel"
        label={label}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        size={size}
        fullWidth
        value={uiElementValue}
        onChange={(e) => changeValue(e.target.value)}
        error={!!nameErrors}
        sx={sx}
        data-cy={`${name}-openMenuButton`}
        inputProps={{ "data-cy": name }}>
        {options.map((option: Option) => {
          return (
            <MenuItem key={option.key} value={option.key} data-cy={`${name}-option-${option.key}`}>
              {option.value}
            </MenuItem>
          );
        })}
      </Select>
      {!!finalHelperText && (
        <FormHelperText data-cy={`${name}-helperText`}>{finalHelperText}</FormHelperText>
      )}
    </FormControl>
  );
};
export default SelectWrapper;
