import { jtmPrefix } from "apps/jtm/conf/routes";
import { createSearchParams } from "react-router-dom";
import { LogType, SingleLogNavigationProps } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const navigateSingleLog = (props: SingleLogNavigationProps) => {
  const { navigate, logType, id, name } = props;
  const navigationState = {
    name,
    variableType: logType as string,
  };
  const urlPluginType = getUrlPluginType(logType);

  if (props.logType === "Variable") {
    navigationState.variableType = props.source;
  }

  navigate({
    pathname: `${jtmPrefix}/${urlPluginType}/log/${id}`,
    search: createSearchParams({
      name,
      variableType: navigationState.variableType,
    }).toString(),
  });
};

const getUrlPluginType = (logType: LogType) => {
  switch (logType) {
    case "Variable":
      return "variables";
    case "Function":
      return "functions";
    case "Tool":
      return "tools";
    case "Code":
      return "codes";
    case "State":
      return "states";
    case "Tag":
      return "tags";
    default:
      return "triggers";
  }
};
