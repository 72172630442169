import { Typography } from "@mui/material";
import { IGroupNavElement } from "apps/admin/conf/sidebar_config";
import { IRouteInfo } from "./business";
import SubNavLink from "./SubNavLink";
import classes from "./sidebar.module.scss";

interface IGroupNavElementProps {
  subNavGroup: IGroupNavElement;
  activeRouteInfo: IRouteInfo;
  groupNavIndex: number;
}
/**
 * The GroupNavElement component
 * @param {IGroupNavElementProps} props
 * @returns a GroupNavElement component
 */
const GroupNavElement = ({
  subNavGroup,
  activeRouteInfo,
  groupNavIndex,
}: IGroupNavElementProps): JSX.Element => {
  return (
    <>
      {/* <Typography className={classes.subNavGroup}>{subNavGroup.groupname || "\u00A0"}</Typography> */}
      {subNavGroup.elements.map((subNavElement, subNavIndex) => {
        const key = subNavElement.name + subNavIndex.toString();
        const isActive =
          activeRouteInfo.groupNavIndex === groupNavIndex &&
          activeRouteInfo.subNavIndex === subNavIndex;
        return <SubNavLink key={key} subNavElement={subNavElement} isActive={isActive} />;
      })}
    </>
  );
};

export default GroupNavElement;
