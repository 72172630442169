import { ApolloCache, ApolloError, FetchResult } from "@apollo/client";
import CLIENT from "libs/business/apollo";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import { GET_ALL_VERSIONS } from "../../business/version/queries";

// eslint-disable-next-line import/prefer-default-export
export const openPreviewMonitor = (
  versionNumber: number,
  containerUrl: string,
  containerID: number,
) => {
  window.open(
    `${window.location.origin}/app/monitor?jts_version=${versionNumber}&container_url=${containerUrl}&container_id=${containerID}`,
    "_blank",
  );
};

export const getUpdatePreviewOptions = (
  containerIds: number[],
  versionNumber: number,
  container_url: string,
  openMonitor = false,
) => {
  return {
    variables: { containerIds },
    context: { clientName: "JTM" },
    onCompleted: (response: any) => {
      notifySuccess("Update Preview successfull!");
      if (openMonitor) {
        openPreviewMonitor(versionNumber, container_url, containerIds[0]);
      }
    },
    update(cache: ApolloCache<any>, response: FetchResult) {
      if (!response.data) {
        return;
      }
      const allVersionQueryCache = CLIENT.readQuery({
        query: GET_ALL_VERSIONS,
      });
      if (!allVersionQueryCache) {
        return;
      }
      const cacheData = allVersionQueryCache.deploymentVersions.map((item: any) => {
        if (response.data && item.id === response.data.updatePreview.id) {
          return response.data.updatePreview;
        }
        return item;
      });
      CLIENT.writeQuery({
        query: GET_ALL_VERSIONS,
        data: {
          deploymentVersions: cacheData,
        },
      });
    },
    onError: (error: ApolloError) => {
      notifyError(error.message);
    },
  };
};
