import { useLazyQuery } from "@apollo/client";
import { IdAndName } from "libs/model/types";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GET_ALL_CONTAINERS } from "../../business/container/queries";

export interface IContainerContext {
  allContainers: IContainer[];
  getContainerByID: (containerID: number) => IContainer | undefined | "LOADING";
}

export interface IContainer extends IdAndName {
  id: number;
  name: string;
  live_url?: string;
  file_hash?: string;
  hash?: string;
  short?: string;
}

const ContainerContext = React.createContext<IContainerContext>({
  allContainers: [],
  getContainerByID: (containerID: number) => {
    return { id: containerID, name: "-" };
  },
});

const ContainerUpdateContext = React.createContext<
  React.Dispatch<React.SetStateAction<IContainer[]>>
>(() => {});
const ActiveContainerContext = React.createContext<number[]>([]);
const ActiveContainerUpdateContext = React.createContext<(containerIds: number[]) => void>(
  () => {},
);

export function useContainer() {
  return useContext(ContainerContext);
}

export function useContainerUpdate() {
  return useContext(ContainerUpdateContext);
}

export function useActiveContainer() {
  return useContext(ActiveContainerContext);
}

export function useActiveContainerUpdate() {
  return useContext(ActiveContainerUpdateContext);
}

interface IContainerProviderProps {
  children: ReactNode;
}
/**
 * The ContainerContext component provides the context for active containers.
 * @returns a ContainerContext component
 */
export const ContainerProvider = ({ children }: IContainerProviderProps) => {
  const [getAllContainers, queryState] = useLazyQuery(GET_ALL_CONTAINERS);
  const [allContainers, setContainer] = useState<IContainer[]>([]);
  const [activeContainerIds, setActiveContainerIds] = useState<number[]>([]);

  const getContainerByID = (containerID: number) => {
    if (allContainers.length === 0) {
      return "LOADING";
    }
    return allContainers.find((container) => container.id === containerID);
  };

  const containerContextMemo = useMemo(() => {
    return { allContainers, getContainerByID };
  }, [allContainers]);

  useEffect(() => {
    const newActiveContainers = allContainers.filter((container) =>
      activeContainerIds.includes(container.id),
    );
    setActiveContainerIds(newActiveContainers.map((container) => container.id));
  }, [allContainers]);

  const updateActiveContainers = (value: number[]) => {
    setActiveContainerIds(value);
    setLocalStorage(value);
  };

  const setLocalStorage = (containerIds: number[]): void => {
    localStorage.setItem("activeContainers", JSON.stringify(containerIds));
  };

  const getActiveContainersFromLocalStorage = (): number[] => {
    const ids: number[] = JSON.parse(localStorage.getItem("activeContainers") || "[]");
    return ids;
  };

  const updateLocalStorageWithNewContainers = (containers: IContainer[]) => {
    let ids = getActiveContainersFromLocalStorage();
    ids = ids.filter((id) => !!containers.find((container) => container.id === id));
    setLocalStorage(ids);
  };

  if (!queryState.called) {
    getAllContainers({
      context: { clientName: "ADMIN" },
      onCompleted: (response: any) => {
        setContainer(response.containers);
        updateLocalStorageWithNewContainers(response.containers);
        if (response.containers.length > 0) {
          updateActiveContainers(getActiveContainersFromLocalStorage());
        }
      },
    });
  }

  return (
    <ContainerContext.Provider value={containerContextMemo}>
      <ContainerUpdateContext.Provider value={setContainer}>
        <ActiveContainerContext.Provider value={activeContainerIds}>
          <ActiveContainerUpdateContext.Provider value={useCallback(updateActiveContainers, [])}>
            {children}
          </ActiveContainerUpdateContext.Provider>
        </ActiveContainerContext.Provider>
      </ContainerUpdateContext.Provider>
    </ContainerContext.Provider>
  );
};

export default ActiveContainerContext;
