import ConditionGroup from "./ConditionGroup";
import style from "./Trigger.module.scss";
import { TriggerCondition } from "./types";

interface IConditionProps {
  condition: TriggerCondition[][];
}

export default function Condition({ condition }: IConditionProps) {
  const conditionDOM = condition.map((group) => {
    return <ConditionGroup group={group} />;
  });
  return <div className={style.condition}>{conditionDOM}</div>;
}
