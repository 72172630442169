import { CaWizardContextType } from "./types";

const CA_INITIAL_CONTEXT: CaWizardContextType = {
  data: {
    containerIDs: [],
    pluginID: "",
    name: "",
    tagTypesToGenerate: undefined,
    createTags: true,
    constants: {},
  },
  activeStep: 1,
  highestVisited: 1,
};

export default CA_INITIAL_CONTEXT;
