// https://usehooks-ts.com/react-hook/use-copy-to-clipboard
import { useState, useEffect, useRef } from "react";
/**
 * This hook handles the problem that the state inside a component is depending on a prop of a component
 * Therefore  useEffect sets the right state
 * We also take care that as the component mount we not render a 2nd time
 *
 * @param initialValue Initial Value of the state
 * @returns
 */
const usePropChangingState = <ArgType>(
  initialValue: ArgType,
): [ArgType, React.Dispatch<React.SetStateAction<ArgType>>] => {
  const [stateValue, setState] = useState<ArgType>(initialValue);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setState(initialValue);
  }, [initialValue]);

  return [stateValue, setState];
};

export default usePropChangingState;
