import { Button } from "@mui/material";
import { Box, Stack } from "@mui/system";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import { FC } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface WarningPageProps {
  title: string;
  message?: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

const WarningPage: FC<WarningPageProps> = ({
  title,
  message,
  buttonText,
  onButtonClick,
}: WarningPageProps): JSX.Element => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={4}
      sx={{ display: "flex", borderColor: "#EF7918", borderRadius: "30px" }}>
      {/* <Box sx={{ position: "absolute", top: "50%", width: "75%" }}> */}
      <Box
        sx={{
          display: "flex",
          width: "75%",
          mt: "30vh",
          height: "30vh",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          rowGap: "1rem",
        }}>
        <AlertBox
          type="warning"
          title={title}
          message={message as string}
          boxStyles={{ width: "100%" }}
        />
        <Button variant="contained" onClick={onButtonClick} endIcon={<OpenInNewIcon />}>
          {buttonText}
        </Button>
      </Box>
    </Stack>
  );
};

export default WarningPage;
