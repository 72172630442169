import {
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, SxProps, Theme } from "@mui/system";
import TabContent from "libs/ui/components/surfaces/tabs/TabContent";
import TabWrapper from "libs/ui/components/surfaces/tabs/TabWrapper";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { IdAndName } from "libs/model/types";

export interface DependencyData {
  [key: string]: {
    list: IdAndName[];
    linkPrefix: string;
  };
}

interface DependenciesModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  elementName: string;
  dependencies: DependencyData;
}

const DependenciesModal: FC<DependenciesModalProps> = ({
  open,
  setOpen,
  dependencies,
  elementName,
}: DependenciesModalProps): JSX.Element => {
  const hasDependencies = Object.values(dependencies).some(
    (dependenciesArray) => dependenciesArray.list.length > 0,
  );

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box>
            <Typography id="modal-modal-title" variant="h2">
              <strong>Cross reference list</strong>
            </Typography>
            {hasDependencies ? (
              <Typography variant="subtitle1" sx={{ mb: "4px" }}>
                If the element &quot;<strong>{elementName}</strong>&quot; utilized elsewhere, it
                will be indicated in the list below.
              </Typography>
            ) : (
              <Typography variant="subtitle1" sx={{ mb: "4px" }}>
                No dependencies found
              </Typography>
            )}
          </Box>
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: "absolute", right: "3%", top: "3%" }}>
            <CloseIcon />
          </IconButton>
          {hasDependencies ? (
            <TabWrapper tabsSx={tabsStyle} tabBoxSx={tabBoxSx}>
              {Object.entries(dependencies).map(([key, dependenciesArr]) => (
                <TabContent label={key} key={key}>
                  <List>
                    {dependenciesArr.list.map((dependency) => (
                      <ListItem disablePadding key={dependency.id}>
                        <ListItemButton sx={ListItemSx}>
                          <Link
                            component={RouterLink}
                            to={`${dependenciesArr.linkPrefix}/${dependency.id}`}
                            sx={LinkSx}>
                            <ListItemIcon sx={ListItemIconSx}>
                              <CircleIcon sx={InnerIconSx} />
                            </ListItemIcon>
                            <ListItemText primary={dependency.name} />
                          </Link>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </TabContent>
              ))}
            </TabWrapper>
          ) : null}
        </Box>
      </Modal>
    </Box>
  );
};

const style: SxProps<Theme> = {
  position: "absolute",
  top: "30%",
  left: "30%",
  width: "40%",
  height: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  pb: 0,
  borderRadius: 2,
};

const tabBoxSx: SxProps<Theme> = {
  overflow: "auto",
  width: "100%",
  height: "55%",
};

const tabsStyle: SxProps<Theme> = {
  bgcolor: "rgba(0, 104, 163, 0.05)",
};

const LinkSx: SxProps<Theme> = {
  display: "flex",
  width: "100%",
  alignItems: "center",
};

const ListItemSx: SxProps<Theme> = {
  gap: "5px",
  pt: "5px",
};

const ListItemIconSx: SxProps<Theme> = { minWidth: "0.7rem" };

const InnerIconSx: SxProps<Theme> = { fontSize: "0.3rem", color: "black" };

export default DependenciesModal;
