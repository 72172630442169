import { Grid, Paper, Typography } from "@mui/material";
import React from "react";

interface ISelectionOverviewProps {
  tagName: string;
  toolName: string;
  imageUrl: string;
  templateName?: string;
}
/**
 * The SelectionOverview component
 * @param {ISelectionOverviewProps} props
 * @returns a SelectionOverview component
 */
const SelectionOverview = ({
  tagName,
  toolName,
  imageUrl,
  templateName,
}: ISelectionOverviewProps): JSX.Element => {
  return (
    <Paper variant="page">
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <img
            src={imageUrl}
            alt="logo"
            style={{ width: "150px", height: "150px", objectFit: "contain" }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5" style={{ wordBreak: "break-word" }}>
            Name: {tagName}
          </Typography>
          <Typography variant="h6">Selected Tool: {toolName}</Typography>
          <Typography variant="body1">Selected Template: {templateName}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SelectionOverview;
