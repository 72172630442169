import { CREATE_STATE, GET_ALL_STATES, GET_STATE_SHORT_IDS } from "apps/jtm/business/state/queries";
import { MaintainedProvider } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import { getCreateOptionsWithShortIDValidation } from "libs/business/apollo/createOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { StateForm } from "../../components/state/StateForm";

interface IUpdateState {
  id: number;
  short_id: string;
  version?: string;
  name: string;
  desc: string;
  value: string;
  maintained: number;
  pluginID?: string;
}

/**
 * The State component
 * @returns a state component
 */
const StateNew = (): JSX.Element => {
  const navigate = useNavigate();
  const [getStateShortIds, queryState] = useQueryWrapper(GET_STATE_SHORT_IDS);
  const [createNewState, createState] = useMutationWrapper(CREATE_STATE);

  const stateVar = {
    id: 0,
    short_id: "",
    version: "",
    name: "",
    desc: "",
    value: "",
    maintained: 1,
    pluginID: "",
  };

  if (!queryState.called) {
    getStateShortIds({ context: { clientName: "JTM" } });
  }

  if (!queryState.data || createState.loading) return <PageLoadingSpinner />;

  const formSubmitHandler: SubmitHandler<IUpdateState> = (submitData: IUpdateState) => {
    createNewState(
      getCreateOptionsWithShortIDValidation(
        "State",
        "JTM",
        submitData,
        GET_ALL_STATES,
        GET_STATE_SHORT_IDS,
        navigate,
      ),
    );
  };

  return (
    <MaintainedProvider
      initialValue={false}
      elementName="state"
      formSubmitHandler={formSubmitHandler}
      shortIds={queryState.data.stateShortIds.shortIds}>
      <StateForm mode="create" data={stateVar} />
    </MaintainedProvider>
  );
};

export default StateNew;
