import { useEnvironment } from "apps/jtm/components/environment/EnvironmentContext";
import MultiSelectWrapper from "libs/ui/components/input/MultiSelectWrapper";
import { memo } from "react";

interface IContainerMultiSelectProps {
  selection?: number[];
  onChange?: (newValues: number[]) => void;
}

const EnvironmentMultiSelect = ({
  selection = [],
  onChange,
}: IContainerMultiSelectProps): JSX.Element => {
  const allEnvironments = useEnvironment();

  return (
    <MultiSelectWrapper
      name="environmentIds"
      label="Environments"
      value={selection}
      options={allEnvironments}
      onChange={onChange}
    />
  );
};

export default memo(EnvironmentMultiSelect);
