import { ApolloError } from "@apollo/client";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  AssistantType,
  CREATE_ASSISTANT,
  CreateAssistantQueryDefinition,
  GET_ASSISTANT_INSTANCES_BY_TYPE,
  GetAssistantInstancesByTypeQueryDefinition,
} from "apps/jtm/business/assistant/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { notifyError } from "libs/business/notification/notification";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AssistantWizardProgressBar from "../assistant-wizard/AssistantWizardProgressBar";
import PageLoadingSpinner from "../feedback/loading-spinner/PageLoadingSpinner";
import AssistantListCard from "./AssistantListCard";

interface AssistantListProps {
  type: AssistantType;
  instanceName: string;
  titles: string[];
}
/**
 * The AssistantList component
 * @param {AssistantListProps} props
 * @returns a AssistantList component
 */
const AssistantList = ({ type, instanceName, titles }: AssistantListProps): JSX.Element => {
  const navigate = useNavigate();

  const response = useSimpleFetching<GetAssistantInstancesByTypeQueryDefinition>({
    query: GET_ASSISTANT_INSTANCES_BY_TYPE,
    queryArguments: { type },
    context: "JTM",
    disableCache: true,
  });

  const [assistantList, setAssistantList] = useState<
    GetAssistantInstancesByTypeQueryDefinition["dataType"][]
  >([]);

  const [createAssistantMutation] =
    useMutationWrapper<CreateAssistantQueryDefinition>(CREATE_ASSISTANT);

  const createAssistant = () => {
    createAssistantMutation({
      variables: {
        type,
        name: "Assistant",
      },
      context: { clientName: "JTM" },
      onCompleted: (res) => {
        navigate(`./${res.createAssistant.id}`);
      },
      onError: (error: ApolloError) => {
        notifyError(error.message);
      },
    });
  };

  const onDeleteComplete = (id: number) => {
    if (response !== false) {
      setAssistantList((oldList) => oldList.filter((a) => a.id !== id));
    }
  };

  useEffect(() => {
    if (response !== false && response.assistantsByType.length === 0) {
      createAssistant();
    }
    if (response !== false) {
      setAssistantList(response.assistantsByType);
    }
  }, [response]);

  if (response === false) {
    return <PageLoadingSpinner />;
  }

  return (
    <Container>
      <AssistantWizardProgressBar
        stepConfig={titles.map((title) => ({ title }))}
        currentStep={1}
        isLoading={false}
      />
      <Paper variant="page">
        <div style={{ height: "70vh" }} />
      </Paper>
      <Paper sx={{ p: 2, mt: 4 }} elevation={0}>
        <Stack direction="row" justifyContent="space-between" mt={1}>
          <div />
          <Stack direction="row" spacing={1}>
            <Button variant="outlined">Pause</Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
          </Stack>
        </Stack>
      </Paper>
      <Dialog open maxWidth="md">
        <DialogTitle align="center">
          We recognized that you have unfinished {instanceName} assistants!
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} alignItems="center" sx={{ mt: 3 }}>
            <div>
              <Button variant="contained" onClick={createAssistant}>
                I want to create a new {instanceName}
              </Button>
            </div>

            <Typography variant="h2">or</Typography>
            <Typography>I want to continue with one of my paused tool-assistants:</Typography>
            <Stack spacing={2} style={{ width: "100%" }}>
              {assistantList.map((assistantData) => (
                <AssistantListCard
                  key={assistantData.id}
                  assistantData={assistantData}
                  onDeleteComplete={() => onDeleteComplete(assistantData.id)}
                />
              ))}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default AssistantList;
