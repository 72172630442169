import {
  GetAllContainerQueryDefinition,
  GET_ALL_CONTAINERS,
} from "../../../apps/admin/business/container/queries";
import useSimpleFetching from "./useSimpleFetching";

const useIsSetupInitialized = (): boolean => {
  const getAllContainersResponse = useSimpleFetching<GetAllContainerQueryDefinition>({
    query: GET_ALL_CONTAINERS,
    context: "ADMIN",
  });

  if (getAllContainersResponse === false) return true;

  const allContainers = getAllContainersResponse.containers;
  return allContainers.length !== 0;
};
export default useIsSetupInitialized;
