import {
  Divider,
  Stack,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import ColoredAccordion from "libs/ui/components/surfaces/accordion/ColoredAccordion";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import React from "react";
import { GuiListData, TextData, ArrayListData } from "../tagTypes";
import FieldSelectionInputNoConsentWrapper from "./FieldSelectionInputNoConsentWrapper";
import GuiList from "./Guilist";

interface IPlaceholderProps {
  element: GuiListData | TextData | ArrayListData;
  displayNoConsent: boolean;
  rootInputName: string;
}
/**
 * The Placeholder component
 * @param {IPlaceholderProps} props
 * @returns a Placeholder component
 */
const Placeholder = ({
  element,
  displayNoConsent,
  rootInputName,
}: IPlaceholderProps): JSX.Element => {
  if (element.type === "TEXT") {
    return (
      <div>
        {/* <Divider /> */}
        <FieldSelectionInputNoConsentWrapper
          inputFieldName={`${rootInputName}.${element.id}`}
          label={element.name}
          desc={element.desc}
          value={{ value: element.value, noConsentValue: element.noConsentValue || "" }}
          optional={element.optional}
          displayNoConsent={displayNoConsent}
        />
      </div>
    );
  }
  if (element.type === "GUILIST") {
    return (
      <Stack spacing={2}>
        {/* <Divider /> */}
        <GuiList
          guiListData={element}
          displayNoConsent={displayNoConsent}
          rootInputName={rootInputName}
        />
      </Stack>
    );
  }
  return (
    <div>
      <ArrayList
        element={element}
        displayNoConsent={displayNoConsent}
        rootInputName={rootInputName}
      />
    </div>
  );
};

export default Placeholder;

interface IArrayListProps {
  element: ArrayListData;
  displayNoConsent: boolean;
  rootInputName: string;
}
/**
 * The ArrayList component
 * @param {IArrayListProps} props
 * @returns a ArrayList component
 */

// !!Not in another file because of cyclic import
const ArrayList = ({ element, displayNoConsent, rootInputName }: IArrayListProps): JSX.Element => {
  return (
    <ColoredAccordion index={0}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography>{element.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <TextFieldWrapper
            name={`arraylistVariables.${element.id}.filterVariable`}
            label="Filter Variable"
            value={element.filterVariable}
          />
          <TextFieldWrapper
            name={`arraylistVariables.${element.id}.filterValue`}
            label="Filter Value"
            value={element.filterValue}
          />
          {element.entries.map((entry) => (
            <Placeholder
              key={entry.id}
              element={entry}
              displayNoConsent={displayNoConsent}
              rootInputName={`${rootInputName}.${element.id}`}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </ColoredAccordion>
  );
};
