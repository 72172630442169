import { GetLogByIdQueryDefinition } from "apps/jtm/business/log/queries";
import Page from "libs/ui/components/surfaces/page/Page";
import CompareAccount from "./CompareAccount";
import CompareName from "./CompareName";
import MessageTransformer from "./MessageTransformer";

interface CompareChangesProps {
  logData: GetLogByIdQueryDefinition["dataType"];
}
/**
 * The CompareChanges component
 * @param {CompareChangesProps} props
 * @returns a CompareChanges component
 */
const CompareChanges = ({ logData }: CompareChangesProps): JSX.Element => {
  let title = "Changes";
  let component;

  if (logData.action === "Name change") {
    title = "Name Change";
    component = <CompareName message={logData.message} />;
  } else if (logData.action === "Account change") {
    title = "Account Change";
    component = <CompareAccount message={logData.message} />;
  } else {
    component = (
      <MessageTransformer
        elementType={logData.element}
        message={logData.message}
        elementID={logData.elementID}
      />
    );
  }

  return <Page title={title}>{component}</Page>;
};

export default CompareChanges;
