import { Box, Button, Typography } from "@mui/material";

import herosImage from "assets/images/1402_HoDP_Heroescollage_web.webp";
import herosText from "assets/images/logos/HoDP_logo_text_1c_white.svg";

const HerosOfDataPrivacy = (): JSX.Element => {
  return (
    <>
      <style>
        {`
        .text {
          display: flex;
          flex-direction: column;
          gap: 38px;
        }
        
        .background {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 16px;
          background: linear-gradient(
            180deg,
            rgba(56, 64, 67, 0) 22%,
            rgba(56, 64, 67, 1) 22%,
            rgba(56, 64, 67, 1) 78%,
            rgba(56, 64, 67, 0) 78%
          );
        }

        .greyText {
          font-size: 26px;
          line-height: 26px;
          color: rgba(56, 64, 67, 1);
        }

        .title {
          height: 120px;
        }

        .goldText {
          color: #d4c18f;
          line-height: 55px;
          font-size: 44px;
          margin-top: 8px;
          font-weight: 400;
        }

        .whiteText{
          color: white;
          font-weight: 300px;
          font-size: 16px;
          margin-top: 16px;
        }

        .imgStyle {
          height: 600px;
        }

        @media only screen and (max-width: 1090px) {
          .text {
            gap: 26px;
          }

          .background {
            gap: 0px;
          }

          .greyText {
            font-size: 20px;
            line-height: 20px;
          }

          .whiteText{
            font-size: 14px;
          }

          .title {
            height: 80px;
          }

          .goldText {
            line-height: 32px;
            font-size: 28px;
            margin-top: 4px;
            font-weight: 300;
          }

          .imgStyle {
            height: 400px;
          }
        }
        }`}
      </style>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}>
        <a
          href="https://www.heroesofdataprivacy.com/?utm_medium=banner&utm_source=dcp&utm_campaign=hodp_2023_event"
          target="_blank"
          rel="noreferrer"
          className="background">
          <div>
            <Box>
              <img src={herosImage} className="imgStyle" alt="Heros of Data & Privacy" />
            </Box>
          </div>
          <div className="text">
            <Typography className="greyText" style={{ fontWeight: "300", marginBottom: "10px" }}>
              Special offer for clients and friends of JENTIS
            </Typography>
            <Typography className="greyText">May 24-25, Marx Palast, Vienna</Typography>
            <div>
              <img src={herosText} alt="Heros of Data & Privacy" className="title" />

              <Typography className="goldText">
                Get 30% off your ticket <br />
                with code <strong>FRIENDS</strong>!
              </Typography>
              <Typography className="whiteText">(eligible only on Standard-Tickets)</Typography>
            </div>
            <Typography className="greyText">heroesofdataprivacy.com</Typography>
            <div>
              <Button variant="contained" style={{ background: "rgba(56, 64, 67, 1)" }}>
                Find out more
              </Button>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default HerosOfDataPrivacy;
