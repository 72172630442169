import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";

interface PageNotificationProps {
  notificationText: string;
  textColor: string;
  backgroundColor: string;
  icon: IconDefinition;
}
/**
 * The PageNotification component
 * @param {PageNotificationProps} props
 * @returns a PageNotification component
 */
const PageNotification = ({
  notificationText,
  textColor,
  backgroundColor,
  icon,
}: PageNotificationProps): JSX.Element => {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          p: 1,
          backgroundColor,
          borderRadius: "4px",
          boxSizing: "border-box",
        }}>
        <FontAwesomeIcon icon={icon} color={textColor} size="lg" />
        <Typography variant="caption" data-cy="pageNotification">
          {notificationText}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PageNotification;
