import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import logo from "assets/images/logos/logo_black.png";
import useIsSetupInitialized from "libs/business/hooks/useIsSetupInitialized";
import HerosOfDataPrivacy from "libs/ui/components/advertisements/HerosOfDataPrivacy";
import { useState } from "react";
import { Navigate } from "react-router-dom";

let open = true;

function Home(): JSX.Element {
  const isSetupInitialized = useIsSetupInitialized();
  const [adOpen, setAdOpen] = useState(open);

  if (!isSetupInitialized) {
    return <Navigate to="/init-customer" />;
  }

  const dueDate = new Date(2023, 4, 26);
  const dueDateTimestamp = dueDate.getTime();

  const onClose = () => {
    open = false;
    setAdOpen(false);
  };

  return (
    <>
      {dueDateTimestamp > Date.now() && (
        <Dialog open={adOpen} fullWidth maxWidth="xl" onClose={onClose}>
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                width: "30px",
                height: "30px",
                color: (theme) => theme.palette.grey[500],
              }}>
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </DialogTitle>
          <HerosOfDataPrivacy />;
        </Dialog>
      )}
      <div
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <img style={{ width: "20%" }} alt="Logo" src={logo} />
      </div>
    </>
  );
}

export default Home;
