import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";

import colorStyles from "assets/styles/colorPalette.module.scss";

interface LogInformationProps {
  versionNumber: number;
  containerName: string;
  editAt: string;
}
/**
 * The Information component
 * @param {LogInformationProps} props
 * @returns a Information component
 */
const LogInformation = ({
  versionNumber,
  containerName,
  editAt,
}: LogInformationProps): JSX.Element => {
  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
      <Card
        sx={{
          width: "100%",
        }}>
        <CardHeader title="Version" sx={{ background: colorStyles.grey3 }} />
        <CardContent>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography variant="h1">{versionNumber}</Typography>
          </Stack>
        </CardContent>
      </Card>
      <Card
        sx={{
          width: "100%",
        }}>
        <CardHeader title="Container" sx={{ background: colorStyles.grey3 }} />
        <CardContent>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography variant="h2">{containerName}</Typography>
          </Stack>
        </CardContent>
      </Card>
      <Card
        sx={{
          width: "100%",
        }}>
        <CardHeader title="Last Change" sx={{ background: colorStyles.grey3 }} />
        <CardContent>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography variant="h2">{editAt}</Typography>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default LogInformation;
