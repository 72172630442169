import { faClipboardList, faCloud, faDesktop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import RequiresAccessGroupWrapper from "apps/admin/components/RequiresAccessGroupWrapper";
import { jtmPrefix } from "apps/jtm/conf/routes";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import { createSearchParams, useNavigate } from "react-router-dom";
import LoadComponentSpinner from "libs/ui/components/feedback/loading-spinner/LoadComponentSpinner";
import { GET_VERSION_CLASSIFICATION_BY_ID } from "apps/jtm/business/version/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { UPDATE_PREVIEW } from "../../business/output/queries";
import { getUpdatePreviewOptions, openPreviewMonitor } from "./update_preview";
import { usePublishDialog } from "./PublishDialog";

const CurrentVersionCardContent = ({ data }: any): JSX.Element => {
  const navigate = useNavigate();
  const [updatePreviewMutation, mutationState] = useMutationWrapper(UPDATE_PREVIEW);
  const { handleOpenModal } = usePublishDialog();

  const fetchedVersion = useSimpleFetching({
    query: GET_VERSION_CLASSIFICATION_BY_ID,
    context: "JTM",
    queryArguments: {
      id: data.id,
    },
  });

  const containerUrl = data.test_url || data.container_url;

  const onPreviewButtonClick = () => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      updatePreviewMutation(
        getUpdatePreviewOptions([data.container_id], data.number, containerUrl, true),
      );
    } else {
      openPreviewMonitor(data.number, containerUrl, data.container_id);
    }
  };

  const handlePublishButtonClick = () => {
    if (fetchedVersion === false) return;
    handleOpenModal({
      containerName: data.container_name,
      versionNumber: data.number,
      versionId: data.id,
      classification: fetchedVersion.deploymentVersion.classification,
    });
  };

  const openChangeLog = (): void => {
    navigate({
      pathname: `${jtmPrefix}/versions/log`,
      search: createSearchParams({
        versionId: data.id,
        containerName: data.container_name,
        versionNumber: data.number,
        editAt: data.updated_on,
      }).toString(),
    });
  };

  if (mutationState.loading || fetchedVersion === false)
    return (
      <Stack style={{ height: "100%" }} justifyContent="center">
        <LoadComponentSpinner />
      </Stack>
    );

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item xs={6}>
        <Box sx={{ m: 1 }}>
          <Typography variant="subtitle2">Updated On</Typography>
          <Typography variant="body2"> {data.updated_on}</Typography>
          <Box sx={{ m: 1 }} />
          <Typography variant="subtitle2">Updated by</Typography>
          <Typography variant="body2" overflow="hidden" textOverflow="ellipsis">
            {data.updated_by}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Stack
          sx={{ p: 1, height: "100%", boxSizing: "border-box" }}
          spacing={1}
          justifyContent="flex-end">
          <Button
            onClick={openChangeLog}
            variant="outlined"
            size="small"
            endIcon={<FontAwesomeIcon icon={faClipboardList} pull="right" />}>
            <Typography variant="caption" sx={{ mr: 1 }}>
              Changelog
            </Typography>
          </Button>
          <Button
            variant="outlined"
            size="small"
            endIcon={<FontAwesomeIcon icon={faDesktop} pull="right" />}
            onClick={onPreviewButtonClick}>
            <Typography variant="caption">Show Inspector</Typography>
          </Button>
          <RequiresAccessGroupWrapper accessGroups={[1, 2, 3, 4, 5, 6]} hideChildren>
            <Button
              variant="contained"
              size="small"
              endIcon={<FontAwesomeIcon icon={faCloud} pull="right" />}
              onClick={handlePublishButtonClick}>
              <Typography variant="caption">Publish</Typography>
            </Button>
          </RequiresAccessGroupWrapper>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CurrentVersionCardContent;
