import { faTag } from "@fortawesome/pro-solid-svg-icons";
import HomeIcon from "libs/ui/components/home-icon/HomeIcon";

/**
 * The ServerTagManagement component
 * @returns a ServerTagManagement component
 */
const ServerTagManagementDefaultPage = (): JSX.Element => {
  return <HomeIcon icon={faTag} title="Server Tag Manager" />;
};

export default ServerTagManagementDefaultPage;
