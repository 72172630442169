import { Route, Routes } from "react-router-dom";
import { WHITELIST_ROUTES } from "../conf/routes";

const WhiteListRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="*" element={<div />} />
      {Object.values(WHITELIST_ROUTES).map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default WhiteListRoutes;
