import { gql } from "@apollo/client";

const GET_PURPOSES = gql`
  query Purposes {
    Purposes {
      id
      purposeid
      textcontent_textcontentid
      text_content {
        text
      }
    }
  }
`;

export default GET_PURPOSES;
