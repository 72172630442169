import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import noInstanceIcon from "../../../../assets/icons/noInstanceIcon.png";

interface NoInstanceWarningProps {
  name: string;
}

const NoInstanceWarning = ({ name }: NoInstanceWarningProps) => {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "15%",
      }}>
      <img src={noInstanceIcon} alt="No instance warning" />
      <Typography variant="h2" fontWeight={500} sx={{ mt: 2 }}>
        No {name.toLowerCase()} found
      </Typography>
      <Typography variant="bodyRegular16" sx={{ mt: 1 }}>
        To add new {name.toLowerCase()}, click on the &quot; Add New {name}&quot; button
      </Typography>
    </Container>
  );
};

export default NoInstanceWarning;
