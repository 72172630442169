import { Diff, diff_match_patch } from "diff-match-patch";

// These icons have must be unique as the regex could break the code
export const newIcon = "✅";
export const oldIcon = "🚫";

export const formatDiffs = (diffs: Diff[]) => {
  let value = "";
  // eslint-disable-next-line no-restricted-syntax
  for (const item of diffs) {
    const diffStatus = item[0];
    const text = item[1];

    if (diffStatus === 0) {
      value += text;
    }

    if (diffStatus === -1) {
      value += addIconToLines(text, oldIcon);
    }

    if (diffStatus === 1) {
      value += addIconToLines(text, newIcon);
    }
  }
  return value;
};

export const addIconToLines = (lines: string, icon: string): string => {
  return `${icon + lines.trimEnd().replaceAll(/\r\n|\r|\n/g, `\n${icon}`)}\n`;
};

export const diffLineMode = (oldText: string, newText: string): Diff[] => {
  // eslint-disable-next-line new-cap
  const dmp = new diff_match_patch();
  // eslint-disable-next-line no-underscore-dangle
  const a = dmp.diff_linesToChars_(oldText, newText);
  const diffs = dmp.diff_main(a.chars1, a.chars2, false);
  // eslint-disable-next-line no-underscore-dangle
  dmp.diff_charsToLines_(diffs, a.lineArray);
  return diffs;
};

export const removeMarkers = (text: string): string => {
  const textWithoutNewIcon = removeNewIcon(text);
  return removeLinesWithOldIcon(textWithoutNewIcon);
};

const removeNewIcon = (text: string): string => {
  const newSymbol = new RegExp(newIcon, "gm");
  return text.replaceAll(newSymbol, "");
};

const removeLinesWithOldIcon = (text: string): string => {
  const wholeLineStartingWithOldIcon = new RegExp(`${oldIcon}(.*?)\n`, "gm");
  return text.replaceAll(wholeLineStartingWithOldIcon, "");
};

export const diffInfo = `Added lines: ${newIcon}\nRemoved lines: ${oldIcon} (These lines will not be saved. To keep lines remove the ${oldIcon}-icon)`;
export const diffInfoReadOnly = `Added lines: ${newIcon}\nRemoved lines: ${oldIcon}`;
