import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MoreVert } from "@mui/icons-material";
import { Grid, ListItemIcon, ListItemText, Paper, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import colorStyles from "assets/styles/colorPalette.module.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

export interface CardListCardButton {
  name: string;
  icon: IconDefinition;
  onClick: Function;
}

type DataField = {
  name: string;
  value: string;
};

export interface ICardListCard {
  title: string;
  image: string;
  link: string;
  menuItemConfigs: Array<CardListCardButton>;
  dataFields?: DataField[];
}

const ITEM_HEIGHT = 48;

const CardListCard = ({ title, link, image, menuItemConfigs, dataFields }: ICardListCard) => {
  const menuItems = menuItemConfigs.map((menuConfigItem: CardListCardButton) => (
    <MenuItem
      key={menuConfigItem.name}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        menuConfigItem.onClick();
      }}>
      <ListItemIcon>
        <FontAwesomeIcon icon={menuConfigItem.icon} />
      </ListItemIcon>
      <ListItemText>{menuConfigItem.name}</ListItemText>
    </MenuItem>
  ));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <Link to={link}>
      <Paper
        sx={{
          minWidth: 280,
          cursor: "pointer",
          boxShadow: "0px 1px 4px rgba(97, 97, 97, 0.2)",
          ":hover": {
            boxShadow: "0px 4px 8px 0px hsla(0, 0%, 38%, 0.24)",
          },
        }}>
        <Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
            <Typography>{title}</Typography>
            {menuItems.length > 0 && (
              <>
                <IconButton onClick={handleMenuClick} size="small">
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}>
                  {menuItems}
                </Menu>
              </>
            )}
          </Stack>
          <div
            style={{
              padding: 16,
              borderTop: "1px solid",
              borderColor: colorStyles.grey3,
            }}>
            <div
              style={{
                background: `url(${image})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: 80,
              }}
            />
          </div>
          {dataFields && dataFields?.length > 0 && (
            <Grid
              container
              rowSpacing={1}
              style={{
                padding: 16,
                borderTop: "1px solid",
                borderColor: colorStyles.grey3,
              }}>
              {dataFields?.map((field, index) => {
                return (
                  <Grid item xs={6} key={field.name}>
                    <Stack
                      style={{
                        flex: 1,
                        borderLeft: index % 2 ? "1px solid" : "",
                        borderColor: colorStyles.grey3,
                        paddingLeft: index % 2 ? 16 : 0,
                      }}>
                      <Typography variant="bodyRegular12">{field.name}</Typography>
                      <Typography variant="bodyMedium14">{field.value}</Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Stack>
      </Paper>
    </Link>
  );
};

export default CardListCard;
