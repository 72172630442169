import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Paper, Stack } from "@mui/material";
import { GuiListEntry, GuiListEntryDefinition } from "../tagTypes";
import FieldSelectionInputNoConsentWrapper from "./FieldSelectionInputNoConsentWrapper";

const style100 = {
  width: "100%",
};

interface IGuiListItemProps {
  entryDefinition: GuiListEntryDefinition[];
  values: GuiListEntry;
  rootInputFieldName: string;
  displayNoConsent: boolean;
  onDelete: () => void;
}
/**
 * The GuiListItem component
 * @param {IGuiListItemProps} props
 * @returns a GuiListItem component
 */
const GuiListItem = ({
  entryDefinition,
  values,
  rootInputFieldName,
  displayNoConsent,
  onDelete,
}: IGuiListItemProps): JSX.Element => {
  return (
    <Stack>
      <Grid
        container
        sx={{ p: 1, backgroundColor: "divider", borderRadius: "4px" }}
        justifyContent="center"
        alignItems="center">
        <Grid item xs={11}>
          <Stack spacing={1} sx={{ flex: 1 }}>
            {entryDefinition.map((definition) => (
              <FieldSelectionInputNoConsentWrapper
                key={definition.id}
                inputFieldName={`${rootInputFieldName}.${definition.id}`}
                label={definition.name}
                desc={definition.desc || definition.name}
                value={{
                  value: values?.[definition.id]?.value || "",
                  noConsentValue: values?.[definition.id]?.noConsentValue || "",
                }}
                displayNoConsent={displayNoConsent}
                optional
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <IconButton sx={{ borderRadius: 0, margin: 0, float: "right" }} onClick={onDelete}>
            <FontAwesomeIcon icon={faTrashCan} size="sm" />
          </IconButton>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default GuiListItem;
