import { Grid, Paper, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import SelectWithSearch, { SelectSearchOption } from "libs/ui/components/search/SelectWithSearch";
import { useWizardData } from "libs/ui/components/surfaces/wizard/WizardDataContext";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  GetTagTypesByPluginIdQueryDefinition,
  GET_TAG_TYPE_DEFINITION_BY_PLUGINID,
} from "../../business/tool/queries";
import { TAG_CONFIG_ROOT_KEY } from "./ConfigureTemplate";
import SelectionOverview from "./SelectionOverview";

interface TagType {
  name: string;
  description: string;
  triggerId?: number | undefined;
}

/**
 * The StepTwo component
 * @param {IStepTwoProps} props
 * @returns a StepTwo component
 */
const StepTwo = (): JSX.Element => {
  const wizardData = useWizardData();
  const { unregister } = useFormContext();

  const [tagType, setTagType] = useState<TagType | undefined>();

  const templateStringId = wizardData.template || undefined;
  // eslint-disable-next-line prefer-destructuring
  const pluginID = wizardData.tool.pluginID;

  const getTagTypes = useSimpleFetching<GetTagTypesByPluginIdQueryDefinition>({
    query: GET_TAG_TYPE_DEFINITION_BY_PLUGINID,
    context: "JTM",
    queryArguments: { pluginID },
  });

  useEffect(() => {
    if (getTagTypes) setTagType(getTagTypes.toolDefinition.tag_types[templateStringId || ""]);
  }, [getTagTypes]);

  const handleChangeTemplate = useCallback(
    (value: SelectSearchOption["key"] | undefined) => {
      if (value !== templateStringId) {
        unregister(TAG_CONFIG_ROOT_KEY);
      }
      if (getTagTypes) setTagType(getTagTypes.toolDefinition.tag_types[value || ""]);
    },
    [getTagTypes],
  );

  if (getTagTypes === false) {
    return <PageLoadingSpinner />;
  }
  const tagTypes = getTagTypes.toolDefinition.tag_types;
  const tagTypeIds: string[] = Object.keys(tagTypes);
  const tagTypeOptions: SelectSearchOption[] = [];
  tagTypeIds.forEach((tagTypeId: string) => {
    tagTypeOptions.push({
      key: tagTypeId,
      value: (
        <SelectOption
          name={tagTypes[tagTypeId].name}
          description={tagTypes[tagTypeId].description}
        />
      ),
      optionLabel: tagTypes[tagTypeId].name,
    });
  });

  tagTypeOptions.sort((a, b) => a.optionLabel.localeCompare(b.optionLabel));
  return (
    <>
      <SelectionOverview
        tagName={wizardData.name}
        toolName={wizardData.tool.name}
        imageUrl={`${wizardData.tool.tool_definition.image_path}medium`}
      />
      <TextFieldWrapper
        name="tag_types"
        label="tag_types"
        value={tagType as any}
        size="medium"
        hidden
      />
      <Typography variant="pageTitle" sx={{ mt: 3 }}>
        Template Selection
      </Typography>
      <Paper variant="page">
        <SelectWithSearch
          inputName="template"
          instanceName="Template"
          defaultValue={templateStringId}
          onChange={handleChangeTemplate}
          options={tagTypeOptions}
        />
        {templateStringId !== undefined && (
          <Typography variant="caption" style={{ color: red[700], margin: " 0 0 0 16px" }}>
            By changing this value you will lose all your template configurations!
          </Typography>
        )}
      </Paper>
    </>
  );
};

export default StepTwo;

export const SelectOption = ({
  name,
  description,
}: {
  name: string;
  description: string;
}): JSX.Element => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <Typography variant="body1" sx={{ whiteSpace: "break-spaces" }}>
          {name}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="caption" style={{ display: "inline-block", whiteSpace: "pre-line" }}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};
