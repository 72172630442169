import { faArrowRight, faArrowRightRotate } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import TextFieldWrapper from "../input/TextFieldWrapper";

interface ICompareSimpleInputProps {
  inputName: string;
  leftLabel: string;
  rightLabel: string;
  leftValue: string;
  rightValue: string;
  allowCompareEdit?: boolean;
}
/**
 * The CompareSimpleInput component
 * @param {ICompareSimpleInputProps} props
 * @returns a CompareSimpleInput component
 */
const CompareSimpleInput = ({
  inputName,
  leftLabel,
  rightLabel,
  leftValue,
  rightValue,
  allowCompareEdit = false,
}: ICompareSimpleInputProps): JSX.Element => {
  const memoizedValue = useMemo(() => rightValue, []);
  const [rightVal, setRightVal] = useState(rightValue);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <TextFieldWrapper name={`left_${inputName}`} label={leftLabel} readonly value={leftValue} />
      {renderCopyArrow(allowCompareEdit, () => {
        setRightVal(leftValue);
      })}
      <TextFieldWrapper
        name={inputName}
        label={rightLabel}
        readonly={!allowCompareEdit}
        value={rightVal}
        onChange={setRightVal}
      />
      {renderReset(allowCompareEdit, () => setRightVal(memoizedValue))}
    </Stack>
  );
};

export default CompareSimpleInput;

const renderCopyArrow = (show: boolean, onClick: () => void): JSX.Element | null => {
  if (show) {
    return (
      <Button style={{ marginTop: "8px" }} onClick={onClick}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    );
  }
  return <FontAwesomeIcon style={{ marginTop: "8px" }} icon={faArrowRight} />;
};

const renderReset = (show: boolean, onClick: () => void): JSX.Element | null => {
  if (show) {
    return (
      <IconButton style={{ marginTop: "8px" }} onClick={onClick} size="small">
        <FontAwesomeIcon icon={faArrowRightRotate} />
      </IconButton>
    );
  }
  return null;
};
