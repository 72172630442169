import { Typography } from "@mui/material";
import style from "./ConsentCard.module.scss";

export interface IConsentCardProps {
  name: string;
  image: string;
  status: true | false | "ncm";
}

export default function ConsentCard({ name, image, status }: IConsentCardProps) {
  let statusIndicator;

  switch (status) {
    case true:
      statusIndicator = <span className={style.accepted} />;
      break;
    case false:
      statusIndicator = <span className={style.declined} />;
      break;
    case "ncm":
      statusIndicator = <span className={style.ncm} />;
      break;
    default:
      statusIndicator = <span className={style.unknown} />;
  }

  return (
    <div className={style.card}>
      {statusIndicator}
      <img src={image} alt={`img-${name}`} className={style.image} />
      <Typography className={style.text}>{name}</Typography>
    </div>
  );
}
