import { Box, Grid, Stack, Typography } from "@mui/material";
import { useContainer } from "apps/admin/components/container/ContainerContext";
import { useEnvironment } from "apps/jtm/components/environment/EnvironmentContext";
import MultiSelectWrapper from "libs/ui/components/input/MultiSelectWrapper";
import SelectWrapper from "libs/ui/components/input/SelectWrapper";
import TextEditor from "libs/ui/components/input/text-editor/TextEditor";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { IdAndName } from "libs/model/types";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import ColorSelectWrapper from "libs/ui/components/input/ColorSelectWrapper";
import ConstantContainerSetting, { IConstantData } from "./ConstantContainerSetting";

interface IConstantEditTableProps {
  constantData: IConstantData;
  selectedContainerIds: string[] | number[];
  submitName: string;
  environmentColumnsNeeded?: boolean;
  defaultReadOnly?: boolean;
  readonly?: boolean;
  hideDefault?: boolean;
}
/**
 * The ConstantEditTable component
 * @param {IConstantEditTableProps} props
 * @returns a ConstantEditTable component
 */
const ConstantEditTable = ({
  constantData,
  selectedContainerIds,
  submitName,
  environmentColumnsNeeded,
  defaultReadOnly = false,
  readonly = false,
  hideDefault = false,
}: IConstantEditTableProps): JSX.Element => {
  const [defaultValue, setDefaultValue] = usePropChangingState(constantData.value.value);
  const { allContainers } = useContainer();
  const environments = useEnvironment();

  const getContainerById = (id: number) => {
    const container = allContainers.find((element) => id === element.id);
    if (typeof container === "undefined") throw new Error(`Container with id ${id} does not exist`);
    return container;
  };

  const inputByType = (): JSX.Element => {
    if (constantData.value.type) {
      switch (constantData.value.type) {
        case "select":
          if (constantData.value.type_options) {
            return (
              <SelectWrapper
                key={`${constantData.short_id}-select`}
                name={`${submitName}.${constantData.short_id}.value`}
                label="Default Value"
                size="medium"
                value={defaultValue}
                options={constantData.value.type_options}
                readonly={readonly || defaultReadOnly}
              />
            );
          }
          break;
        case "multiselect":
          if (constantData.value.type_options) {
            return (
              <MultiSelectWrapper
                key={`${constantData.short_id}-select`}
                name={`${submitName}.${constantData.short_id}.value`}
                label="Default Value"
                value={defaultValue}
                options={constantData.value.type_options}
                readonly={readonly || defaultReadOnly}
              />
            );
          }
          break;
        case "editor":
          return (
            <TextEditor
              key={`${constantData.short_id}-select`}
              name={`${submitName}.${constantData.short_id}.value`}
              placeholder="No default value..."
              value={defaultValue}
              disabled={readonly || defaultReadOnly}
            />
          );
        case "color":
          return (
            <ColorSelectWrapper
              name={`${submitName}.${constantData.short_id}.value`}
              value={defaultValue}
              disabled={readonly || defaultReadOnly}
            />
          );
        default:
          return (
            <TextFieldWrapper
              name={`${submitName}.${constantData.short_id}.value`}
              label="Default Value"
              size="medium"
              value={defaultValue}
              readonly={readonly || defaultReadOnly}
            />
          );
      }
    }

    return (
      <TextFieldWrapper
        name={`${submitName}.${constantData.short_id}.value`}
        label="Default Value"
        size="medium"
        value={defaultValue}
        onChange={setDefaultValue}
        readonly={readonly || defaultReadOnly}
      />
    );
  };

  const desc = constantData?.value?.description || constantData?.desc;

  return (
    <Stack>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4">{constantData.name}</Typography>
        <Typography variant="caption">{desc}</Typography>
      </Box>

      {!hideDefault && (
        <Stack direction="row" spacing={2} justifyContent="space-between" alignContent="flex-end">
          <Box sx={{ mt: "8px" }}>
            <Typography variant="body1">Default Value</Typography>
            <Typography variant="caption">
              Use this value for all the containers which do not have a specified value.
            </Typography>
          </Box>
          {inputByType()}
        </Stack>
      )}

      {selectedContainerIds.length > 0 &&
        environmentColumnsNeeded &&
        renderEnvironmentNames(environments)}

      {environmentColumnsNeeded
        ? selectedContainerIds?.map((containerId) => {
            const container = getContainerById(
              typeof containerId === "number" ? containerId : parseInt(containerId, 10),
            );
            return (
              <Box key={containerId} sx={{ mt: 2 }}>
                <ConstantContainerSetting
                  containerInfo={container}
                  constant={constantData}
                  submitName={submitName}
                  allEnvironments={environments}
                  defaultValue={defaultValue}
                  readOnly={readonly}
                />
              </Box>
            );
          })
        : selectedContainerIds?.map((containerId) => {
            const container = getContainerById(
              typeof containerId === "number" ? containerId : parseInt(containerId, 10),
            );
            return (
              <Box key={containerId} sx={{ mt: 2 }}>
                <ConstantContainerSetting
                  containerInfo={container}
                  submitName={submitName}
                  constant={constantData}
                  defaultValue={defaultValue}
                  readOnly={readonly}
                />
              </Box>
            );
          })}
    </Stack>
  );
};

export default ConstantEditTable;

const renderEnvironmentNames = (allEnvironments: IdAndName[]) => {
  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={3} />
      <Grid item xs={9}>
        <Stack direction="row" spacing={2} justifyContent="space-around">
          {allEnvironments.map((environment) => {
            return (
              <Typography key={environment.id} variant="body1" align="center">
                {environment.name}
              </Typography>
            );
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};
