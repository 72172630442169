import {
  GET_ALL_TOOLS,
  GET_TOOL_BY_ID,
  GetToolByIdQueryDefinition,
  UPDATE_TOOL,
} from "apps/jtm/business/tool/queries";
import { GET_TOOL_CONSTANTS_BY_TAG_OR_TOOL_ID } from "apps/jtm/business/variable/queries";
import {
  removesEmptyOptionsOfCMPInfo,
  removesEmptyOptionsOfConstants,
} from "apps/jtm/components/shared/preprocess_constant_values";
import Tool from "apps/jtm/components/tool/Tool";
import { getUpdateOptions } from "libs/business/apollo/updateOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";

const ToolUpdate = (): JSX.Element => {
  const id = useParamsId("id");

  const [getTool, queryState] = useQueryWrapper<GetToolByIdQueryDefinition>(GET_TOOL_BY_ID);

  const [updateTool, mutationState] = useMutationWrapper(UPDATE_TOOL);

  const formSubmitHandler: SubmitHandler<any> = (submitData: any) => {
    submitData.constants = removesEmptyOptionsOfConstants(submitData.constants);
    submitData.cmpInformation = removesEmptyOptionsOfCMPInfo(submitData.cmpInformation);

    updateTool({
      ...getUpdateOptions("Tool", "JTM", submitData, GET_ALL_TOOLS),
      refetchQueries: [
        {
          query: GET_TOOL_BY_ID,
          variables: { toolId: id },
          context: { clientName: "JTM" },
        },
        GET_TOOL_CONSTANTS_BY_TAG_OR_TOOL_ID,
      ],
      awaitRefetchQueries: true,
    });
  };

  if (!queryState.called) {
    getTool({ variables: { toolId: id }, context: { clientName: "JTM" } });
  }

  if (queryState.loading || !queryState.data || mutationState.loading)
    return <PageLoadingSpinner />;

  const toolData = queryState.data.tool;

  return <Tool toolData={toolData} formSubmitHandler={formSubmitHandler} />;
};

export default ToolUpdate;
