import { Button, Container, Paper, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import GET_CONSENTS_BY_CONSENTID from "apps/cmp/business/consent/queries";
import { cmpPrefix } from "apps/cmp/conf/routes";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { useParams } from "react-router-dom";

const columns: GridColDef[] = [
  { field: "consentID", headerName: "Consent ID", width: 400 },
  {
    field: "date",
    headerName: "Date",
    width: 200,
  },
  {
    field: "vendorConsent",
    headerName: "Vendor Consent",
    width: 400,
  },
];

export default function Consent() {
  const { id } = useParams<string>();
  const getConsentResponse = useSimpleFetching({
    query: GET_CONSENTS_BY_CONSENTID,
    context: "CMP",
    queryArguments: { consentId: id },
  });

  if (getConsentResponse === false) {
    return <PageLoadingSpinner />;
  }

  const consentData = getConsentResponse.ConsentsByID;

  const deleteConsent = () => {};
  const exportConsent = () => {};

  return (
    <Container>
      <Typography variant="pageTitle">Consent Management</Typography>
      <Paper variant="page">
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mb: 3 }}>
          <Button variant="outlined" href={`${cmpPrefix}/consent`} sx={{ marginRight: "auto" }}>
            Back to search
          </Button>
          <Button variant="outlined" onClick={deleteConsent}>
            Delete
          </Button>
          <Button variant="contained" onClick={exportConsent}>
            PDF export
          </Button>
        </Stack>
        <Box height="600px">
          <DataGrid
            rows={consentData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Paper>
    </Container>
  );
}
