import { ApolloCache, FetchResult } from "@apollo/client";
import { faClockRotateLeft, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { Container, Stack, Typography } from "@mui/material";
import { useActiveContainer } from "apps/admin/components/container/ContainerContext";
import NewInstanceDialog from "apps/jtm/components/tool/NewInstanceDialog";
import { DeleteToolByIdDocument, GetToolsForListDocument } from "gql/autogeneratedTypes";
import CLIENT from "libs/business/apollo";
import EmptyDataError from "libs/business/apollo/EmptyDataError";
import UserService from "libs/business/authentication/UserService";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useTypedDocumentQueryWrapper from "libs/business/hooks/useTypedDocumentQueryWrapper";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import getRoutePath from "libs/business/routes/routes";
import { filterDataOnContainer } from "libs/business/utils/filterDataOnContainer";
import filterNullItems from "libs/business/utils/filterNullItemsInArray";
import getFirstValueOfObject from "libs/business/utils/objectFunctions";
import { dateFormatterWithoutHours } from "libs/business/utils/timeTransformation";
import { navigateSingleLog } from "libs/ui/components/datagrid/business";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import ConfirmDialog from "libs/ui/components/feedback/modal/ConfirmDialog";
import ContainerFilter from "libs/ui/components/input/ContainerFilter";
import SearchBar from "libs/ui/components/input/SearchBar";
import { ICardListCard } from "libs/ui/components/surfaces/card-list/Card";
import CardList from "libs/ui/components/surfaces/card-list/CardList";
import NoInstanceWarning from "libs/ui/components/warnings/NoInstanceWarning";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_ALL_TAGS } from "../../business/tag/queries";

export default function ToolList() {
  const activeContainerIds = useActiveContainer();
  const userAccessGroup = UserService.getAccessGroup();
  const navigate = useNavigate();

  const toolListResponse = useTypedDocumentQueryWrapper(GetToolsForListDocument, {
    clientName: "JTM",
  });

  const [deleteTool] = useMutationWrapper(DeleteToolByIdDocument);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const [filterValue, setFilterValue] = useState("");

  if (!toolListResponse) return <PageLoadingSpinner />;
  if (!toolListResponse.tools) throw new EmptyDataError();

  const { tools } = toolListResponse;

  const filteredNonNullTools = filterNullItems(tools);

  const filteredToolsOnActiveContainer = filterDataOnContainer(
    filteredNonNullTools,
    activeContainerIds,
  );

  const onDelete = (_tool: any) => {
    const deleteToolFunc = (tool: any) => {
      deleteTool({
        variables: { id: tool.id },
        context: { clientName: "JTM" },
        onCompleted: (data) => {
          const deletedElement = getFirstValueOfObject(data);
          notifySuccess(`Deleted tool ${deletedElement.name}`);
          setConfirmDialog({ ...confirmDialog, isOpen: false });
        },
        update(cache: ApolloCache<any>, response: FetchResult) {
          if (!response.data) {
            return;
          }
          const deletedID = response.data.deleteTool.id;
          const normalizedId = cache.identify({
            id: deletedID,
            __typename: "Tool",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
          const tagsQueryCache = CLIENT.readQuery({
            query: GET_ALL_TAGS,
          });
          if (tagsQueryCache) {
            const toolID = response.data.deleteTool.id;
            const newTagCache = tagsQueryCache.tags.filter((tag: any) => tag.tool.id !== toolID);
            CLIENT.writeQuery({
              query: GET_ALL_TAGS,
              data: {
                tags: newTagCache,
              },
            });
          }
        },
        onError: (error) => {
          notifyError(error.message);
        },
      });
    };
    if (_tool.name) {
      setConfirmDialog((old) => ({
        ...old,
        title: `Are you sure you want to delete ${_tool.name}?`,
      }));
    } else if (_tool.username) {
      setConfirmDialog((old) => ({
        ...old,
        title: `Are you sure you want to delete ${_tool.username}?`,
      }));
    } else {
      setConfirmDialog((old) => ({
        ...old,
        title: `Are you sure you want to delete ${_tool.id}?`,
      }));
    }
    setConfirmDialog((old) => ({
      ...old,
      isOpen: true,
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        deleteToolFunc(_tool);
      },
    }));
  };

  const getMenuItemConfigs = (tool: typeof tools[0]) => {
    if (!tool) return [];

    const menuItems = [
      {
        name: "Edit",
        icon: faPen,
        onClick: () => navigate(getRoutePath("jtm", "tool_id", { id: tool.id })),
      },
      {
        name: "Log",
        icon: faClockRotateLeft,
        onClick: () =>
          navigateSingleLog({ navigate, logType: "Tool", id: tool.id, name: tool.name || "" }),
      },
    ];

    if ([1, 2, 3, 4, 5, 6].includes(userAccessGroup)) {
      menuItems.push({
        name: "Delete",
        icon: faTrashCan,
        onClick: () => onDelete(tool),
      });
    }

    return menuItems;
  };

  const cardListConfig: ICardListCard[] = filteredToolsOnActiveContainer
    .filter((tool: any) => meetsFilterCriteria(tool, filterValue))
    .map((tool: any) => {
      return {
        title: tool.name,
        image: `${tool.tool_definition.image_path}medium`,
        link: getRoutePath("jtm", "tool_id", { id: tool.id }),
        menuItemConfigs: getMenuItemConfigs(tool),
        dataFields: [
          {
            name: "Created On",
            value: dateFormatterWithoutHours(tool.create_at),
          },
          {
            name: "Updated On",
            value: dateFormatterWithoutHours(tool.edit_at),
          },
        ],
      };
    });

  return (
    <>
      <Stack direction="row" justifyContent="space-between" style={{ marginBottom: 16 }}>
        <Typography variant="pageTitle" data-cy="pageTitle">
          Tools
        </Typography>
        <NewInstanceDialog
          instanceName="Tool"
          newInstanceLink={getRoutePath("jtm", "tool_selection")}
          newAssistantInstanceLink={getRoutePath("jtm", "tool_assistant")}
        />
      </Stack>
      <Container>
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          <ContainerFilter />
          <SearchBar debouncedOnChange={setFilterValue} />
        </Stack>
        <CardList list={cardListConfig} />
        {cardListConfig.length === 0 && <NoInstanceWarning name="Tool" />}
        <ConfirmDialog
          title={confirmDialog.title}
          isOpen={confirmDialog.isOpen}
          message={confirmDialog.subTitle}
          onConfirm={confirmDialog.onConfirm}
          onCancel={() => {
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          }}
        />
      </Container>
    </>
  );
}

const meetsFilterCriteria = (
  tool: { name: string; tool_definition: { name: string } },
  value: string,
) => {
  const lowerCaseValue = value.toLowerCase();
  const lowerCaseTitle = tool.name.toLowerCase();
  const lowerCaseShortPluginId = tool.tool_definition.name.toLowerCase();

  return (
    lowerCaseTitle.includes(lowerCaseValue) || lowerCaseShortPluginId?.includes(lowerCaseValue)
  );
};
