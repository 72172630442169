import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Paper, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { useAssistantWizard } from "./AssistantWizardContext";
import { AssistantStepFormConfig } from "./types";

interface AssistantWizardNavigationBarProps {
  handlePrev: () => void;
  onPause: () => void;
  stepConfig: AssistantStepFormConfig[];
  finalSubmitButtonText: string;
}
/**
 * The AssistantWizardNavigationBar component
 * @param {AssistantWizardNavigationBarProps} props
 * @returns a AssistantWizardNavigationBar component
 */
const AssistantWizardNavigationBar = ({
  handlePrev,
  onPause,
  stepConfig,
  finalSubmitButtonText,
}: AssistantWizardNavigationBarProps): JSX.Element => {
  const wizardData = useAssistantWizard();
  const isLastStep = wizardData.activeStep === stepConfig.length;

  return (
    <Paper variant="page" sx={{ p: 2, mt: 4 }}>
      <Stack direction="row" justifyContent="space-between">
        <div>
          {wizardData.activeStep !== 1 && !stepConfig[wizardData.activeStep - 1].blockGoPrevious && (
            <Button onClick={handlePrev}>
              <FontAwesomeIcon size="xs" icon={faChevronLeft} />
              <span style={{ marginLeft: "4px" }}>Previous</span>
            </Button>
          )}
        </div>
        <Stack direction="row" spacing={1}>
          <Tooltip title="You can pause the assistant and continue editing later.">
            <Button variant="outlined" onClick={onPause}>
              Pause
            </Button>
          </Tooltip>
          <Button variant="contained" type="submit">
            {isLastStep ? finalSubmitButtonText : "Next"}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default AssistantWizardNavigationBar;
