import {
  GetContainerQueryDefinition,
  GET_ALL_CONTAINERS,
  GET_CONTAINER,
  UpdateContainerQueryDefinition,
  UPDATE_CONTAINER,
} from "apps/admin/business/container/queries";
import { GET_ALL_VERSIONS } from "apps/jtm/business/version/queries";
import { AnyCnameRecord } from "dns";
import { getUpdateOptions } from "libs/business/apollo/updateOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import ContainerForm from "./ContainerForm";

/**
 * The ContainerUpdate component
 * @returns a ContainerUpdate component
 */
const ContainerUpdate = (): JSX.Element => {
  const id = useParamsId("id");
  const containerData = useSimpleFetching<GetContainerQueryDefinition>({
    query: GET_CONTAINER,
    context: "ADMIN",
    queryArguments: { id },
  });

  const [updaterContainer] = useMutationWrapper<UpdateContainerQueryDefinition>(UPDATE_CONTAINER);

  const formSubmitHandler: SubmitHandler<AnyCnameRecord> = (data) => {
    const dataWithId = {
      id,
      ...data,
    };
    updaterContainer(
      getUpdateOptions("Container", "ADMIN", dataWithId, GET_ALL_CONTAINERS, {
        REFETCH_QUERY: GET_ALL_VERSIONS,
        context: "JTM",
        variables: {},
      }),
    );
  };

  if (containerData === false) {
    return <PageLoadingSpinner />;
  }

  return (
    <ContainerForm
      containerData={containerData.getContainer}
      formSubmitHandler={formSubmitHandler}
    />
  );
};

export default ContainerUpdate;
