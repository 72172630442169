import { RowData } from "../SessionRow/type";

const getAllUniqueConnectorShortIdsFromData = (rowData: RowData[]) => {
  const allConnectors: string[] = [];

  rowData.forEach((row) => {
    if (row.type === "tag" && !allConnectors.includes(row.tool_id)) {
      allConnectors.push(row.tool_id);
    }
  });

  return allConnectors;
};

export default getAllUniqueConnectorShortIdsFromData;
