import LoginForm from "./LoginForm";
import LoginScreen from "./LoginScreen";

export default function Login() {
  return (
    <LoginScreen>
      <LoginForm />
    </LoginScreen>
  );
}
