import { AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import {
  GetManifestIdNameMappingTableQueryDefinition,
  GET_MANIFEST_ID_NAME_MAPPING_TABLE,
} from "apps/jtm/business/log/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import mergeArrays from "libs/business/utils/arrayFunctions";
import LoadComponentSpinner from "libs/ui/components/feedback/loading-spinner/LoadComponentSpinner";
import ColoredAccordion from "../../../surfaces/accordion/ColoredAccordion";
import CompareGuiList, { GuiListChange } from "./CompareGuiList";
import CompareSimplePlaceholder from "./CompareSimplePlaceholder";

export interface PlaceholderChanges {
  [key: string]: null | string | GuiListChange | ArrayListChange;
}

export interface ArrayListChange {
  [arrayListPlaceholder: string]: string | GuiListChange | null;
}

export interface ArrayListVariables {
  [arrayListShortId: string]: {
    filterValue?: string | null;
    filterOperator?: string | null;
    filterVariable?: string | null;
  };
}

interface ComparePlaceholdersProps {
  leftPlaceholderChanges: PlaceholderChanges;
  rightPlaceholderChanges: PlaceholderChanges;
  leftArrayListVariables?: ArrayListVariables;
  rightArrayListVariables?: ArrayListVariables;
  tagId: number;
}
/**
 * The ComparePlaceholders component
 * @param {ComparePlaceholdersProps} props
 * @returns a ComparePlaceholders component
 */
const ComparePlaceholders = ({
  leftPlaceholderChanges,
  rightPlaceholderChanges,
  leftArrayListVariables,
  rightArrayListVariables,
  tagId,
}: ComparePlaceholdersProps): JSX.Element => {
  const allKeys = mergeArrays(
    Object.keys(leftPlaceholderChanges),
    Object.keys(rightPlaceholderChanges),
  );

  const mappingTable = useSimpleFetching<GetManifestIdNameMappingTableQueryDefinition>({
    query: GET_MANIFEST_ID_NAME_MAPPING_TABLE,
    context: "JTM",
    queryArguments: { tagId },
  });

  if (mappingTable === false) return <LoadComponentSpinner />;

  return (
    <>
      {allKeys.map((placeholderKey) => {
        const type = getPlaceholderType(
          leftPlaceholderChanges[placeholderKey],
          rightPlaceholderChanges[placeholderKey],
        );
        const leftPlaceholder = leftPlaceholderChanges[placeholderKey];
        const rightPlaceholder = rightPlaceholderChanges[placeholderKey];

        const label = mappingTable.manifestIdNameMappings[placeholderKey] || placeholderKey;

        if (type === "SIMPLE") {
          return (
            <CompareSimplePlaceholder
              key={placeholderKey}
              label={label}
              inputName={placeholderKey}
              leftValue={(leftPlaceholder as string) || ""}
              rightValue={(rightPlaceholder as string) || ""}
            />
          );
        }
        if (type === "GUILIST") {
          const leftGuiList = leftPlaceholder as GuiListChange;
          const rightGuiList = rightPlaceholder as GuiListChange;
          return (
            <CompareGuiList
              key={placeholderKey}
              leftGuiList={leftGuiList}
              rightGuiList={rightGuiList}
              guiListName={label}
            />
          );
        }
        const leftArrayList = leftPlaceholder as ArrayListChange;
        const rightArrayList = rightPlaceholder as ArrayListChange;
        return (
          <CompareArrayList
            key={placeholderKey}
            placeholderKey={placeholderKey}
            leftArrayList={leftArrayList}
            rightArrayList={rightArrayList}
            leftArrayListVariables={leftArrayListVariables}
            rightArrayListVariables={rightArrayListVariables}
            tagID={tagId}
          />
        );
      })}
    </>
  );
};

export default ComparePlaceholders;

const getPlaceholderType = (
  leftValue: string | GuiListChange | ArrayListChange | null,
  rightValue: string | GuiListChange | ArrayListChange | null,
): "SIMPLE" | "GUILIST" | "ARRAYLIST" => {
  let value;
  if (leftValue !== null) value = leftValue;
  else if (rightValue !== null) value = rightValue;
  else value = ""; // Empty string is not possible but necessary for typescript

  if (typeof value === "string") return "SIMPLE";

  const firstKey = Object.keys(value)[0];

  if (Number.isNaN(+firstKey)) return "ARRAYLIST";

  return "GUILIST";
};

interface CompareArrayListProps {
  placeholderKey: string;
  leftArrayListVariables: ArrayListVariables | undefined;
  rightArrayListVariables: ArrayListVariables | undefined;
  leftArrayList: ArrayListChange;
  rightArrayList: ArrayListChange;
  tagID: number;
}

// Cannot be in a separate file, because of circular dependencies(recursive component)
const CompareArrayList = ({
  placeholderKey,
  leftArrayListVariables,
  rightArrayListVariables,
  leftArrayList,
  rightArrayList,
  tagID,
}: CompareArrayListProps): JSX.Element => {
  return (
    <Box sx={{ mt: 2 }}>
      <ColoredAccordion index={6}>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls={`arrayList-${placeholderKey}`}
          id={`arrayList-${placeholderKey}`}>
          <Typography>{placeholderKey}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {leftArrayListVariables?.[placeholderKey]?.filterVariable !== undefined && (
            <CompareSimplePlaceholder
              inputName={`${placeholderKey}.filterVariable`}
              label="Filter Variable"
              leftValue={leftArrayListVariables?.[placeholderKey]?.filterVariable || ""}
              rightValue={rightArrayListVariables?.[placeholderKey]?.filterVariable || ""}
            />
          )}
          {leftArrayListVariables?.[placeholderKey]?.filterOperator !== undefined && (
            <CompareSimplePlaceholder
              inputName={`${placeholderKey}.filterOperator`}
              label="Filter Operator"
              leftValue={leftArrayListVariables?.[placeholderKey]?.filterOperator || ""}
              rightValue={rightArrayListVariables?.[placeholderKey]?.filterOperator || ""}
            />
          )}
          {leftArrayListVariables?.[placeholderKey]?.filterValue !== undefined && (
            <CompareSimplePlaceholder
              inputName={`${placeholderKey}.filterValue`}
              label="Filter Value"
              leftValue={leftArrayListVariables?.[placeholderKey]?.filterValue || ""}
              rightValue={rightArrayListVariables?.[placeholderKey]?.filterValue || ""}
            />
          )}
          <ComparePlaceholders
            leftPlaceholderChanges={leftArrayList}
            rightPlaceholderChanges={rightArrayList}
            tagId={tagID}
          />
        </AccordionDetails>
      </ColoredAccordion>
    </Box>
  );
};
