import { AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import colorStyles from "assets/styles/colorPalette.module.scss";
import mergeArrays from "libs/business/utils/arrayFunctions";
import ColoredAccordion from "../../../surfaces/accordion/ColoredAccordion";
import CompareSimplePlaceholder from "./CompareSimplePlaceholder";

export interface GuiListChange {
  [guiListEntryNumber: string]: {
    [guiListProperty: string]: string | null;
  };
}

interface CompareGuiListProps {
  guiListName: string;
  leftGuiList: GuiListChange;
  rightGuiList: GuiListChange;
}
/**
 * The CompareGuiList component
 * @param {CompareGuiListProps} props
 * @returns a CompareGuiList component
 */
const CompareGuiList = ({
  guiListName,
  leftGuiList,
  rightGuiList,
}: CompareGuiListProps): JSX.Element => {
  const allGuiListEntryNumbers = mergeArrays(Object.keys(leftGuiList), Object.keys(rightGuiList));

  return (
    <Box sx={{ mt: 2 }}>
      <ColoredAccordion index={0}>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls={`guiList-${guiListName}`}
          id={`guiList-${guiListName}`}>
          <Typography>{guiListName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            {allGuiListEntryNumbers.map((number) => {
              const leftGuiListEntry = leftGuiList[number];
              const rightGuiListEntry = rightGuiList[number];
              const allGuiListProperties = mergeArrays(
                Object.keys(leftGuiListEntry || {}),
                Object.keys(rightGuiListEntry || {}),
              );
              return (
                <Box
                  sx={{ p: 1, backgroundColor: colorStyles.grey3, borderRadius: "4px" }}
                  key={guiListName + number}>
                  {allGuiListProperties.map((property) => {
                    return (
                      <CompareSimplePlaceholder
                        key={guiListName + number + property}
                        inputName={`${guiListName}.${number}.${property}`}
                        label={property}
                        leftValue={leftGuiListEntry?.[property] || ""}
                        rightValue={rightGuiListEntry?.[property] || ""}
                      />
                    );
                  })}
                </Box>
              );
            })}
          </Stack>
        </AccordionDetails>
      </ColoredAccordion>
    </Box>
  );
};

export default CompareGuiList;
