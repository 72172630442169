import {
  faBadgeCheck,
  faDiamondExclamation,
  faShieldCheck,
} from "@fortawesome/pro-solid-svg-icons";
import Page, { PageNotificationType } from "libs/ui/components/surfaces/page/Page";
import { ReactNode } from "react";

interface MaintainedPageProps {
  title: string;
  children?: ReactNode;
  showMaintainedInfo?: boolean;
  maintainedTypePlaceholder?: "function" | "state" | "trigger" | "variable";
  maintainedBy?: "jentis" | "user" | "custom";
  notFirst?: boolean;
}
/**
 * The MaintainedPage component
 * @param {MaintainedPageProps} props
 * @returns a MaintainedPage component
 */
const MaintainedPage = ({
  title,
  children,
  notFirst = false,
  showMaintainedInfo = false,
  maintainedTypePlaceholder = "function",
  maintainedBy = "jentis",
}: MaintainedPageProps): JSX.Element => {
  let icon;
  let notificationText;
  let notificationType: PageNotificationType = "INFO";

  if (maintainedBy === "jentis") {
    notificationText = `This ${maintainedTypePlaceholder} is maintained by JENTIS.`;
    notificationType = "SUCCESS";
    icon = faShieldCheck;
  }

  if (maintainedBy === "user") {
    notificationText = `This ${maintainedTypePlaceholder} is maintained by you.`;
    notificationType = "WARNING";
    icon = faDiamondExclamation;
  }

  if (maintainedBy === "custom") {
    notificationText = `This is a custom ${maintainedTypePlaceholder} created by you.`;
    notificationType = "INFO";
    icon = faBadgeCheck;
  }

  if (!showMaintainedInfo) {
    notificationText = undefined;
  }

  return (
    <Page
      title={title}
      notFirst={notFirst}
      notificationText={notificationText}
      notificationType={notificationType}
      notificationIcon={icon}>
      {children}
    </Page>
  );
};

export default MaintainedPage;
