import { faSection } from "@fortawesome/pro-solid-svg-icons";
import HomeIcon from "libs/ui/components/home-icon/HomeIcon";

interface LegalManagementDefaultPageProps {
  testProp?: any;
}
/**
 * The LegalManagementDefaultPage component
 * @param {LegalManagementDefaultPageProps} props
 * @returns a LegalManagementDefaultPage component
 */
const LegalManagementDefaultPage = ({ testProp }: LegalManagementDefaultPageProps): JSX.Element => {
  return <HomeIcon icon={faSection} title="Legal Management" />;
};

export default LegalManagementDefaultPage;
