import { FileUpload } from "@mui/icons-material";
import { Box, Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import UPDATE_SETUP_STEP from "apps/setup/business/container/mutations";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import { FC, useState } from "react";
import { getUpdateStepOptions } from "./getUpdateStepOptions";

interface IAdminStepUpdaterProps {
  containerId?: number;
  stepNumber: string;
  defaultValue: boolean;
  message: string;
  containerName: string;
  className?: string;
}

const AdminStepUpdater: FC<IAdminStepUpdaterProps> = ({
  containerId,
  stepNumber,
  defaultValue,
  message,
  containerName,
  className,
}: IAdminStepUpdaterProps) => {
  const [updateSetupStep] = useMutationWrapper(UPDATE_SETUP_STEP);
  const [stepValue, setStepValue] = useState(defaultValue);
  const labelMessage = `Change the status of ${containerName}`;

  const handleButtonPush = () => {
    updateSetupStep(getUpdateStepOptions({ setupStepNumber: stepNumber, done: stepValue }));
  };

  return (
    <Box
      className={className}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        width: "100%",
        mb: "0.5rem",
      }}>
      <AlertBox
        type="info"
        message={message}
        title="Info"
        boxStyles={{ display: "flex", flex: "5" }}
      />

      <FormGroup
        sx={{
          display: "flex",
          flex: "4",
          flexDirection: "column",
          gap: "0.2rem",
          alignItems: "center",
          justifyContent: "center",
          width: "75%",
        }}>
        <FormControlLabel
          label={labelMessage}
          labelPlacement="start"
          control={<Switch checked={stepValue} onChange={() => setStepValue(!stepValue)} />}
        />
        <Button
          variant="outlined"
          onClick={handleButtonPush}
          sx={{ height: "45px", width: "75%" }}
          endIcon={<FileUpload />}>
          {containerId ? `Push changes to container ${containerId}` : "Push changes"}
        </Button>
      </FormGroup>
    </Box>
  );
};
export default AdminStepUpdater;
