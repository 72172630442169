import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import {
  GET_ID_NAME_MAPPING_TABLE,
  GetIdNameMappingTableQueryDefinition,
} from "apps/jtm/business/log/queries";
import colorStyles from "assets/styles/colorPalette.module.scss";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { useCallback } from "react";
import FieldSelection from "./FieldSelection";
import { IFunctionItem, ITextItem, IVariableItem } from "./FieldSelectionTypes";
import { transformToFieldItemArray, transformToString } from "./fieldSelctionTransformers";

const width100 = {
  width: "100%",
};
interface IFieldSelectionInputWrapperProps {
  inputFieldName: string;
  label: string;
  desc: string;
  value: string;
  optional?: boolean;
  hidden?: boolean;
  hideDesc?: boolean;
  readOnly?: boolean;
}
/**
 * The FieldSelectionInputWrapper component
 * @param {IFieldSelectionInputWrapperProps} props
 * @returns a FieldSelectionInputWrapper component
 */
const FieldSelectionInputWrapper = ({
  inputFieldName,
  label,
  desc,
  value = "",
  optional = false,
  hidden = false,
  hideDesc = false,
  readOnly = false,
}: IFieldSelectionInputWrapperProps): JSX.Element => {
  const [valueState, setValueState] = usePropChangingState<string>(value);

  const getIdNameMapping = useSimpleFetching<GetIdNameMappingTableQueryDefinition>({
    query: GET_ID_NAME_MAPPING_TABLE,
    context: "JTM",
  });

  const onChangeHandler = useCallback((data: (IFunctionItem | IVariableItem | ITextItem)[]) => {
    setValueState(transformToString(data));
  }, []);

  const mappingTable = getIdNameMapping === false ? {} : getIdNameMapping.idNameMappings;
  const initialValue = transformToFieldItemArray(valueState, mappingTable || {});

  const backgroundColor = optional ? colorStyles.grey3 : "secondary.main";
  const paddingTop = optional ? "8px" : "0px";

  return (
    <Box
      style={width100}
      hidden={hidden}
      sx={{ backgroundColor, borderRadius: "4px", p: 1, paddingTop, width: "100%" }}>
      <TextFieldWrapper name={inputFieldName} label={label} hidden value={valueState} />
      {!optional && (
        <Typography variant="caption" color="secondary.dark">
          Mandatory
        </Typography>
      )}
      <Typography variant="h5" sx={{ mb: 1 }}>
        {label}{" "}
        <Tooltip title={desc}>
          <FontAwesomeIcon icon={faQuestionCircle} size="sm" color="grey" />
        </Tooltip>
      </Typography>
      <Stack direction="row" spacing={2} style={width100} alignItems="center">
        <div style={width100}>
          <FieldSelection
            initialItems={initialValue}
            onChange={onChangeHandler}
            readOnly={readOnly}
          />
        </div>
        <div style={width100} hidden={hideDesc}>
          <Typography variant="body2">{desc}</Typography>
        </div>
      </Stack>
    </Box>
  );
};

export default FieldSelectionInputWrapper;
