import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Container, Paper, Stack, Typography } from "@mui/material";

import { faDiamondExclamation } from "@fortawesome/pro-regular-svg-icons";
import NewInstanceDialog, {
  NewInstanceDialogProps,
} from "apps/jtm/components/tool/NewInstanceDialog";
import colorStyles from "assets/styles/colorPalette.module.scss";
import { ReactNode } from "react";
import PageNotification from "./PageNotification";

export type PageNotificationType = "WARNING" | "ERROR" | "SUCCESS" | "INFO";
interface PageProps {
  title: string;
  children?: ReactNode;
  notificationText?: string;
  notificationType?: PageNotificationType;
  notificationIcon?: IconDefinition;
  notFirst?: boolean;
  newButtonConfig?: NewInstanceDialogProps;
}
/**
 * The Page component
 * @param {PageProps} props
 * @param {PageProps} props.notificationText if this value exists, the notification will be shown
 * @returns a Page component
 */
const Page = ({
  title,
  children,
  notificationText,
  notificationType,
  notificationIcon,
  notFirst = false,
  newButtonConfig,
}: PageProps): JSX.Element => {
  const showNotification = typeof notificationText === "string";
  const icon = notificationIcon || faDiamondExclamation;

  let color = colorStyles.primaryMain;
  let backgroundColor = colorStyles.secondaryMain;

  if (notificationType === "WARNING") {
    color = colorStyles.warningMain;
    backgroundColor = colorStyles.warningLight;
  }
  if (notificationType === "ERROR") {
    color = colorStyles.errorMain;
    backgroundColor = colorStyles.errorLight;
  }
  if (notificationType === "SUCCESS") {
    color = colorStyles.successMain;
    backgroundColor = colorStyles.successLight;
  }

  return (
    <Container>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: notFirst ? 3 : 0, mx: "4px" }}>
        <Typography variant="pageTitle" data-cy="pageTitle">
          {title}
        </Typography>
        {!!newButtonConfig && (
          <NewInstanceDialog
            instanceName={newButtonConfig.instanceName}
            newInstanceLink={newButtonConfig.newInstanceLink}
            newAssistantInstanceLink={newButtonConfig.newAssistantInstanceLink}
            accessGroups={newButtonConfig.accessGroups}
          />
        )}
        {showNotification && (
          <PageNotification
            notificationText={notificationText}
            textColor={color}
            backgroundColor={backgroundColor}
            icon={icon}
          />
        )}
      </Stack>
      <Paper variant="page">{children}</Paper>
    </Container>
  );
};

export default Page;
