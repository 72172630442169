import { Card, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";

import colorStyles from "assets/styles/colorPalette.module.scss";

const cardHeaderHeight = 54;

interface VersionCardProps {
  versionNumber: string;
  children?: JSX.Element;
  cardTitle: string;
}
/**
 * The VersionCard component
 * @param {VersionCardProps} props
 * @returns a VersionCard component
 */
const VersionCard = ({ versionNumber, children, cardTitle }: VersionCardProps): JSX.Element => {
  return (
    <Card
      sx={{
        width: "100%",
      }}>
      <CardHeader title={cardTitle} sx={{ background: colorStyles.grey3 }} />
      <Grid container sx={{ height: `calc(100% - ${cardHeaderHeight}px)` }}>
        <Grid item xs={3}>
          <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
            <Typography align="center" variant="h3">
              {versionNumber}
            </Typography>
            <Typography variant="subtitle2">Version</Typography>
            <Typography variant="subtitle2">Number</Typography>
          </Stack>
        </Grid>
        <Divider
          orientation="vertical"
          sx={{ marginRight: "-1px", borderColor: "secondary.main" }}
        />
        <Grid item xs={9}>
          {children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default VersionCard;
