import { faDisplay } from "@fortawesome/pro-solid-svg-icons";
import { jtmPrefix } from "apps/jtm/conf/routes";
import CREATE_DEBUG_SESSION from "apps/monitor/business/mutations";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import { notifyError } from "libs/business/notification/notification";
import HomeIcon from "libs/ui/components/home-icon/HomeIcon";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function MonitorDefaultPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jtsValue: string | null = searchParams.get("jts_version");
  const containerUrl: string | null = searchParams.get("container_url");
  const containerId: string | null = searchParams.get("container_id");
  const [createDebugSession, createDebugSessionQueryState] =
    useMutationWrapper(CREATE_DEBUG_SESSION);

  if (jtsValue === null) {
    navigate(`${jtmPrefix}/versions`);
    notifyError("No JTS version specified");
  }

  if (containerUrl === null) {
    notifyError("No container url defined");
    navigate(`${jtmPrefix}/versions`);
    return null;
  }

  const jtsVersion: number = parseInt(jtsValue as string, 10);

  if (!createDebugSessionQueryState.called) {
    createDebugSession({
      variables: { jtsVersion, accountUrl: containerUrl },
      context: { clientName: "MONITOR" },
      onError: (error) => {
        notifyError(error.message);
        // navigate(`${jtmPrefix}/versions`);
      },
      onCompleted: (data) => {
        navigate(`/app/monitor/session/${containerId}/${data.createDebugSession}`);
      },
    });
  }
  if (createDebugSessionQueryState.loading || !createDebugSessionQueryState.data) {
    return <PageLoadingSpinner />;
  }

  return <HomeIcon icon={faDisplay} title="Tracking Monitor" />;
}
