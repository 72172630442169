import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useEnvironment } from "apps/jtm/components/environment/EnvironmentContext";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import CopyClipboardButton from "libs/ui/components/buttons/CopyClipboardButton";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { IdAndName } from "libs/model/types";
import { FC, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { IContainer } from "apps/admin/components/container/ContainerContext";

export interface ITrackingCodeManagerProps {
  container: IContainer;
}

const TrackingCodeManager: FC<ITrackingCodeManagerProps> = ({
  container,
}: ITrackingCodeManagerProps) => {
  const enviroments = useEnvironment();
  const [enviromentState, setEnviromentState] = useState(enviroments[0]);
  const codeSnippet =
    "(function (sCDN,sCDNProject,sCDNWorkspace,sCDNVers) {" +
    "\n \t if ( window.localStorage !== null && \n" +
    '\t typeof window.localStorage === "object" && \n' +
    '\t typeof window.localStorage.getItem === "function" && \n' +
    "\t window.sessionStorage !== null && \n" +
    '\t typeof window.sessionStorage === "object" && \n' +
    '\t typeof window.sessionStorage.getItem === "function" ) {\n' +
    "\t\t sCDNVers = window.sessionStorage.getItem('jts_preview_version') || \n" +
    "\t \t \t window.localStorage.getItem('jts_preview_version') || \n \t \t \t sCDNVers; \n \t } \n " +
    "\t window.jentis = window.jentis || {};\n " +
    "\t window.jentis.config = window.jentis.config || {}; \n" +
    "\t window.jentis.config.frontend = window.jentis.config.frontend || {}; \n" +
    '\t window.jentis.config.frontend.cdnhost = sCDN+"/get/"+sCDNWorkspace+"/web/"+sCDNVers+"/"; \n' +
    "\t window.jentis.config.frontend.vers = sCDNVers;\n " +
    "\t window.jentis.config.frontend.env = sCDNWorkspace;\n" +
    "\t window.jentis.config.frontend.project = sCDNProject; \n" +
    "\t window._jts = window._jts || []; \n " +
    '\t var f   = document.getElementsByTagName("script")[0]; \n' +
    '\t var j = document.createElement("script"); \n' +
    "\t j.async = true; \n" +
    `\t j.src   = window.jentis.config.frontend.cdnhost+"${container.file_hash}.js";\n` +
    "\t f.parentNode.insertBefore(j, f) \n" +
    ` })("https://${container.hash}.${container.live_url}","${container.short}","${enviromentState.name}","_");`;

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    const enviroment: IdAndName | undefined = enviroments.find(
      (x: any) => x.name === (event.target.value as string),
    );
    if (enviroment) {
      setEnviromentState(enviroment);
    }
  };

  if (!enviroments) {
    return <PageLoadingSpinner />;
  }

  return (
    <Box>
      <Box
        sx={{
          m: "10px 0px",
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          alignItems: "center",
        }}>
        <AlertBox
          type="info"
          message={
            "We give you the possibility to integrate Jentis in different enviroments. " +
            " For example, if you have a test system you can choose a different enviroment. " +
            "For this you need to include diffeent tracking code. Please choose your enviroment!"
          }
          boxStyles={{ display: "flex", flex: 8 }}
        />

        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="demo-simple-select-helper-label">Enviroment</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            defaultValue={enviromentState.name}
            value={enviromentState.name}
            label="Enviroment"
            onChange={handleChange}>
            {enviroments.map((x) => {
              return (
                <MenuItem key={x.id} value={x.name}>
                  {x.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <Divider />

      <Box sx={{ mt: "5px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Typography sx={{ alignSelf: "flex-end" }}>
            JavaScript code to paste in your website:
          </Typography>
          <CopyClipboardButton textToCopy={codeSnippet} buttonText="Copy" />
        </Box>

        <SyntaxHighlighter
          customStyle={{ borderRadius: "10px" }}
          wrapLongLines
          language="javascript"
          style={dark}>
          {codeSnippet}
        </SyntaxHighlighter>
      </Box>
    </Box>
  );
};

export default TrackingCodeManager;
