import { MaintainedProvider } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import {
  CreateVariableDocument,
  GetAllVariablesDocument,
  GetVariableShortIdsDocument,
} from "gql/autogeneratedTypes";
import { getCreateOptionsWithShortIDValidation } from "libs/business/apollo/createOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { filterEmptyOptions } from "../../components/shared/preprocess_constant_values";
import { VariableForm } from "../../components/variable/VariableForm";

interface IVariableNewProps {
  type?: string;
}

/**
 * The variable component
 * @returns a variable component
 */
export const VariableNew: React.FC<IVariableNewProps> = ({ type }: IVariableNewProps) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const source = params.get("type") ?? type;
  const template = params.get("template") ?? undefined;

  const [getVariableShortIds, queryState] = useQueryWrapper(GetVariableShortIdsDocument);
  const [createVariable, mutationState] = useMutationWrapper(CreateVariableDocument);

  if (!queryState.called) {
    getVariableShortIds({ context: { clientName: "JTM" } });
  }

  const newVariableSkeleton = {
    id: 0,
    short_id: "",
    name: "",
    scope: "EVENT",
    source,
    group: "",
    personal_data: 0,
    value: "",
    maintained: 0,
    placeholder: template,
    pluginID: template,
  };

  const formSubmitHandler = (submitData: any) => {
    let newData = submitData;
    if (typeof submitData.value === "object") {
      newData = {
        ...submitData,
        value: JSON.stringify({
          value: submitData.value.value,
          options: filterEmptyOptions(submitData.value.options),
        }),
      };
    }

    createVariable(
      getCreateOptionsWithShortIDValidation(
        "Variable",
        "JTM",
        newData,
        GetAllVariablesDocument,
        GetVariableShortIdsDocument,
        navigate,
      ),
    );
  };

  if (!queryState.data || mutationState.loading) return <PageLoadingSpinner />;

  return (
    <MaintainedProvider
      initialValue={false}
      elementName="variable"
      formSubmitHandler={formSubmitHandler}
      shortIds={queryState.data.variableShortIds.shortIds}>
      <VariableForm data={newVariableSkeleton} mode="create" />
    </MaintainedProvider>
  );
};

export default VariableNew;
