import { gql } from "@apollo/client";
import { PlaceholderDefinition } from "apps/jtm/components/tag/tagTypes";
import { GQLTypeDefinition, SingleResponse } from "libs/model/types";
import { BackendNexusTriggerType } from "../trigger/queries";

export interface BackendNexusTagType {
  id: number;
  create_by: number;
  create_at: string;
  edit_by: number;
  edit_at: string;
  name: string;
  templateid: string;
  sender: string;
  placeholder: { [key: string]: any }; // TODO
  no_consent_placeholder: { [key: string]: any }; // TODO
  arraylist_variables: { [key: string]: any }; // TODO
  type: string;
  tool_id: number;
  template_name: string;
  containerIDs: { [key: string]: any }; // TODO: type
  placeholder_definition: PlaceholderDefinition;
  trigger: BackendNexusTriggerType;
  tool: {
    // TODO: create and get Type from BackendNexusToolType
    id: number;
    name: string;
    tool_definition?: {
      image_path: string;
    };
  };
}

export const GET_TAG_DEFINITION = gql`
  query tagDefinition($toolId: Int!, $tagType: String!) {
    tagDefinition(tool_id: $toolId, tag_type: $tagType)
  }
`;

export interface GetTagByIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusTagType, "placeholder_definition" | "name" | "template_name"> & {
    trigger: Pick<BackendNexusTriggerType, "id">;
  };
  response: SingleResponse<"tag", GetTagByIdQueryDefinition["dataType"]>;
  arguments: { id: number };
}

export const GET_ALL_TAGS = gql`
  query tags {
    tags {
      id
      name
      create_at
      edit_at
      templateid
      template_name
      tool_id
      containerIDs
      trigger {
        id
        name
      }
      tool {
        id
        name
        tool_definition {
          image_path
        }
      }
    }
  }
`;

export const CREATE_TAG = gql`
  mutation createTag(
    $pluginID: String!
    $name: String!
    $toolId: Int!
    $template: String!
    $triggerId: Int!
    $placeholder: JSON!
    $arraylistVariables: JSON
    $hitType: String
  ) {
    createTag(
      pluginid: $pluginID
      name: $name
      toolid: $toolId
      templateid: $template
      trigger_id: $triggerId
      placeholder: $placeholder
      arraylist_variables: $arraylistVariables
      hitType: $hitType
    ) {
      id
      name
      create_at
      edit_at
      templateid
      tool_id
      containerIDs
      trigger {
        id
        name
      }
      tool {
        id
        name
      }
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag(
    $id: Int!
    $name: String!
    $triggerId: Int!
    $placeholder: JSON!
    $arraylistVariables: JSON
    $hitType: String
  ) {
    updateTag(
      id: $id
      name: $name
      trigger_id: $triggerId
      placeholder: $placeholder
      arraylist_variables: $arraylistVariables
      hitType: $hitType
    ) {
      id
      name
      create_at
      edit_at
      templateid
      tool_id
      containerIDs
      trigger {
        id
        name
      }
      tool {
        id
        name
      }
    }
  }
`;

export const DELETE_TAG = gql`
  mutation deleteTag($id: Int!) {
    deleteTag(id: $id) {
      id
      name
    }
  }
`;

export default GET_ALL_TAGS;
