import useAsync from "./useAsync";

export default function useFetch(
  url: string,
  headers: HeadersInit | undefined = {},
  dependencies: any[] = [],
) {
  return useAsync(() => {
    return fetch(url, { headers }).then((res) => {
      if (res.ok) return res.json();
      return res.json().then((json) => Promise.reject(json));
    });
  }, dependencies as any);
}
