import { ApolloError } from "@apollo/client";
import {
  UpdateAssistantQueryDefinition,
  UPDATE_ASSISTANT,
} from "apps/jtm/business/assistant/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import { notifyError } from "libs/business/notification/notification";
import { WizardContextType } from "../AssistantWizardContext";

const useUpdateAssistantExternally = (assistantId: number) => {
  const [updateAssistantMutation, mutationState] =
    useMutationWrapper<UpdateAssistantQueryDefinition>(UPDATE_ASSISTANT);

  const updateAssistantExternally = (
    wizardContextData: WizardContextType,
    options?: {
      onCompleted?: () => void;
    },
  ) => {
    updateAssistantMutation({
      variables: {
        id: assistantId,
        name: wizardContextData.data?.name as string,
        data: wizardContextData,
      },
      context: { clientName: "JTM" },
      onError: (error: ApolloError) => {
        notifyError(error.message);
      },
      onCompleted: () => {
        if (options?.onCompleted) options.onCompleted();
      },
    });
  };
  return { updateAssistantExternally, updatingAssistantIsLoading: mutationState.loading };
};

export default useUpdateAssistantExternally;
