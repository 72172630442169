import { Box } from "@mui/material";
import TabContent, { ITabWrapperChildProps } from "libs/ui/components/surfaces/tabs/TabContent";
import TabWrapper from "libs/ui/components/surfaces/tabs/TabWrapper";
import { JSXElementConstructor, ReactElement } from "react";
import { BackendTagDetailData, FrontendTagDetailData } from "../type";
import { getTabErrorIcons } from "./business";
import ConsentTabContent from "./TagDetailTabs/ConsentTabContent";
import HttpTabContent from "./TagDetailTabs/HttpTabContent";
import IsNegativeInfo from "./TagDetailTabs/IsNegativeInfo";
import NameElementTabContent from "./TagDetailTabs/NameElementTabContent";
import PayloadTabContent from "./TagDetailTabs/PayloadTabContent";
import ResponseTabContent from "./TagDetailTabs/ResponseTabContent";
import TriggerTabContent from "./TagDetailTabs/TriggerTabContent";
import VariableTabContent from "./TagDetailTabs/VariableTabContent";

interface TagDetailsProps {
  detailData: BackendTagDetailData | FrontendTagDetailData;
  isNegative: boolean;
}
/**
 * The TagDetails component
 * @param {TagDetailsProps} props
 * @returns a TagDetails component
 */
const TagDetails = ({ detailData, isNegative }: TagDetailsProps): JSX.Element => {
  const tabErrorIcon: ReturnType<typeof getTabErrorIcons<typeof detailData>> =
    getTabErrorIcons(detailData);
  // Cannot extract to an own component as TabContent must be direct child of TabWrapper

  const hasSpecialComponent = detailData.source === "backend" || detailData.source === "hybrid";

  if (!hasSpecialComponent) {
    return (
      <Box sx={{ background: "white" }}>
        <TabWrapper>
          <TabContent label="Tag Placeholders" icon={tabErrorIcon.tag_placeholders}>
            <Box sx={{ m: 2 }}>
              <NameElementTabContent
                values={detailData.tag_placeholders.placeholders}
                collapsible={false}
              />
            </Box>
          </TabContent>
          <TabContent label="Payload" icon={tabErrorIcon.payload}>
            <Box sx={{ m: 2 }}>
              {isNegative ? <IsNegativeInfo /> : <PayloadTabContent detailData={detailData} />}
            </Box>
          </TabContent>
          <TabContent label="Response" icon={tabErrorIcon.response}>
            <Box sx={{ m: 2 }}>
              {isNegative ? <IsNegativeInfo /> : <ResponseTabContent detailData={detailData} />}
            </Box>
          </TabContent>
          <TabContent label="Trigger" icon={tabErrorIcon.trigger}>
            <Box sx={{ m: 2 }}>
              <TriggerTabContent detailData={detailData} />
            </Box>
          </TabContent>
          <TabContent label="Variables" icon={tabErrorIcon.variables}>
            <Box sx={{ m: 2 }}>
              <VariableTabContent variables={detailData.variables} />
            </Box>
          </TabContent>
          <TabContent label="Functions" icon={tabErrorIcon.functions}>
            <Box sx={{ m: 2 }}>
              <NameElementTabContent values={detailData.functions} />
            </Box>
          </TabContent>
          <TabContent label="Consent" icon={tabErrorIcon.consent}>
            <Box sx={{ m: 2 }}>
              <ConsentTabContent detailData={detailData} />
            </Box>
          </TabContent>
          <TabContent label="Documents" icon={tabErrorIcon.documents}>
            <Box sx={{ m: 2 }}>
              <NameElementTabContent values={detailData.documents} />
            </Box>
          </TabContent>
        </TabWrapper>
      </Box>
    );
  }

  const tabErrorIcon2: ReturnType<typeof getTabErrorIcons<typeof detailData>> =
    getTabErrorIcons(detailData);
  return (
    <Box sx={{ background: "white" }}>
      <TabWrapper>
        <TabContent label="HTTP" icon={tabErrorIcon2.http}>
          <Box sx={{ m: 2 }}>
            {isNegative ? <IsNegativeInfo /> : <HttpTabContent detailData={detailData} />}
          </Box>
        </TabContent>
        <TabContent label="Tag Placeholders" icon={tabErrorIcon.tag_placeholders}>
          <Box sx={{ m: 2 }}>
            <NameElementTabContent
              values={detailData.tag_placeholders.placeholders}
              collapsible={false}
            />
          </Box>
        </TabContent>
        <TabContent label="Payload" icon={tabErrorIcon.payload}>
          <Box sx={{ m: 2 }}>
            {isNegative ? <IsNegativeInfo /> : <PayloadTabContent detailData={detailData} />}
          </Box>
        </TabContent>
        <TabContent label="Response" icon={tabErrorIcon.response}>
          <Box sx={{ m: 2 }}>
            {isNegative ? <IsNegativeInfo /> : <ResponseTabContent detailData={detailData} />}
          </Box>
        </TabContent>
        <TabContent label="Trigger" icon={tabErrorIcon.trigger}>
          <Box sx={{ m: 2 }}>
            <TriggerTabContent detailData={detailData} />
          </Box>
        </TabContent>
        <TabContent label="Variables" icon={tabErrorIcon.variables}>
          <Box sx={{ m: 2 }}>
            <VariableTabContent variables={detailData.variables} />
          </Box>
        </TabContent>
        <TabContent label="Functions" icon={tabErrorIcon.functions}>
          <Box sx={{ m: 2 }}>
            <NameElementTabContent values={detailData.functions} />
          </Box>
        </TabContent>
        <TabContent label="Consent" icon={tabErrorIcon.consent}>
          <Box sx={{ m: 2 }}>
            <ConsentTabContent detailData={detailData} />
          </Box>
        </TabContent>
        <TabContent label="Documents" icon={tabErrorIcon.documents}>
          <Box sx={{ m: 2 }}>
            <NameElementTabContent values={detailData.documents} />
          </Box>
        </TabContent>
      </TabWrapper>
    </Box>
  );
};

export default TagDetails;
