import { useState } from "react";
import DevEnv from "../utils/devEnv";

const useTriggerRerender = () => {
  const [, setToggle] = useState(true);

  const triggerRerender = () => {
    setToggle((prev) => !prev);
    DevEnv.logWarning("Triggered manual rerender!");
  };

  return triggerRerender;
};

export default useTriggerRerender;
