import { cloneDeep } from "lodash";
import { createContext, useContext } from "react";

export interface WizardContextType {
  activeStep: number;
  highestVisited: number;
  data: { [key: string]: unknown };
}

export const initialAssistantContext: WizardContextType = {
  activeStep: 1,
  highestVisited: 1,
  data: {},
};

export const AssistantContext = createContext(initialAssistantContext);

export const useAssistantWizard = <T extends WizardContextType>() => {
  return useContext(AssistantContext) as T;
};

export type ReducerAction =
  | {
      type: "incrementStep";
    }
  | {
      type: "decrementStep";
    }
  | {
      type: "goToPrevStep";
      step: number;
      submittedData: WizardContextType["data"];
      updateExternal: (
        wizardContextData: WizardContextType,
        options?: {
          onCompleted?: () => void;
        },
      ) => void;
    }
  | {
      type: "prev";
      submittedData: WizardContextType["data"];
      updateExternal: (
        wizardContextData: WizardContextType,
        options?: {
          onCompleted?: () => void;
        },
      ) => void;
    }
  | {
      type: "next";
      submittedData: WizardContextType["data"];
      updateExternal: (
        wizardContextData: WizardContextType,
        options?: {
          onCompleted?: () => void;
        },
      ) => void;
    }
  | {
      type: "update";
      submittedData: WizardContextType["data"];
      updateExternal: (
        wizardContextData: WizardContextType,
        options?: {
          onCompleted?: () => void;
        },
      ) => void;
    };

export const wizardContextReducer = <TContextType extends WizardContextType>(
  state: TContextType,
  action: ReducerAction,
): TContextType => {
  const goToPrevStep = (newState: WizardContextType, step: number) => {
    if (step >= newState.activeStep) return newState;

    newState.activeStep = step;
    return newState;
  };

  const incrementStep = (newState: WizardContextType) => {
    newState.activeStep += 1;

    if (newState.activeStep > newState.highestVisited) {
      newState.highestVisited += 1;
    }
  };

  const decrementStep = (newState: WizardContextType) => {
    newState.activeStep -= 1;
  };

  const updateStateData = (
    submittedData: WizardContextType["data"],
    newState: WizardContextType,
  ) => {
    for (const [key, value] of Object.entries(submittedData)) {
      newState.data[key] = value;
    }
  };

  switch (action.type) {
    case "goToPrevStep": {
      const newState = cloneDeep(state);
      goToPrevStep(newState, action.step);
      updateStateData(action.submittedData, newState);
      action.updateExternal(newState);
      return newState;
    }
    case "prev": {
      const newInternalState = cloneDeep(state);
      updateStateData(action.submittedData, newInternalState);

      // internal step will be set on external completion in AssistantWizard
      const externalState = cloneDeep(newInternalState);
      decrementStep(externalState);
      action.updateExternal(externalState);

      return newInternalState;
    }

    case "next": {
      const newInternalState = cloneDeep(state);
      updateStateData(action.submittedData, newInternalState);

      // internal step will be set on external completion in AssistantWizard
      const externalState = cloneDeep(newInternalState);
      incrementStep(externalState);
      action.updateExternal(externalState);

      return newInternalState;
    }

    case "update": {
      const newInternalState = cloneDeep(state);
      updateStateData(action.submittedData, newInternalState);
      action.updateExternal(newInternalState);
      return newInternalState;
    }

    case "incrementStep": {
      const newInternalState = cloneDeep(state);
      incrementStep(newInternalState);
      return newInternalState;
    }

    case "decrementStep": {
      const newInternalState = cloneDeep(state);
      decrementStep(newInternalState);
      return newInternalState;
    }

    default:
      return state;
  }
};

interface IAssistantWizardExternalMethods {
  goToPrevStep: (step: number) => void;
}
export const AssistantWizardExternalMethods = createContext<IAssistantWizardExternalMethods>({
  goToPrevStep: () => {},
});

export const useAssistantWizardExternalMethods = () => {
  return useContext(AssistantWizardExternalMethods);
};
