import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  COPY_VARIABLE,
  DELETE_VARIABLE,
  GET_ALL_VARIABLES,
  GET_VARIABLES_DEPENDENCIES,
} from "apps/jtm/business/variable/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import getRoutePath from "libs/business/routes/routes";
import dateFormatterDatagrid from "libs/business/utils/timeTransformation";
import BasicGrid from "libs/ui/components/datagrid/BasicGrid";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";
import {
  TVariableSources,
  VARIABLE_SOURCES,
  VARIABLE_SOURCE_STYLE_CONFIG,
} from "../../conf/variable_source";

/**
 * The FunctionGrid component displays a grid containing all functions in the db.
 * @returns a FunctionGrid component
 */
const VariablesGrid = (): JSX.Element => {
  const [getAllVariables, queryState] = useQueryWrapper(GET_ALL_VARIABLES);
  if (!queryState.called) {
    getAllVariables({ context: { clientName: "JTM" } });
  }
  const [deleteFunction, mutationDeleteState] = useMutationWrapper(DELETE_VARIABLE);
  const [copyFunction, mutationcopyState] = useMutationWrapper(COPY_VARIABLE);

  if (queryState.error) {
    throw new Error(queryState.error.message);
  }

  if (
    queryState.loading ||
    !queryState.data ||
    mutationDeleteState.loading ||
    mutationcopyState.loading
  )
    return <PageLoadingSpinner />;

  const fetchedVariables = queryState.data.variables;
  const shortIds: string[] = fetchedVariables.map((element: any) => element.short_id);

  return (
    <Container>
      <Page
        title="Variables"
        newButtonConfig={{
          instanceName: "Variable",
          newInstanceLink: getRoutePath("jtm", "variable_selection"),
        }}>
        <BasicGrid
          columnConfig={[
            {
              field: "source",
              headerName: "Type",
              width: 50,
              renderCell: (params: GridRenderCellParams<any>) => {
                const type: TVariableSources = params.value || "FRONTENDJS"; // FRONTENDJS as fallback because of corrupt database

                return (
                  <FontAwesomeIcon
                    icon={VARIABLE_SOURCE_STYLE_CONFIG[type].icon}
                    color={VARIABLE_SOURCE_STYLE_CONFIG[type].color}
                    style={{ width: "20px" }}
                  />
                );
              },
            },
            {
              field: "name",
              headerName: "Name",
              minWidth: 250,
              flex: 1,
            },
            {
              field: "group",
              headerName: "Group",
              minWidth: 250,
            },
            {
              field: "create_at",
              headerName: "Created",
              width: 200,
              type: "dateTime",
              valueFormatter: dateFormatterDatagrid,
            },
            {
              field: "edit_at",
              headerName: "Edited",
              width: 200,
              type: "dateTime",
              valueFormatter: dateFormatterDatagrid,
            },
          ]}
          actionConfig={{
            editLink: {
              service: "jtm",
              routeName: "variable_id",
            },
            context: "JTM",
            deleteFunction,
            copy: {
              copyFunction,
              GET_ALL_QUERY: GET_ALL_VARIABLES,
              elementTypes: "variables",
              shortIds,
            },
            log: "Variable",
            settings: {
              editable: (params) => params.row.source !== VARIABLE_SOURCES.ENRICHMENT,
              deletable: (params) => params.row.source !== VARIABLE_SOURCES.ENRICHMENT,
              copyable: (params) => params.row.source !== VARIABLE_SOURCES.ENRICHMENT,
              hasLog: (params) => params.row.source !== VARIABLE_SOURCES.ENRICHMENT,
              hasDependencies: true,
            },
            onShowDependencies: {
              query: GET_VARIABLES_DEPENDENCIES,
              context: {
                clientName: "jtm",
              },
            },
          }}
          rowsData={fetchedVariables}
        />
      </Page>
    </Container>
  );
};

export default VariablesGrid;
