import {
  GetStateByIdQueryDefinition,
  GET_ALL_STATES,
  GET_STATE_BY_ID,
  GET_STATE_DEFAULT_VALUES,
  UPDATE_STATE,
} from "apps/jtm/business/state/queries";
import { MaintainedProvider } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import { getUpdateOptions } from "libs/business/apollo/updateOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import { StateForm } from "../../components/state/StateForm";

interface IUpdateState {
  id: number;
  short_id: string;
  version?: string;
  name: string;
  desc: string;
  value: string;
  maintained: number;
  pluginID?: string;
}
/**
 * The State component
 * @returns a state component
 */
const StateUpdate = (): JSX.Element => {
  const id = useParamsId("id");
  const getStateResponse = useSimpleFetching<GetStateByIdQueryDefinition>({
    query: GET_STATE_BY_ID,
    context: "JTM",
    queryArguments: { id },
  });

  const [updateState, mutationState] = useMutationWrapper(UPDATE_STATE);

  if (getStateResponse === false || mutationState.loading) {
    return <PageLoadingSpinner />;
  }

  const formSubmitHandler: SubmitHandler<IUpdateState> = (submitData: IUpdateState) => {
    updateState(getUpdateOptions("State", "JTM", submitData, GET_ALL_STATES));
  };

  const stateData = getStateResponse.state;
  const isMaintained = stateData.maintained === 1;

  return (
    <MaintainedProvider
      initialValue={isMaintained}
      elementName="trigger"
      id={stateData.id}
      pluginID={stateData.pluginID}
      formSubmitHandler={formSubmitHandler}
      updateMutationFunction={updateState}
      GET_DEFAULT_VALUES={GET_STATE_DEFAULT_VALUES}>
      <StateForm mode="update" data={stateData} />
    </MaintainedProvider>
  );
};

export default StateUpdate;
