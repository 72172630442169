import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, ButtonPropsVariantOverrides, SxProps, Theme } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { notifySuccess } from "libs/business/notification/notification";
import useCopyToClipboard from "libs/business/hooks/useCopyToClipboard";
import { FC } from "react";

export interface ICopyToClipboard {
  textToCopy: string;
  buttonText: string;
  showSuccessMessage?: boolean;
  successMessage?: string;
  buttonVariant?: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >;
  buttonSx?: SxProps<Theme>;
}

const CopyClipboardButton: FC<ICopyToClipboard> = ({
  textToCopy,
  buttonText,
  successMessage = "Copied to clipboard!",
  buttonVariant = "outlined",
  showSuccessMessage = true,
  buttonSx,
}: ICopyToClipboard) => {
  const [, copyToClipboard] = useCopyToClipboard();
  const handleClick = () => {
    copyToClipboard(textToCopy);
    if (showSuccessMessage) {
      notifySuccess(successMessage);
    }
  };

  return (
    <Button
      sx={buttonSx}
      variant={buttonVariant}
      onClick={handleClick}
      startIcon={<ContentCopyIcon />}>
      {buttonText}
    </Button>
  );
};

export default CopyClipboardButton;
