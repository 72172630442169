import { Paper, Stack } from "@mui/material";
import {
  BackendNexusToolConstantDetails,
  GET_TOOL_DEFINITION_BY_PLUGINID,
  GetToolDefinitionByPluginIdQueryDefinition,
} from "apps/jtm/business/tool/queries";
import ConstantGrid from "apps/jtm/components/tool/ConstantGrid";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import useIsFirstRender from "libs/business/hooks/useIsFirstRender";
import { useAssistantWizard } from "libs/ui/components/assistant-wizard/AssistantWizardContext";
import SwitchWrapper from "libs/ui/components/input/SwitchWrapper";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import TemplateSelection from "libs/ui/components/input/connector/TemplateSelection";
import DividerAccordion from "libs/ui/components/surfaces/accordion/DividerAccordion";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import TaskWrapper from "libs/ui/components/surfaces/helpers/TaskWrapper";
import { useFormContext } from "react-hook-form";
import { updateWizardContextConstantsFromManifest } from "../business/onManifestChange";
import {
  CA_INPUT_NAME_CONSTANTS,
  CA_INPUT_NAME_CONTAINER_IDS,
  CA_INPUT_NAME_CREATE_TAGS,
  CA_INPUT_NAME_NAME,
  CA_INPUT_NAME_TAG_TYPES_TO_GENERATE,
  CA_INPUT_NAME_TOOL_ID,
} from "../conf/schema";
import { CaWizardContextType } from "../conf/types";

/**
 * The ConnectorAssistantStepTwo component
 * @returns a ConnectorAssistantStepTwo component
 */
const ConnectorAssistantStepTwo = (): JSX.Element => {
  const wizardData = useAssistantWizard<CaWizardContextType>();
  const isFirstRender = useIsFirstRender();
  const { watch } = useFormContext();

  const pluginID = wizardData.data[CA_INPUT_NAME_TOOL_ID];

  const [callQuery, queryState] = useQueryWrapper<GetToolDefinitionByPluginIdQueryDefinition>(
    GET_TOOL_DEFINITION_BY_PLUGINID,
  );

  if (!queryState.called) {
    callQuery({
      variables: { pluginID },
      context: { clientName: "JTM" },
      fetchPolicy: "no-cache",
    });
  }

  const constants = !queryState.data
    ? {}
    : updateWizardContextConstantsFromManifest(
        wizardData.data[CA_INPUT_NAME_CONSTANTS],
        queryState.data.toolDefinition.constants,
      );

  const transformedConstants: BackendNexusToolConstantDetails[] = Object.entries(constants).map(
    ([shortId, constant]) => ({
      short_id: shortId,
      name: constant.name || shortId,
      desc: constant.desc || "",
      value: constant,
      optional: constant.optional !== undefined ? constant.optional : true,
      type: "text",
    }),
  );

  const mandatoryConstants = transformedConstants.filter((constant) => constant.optional === false);

  const optionalConstants = transformedConstants.filter((constant) => constant.optional === true);

  const watchedName = watch(CA_INPUT_NAME_NAME);
  const watchedMandatoryConstants = watch(CA_INPUT_NAME_CONSTANTS);

  const allMandatoryConstantsDone = (constantObj: { [key: string]: { value: string } }) => {
    let allDone = true;

    if (!constantObj) return false;

    mandatoryConstants.forEach((constant) => {
      if (!constantObj[constant.short_id]?.value) {
        allDone = false;
      }
    });
    return allDone;
  };

  return (
    <Paper variant="page" style={{ overflow: "auto", maxHeight: "100%" }}>
      <Stack spacing={2}>
        <TaskWrapper
          taskDescription="Give the new tool a descriptive name."
          done={isFirstRender ? !!wizardData.data[CA_INPUT_NAME_NAME] : !!watchedName}>
          <TextFieldWrapper
            name={CA_INPUT_NAME_NAME}
            label="Name"
            value={wizardData.data[CA_INPUT_NAME_NAME]}
            maxLength={150}
            size="medium"
          />
        </TaskWrapper>

        <SwitchWrapper hidden name={CA_INPUT_NAME_CREATE_TAGS} label="Create Tags" value />

        {mandatoryConstants.length > 0 && (
          <TaskWrapper
            done={
              isFirstRender
                ? !!allMandatoryConstantsDone(wizardData.data[CA_INPUT_NAME_CONSTANTS])
                : !!allMandatoryConstantsDone(watchedMandatoryConstants)
            }
            taskDescription="You will need to provide the necessary configuration values for the tool to be able to correctly track and send the data to your tool account.">
            <ConstantGrid
              rootInputName={CA_INPUT_NAME_CONSTANTS}
              constants={mandatoryConstants}
              selectedContainerIds={wizardData.data[CA_INPUT_NAME_CONTAINER_IDS]}
            />
          </TaskWrapper>
        )}
      </Stack>
      <DividerAccordion title="Advanced settings (optional)">
        <Stack spacing={4} sx={{ mt: 4 }}>
          {optionalConstants.length > 0 && (
            <ConstantGrid
              rootInputName={CA_INPUT_NAME_CONSTANTS}
              constants={optionalConstants}
              selectedContainerIds={wizardData.data[CA_INPUT_NAME_CONTAINER_IDS]}
            />
          )}
          <AlertBox
            type="info"
            message="We recommend that you only install the tags you need. However, by default we install every tag of the given tool, so you won't miss any functionality      ."
          />
          <TemplateSelection
            pluginId={pluginID || ""}
            name={CA_INPUT_NAME_TAG_TYPES_TO_GENERATE}
            label="Tags"
            value={wizardData.data[CA_INPUT_NAME_TAG_TYPES_TO_GENERATE]}
          />
        </Stack>
      </DividerAccordion>
    </Paper>
  );
};

export default ConnectorAssistantStepTwo;
