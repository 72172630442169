/* eslint-disable no-param-reassign */
import { Paper, Stack, Typography, AccordionSummary, AccordionDetails } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import { deepCopyObjectWithoutFunction } from "libs/business/utils/objectFunctions";
import { capitalizeFirstLetter } from "libs/business/utils/stringManipulation";
import ColoredAccordion from "libs/ui/components/surfaces/accordion/ColoredAccordion";
import Page from "libs/ui/components/surfaces/page/Page";
import SelectWrapper from "libs/ui/components/input/SelectWrapper";
import SwitchWrapper from "libs/ui/components/input/SwitchWrapper";
import { useWizardData } from "libs/ui/components/surfaces/wizard/WizardDataContext";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import Placeholder from "./field_selection/Placeholder";
import {
  GuiListData,
  TextData,
  ArrayListData,
  PlaceholderDefinition,
  GroupedTagEntryData,
} from "./tagTypes";

export const TAG_CONFIG_ROOT_KEY = "placeholder";

interface IConfigureTemplateProps {
  placeholderDefinition: PlaceholderDefinition;
  essentialMode?: boolean;
}
/**
 * The ConfigureTemplate component
 * @param {IConfigureTemplateProps} props
 * @returns a ConfigureTemplate component
 */
const ConfigureTemplate = ({
  placeholderDefinition,
  essentialMode,
}: IConfigureTemplateProps): JSX.Element => {
  const wizardData = useWizardData();
  const wizardTagData = wizardData?.[TAG_CONFIG_ROOT_KEY];
  const { unregister } = useFormContext();
  const [hitType, setHitType] = usePropChangingState<string | undefined>(
    wizardData?.[TAG_CONFIG_ROOT_KEY]?.hitType || placeholderDefinition?.hitTypeConfig?.active,
  );
  const [displayNoConsent, setDisplayNoConsent] = useState(false);

  const groupedTagEntries = overwriteGroupedTagEntriesFromWizardData(
    placeholderDefinition.groups,
    wizardTagData,
    hitType,
  );

  const updateHitType = (newHitType: string | number): void => {
    if (typeof newHitType === "string") {
      if (typeof hitType === "undefined" || hitType === newHitType) return;
      unregisterRemovedPlaceholder(hitType, newHitType);
      setHitType(newHitType);
    }
  };

  const unregisterRemovedPlaceholder = (oldHitType: string, newHitType: string) => {
    const allOldIds: string[] = [];
    const allNewIds: string[] = [];
    Object.keys(groupedTagEntries).forEach((groupName) => {
      groupedTagEntries[groupName]?.hitTypes?.[oldHitType]?.forEach((entry) =>
        allOldIds.push(entry.id),
      );
      groupedTagEntries[groupName]?.hitTypes?.[newHitType]?.forEach((entry) =>
        allNewIds.push(entry.id),
      );
    });
    const oldIdsToUnregister = allOldIds.filter((id) => !allNewIds.includes(id));

    oldIdsToUnregister.forEach((id) => {
      unregister(`${TAG_CONFIG_ROOT_KEY}.${id}`);
    });
  };
  sortGuiListsLast(groupedTagEntries);

  const groupNamesWithoutDefault = Object.keys(groupedTagEntries).filter(
    (str) => str !== "default",
  );

  const showAdvancedSettings =
    placeholderDefinition?.hitTypeConfig || groupNamesWithoutDefault.length > 0;

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
        {placeholderDefinition.privacy_mode && (
          <SwitchWrapper
            name="consentmode"
            label="Show/hide essential mode"
            value={essentialMode}
            onChange={(value: boolean | number) => {
              setDisplayNoConsent(!!value);
            }}
            style={{ margin: "0px" }}
          />
        )}
      </Stack>
      {(groupedTagEntries?.default?.fields?.length || hitType) && (
        <>
          <Typography variant="pageTitle" sx={{ mt: 3 }}>
            Template Configuration
          </Typography>
          <Paper variant="page">
            <Stack spacing={2}>
              {groupedTagEntries.default.fields.map((element) => {
                return (
                  <Placeholder
                    key={element.id}
                    element={element}
                    displayNoConsent={displayNoConsent}
                    rootInputName={TAG_CONFIG_ROOT_KEY}
                  />
                );
              })}
              {hitType && groupedTagEntries.default?.hitTypes?.[hitType] && (
                <div>
                  <ColoredAccordion index={3}>
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls={`group-${hitType}-content`}
                      id={`group-${hitType}-header`}>
                      <Typography>{`Specific fields for HIT Type/Sender Tag: ${hitType}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={2}>
                        {groupedTagEntries.default?.hitTypes?.[hitType].map((element) => {
                          return (
                            <Placeholder
                              key={element.id}
                              element={element}
                              displayNoConsent={displayNoConsent}
                              rootInputName={TAG_CONFIG_ROOT_KEY}
                            />
                          );
                        })}
                      </Stack>
                    </AccordionDetails>
                  </ColoredAccordion>
                </div>
              )}
            </Stack>
          </Paper>
        </>
      )}
      {showAdvancedSettings && (
        <Page title="Advanced Settings" notFirst>
          {placeholderDefinition?.hitTypeConfig && (
            <SelectWrapper
              name="hitType"
              label="HIT Type/Sender Tag"
              options={placeholderDefinition?.hitTypeConfig.options.map((option) => {
                return { key: option.id, value: option.name };
              })}
              value={hitType}
              onChange={updateHitType}
            />
          )}
          <div>
            {groupNamesWithoutDefault.map((groupName, index) => {
              return (
                <ColoredAccordion index={index} key={groupName}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls={`group-${index}-content`}
                    id={`group-${index}-header`}>
                    <Typography>{capitalizeFirstLetter(groupName)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={2}>
                      {groupedTagEntries[groupName]?.fields.map((element) => {
                        return (
                          <Placeholder
                            key={element.id}
                            element={element}
                            displayNoConsent={displayNoConsent}
                            rootInputName={TAG_CONFIG_ROOT_KEY}
                          />
                        );
                      })}
                      {hitType && groupedTagEntries[groupName]?.hitTypes?.[hitType] && (
                        <div>
                          <ColoredAccordion index={3}>
                            <AccordionSummary
                              expandIcon={<GridExpandMoreIcon />}
                              aria-controls={`group-${hitType}-content`}
                              id={`group-${hitType}-header`}>
                              <Typography>{`Specific fields for HIT Type/Sender Tag: ${hitType}`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stack spacing={2}>
                                {groupedTagEntries[groupName]?.hitTypes?.[hitType].map(
                                  (element) => {
                                    return (
                                      <Placeholder
                                        key={element.id}
                                        element={element}
                                        displayNoConsent={displayNoConsent}
                                        rootInputName={TAG_CONFIG_ROOT_KEY}
                                      />
                                    );
                                  },
                                )}
                              </Stack>
                            </AccordionDetails>
                          </ColoredAccordion>
                        </div>
                      )}
                    </Stack>
                  </AccordionDetails>
                </ColoredAccordion>
              );
            })}
          </div>
        </Page>
      )}
    </>
  );
};

export default ConfigureTemplate;

const overwriteGroupedTagEntriesFromWizardData = (
  groupedTagData: {
    [groupName: string]: GroupedTagEntryData;
  },
  wizardTagData: any,
  hitType: string | undefined,
): { [x: string]: GroupedTagEntryData } => {
  const newGroupedTagEntries: {
    [groupName: string]: GroupedTagEntryData;
  } = deepCopyObjectWithoutFunction(groupedTagData);

  Object.keys(newGroupedTagEntries).forEach((groupName) => {
    newGroupedTagEntries[groupName]?.fields.forEach((item) => {
      if (item.type === "TEXT") {
        overwriteTextItem(item, wizardTagData);
      }
      if (item.type === "GUILIST") {
        overwriteGuiListItem(item, wizardTagData);
      }
      if (item.type === "ARRAYLIST") {
        overwriteArrayListItem(item, wizardTagData);
      }
    });
    if (hitType && newGroupedTagEntries[groupName]?.hitTypes?.[hitType])
      newGroupedTagEntries[groupName]?.hitTypes?.[hitType].forEach((item) => {
        if (item.type === "TEXT") {
          overwriteTextItem(item, wizardTagData);
        }
        if (item.type === "GUILIST") {
          overwriteGuiListItem(item, wizardTagData);
        }
        if (item.type === "ARRAYLIST") {
          overwriteArrayListItem(item, wizardTagData);
        }
      });
  });
  return newGroupedTagEntries;
};

const overwriteTextItem = (item: TextData, wizardTagData: any): void => {
  const wizardValue = wizardTagData?.[item.id]?.value;
  const wizardNoConsentValue = wizardTagData?.[item.id]?.noConsentValue;
  item.value = isUndefined(wizardValue) ? item.value : wizardValue;
  item.noConsentValue = isUndefined(wizardNoConsentValue)
    ? item.noConsentValue
    : wizardNoConsentValue;
};

const overwriteGuiListItem = (item: GuiListData, wizardTagData: any): void => {
  const wizardValue = wizardTagData?.[item.id];
  item.entries = isUndefined(wizardValue) ? item.entries : wizardValue;
};

const overwriteArrayListItem = (item: ArrayListData, wizardTagData: any): void => {
  item.filterValue = wizardTagData?.[item.id]?.filterValue || item.filterValue;
  item.filterVariable = wizardTagData?.[item.id]?.filterVariable || item.filterVariable;
  item.entries.forEach((innerItem) => {
    if (innerItem.type === "TEXT") {
      overwriteTextItem(innerItem, wizardTagData?.[item.id]);
    }
    if (innerItem.type === "GUILIST") {
      overwriteGuiListItem(innerItem, wizardTagData?.[item.id]);
    }
  });
};

const isUndefined = (value: any): boolean => {
  return typeof value === "undefined";
};

const sortGuiListsLast = (groupedTagEntryData: { [x: string]: GroupedTagEntryData }): void => {
  const groupNames = Object.keys(groupedTagEntryData);

  groupNames.forEach((groupName) => {
    const groupData = groupedTagEntryData[groupName];

    sortGuiListsLastInArrayLists(groupData);

    groupData.fields = groupData.fields.sort((placeholder1) => {
      if (placeholder1.type === "GUILIST") return 1;
      return -1;
    });
  });
};

const sortGuiListsLastInArrayLists = (groupData: GroupedTagEntryData) => {
  groupData.fields.forEach((placeholder) => {
    if (placeholder.type === "ARRAYLIST") {
      placeholder.entries = placeholder.entries.sort((arrayListPlaceholder) => {
        if (arrayListPlaceholder.type === "GUILIST") return 1;
        return -1;
      });
    }
  });
};
