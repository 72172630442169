/* eslint-disable react/no-array-index-key */
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs, Box, IconButton, Stack } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { ITabWrapperChildProps } from "./TabContent";

interface ITabWrapperProps {
  variant?: "fullWidth" | "scrollable" | "standard" | undefined;
  iconPosition?: "start" | "end" | "bottom" | "top";
  onPlusClick?: Function;
  children?: ReactElement<ITabWrapperChildProps> | ReactElement<ITabWrapperChildProps>[];
}
/**
 * The VerticalTabWrapper component
 * @param {IVerticalTabWrapperProps} props
 * @returns a VerticalTabWrapper component
 */
const VerticalTabWrapper = ({
  variant,
  iconPosition = "start",
  onPlusClick,
  children,
}: ITabWrapperProps): JSX.Element => {
  const showAddIcon = typeof onPlusClick !== "undefined";

  let childrenArray: ReactElement<ITabWrapperChildProps>[] = [];
  const [tabIndex, setTabIndex] = React.useState(0);

  useEffect(() => {
    if (Array.isArray(children) && tabIndex > children.length - 1) {
      setTabIndex(children.length - 1);
    }
  }, [children, tabIndex]);

  if (typeof children !== "undefined" && !Array.isArray(children)) {
    childrenArray = [children];
  } else if (typeof children !== "undefined") {
    childrenArray = children;
  }

  const handleTabSwitch = (event: React.SyntheticEvent, newTabIndex: number): void => {
    setTabIndex(newTabIndex);
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
      <Stack sx={{ borderRight: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabSwitch}
          orientation="vertical"
          variant={variant}
          sx={{ overflow: "initial", minWidth: "max-content" }}>
          {childrenArray.map((child, index) => {
            return (
              <Tab
                key={typeof child.props.label === "string" ? child.props.label : index}
                label={child.props.label}
                icon={child.props.icon}
                iconPosition={iconPosition}
                id={`tab-${index}`}
                aria-controls={`tabpanel-${index}`}
                sx={{ minHeight: 48, color: child.props.error ? "error.main" : "" }}
              />
            );
          })}
        </Tabs>
        {showAddIcon && (
          <IconButton
            onClick={() => onPlusClick()}
            size="small"
            color="primary"
            sx={{ borderRadius: 0, height: "40px" }}>
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        )}
      </Stack>
      {childrenArray.map((child, index) => {
        return (
          <div
            key={typeof child.props.label === "string" ? child.props.label : index}
            hidden={tabIndex !== index}
            role="tabpanel"
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            style={{ width: "100%" }}>
            {child}
          </div>
        );
      })}
    </Box>
  );
};

export default VerticalTabWrapper;
