import { faCrosshairs, faGavel } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { GetToolByIdQueryDefinition, GET_ALL_TOOL_NAMES } from "apps/jtm/business/tool/queries";
import useFetchNotOneOfList from "libs/business/hooks/useFetchNotOneOfList";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import Page from "libs/ui/components/surfaces/page/Page";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import TabContent from "libs/ui/components/surfaces/tabs/TabContent";
import TabWrapper from "libs/ui/components/surfaces/tabs/TabWrapper";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { setShortIDForCustomConstants } from "../shared/preprocess_constant_values";
import ConsentSettings from "./ConsentSettings";
import TrackingSettings from "./TrackingSettings";

interface IToolProps {
  toolData: GetToolByIdQueryDefinition["dataType"];
  formSubmitHandler: SubmitHandler<any>;
}

const shouldCreateTagsBeShown = (toolID: number) => {
  if (toolID > 0) {
    return false;
  }
  return true;
};

const Tool = ({ toolData, formSubmitHandler }: IToolProps): JSX.Element => {
  const usedNames = useFetchNotOneOfList({
    query: GET_ALL_TOOL_NAMES,
    queryName: "tools",
    context: "JTM",
    queryProperty: "name",
    exclude: [toolData.name],
  });

  // const mandatoryConstants = toolData.constants.filter((constant: any) => !constant.optional);

  // const mandatoryYupObject: { [key: string]: any } = {};
  // mandatoryConstants.forEach((constant: any) => {
  //   mandatoryYupObject[constant.short_id] = yup.object({
  //     value: yup.string().required("This constant is required!"),
  //   });
  // });

  const schema = yup.object({
    name: yup
      .string()
      .required()
      .max(256, "The name must be shorter than 256 characters.")
      .notOneOf(usedNames, "This name is already in use!"),
    // constants: yup.object(mandatoryYupObject),
  });

  const methods = useForm<any>({ resolver: yupResolver(schema) });
  const [selectedContainerIds, setContainerSelection] = usePropChangingState(toolData.containerIDs);
  const showNotification = selectedContainerIds.length === 0;

  const notificationText = showNotification ? "No container selected for this Tool." : undefined;

  return (
    <Page title="Tool" notificationText={notificationText} notificationType="WARNING">
      <Stack direction="row" spacing={2} alignItems="center">
        <img
          src={`${toolData.tool_definition.image_path}medium`}
          alt="Tool"
          style={{ width: "150px" }}
        />
        <Typography variant="h1">{toolData.tool_definition.name}</Typography>
      </Stack>
      <FormProvider {...methods} key="ToolForm">
        <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(formSubmitHandler)}>
          {toolData.id && <TextFieldWrapper name="id" label="id" value={toolData.id} hidden />}
          {toolData.pluginID && (
            <TextFieldWrapper name="pluginID" label="pluginID" value={toolData.pluginID} hidden />
          )}

          <TabWrapper>
            <TabContent label="Tracking Settings" icon={<FontAwesomeIcon icon={faCrosshairs} />}>
              <TrackingSettings
                name={toolData.name}
                selectedContainerIds={selectedContainerIds}
                setContainerSelection={setContainerSelection}
                constants={setShortIDForCustomConstants(toolData.constants)}
                createTags={shouldCreateTagsBeShown(toolData.id)}
              />
            </TabContent>
            <TabContent label="Consent Settings" icon={<FontAwesomeIcon icon={faGavel} />}>
              <ConsentSettings
                cmpInformation={toolData.tool_definition.cmpInformation}
                selectedContainerIds={selectedContainerIds}
              />
            </TabContent>
          </TabWrapper>
          <SaveCancelBar />
        </form>
      </FormProvider>
    </Page>
  );
};

export default Tool;
