import styles from "./CircularSpinner.module.scss";
/**
 * The PageLoadingSpinner component
 * @returns a PageLoadingSpinner component
 */
const CircularSpinner = (): JSX.Element => {
  return (
    <div className={styles.spinner}>
      <div className={`${styles["spinner-sector"]} ${styles["spinner-sector-yellow"]}`} />
      <div className={`${styles["spinner-sector"]} ${styles["spinner-sector-orange"]}`} />
      <div className={`${styles["spinner-sector"]} ${styles["spinner-sector-red"]}`} />
      <div className={`${styles["spinner-sector"]} ${styles["spinner-sector-dark-red"]}`} />
      <div className={`${styles["spinner-sector"]} ${styles["spinner-sector-green"]}`} />
      <div className={`${styles["spinner-sector"]} ${styles["spinner-sector-light-blue"]}`} />
      <div className={`${styles["spinner-sector"]} ${styles["spinner-sector-mid-blue"]}`} />
      <div className={`${styles["spinner-sector"]} ${styles["spinner-sector-dark-blue"]}`} />
    </div>
  );
};

export default CircularSpinner;
