import { Container } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useActiveContainer } from "apps/admin/components/container/ContainerContext";
import {
  DELETE_FRONTEND_CODE,
  GET_ALL_FRONTEND_CODES,
} from "apps/jtm/business/frontend_code/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import getRoutePath from "libs/business/routes/routes";
import { filterDataOnContainer } from "libs/business/utils/filterDataOnContainer";
import dateFormatterDatagrid from "libs/business/utils/timeTransformation";
import BasicGrid from "libs/ui/components/datagrid/BasicGrid";
import IRow from "libs/ui/components/datagrid/IRow";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";

/**
 * The FunctionGrid component displays a grid containing all functions in the db.
 * @returns a FunctionGrid component
 */
const FrontendCodeGrid = (): JSX.Element => {
  const activeContainerIds = useActiveContainer();
  const [getFrontendCode, queryState] = useQueryWrapper(GET_ALL_FRONTEND_CODES);
  const [deleteFunction] = useMutationWrapper(DELETE_FRONTEND_CODE);

  if (!queryState.called) {
    getFrontendCode({ context: { clientName: "JTM" } });
  }
  if (queryState.loading || !queryState.data) return <PageLoadingSpinner />;

  const filteredCodeData: IRow[] = filterDataOnContainer(
    queryState.data.frontendCodes,
    activeContainerIds,
    "containerIds",
  );

  return (
    <Container>
      <Page
        title="Codes"
        newButtonConfig={{
          instanceName: "Code",
          newInstanceLink: getRoutePath("jtm", "code_new"),
        }}>
        <BasicGrid
          showContainerFilter
          columnConfig={[
            {
              field: "name",
              headerName: "Name",
              minWidth: 250,
              flex: 1,
            },
            {
              field: "trigger",
              headerName: "Trigger",
              minWidth: 250,
              flex: 1,
              renderCell: (params: GridRenderCellParams<any>) => {
                return params.row.trigger.name;
              },
              valueGetter: (params) => params.row.trigger.name,
            },
            {
              field: "createAt",
              headerName: "Created",
              width: 250,
              type: "dateTime",
              valueFormatter: dateFormatterDatagrid,
            },
            {
              field: "editAt",
              headerName: "Edited",
              width: 250,
              type: "dateTime",
              valueFormatter: dateFormatterDatagrid,
            },
          ]}
          actionConfig={{
            editLink: {
              service: "jtm",
              routeName: "code_id",
            },
            deleteFunction,
            copy: false,
            log: "Code",
            settings: {
              editable: true,
              deletable: true,
              copyable: false,
              hasLog: true,
            },
            context: "JTM",
          }}
          rowsData={filteredCodeData}
        />
      </Page>
    </Container>
  );
};

export default FrontendCodeGrid;
