import { gql } from "@apollo/client";

export const GET_ALL_ENVIRONMENTS = gql`
  query configEnvironments {
    configEnvironments {
      id
      name
    }
  }
`;
export default GET_ALL_ENVIRONMENTS;
