import { Stack } from "@mui/material";
import { GetAllStatesQueryDefinition, GET_ALL_STATES } from "apps/jtm/business/state/queries";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { IdAndName } from "libs/model/types";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import SelectWrapper, { Option } from "libs/ui/components/input/SelectWrapper";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Trigger } from "./types";

interface StateSelectProps {
  triggerData: Trigger;
}
/**
 * The StateSelect component
 * @param {StateSelectProps} props
 * @returns a StateSelect component
 */
const StateSelect = ({ triggerData }: StateSelectProps): JSX.Element => {
  const { setMaintained } = useMaintained();
  const [feTriggerType, setFeTriggerType] = useState(triggerData.frontend_trigger_type);
  const getStatesResponse = useSimpleFetching<GetAllStatesQueryDefinition>({
    query: GET_ALL_STATES,
    context: "JTM",
  });
  const { unregister } = useFormContext();

  if (getStatesResponse === false) {
    return <PageLoadingSpinner />;
  }

  // eslint-disable-next-line prefer-destructuring
  const states: IdAndName[] = [...getStatesResponse.states, { id: 0, name: "SELECTORACTION" }];

  const selectOrActionState = states.find((state) => state.name === "SELECTORACTION");

  let selectedState = triggerData?.state?.id || selectOrActionState?.id;

  const isNewTrigger = triggerData.frontend_trigger_type === "STATE" && !triggerData?.state;

  if (isNewTrigger) {
    selectedState = states[0].id;
  }

  const stateOptions: Option[] = states.map((element: IdAndName) => {
    const key = element.id;
    return { key, value: element.name };
  });

  const onStateChange = (newValue: string | number) => {
    if (
      states.find((state) => state.id.toString() === newValue.toString())?.name === "SELECTORACTION"
    ) {
      setFeTriggerType("SELECTORACTION");
    } else {
      setFeTriggerType("STATE");
      unregister("selector");
      unregister("action");
    }
    if (newValue.toString() !== selectedState?.toString()) {
      setMaintained(false);
    }
  };

  return (
    <>
      <SelectWrapper
        name="stateId"
        label="State"
        value={selectedState}
        options={stateOptions}
        onChange={onStateChange}
      />
      <TextFieldWrapper
        name="frontend_trigger_type"
        label="frontend_trigger_type"
        hidden
        value={feTriggerType}
      />
      {feTriggerType === "SELECTORACTION" && (
        <Stack direction="row" spacing={2}>
          <SelectWrapper
            name="action"
            label="Action"
            value={triggerData.action || "click"}
            options={actionOptions}
          />
          <TextFieldWrapper
            name="selector"
            label="CSS Selector"
            value={triggerData.selector || ""}
          />
        </Stack>
      )}
    </>
  );
};

const actionOptions: Option[] = [
  {
    key: "mousedown",
    value: "Mouse press down",
  },
  {
    key: "mouseup",
    value: "Mouse press up",
  },
  {
    key: "mousemove",
    value: "Mouse move",
  },
  {
    key: "mouseover",
    value: "Mouse over",
  },
  {
    key: "mouseenter",
    value: "Mouse enter",
  },
  {
    key: "mouseleave",
    value: "Mouse leave",
  },
  {
    key: "wheel",
    value: "Wheel",
  },
  {
    key: "keydown",
    value: "Key down",
  },
  {
    key: "keyup",
    value: "Key up",
  },
  {
    key: "keypress",
    value: "Key press",
  },
  {
    key: "click",
    value: "Click",
  },
  {
    key: "change",
    value: "Change",
  },
  {
    key: "focus",
    value: "Focus",
  },
  {
    key: "select",
    value: "Select",
  },
  {
    key: "submit",
    value: "Submit",
  },
  {
    key: "resize",
    value: "Resize",
  },
];
export default StateSelect;
