import { gql } from "@apollo/client";
import { GQLTypeDefinition, ListResponse } from "libs/model/types";

export interface ConfigTextContenti18n {
  id: number;
  language_languageid: number;
  translation: string;
}

export interface BackendNexusTranslationType {
  id: number;
  text: string;
  config_text_content_i18n: ConfigTextContenti18n[];
}
export interface GetTranslationsQueryDefinition extends GQLTypeDefinition {
  dataType: BackendNexusTranslationType;
  response: ListResponse<
    "getAllTextContentWithTranslationsByLanguages",
    GetTranslationsQueryDefinition["dataType"]
  >;
  arguments: { languages: number[] }; // Delete row if there are not args
}

export const GET_TRANSLATIONS = gql`
  query GetAllTextContentWithTranslationsByLanguages($languages: [Int!]!) {
    getAllTextContentWithTranslationsByLanguages(languages: $languages) {
      id
      text
      config_text_content_i18n
    }
  }
`;

export const UPDATE_TRANSLATIONS = gql`
  mutation UpdateTranslations($translations: [JSON!]!) {
    updateTranslations(translations: $translations) {
      id
      create_at
      create_by
      edit_at
      edit_by
      name
      textcontent_textcontentid
      language_languageid
      text_content {
        text
      }
      language {
        text_content {
          text
        }
      }
    }
  }
`;

export default GET_TRANSLATIONS;
