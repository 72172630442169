import { faPlay, faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack, Typography } from "@mui/material";
import { UPDATE_PREVIEW } from "apps/jtm/business/output/queries";
import { GetAllVersionsQueryDefinition, GET_ALL_VERSIONS } from "apps/jtm/business/version/queries";
import { getUpdatePreviewOptions } from "apps/jtm/components/version/update_preview";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import LoadComponentSpinner from "libs/ui/components/feedback/loading-spinner/LoadComponentSpinner";

import Page from "libs/ui/components/surfaces/page/Page";
import { useParams } from "react-router-dom";

interface InformationHeaderProps {
  jtsVersion: number;
  containerURL: string;
}

const InformationHeader = ({ jtsVersion, containerURL }: InformationHeaderProps): JSX.Element => {
  const [updatePreview, updatePreviewMutationState] = useMutationWrapper(UPDATE_PREVIEW);
  const versionsData = useSimpleFetching<GetAllVersionsQueryDefinition>({
    query: GET_ALL_VERSIONS,
    context: "JTM",
  });

  const urlParams = useParams();
  const containerId = parseInt(urlParams?.container_id!, 10);
  const debugURL = `https://${containerURL}/?jts_preview=${jtsVersion}&jts_debug=${urlParams.id}&jts_log=1`;
  const startDebugSession = () => {
    window.open(debugURL, "_blank");
  };

  const updatePreviewMutation = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "dev") {
      return;
    }

    updatePreview(getUpdatePreviewOptions([containerId], jtsVersion, containerURL, false));
  };

  if (versionsData === false) {
    return <LoadComponentSpinner />;
  }

  const isLatestVersion = () => {
    const filteredVersions = versionsData.deploymentVersions
      .filter((v) => v.container_id === containerId)
      .map((v) => parseInt(v.number, 10));

    return Math.max(...filteredVersions) === jtsVersion;
  };

  return (
    <Page title="Preview Monitor">
      <Stack spacing={2}>
        <Stack direction="row" spacing={5}>
          <Typography variant="h5">
            <span style={{ fontWeight: 800 }}>Session Id: </span>
            {urlParams.id}
          </Typography>
          <Typography variant="h5">
            <span style={{ fontWeight: 800 }}>Version: </span>
            {jtsVersion}
          </Typography>
        </Stack>
        <div>
          <Typography variant="h5">
            <span style={{ fontWeight: 800 }}>Debug URL: </span>
            {debugURL}
          </Typography>
        </div>
        <Stack direction="row" spacing={2}>
          {isLatestVersion() && (
            <Button
              onClick={updatePreviewMutation}
              variant="outlined"
              size="small"
              endIcon={
                <FontAwesomeIcon
                  icon={faRotateRight}
                  size="xs"
                  spin={updatePreviewMutationState.loading}
                />
              }>
              Update Preview
            </Button>
          )}
          <Button
            onClick={startDebugSession}
            variant="contained"
            size="small"
            endIcon={<FontAwesomeIcon icon={faPlay} size="xs" />}>
            Start Debug Session
          </Button>
        </Stack>
      </Stack>
    </Page>
  );
};

export default InformationHeader;
