import ContainersGuideGrid from "apps/setup/components/containers-guide/ContainersGuideGrid";
import Page from "libs/ui/components/surfaces/page/Page";

const Home = (): JSX.Element => {
  return (
    <Page title="Home">
      <ContainersGuideGrid />
    </Page>
  );
};

export default Home;
