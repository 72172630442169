import {
  GetFunctionByIdQueryDefinition,
  GET_ALL_FUNCTIONS,
  GET_FUNCTION_BY_ID,
  GET_FUNCTION_DEFAULT_VALUES,
  UPDATE_FUNCTION,
} from "apps/jtm/business/function/queries";
import { MaintainedProvider } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import { getUpdateOptions } from "libs/business/apollo/updateOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import { FunctionForm } from "../../components/function/FunctionForm";

interface IUpdateFunction {
  id: number;
  short_id: string;
  version?: string;
  name: string;
  desc: string;
  value: string;
  maintained: number;
  pluginID?: string;
}

/**
 * The function component
 * @returns a function component
 */
const Function = (): JSX.Element => {
  const id = useParamsId("id");
  const getFunctionResponse = useSimpleFetching<GetFunctionByIdQueryDefinition>({
    query: GET_FUNCTION_BY_ID,
    context: "JTM",
    queryArguments: { id },
  });
  const [updateFunction, mutationState] = useMutationWrapper(UPDATE_FUNCTION);

  if (getFunctionResponse === false || mutationState.loading) {
    return <PageLoadingSpinner />;
  }

  const formSubmitHandler: SubmitHandler<IUpdateFunction> = (submitData: IUpdateFunction) => {
    updateFunction(getUpdateOptions("Function", "JTM", submitData, GET_ALL_FUNCTIONS));
  };

  const functionData = getFunctionResponse.function;
  const isMaintained = functionData.maintained === 1;

  return (
    <MaintainedProvider
      initialValue={isMaintained}
      elementName="trigger"
      id={functionData.id}
      pluginID={functionData.pluginID}
      formSubmitHandler={formSubmitHandler}
      updateMutationFunction={updateFunction}
      GET_DEFAULT_VALUES={GET_FUNCTION_DEFAULT_VALUES}>
      <FunctionForm data={functionData} mode="update" />
    </MaintainedProvider>
  );
};

export default Function;
