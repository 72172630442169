import { Box } from "@mui/material";
import KeyValueGroup from "../../../KeyValueGroup/KeyValueGroup";
import { VariablesData } from "../../type";

interface VariableTabContentProps {
  variables: VariablesData;
}
/**
 * The VariableTabContent component
 * @param {VariableTabContentProps} props
 * @returns a VariableTabContent component
 */
const VariableTabContent = ({ variables }: VariableTabContentProps): JSX.Element => {
  return (
    <>
      {variables?.frontend?.elements !== undefined && (
        <KeyValueGroup name="Frontend" elements={variables.frontend.elements || []} collapsible />
      )}
      {variables?.backend?.elements !== undefined && (
        <KeyValueGroup name="Backend" elements={variables.backend.elements || []} collapsible />
      )}
      {variables.constant !== undefined && (
        <KeyValueGroup name="Constant" elements={variables.constant.elements || []} collapsible />
      )}
      {variables.enrichment !== undefined && (
        <KeyValueGroup
          name="Enrichment"
          elements={variables.enrichment.elements || []}
          collapsible
        />
      )}
    </>
  );
};

export default VariableTabContent;
