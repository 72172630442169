import { gql } from "@apollo/client";

const CREATE_DEBUG_SESSION = gql`
  mutation createDebugSession($jtsVersion: Int!, $accountUrl: String!) {
    createDebugSession(jtsVersion: $jtsVersion, accountUrl: $accountUrl)
  }
`;

export const DELETE_REQUESTS_BY_SESSION_ID = gql`
  mutation deleteRequetsBySessionId($sessionId: String!) {
    deleteRequetsBySessionId(sessionId: $sessionId)
  }
`;

export default CREATE_DEBUG_SESSION;
