import { ApolloCache, ApolloError, FetchResult } from "@apollo/client";
import { Container } from "@mui/material";
import {
  CodeCreateArguments,
  CreateFrontendCodeMutationDefenition,
  CREATE_FRONTEND_CODE,
  GET_ALL_FRONTEND_CODES,
} from "apps/jtm/business/frontend_code/queries";
import CLIENT from "libs/business/apollo";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import getRoutePath from "libs/business/routes/routes";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FrontendCodeForm } from "../../components/code/FrontendCodeForm";

function Code() {
  const id = useParamsId("id");
  const navigate = useNavigate();
  const [createCode] = useMutationWrapper(CREATE_FRONTEND_CODE);

  const codeVar = {
    id,
    name: "",
    js: "",
    css: "",
    order: 0,
    containerIds: [],
    triggerId: "jts_framework_loaded",
    vendorId: undefined,
  };

  const formSubmitHandler: SubmitHandler<CodeCreateArguments> = (submitData) => {
    createCode({
      variables: submitData,
      context: { clientName: "JTM" },
      onCompleted: (response: any) => {
        const newPath = getRoutePath("jtm", "code_id", { id: response.createFrontendCode.id });
        navigate(newPath, { replace: true });
        notifySuccess("Code created");
      },
      update(cache: ApolloCache<any>, response: FetchResult) {
        if (response.data) {
          const data = CLIENT.readQuery({
            query: GET_ALL_FRONTEND_CODES,
          });
          if (data) {
            CLIENT.writeQuery({
              query: GET_ALL_FRONTEND_CODES,
              data: {
                frontendCodes: [response.data.createFrontendCode, ...data.frontendCodes],
              },
            });
          }
        }
      },
      onError: (error: ApolloError) => {
        notifyError(error.message);
      },
    });
  };

  return (
    <Container>
      <FrontendCodeForm
        formSubmitHandler={formSubmitHandler}
        data={codeVar}
        containerSelection={[]}
      />
    </Container>
  );
}

export default Code;
