import { Box, Grid } from "@mui/material";
import { BackendNexusToolConstantDetails } from "apps/jtm/business/tool/queries";
import ContainerValueSelection from "../../../../libs/ui/components/input/ContainerValueSelection";

interface ConstantGridProps {
  rootInputName: string;
  constants: (BackendNexusToolConstantDetails & { isNew?: boolean })[];
  selectedContainerIds: number[];
  onDelete?: (shortId: string) => void;
}
/**
 * The ConstantGrid component
 * @param {ConstantGridProps} props
 * @returns a ConstantGrid component
 */
const ConstantGrid = ({
  rootInputName,
  constants,
  selectedContainerIds,
  onDelete,
}: ConstantGridProps): JSX.Element => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        {constants.map((constant) => (
          <Grid item xs={6} key={constant.short_id}>
            <ContainerValueSelection
              rootInputName={rootInputName}
              containerEnvironmentValues={constant.value}
              shortPluginId={constant.short_id}
              selectedContainerIds={selectedContainerIds}
              labelText={constant.name}
              onDelete={
                constant.isNew && constant.short_id === constant.name ? onDelete : undefined
              }
              isMandatory={constant.optional !== undefined ? !constant.optional : undefined}
              description={constant.desc}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ConstantGrid;
