import { Container } from "@mui/material";
import {
  COPY_STATE,
  DELETE_STATE,
  GET_ALL_STATES,
  GET_STATE_DEPENDENCIES,
  GetAllStatesQueryDefinition,
} from "apps/jtm/business/state/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import getRoutePath from "libs/business/routes/routes";
import dateFormatterDatagrid from "libs/business/utils/timeTransformation";
import BasicGrid from "libs/ui/components/datagrid/BasicGrid";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";

/**
 * The FunctionGrid component displays a grid containing all functions in the db.
 * @returns a FunctionGrid component
 */
const StateGrid = (): JSX.Element => {
  const getAllStatesResponse = useSimpleFetching<GetAllStatesQueryDefinition>({
    query: GET_ALL_STATES,
    context: "JTM",
  });

  const [deleteFunction, mutationDeleteState] = useMutationWrapper(DELETE_STATE);
  const [copyFunction, mutationcopyState] = useMutationWrapper(COPY_STATE);

  if (getAllStatesResponse === false || mutationcopyState.loading || mutationDeleteState.loading)
    return <PageLoadingSpinner />;

  const fetchedStates = getAllStatesResponse.states;
  const shortIds: string[] = fetchedStates.map((element: any) => element.short_id);

  return (
    <Container>
      <Page
        title="States"
        newButtonConfig={{
          instanceName: "State",
          newInstanceLink: getRoutePath("jtm", "state_new"),
        }}>
        <BasicGrid
          columnConfig={[
            {
              field: "name",
              headerName: "Name",
              minWidth: 250,
              flex: 1,
            },
            {
              field: "create_at",
              headerName: "Created",
              minWidth: 170,
              flex: 1,
              type: "dateTime",
              valueFormatter: dateFormatterDatagrid,
            },
            {
              field: "edit_at",
              headerName: "Edited",
              minWidth: 170,
              flex: 1,
              type: "dateTime",
              valueFormatter: dateFormatterDatagrid,
            },
          ]}
          actionConfig={{
            editLink: {
              service: "jtm",
              routeName: "state_id",
            },
            deleteFunction,
            copy: {
              copyFunction,
              GET_ALL_QUERY: GET_ALL_STATES,
              elementTypes: "states",
              shortIds,
            },
            log: "State",
            settings: {
              editable: true,
              deletable: true,
              copyable: true,
              hasLog: true,
              hasDependencies: true,
            },
            context: "JTM",
            onShowDependencies: {
              query: GET_STATE_DEPENDENCIES,
              context: { clientName: "jtm" },
            },
          }}
          rowsData={fetchedStates}
        />
      </Page>
    </Container>
  );
};

export default StateGrid;
