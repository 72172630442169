import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { AnyObject } from "yup/lib/types";
import { WizardContextType } from "../AssistantWizardContext";

const useCurrentValidationSchema = (
  wizardContext: WizardContextType,
  yupSchemaConfig: OptionalObjectSchema<any, AnyObject, TypeOfShape<any>>[],
) => {
  const [currentValidationSchema, setCurrentValidationSchema] = useState(
    yupSchemaConfig[wizardContext.activeStep - 1] || yup.object(),
  );

  useEffect(() => {
    setCurrentValidationSchema(yupSchemaConfig[wizardContext.activeStep - 1] || yup.object());
  }, [wizardContext, yupSchemaConfig]);

  return currentValidationSchema;
};

export default useCurrentValidationSchema;
