import {
  CREATE_FUNCTION,
  GET_ALL_FUNCTIONS,
  GET_FUNCTION_SHORT_IDS,
} from "apps/jtm/business/function/queries";
import { MaintainedProvider } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import { getCreateOptionsWithShortIDValidation } from "libs/business/apollo/createOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FunctionForm } from "../../components/function/FunctionForm";

interface IUpdateFunction {
  id: number;
  short_id: string;
  version?: string;
  name: string;
  desc: string;
  value: string;
  maintained: number;
  pluginID?: string;
}

/**
 * The function component
 * @returns a function component
 */
const Function = (): JSX.Element => {
  const navigate = useNavigate();
  const [getFunctionShortIds, queryState] = useQueryWrapper(GET_FUNCTION_SHORT_IDS);
  const [createFunction, mutationState] = useMutationWrapper(CREATE_FUNCTION);

  if (!queryState.called) {
    getFunctionShortIds({ context: { clientName: "JTM" } });
  }

  const functionVar = {
    id: 0,
    short_id: "",
    version: "",
    name: "",
    desc: "",
    value: "",
    maintained: 0,
    pluginID: "",
  };

  if (!queryState.data || mutationState.loading) return <PageLoadingSpinner />;

  const formSubmitHandler: SubmitHandler<IUpdateFunction> = (submitData: IUpdateFunction) => {
    createFunction(
      getCreateOptionsWithShortIDValidation(
        "Function",
        "JTM",
        submitData,
        GET_ALL_FUNCTIONS,
        GET_FUNCTION_SHORT_IDS,
        navigate,
      ),
    );
  };

  return (
    <MaintainedProvider
      initialValue={false}
      elementName="function"
      formSubmitHandler={formSubmitHandler}
      shortIds={queryState.data.functionShortIds.shortIds}>
      <FunctionForm data={functionVar} mode="create" />
    </MaintainedProvider>
  );
};

export default Function;
