import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Link, Typography } from "@mui/material";
import UserService from "libs/business/authentication/UserService";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import LoginHeader from "./LoginHeader";

interface IUpdateFunction {
  email: string;
  password: string;
}

const schema = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
});

export default function LoginForm() {
  const methods = useForm<IUpdateFunction>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IUpdateFunction> = async (submitData: IUpdateFunction) => {
    await UserService.login(submitData.email, submitData.password);
  };

  return (
    <FormProvider {...methods} key="loginForm">
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <LoginHeader title="Welcome back" description="Please log in to your account!" />
        <TextFieldWrapper name="email" label="E-mail" value="" />
        <TextFieldWrapper name="password" label="Password" value="" type="password" />
        <Button type="submit" variant="contained" sx={{ mt: 3 }} fullWidth data-cy="submit">
          Login
        </Button>
        <Box sx={{ mt: 2 }}>
          <Link href="/password-forgot" underline="none" align="center" data-cy="password-forgot">
            <Typography>Forgot password?</Typography>
          </Link>
        </Box>
      </form>
    </FormProvider>
  );
}
