import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleButtonGroup, ToggleButton, Button } from "@mui/material";
import { deepCopyObjectWithoutFunction } from "libs/business/utils/objectFunctions";
import React, { useCallback } from "react";
import { RowFilterConfig } from "../types";

interface ButtonFiltersProps {
  filter: RowFilterConfig;
  setFilter: React.Dispatch<React.SetStateAction<RowFilterConfig>>;
  deleteRows: () => void;
}
/**
 * The ButtonFilters component
 * @param {ButtonFiltersProps} props
 * @returns a ButtonFilters component
 */
const ButtonFilters = ({ filter, setFilter, deleteRows }: ButtonFiltersProps): JSX.Element => {
  const onShowInfoChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
      setFilter((currentFilter) => {
        const newFilter: RowFilterConfig = deepCopyObjectWithoutFunction(currentFilter);
        newFilter.showInfo = newAlignment === "info";
        return newFilter;
      });
    },
    [setFilter],
  );

  const onShowErrorChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
      setFilter((currentFilter) => {
        const newFilter: RowFilterConfig = deepCopyObjectWithoutFunction(currentFilter);
        newFilter.showErrorsOnly = newAlignment === "error";
        return newFilter;
      });
    },
    [setFilter],
  );

  const onShowNegativeChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
      setFilter((currentFilter) => {
        const newFilter: RowFilterConfig = deepCopyObjectWithoutFunction(currentFilter);
        newFilter.showNegatives = newAlignment === "negative";
        return newFilter;
      });
    },
    [setFilter],
  );

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        onChange={onShowInfoChange}
        exclusive
        value={filter.showInfo ? "info" : ""}>
        <ToggleButton value="info" color="primary" aria-label="info" sx={{ width: "70px" }}>
          Infos
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        color="primary"
        onChange={onShowNegativeChange}
        exclusive
        value={filter.showNegatives ? "negative" : ""}>
        <ToggleButton
          value="negative"
          color="primary"
          aria-label="negative"
          sx={{ minWidth: "170px" }}>
          Unexecuted Streams
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        color="primary"
        onChange={onShowErrorChange}
        exclusive
        value={filter.showErrorsOnly ? "error" : ""}>
        <ToggleButton value="error" color="primary" aria-label="error" sx={{ width: "100px" }}>
          Errors Only
        </ToggleButton>
      </ToggleButtonGroup>
      <Button
        variant="outlined"
        color="error"
        style={{ minWidth: "130px" }}
        startIcon={<FontAwesomeIcon icon={faTrash} />}
        onClick={deleteRows}>
        Delete Rows
      </Button>
    </>
  );
};

export default ButtonFilters;
