import { Backdrop } from "@mui/material";
import CircularSpinner from "./CircularSpinner";

/**
 * The LoadingSpinner component
 * @param {ILoadingSpinnerProps} props
 * @returns a LoadingSpinner component
 */
const LoadingSpinner = (): JSX.Element => {
  return (
    <Backdrop
      key="backdrop"
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open>
      <CircularSpinner />
    </Backdrop>
  );
};

export default LoadingSpinner;
