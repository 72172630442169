import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

interface ContinueCancelDialogProps {
  open: boolean;
  title: string;
  bodyText: string;
  primaryButtonText?: string;
  onClose: () => void;
  onSubmit: () => void;
}
/**
 * The ContinueCancelDialog component
 * @param {ContinueCancelDialogProps} props
 * @returns a ContinueCancelDialog component
 */
const ContinueCancelDialog = ({
  open,
  title,
  bodyText,
  primaryButtonText,
  onClose,
  onSubmit,
}: ContinueCancelDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={onSubmit}>
          {primaryButtonText || "Continue"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContinueCancelDialog;
