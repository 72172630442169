import { ApolloError } from "@apollo/client";
import { useContainer } from "apps/admin/components/container/ContainerContext";
import {
  DELETE_ASSISTANT,
  DeleteAssistantQueryDefinition,
  GET_ASSISTANT_BY_ID,
  GetAssistantByIdQueryDefinition,
} from "apps/jtm/business/assistant/queries";
import GET_ALL_TAGS from "apps/jtm/business/tag/queries";
import { CREATE_TOOL, GET_ALL_TOOLS, GET_ALL_TOOL_NAMES } from "apps/jtm/business/tool/queries";
import removesEmptyOptionsOfConstants from "apps/jtm/components/shared/preprocess_constant_values";
import { getCreateOptions } from "libs/business/apollo/createOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { notifyError } from "libs/business/notification/notification";
import AssistantWizard from "libs/ui/components/assistant-wizard/AssistantWizard";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { cloneDeep } from "lodash";
import { useNavigate } from "react-router-dom";
import CA_INITIAL_CONTEXT from "./conf/initialContext";
import {
  CA_FORM_SCHEMA,
  CA_INPUT_NAME_CONSTANTS,
  CA_INPUT_NAME_CONTAINER_IDS,
} from "./conf/schema";
import connectorAssistantStepConfig from "./conf/stepConfig";
import { CaFinalSubmitData, CaWizardContextType } from "./conf/types";

/**
 * The ConnectorAssistant component
 * @returns a ConnectorAssistant component
 */
const ConnectorAssistant = (): JSX.Element => {
  const { allContainers } = useContainer();

  const id = useParamsId("id");

  const response = useSimpleFetching<GetAssistantByIdQueryDefinition>({
    query: GET_ASSISTANT_BY_ID,
    queryArguments: { id },
    context: "JTM",
    disableCache: true,
  });

  const [createTool, createToolMutationState] = useMutationWrapper(CREATE_TOOL);

  const [deleteAssistantMutation] =
    useMutationWrapper<DeleteAssistantQueryDefinition>(DELETE_ASSISTANT);

  const deleteAssistant = () => {
    deleteAssistantMutation({
      variables: { id },
      context: { clientName: "JTM" },
      onCompleted: () => {},
      onError: (error: ApolloError) => {
        notifyError(error.message);
      },
    });
  };
  const navigate = useNavigate();

  const onFinalSubmit = (submitData: CaFinalSubmitData) => {
    submitData[CA_INPUT_NAME_CONSTANTS] = removesEmptyOptionsOfConstants(
      submitData[CA_INPUT_NAME_CONSTANTS],
    );
    createTool(
      getCreateOptions("Tool", "JTM", submitData, GET_ALL_TOOLS, navigate, {
        refetchQueriesArg: [
          {
            query: GET_ALL_TAGS,
            context: { clientName: "JTM" },
          },
          {
            query: GET_ALL_TOOL_NAMES,
            context: { clientName: "JTM" },
          },
        ],
        customNavigationPath: "./completed",
        customOnComplete: () => {
          deleteAssistant();
        },
      }),
    );
  };

  if (response === false || createToolMutationState.loading) {
    return <PageLoadingSpinner />;
  }

  const preProcessData = (wizardContext: CaWizardContextType | null) => {
    if (wizardContext === null) {
      return wizardContext;
    }
    const newData = cloneDeep(wizardContext);

    const filterNotExistingIds = (ids: number[]) => {
      return ids.filter((containerId: number) =>
        allContainers.find((container) => container.id === containerId),
      );
    };

    newData.data[CA_INPUT_NAME_CONTAINER_IDS] = filterNotExistingIds(
      (newData.data[CA_INPUT_NAME_CONTAINER_IDS] || []) as number[],
    );
    return newData;
  };

  return (
    <AssistantWizard
      assistantId={id}
      stepConfig={connectorAssistantStepConfig}
      yupSchemaConfig={CA_FORM_SCHEMA}
      fetchedData={preProcessData(response.assistant.data as CaWizardContextType)}
      onFinalSubmit={onFinalSubmit}
      finalSubmitButtonText="Create Tool"
      initialContextData={CA_INITIAL_CONTEXT}
    />
  );
};

export default ConnectorAssistant;
