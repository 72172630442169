import { AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import { Box } from "@mui/system";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import ColoredAccordion from "../../../surfaces/accordion/ColoredAccordion";
import CompareSimplePlaceholder from "./CompareSimplePlaceholder";

interface CompareFilterOnlyProps {
  shortId: string;
  oldArrayListVariables: any;
  newArrayListVariables: any;
}
/**
 * The CompareFilterOnly component
 * @param {CompareFilterOnlyProps} props
 * @returns a CompareFilterOnly component
 */
const CompareFilterOnly = ({
  shortId,
  oldArrayListVariables,
  newArrayListVariables,
}: CompareFilterOnlyProps): JSX.Element => {
  const filterVariableShouldBeRendered =
    oldArrayListVariables?.[shortId]?.filterVariable !== undefined &&
    newArrayListVariables?.[shortId]?.filterVariable !== undefined;

  const filterValueShouldBeRendered =
    oldArrayListVariables?.[shortId]?.filterValue !== undefined &&
    newArrayListVariables?.[shortId]?.filterValue !== undefined;
  return (
    <Box sx={{ mt: 2 }} key={shortId}>
      <ColoredAccordion index={6}>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls={`arrayList-${shortId}`}
          id={`arrayList-${shortId}`}>
          <Typography>{shortId}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {filterVariableShouldBeRendered && (
            <CompareSimplePlaceholder
              inputName={`${shortId}.filterVariable`}
              label="Filter Variable"
              leftValue={oldArrayListVariables?.[shortId]?.filterVariable || ""}
              rightValue={newArrayListVariables?.[shortId]?.filterVariable || ""}
            />
          )}
          {filterValueShouldBeRendered && (
            <CompareSimplePlaceholder
              inputName={`${shortId}.filterValue`}
              label="Filter Value"
              leftValue={oldArrayListVariables?.[shortId]?.filterValue || ""}
              rightValue={newArrayListVariables?.[shortId]?.filterValue || ""}
            />
          )}
        </AccordionDetails>
      </ColoredAccordion>
    </Box>
  );
};

export default CompareFilterOnly;
