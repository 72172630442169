import {
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import {
  useActiveContainer,
  useActiveContainerUpdate,
  useContainer,
} from "apps/admin/components/container/ContainerContext";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colorStyles from "assets/styles/colorPalette.module.scss";
import { useState } from "react";
import SearchBar from "./SearchBar";

/**
 * The ContainerFilter component
 * @returns a ContainerFilter component
 */
const ContainerFilter = (): JSX.Element => {
  const activeContainerIds = useActiveContainer();
  const { allContainers } = useContainer();
  const containerUpdate = useActiveContainerUpdate();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [filteredContainers, setFilteredContainers] = useState(allContainers);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElement(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, containerId: number) => {
    const {
      target: { checked },
    } = event;
    containerUpdate(getUpdatedContainerIds(checked, containerId));
  };

  const getUpdatedContainerIds = (checked: boolean, containerId: number) => {
    let newContainerIds = [...activeContainerIds];
    const isNotInArray = newContainerIds.indexOf(containerId) < 0;

    if (isNotInArray && checked) {
      newContainerIds.push(containerId);
    } else {
      newContainerIds = newContainerIds.filter((id) => containerId !== id);
    }

    return newContainerIds;
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, value: any) => {
    containerUpdate(value ? allContainers.map((c) => c.id) : []);
  };

  return (
    <>
      <Badge
        color="info"
        badgeContent={
          activeContainerIds.length === allContainers.length ? "all" : activeContainerIds.length
        }
        showZero>
        <Button
          variant="outlined"
          onClick={openMenu}
          style={{ borderColor: colorStyles.grey2, color: colorStyles.grey1, background: "white" }}>
          <svg
            style={{ marginRight: 8, marginLeft: -8 }}
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill={colorStyles.grey1}
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_558_393)">
              <path
                fill="inherit"
                d="M7.55556 3.5H10.8889V6.6875H13.1111V3.5H16.4444V10.9375H7.55556V3.5ZM5.33333 13.0625V16.25H7.55556V13.0625H10.8889V20.5H2V13.0625H5.33333ZM16.4444 13.0625V16.25H18.6667V13.0625H22V20.5H13.1111V13.0625H16.4444Z"
              />
            </g>
            <defs>
              <clipPath id="clip0_558_393">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Container Filter
          <FontAwesomeIcon
            icon={anchorElement ? faChevronUp : faChevronDown}
            size="xs"
            style={{ marginLeft: 20, marginRight: -4 }}
          />
        </Button>
      </Badge>
      <Popover
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 42, horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={Boolean(anchorElement)}
        onClose={closeMenu}>
        <div style={{ padding: 16 }}>
          <Stack style={{ marginBottom: 8 }} direction="row" gap={2}>
            <div style={{ minWidth: "220px" }}>
              <SearchBar
                searchParamName="container"
                debouncedOnChange={(search) =>
                  setFilteredContainers(
                    allContainers.filter((container) =>
                      container.name.toLowerCase().includes(search.toLowerCase()),
                    ),
                  )
                }
              />
            </div>
            <Tooltip title="Select/Deselect all">
              <Switch
                defaultChecked={activeContainerIds.length === allContainers.length}
                onChange={handleSwitchChange}
              />
            </Tooltip>
          </Stack>
          <MenuList sx={{ overflow: "auto", maxHeight: "500px", padding: 0 }} dense>
            {filteredContainers.map((container) => (
              <MenuItem key={container.id} sx={{ p: 0 }}>
                <FormControlLabel
                  value={container.name}
                  control={
                    <Checkbox
                      checked={activeContainerIds.indexOf(container.id) > -1}
                      onChange={(e) => handleChange(e, container.id)}
                      name={container.name}
                      inputProps={{
                        "aria-label": container.name,
                      }}
                      sx={{ p: 0, mr: "16px" }}
                    />
                  }
                  sx={{ width: "100%", m: 0, py: 1 }}
                  label={container.name}
                  labelPlacement="end"
                />
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </Popover>
    </>
  );
};

export default ContainerFilter;
