import { Divider, Grid, Typography } from "@mui/material";
import logoBlack from "assets/images/logos/logo_black.png";

interface ILoginHeader {
  title: String;
  description: String;
}

export default function LoginHeader({ title, description }: ILoginHeader) {
  return (
    <>
      <img
        className="img-fluid login-image"
        src={logoBlack}
        alt="JENTIS"
        style={{ height: "30px", marginBottom: "20px" }}
      />
      <Typography variant="h2" component="h2" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
      <Divider />
    </>
  );
}
