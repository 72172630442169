import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";
import { MouseEventHandler } from "react";
import { NavLink } from "react-router-dom";
import classes from "./sidebar.module.scss";
import config from "../../../conf/sidebar_config";
import { IRouteInfo } from "./business";
import IconLink from "./IconLink";

interface IMainNavLinkProps {
  activeRouteInfo: IRouteInfo;
  mainNavIndex: number;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onMouseEnter?: MouseEventHandler<HTMLAnchorElement>;
}
/**
 * The MainNavLink component
 * @param {IMainNavLinkProps} props
 * @returns a MainNavLink component
 */
const MainNavLink = ({
  activeRouteInfo,
  mainNavIndex,
  onClick,
  onMouseEnter,
}: IMainNavLinkProps): JSX.Element => {
  const mainNavElement = config[mainNavIndex];
  const isActive = activeRouteInfo.mainNavIndex === mainNavIndex;

  return (
    <li style={{ whiteSpace: "nowrap" }}>
      <NavLink to={mainNavElement.to} onClick={onClick} onMouseEnter={onMouseEnter}>
        <IconLink icon={mainNavElement.icon} name={mainNavElement.name} isActive={isActive} />
      </NavLink>
    </li>
  );
};

export default MainNavLink;
