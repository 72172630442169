import RouteInterface from "libs/business/routes/RouteInterface";
import MonitorDefaultPage from "../pages/home/MonitorDefaultPage";
import Session from "../pages/session/Session";

export const monitorPrefix = "/app/monitor";

const routes: RouteInterface = {
  home: {
    path: `${monitorPrefix}`,
    element: <MonitorDefaultPage />,
  },
  session: {
    path: `${monitorPrefix}/session/:container_id/:id`,
    element: <Session />,
  },
};

export default routes;
