import { gql } from "@apollo/client";

export const GET_CONSENTS_BY_CONSENTID = gql`
  query ConsentsByID($consentId: String!) {
    ConsentsByID(consentID: $consentId) {
      id
      create_at
      create_by
      edit_at
      edit_by
      consentid
      currentdata_consentdataid
      consentdata {
        id
        update_time
        consent_type
      }
    }
  }
`;

export const GET_VENDOR_CONSENTS = gql`
  query Languages {
    Languages {
      id
      status
      mid
      original
      create_at
      create_by
      edit_at
      edit_by
      iso
      textcontent_textcontentid
      text_content {
        id
        text
      }
    }
  }
`;

export default GET_CONSENTS_BY_CONSENTID;
