import { Avatar, Box } from "@mui/material";
import GET_CATEGORIES from "apps/cmp/business/categories/queries";
import GET_COUNTRIES from "apps/cmp/business/countries/queries";
import GET_JUSTIFICATIONS from "apps/cmp/business/justifications/queries";
import GET_PURPOSES from "apps/cmp/business/purposes/queries";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import SwitchWrapper from "libs/ui/components/input/SwitchWrapper";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import TabContent from "libs/ui/components/surfaces/tabs/TabContent";
import TabWrapper from "libs/ui/components/surfaces/tabs/TabWrapper";
import { IConstantData } from "../constants/ConstantContainerSetting";
import ConstantsTabConfiguration from "../constants/ConstantsTabConfiguration";

interface ICMPInformation {
  no_consent_mode: boolean;
  essential_mode_available: boolean;
  consentbar_configs: IConsentBarConfigs[];
}

interface IConsentBarConfigs {
  id: number;
  name: string;
  image_path: string;
  defaultConfig: IConsentData;
  data: {
    [key: number]: IConsentData;
  };
}

interface IConsentData {
  justification: number | null;
  name: string;
  street: string;
  zip: string;
  country: number | null;
  category: number | null;
  purposes: number[];
  description: string;
}

interface IConsentSettingsProps {
  cmpInformation: ICMPInformation;
  selectedContainerIds: number[];
}

const ConsentSettings = ({
  cmpInformation,
  selectedContainerIds,
}: IConsentSettingsProps): JSX.Element => {
  const [getJustifications, checkGetJustifications] = useQueryWrapper(GET_JUSTIFICATIONS);
  const [getCountries, checkGetCountries] = useQueryWrapper(GET_COUNTRIES);
  const [getCategories, checkGetCategories] = useQueryWrapper(GET_CATEGORIES);
  const [getPurposes, checkGetPurposes] = useQueryWrapper(GET_PURPOSES);
  let justifications: any[] = [];
  let countries: any[] = [];
  let categories: any[] = [];
  let purposes: any[] = [];
  let cmpData: any[] = [];

  if (!checkGetJustifications.called) {
    getJustifications({
      context: { clientName: "CMP" },
    });
  }
  if (!checkGetCountries.called) {
    getCountries({
      context: { clientName: "CMP" },
    });
  }
  if (!checkGetCategories.called) {
    getCategories({
      context: { clientName: "CMP" },
    });
  }
  if (!checkGetPurposes.called) {
    getPurposes({
      context: { clientName: "CMP" },
    });
  }

  if (
    !checkGetJustifications.called ||
    !checkGetCountries.called ||
    !checkGetCategories.called ||
    !checkGetPurposes.called ||
    checkGetJustifications.loading ||
    checkGetCountries.loading ||
    checkGetCategories.loading ||
    checkGetPurposes.loading
  ) {
    return <PageLoadingSpinner />;
  }

  if (
    checkGetJustifications.called &&
    !checkGetJustifications.loading &&
    checkGetJustifications.data
  ) {
    const allJustifications = [];
    for (const justification of checkGetJustifications.data.Justifications) {
      allJustifications.push({
        key: justification.id,
        value: justification.text_content.text,
      });
    }

    justifications = allJustifications;
  }

  if (checkGetCountries.called && !checkGetCountries.loading && checkGetCountries.data) {
    const allCountries = [];
    for (const country of checkGetCountries.data.Countries) {
      allCountries.push({
        key: country.id,
        value: country.text_content.text,
      });
    }

    countries = allCountries;
  }

  if (checkGetCategories.called && !checkGetCategories.loading && checkGetCategories.data) {
    const allCategories = [];
    for (const category of checkGetCategories.data.Categories) {
      allCategories.push({
        key: category.id,
        value: category.text_content.text,
      });
    }

    categories = allCategories;
  }

  if (checkGetPurposes.called && !checkGetPurposes.loading && checkGetPurposes.data) {
    const allPurposes = [];
    for (const purpose of checkGetPurposes.data.Purposes) {
      allPurposes.push({
        id: purpose.id,
        name: purpose.text_content.text,
      });
    }

    purposes = allPurposes;
  }

  cmpData = createCMPData(
    cmpInformation.consentbar_configs[0],
    countries,
    categories,
    justifications,
    purposes,
  );

  return (
    <>
      <Box sx={{ m: 2 }} />
      <AlertBox
        type="info"
        message="Note: Consent preferences can only be set on a vendor level. Changes made here will be effective for all other properties integrated from this vendor."
      />
      {cmpInformation.essential_mode_available && (
        <SwitchWrapper
          name="no_consent_mode"
          label="Essential Mode"
          value={cmpInformation.no_consent_mode}
          labelPlacement="start"
        />
      )}
      <Box sx={{ m: 2 }} />
      <TabWrapper>
        <TabContent
          label="Jentis"
          icon={
            <Avatar
              sx={{ borderRadius: 0 }}
              alt="Jentis"
              src={cmpInformation.consentbar_configs[0].image_path}
            />
          }>
          <Box sx={{ m: 2 }}>
            <ConstantsTabConfiguration
              constantData={cmpData}
              submitName="cmpInformation"
              selectedContainerIds={selectedContainerIds}
              defaultReadOnly
            />
          </Box>
        </TabContent>
      </TabWrapper>
    </>
  );
};

export default ConsentSettings;

const createCMPData = (
  configs: any,
  countries: any[],
  categories: any[],
  justifications: any[],
  allPurposes: any[],
) => {
  const { data, defaultConfig } = configs;
  const displayNames: IConstantData = {
    short_id: "display_name",
    name: "Display Name",
    value: {
      value: defaultConfig.name ?? "",
      type: "input",
      options: [],
    },
  };
  const street: IConstantData = {
    short_id: "street",
    name: "Street",
    value: {
      value: defaultConfig.street ?? "",
      type: "input",
      options: [],
    },
  };
  const zip: IConstantData = {
    short_id: "zip",
    name: "Zip Code",
    value: {
      value: defaultConfig.zip ?? "",
      type: "input",
      options: [],
    },
  };
  const country: IConstantData = {
    short_id: "country",
    name: "Country",
    value: {
      value: defaultConfig.country ?? "",
      type: "select",
      type_options: countries,
      options: [],
    },
  };
  const category: IConstantData = {
    short_id: "category",
    name: "Category",
    value: {
      value: defaultConfig.category ?? "",
      type: "select",
      type_options: categories,
      options: [],
    },
  };
  const justification: IConstantData = {
    short_id: "justification",
    name: "Justification",
    value: {
      value: defaultConfig.justification ?? "",
      type: "select",
      type_options: justifications,
      options: [],
    },
  };
  const purposes: IConstantData = {
    short_id: "purposes",
    name: "Purposes",
    value: {
      value: defaultConfig.purposes ?? "",
      type: "multiselect",
      type_options: allPurposes,
      options: [],
    },
  };
  const description: IConstantData = {
    short_id: "description",
    name: "Description",
    value: {
      value: defaultConfig.description ?? "",
      type: "editor",
      options: [],
    },
  };

  for (const key in data) {
    const containerID = parseInt(key, 10);

    if (displayNames.value.options)
      displayNames.value.options.push({
        account: containerID,
        environment: 1,
        value: data[key].name ?? "",
      });
    if (street.value.options)
      street.value.options.push({
        account: containerID,
        environment: 1,
        value: data[key].street ?? "",
      });
    if (zip.value.options)
      zip.value.options.push({
        account: containerID,
        environment: 1,
        value: data[key].zip ?? "",
      });
    if (country.value.options)
      country.value.options.push({
        account: containerID,
        environment: 1,
        value: data[key].country ?? "",
      });
    if (category.value.options)
      category.value.options.push({
        account: containerID,
        environment: 1,
        value: data[key].category ?? "",
      });
    if (justification.value.options)
      justification.value.options.push({
        account: containerID,
        environment: 1,
        value: data[key].justification ?? "",
      });
    if (purposes.value.options)
      purposes.value.options.push({
        account: containerID,
        environment: 1,
        value: data[key].purposes ?? [],
      });
    if (description.value.options)
      description.value.options.push({
        account: containerID,
        environment: 1,
        value: data[key].description ?? "",
      });
  }

  return [displayNames, street, zip, country, category, justification, purposes, description];
};
