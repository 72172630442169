import { Stack } from "@mui/material";
import colorStyles from "assets/styles/colorPalette.module.scss";
import FieldSelectionInputWrapper from "./FieldSelectionInputWrapper";

interface IFieldSelectionInputNoConsentWrapperProps {
  inputFieldName: string;
  label: string;
  desc: string;
  value: { value: string; noConsentValue: string };
  optional?: boolean;
  displayNoConsent?: boolean;
}
/**
 * The FieldSelectionInputWrapper component
 * @param {IFieldSelectionInputNoConsentWrapperProps} props
 * @returns a FieldSelectionInputWrapper component
 */
const FieldSelectionInputNoConsentWrapper = ({
  inputFieldName,
  label,
  desc,
  value,
  optional = false,
  displayNoConsent = false,
}: IFieldSelectionInputNoConsentWrapperProps): JSX.Element => {
  const backgroundColor = optional ? colorStyles.grey3 : "secondary.light";

  return (
    <Stack direction="row" spacing={2} sx={{ backgroundColor, borderRadius: "4px" }}>
      <FieldSelectionInputWrapper
        inputFieldName={`${inputFieldName}.value`}
        label={label}
        desc={desc}
        value={value.value}
        optional={optional}
        hideDesc={displayNoConsent}
      />
      <FieldSelectionInputWrapper
        inputFieldName={`${inputFieldName}.noConsentValue`}
        label={`${label} (Essential Mode)`}
        desc={desc}
        value={value.noConsentValue}
        optional={optional}
        hideDesc
        hidden={!displayNoConsent}
      />
    </Stack>
  );
};

export default FieldSelectionInputNoConsentWrapper;
