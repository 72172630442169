import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { cmpPrefix } from "apps/cmp/conf/routes";

interface IFormProps {
  consentID: string;
}

const schema = yup.object({
  consentID: yup.string().required(),
});

export default function Consents() {
  const methods = useForm<IFormProps>({ resolver: yupResolver(schema) });
  const navigate = useNavigate();

  const formSubmitHandler: SubmitHandler<IFormProps> = (submitData: IFormProps) => {
    navigate(`${cmpPrefix}/consent/${submitData.consentID}`);
  };

  return (
    <Container>
      <Typography variant="pageTitle">Consent Management</Typography>
      <Paper variant="page">
        <FormProvider {...methods} key="containerForm">
          <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
            <TextFieldWrapper name="consentID" label="Consent ID" />
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
              <Button variant="contained" type="submit">
                Send
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Paper>
    </Container>
  );
}
