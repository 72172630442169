import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridActionsCellItem, GridEnrichedColDef, GridRowParams } from "@mui/x-data-grid";
import LogModal from "apps/jtm/components/log/LogModal";
import dateFormatterDatagrid from "libs/business/utils/timeTransformation";
import getCustomToolBar from "libs/ui/components/datagrid/CustomToolBar";
import { useState } from "react";

interface LogGridProps {
  rowData: any;
}
/**
 * The LogGrid component
 * @returns a LogGrid component
 */
const LogGrid = ({ rowData }: LogGridProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [logId, setLogId] = useState<number | undefined>();
  const [pageSize, setPageSize] = useState(10);

  const getActions = (params: GridRowParams) => {
    if (isShowableLog(params)) {
      return [
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          label="Inspect"
          onClick={() => {
            setLogId(parseInt(params.id.toString(), 10));
            setModalOpen(true);
          }}
        />,
      ];
    }
    return [<div />];
  };

  return (
    <>
      <DataGrid
        initialState={{
          sorting: { sortModel: [{ field: "create_at", sort: "desc" }] },
        }}
        rows={rowData}
        columns={getColumns(getActions)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        disableSelectionOnClick
        // sx={sxForHighlightCriticalRows}
        // getRowClassName={(params) => `${params.row.release_log_classification || ""}`}
        autoHeight
        {...getCustomToolBar({})}
      />
      {logId && <LogModal open={modalOpen} handleClose={() => setModalOpen(false)} id={logId} />}
    </>
  );
};

export default LogGrid;

const getColumns = (actionArrayDOM: any): GridEnrichedColDef[] => {
  return [
    {
      field: "create_at",
      headerName: "Created",
      width: 150,
      type: "dateTime",
      valueFormatter: dateFormatterDatagrid,
    },
    // {
    //   field: "release_log_classification",
    //   headerName: "Update Type",
    //   width: 100,
    //   renderCell: renderUpdateTypeCell,
    //   valueGetter: ({ value }) =>
    //     value === "emergency" || value === "critical" ? "critical, emergency" : "normal",
    // },
    { field: "user", headerName: "User", width: 140 },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "element",
      headerName: "Element",
      width: 160,
    },
    { field: "action", headerName: "Change", width: 120 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: actionArrayDOM,
      hideable: false,
    },
  ];
};

export const noLogActionsFor = ["Version update", "Create", "Delete"];

const isShowableLog = (params: GridRowParams) => !noLogActionsFor.includes(params.row.action);

// const renderUpdateTypeCell = (params: GridRenderCellParams<any>) => {
//   if (
//     params.row.release_log_classification === "emergency" ||
//     params.row.release_log_classification === "critical"
//   )
//     return <Chip label={params.row.release_log_classification} color="warning" size="small" />;
//   return "";
// };
