import { GQLTypeDefinition, ListResponse } from "libs/model/types";
import useSimpleFetching, { FetchingConfig } from "./useSimpleFetching";

interface FetchNotOneOfListConfig extends FetchingConfig<undefined, GQLTypeDefinition["context"]> {
  queryProperty: string;
  queryName: string;
  exclude?: any[];
}

export default function useFetchNotOneOfList({
  query,
  queryName,
  context,
  customOnError,
  disableCache = true,
  queryProperty,
  exclude,
}: FetchNotOneOfListConfig) {
  const response = useSimpleFetching<{
    dataType: any;
    response: ListResponse<string, any>;
    context: GQLTypeDefinition["context"];
  }>({
    query,
    context,
    customOnError,
    disableCache,
  });

  if (response === false) {
    return [];
  }

  if (typeof response[queryName] === undefined) {
    throw new Error("Wrong query Name!");
  }

  const fetchedList = response[queryName];

  return fetchedList
    .map((obj) => obj[queryProperty])
    .filter((element) => !exclude?.includes(element));
}
