import { GET_LOGS_BY_VERSION_ID } from "apps/jtm/business/log/queries";
import LogInformation from "apps/jtm/components/log/LogInformation";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { notifyError } from "libs/business/notification/notification";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";
import { useSearchParams } from "react-router-dom";
import LogGrid from "./LogGrid";

/**
 * The VersionLogGrid component
 * @param {VersionLogGridProps} props
 * @returns a VersionLogGrid component
 */
const VersionLogGrid = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const versionId = searchParams.get("versionId");
  const containerName = searchParams.get("containerName");
  const versionNumber = searchParams.get("versionNumber");
  const editAt = searchParams.get("editAt");

  const logData = useSimpleFetching({
    query: GET_LOGS_BY_VERSION_ID,
    context: "JTM",
    queryArguments: { versionID: versionId ? parseInt(versionId, 10) : null },
  });

  if (logData === false) return <PageLoadingSpinner />;

  if (!versionNumber || !containerName || !editAt) {
    notifyError("No version data provided");
    return <PageLoadingSpinner />;
  }
  return (
    <Page title="Change Log">
      <LogInformation
        versionNumber={parseInt(versionNumber, 10)}
        containerName={containerName}
        editAt={editAt}
      />
      <LogGrid rowData={logData.changeLogs} />
    </Page>
  );
};

export default VersionLogGrid;
