import noAccessImg from "assets/images/custom/no_access.png";
import oopsImg from "assets/images/custom/oops.png";
import img404 from "assets/images/custom/404.png";
import { Stack, Typography, Button } from "@mui/material";

const imageStyle = {
  height: "300px",
};

interface ErrorPageProps {
  description?: string;
  errorMsg?: string;
  variant?: "no-access" | "404" | "oops";
  buttonText: string;
  onButtonClick: () => void;
}
/**
 * The ErrorPage component
 * @param {ErrorPageProps} props
 * @returns a ErrorPage component
 */
const ErrorPage = ({
  description = "Something went wrong!",
  errorMsg = "",
  variant = "oops",
  buttonText,
  onButtonClick,
}: ErrorPageProps): JSX.Element => {
  let image = oopsImg;

  if (variant === "no-access") image = noAccessImg;
  if (variant === "404") image = img404;

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", width: "100%" }}
      spacing={4}>
      <img alt="no_access" src={image} style={imageStyle} />
      <Typography
        variant="h1"
        color="text.disabled"
        sx={{ maxWidth: "600px", textAlign: "center" }}>
        {description}
      </Typography>
      {/* <Typography
        variant="caption"
        color="text.disabled"
        sx={{ maxWidth: "600px", textAlign: "center" }}>
        {errorMsg}
      </Typography> */}
      <Button variant="contained" onClick={onButtonClick}>
        {buttonText}
      </Button>
    </Stack>
  );
};

export default ErrorPage;
