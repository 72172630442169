import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/system";
import { BackendTagDetailData, FrontendTagDetailData } from "../type";

const excludeKeysFromErrors = ["exclude-triggers"];

export const includesError = (key: string, any: any): boolean => {
  if (excludeKeysFromErrors.includes(key)) {
    return false;
  }

  if (Array.isArray(any)) {
    for (const element of any) {
      if (includesError(key, element)) {
        return true;
      }
    }
  }

  if (typeof any === "object" && !Array.isArray(any)) {
    for (const innerKey in any) {
      if (Object.prototype.hasOwnProperty.call(any, innerKey)) {
        const element = any[innerKey];
        if (includesError(innerKey, element)) return true;
      }
    }
  }

  if (typeof any === "string" && key === "extra" && any === "fail") {
    return true;
  }

  return false;
};

export const getTabErrorIcons = <T extends BackendTagDetailData | FrontendTagDetailData>(
  detailData: T,
): { [x in keyof typeof detailData]: JSX.Element | undefined } => {
  const tabErrorIcon = (
    <Box sx={{ color: "error.main" }}>
      <FontAwesomeIcon icon={faCircleXmark} />
    </Box>
  );

  const objKeys = Object.keys(detailData) as (keyof typeof detailData)[];
  type ObjKeys = typeof objKeys[number];
  const iconObject: { [x in ObjKeys]: JSX.Element | undefined } = {} as {
    [x in ObjKeys]: JSX.Element | undefined;
  };

  objKeys.forEach((key) => {
    iconObject[key] = includesError(key as string, detailData[key]) ? tabErrorIcon : undefined;
  });

  return iconObject;
};
