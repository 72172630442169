import { Stack } from "@mui/material";
import { ContainerProvider } from "apps/admin/components/container/ContainerContext";
import { EnvironmentProvider } from "apps/jtm/components/environment/EnvironmentContext";
import colorStyles from "assets/styles/colorPalette.module.scss";
import MyErrorBoundary from "libs/business/error/MyErrorBoundary";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./apps/admin/components/layout/header/Header";
import { HelpProvider } from "./apps/jtm/components/url/HelpContext";

import Sidebar from "./apps/admin/components/layout/sidebar/Sidebar";
import SiteNotFound from "./libs/business/error/SiteNoutFound";

import ADMIN_ROUTES from "./apps/admin/conf/routes";
import CMPRoutes from "./apps/cmp/conf/routes";
import JTMRoutes from "./apps/jtm/conf/routes";
import MonitorRoutes from "./apps/monitor/conf/routes";
import SetupRoutes from "./apps/setup/conf/routes";

const mainContentStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  padding: "30px",
  backgroundColor: colorStyles.backgroundDefault,
  overflow: "auto",
};

const App = (): JSX.Element => {
  const location = useLocation();
  const allRoutes = [
    ...Object.values(ADMIN_ROUTES),
    ...Object.values(JTMRoutes),
    ...Object.values(CMPRoutes),
    ...Object.values(SetupRoutes),
    ...Object.values(MonitorRoutes),
  ];

  return (
    <ContainerProvider>
      <EnvironmentProvider>
        <HelpProvider>
          <Stack sx={{ height: "100vh", overflow: "hidden" }}>
            <Header />
            <Stack direction="row" sx={{ marginTop: "64px", height: "calc(100% - 64px)" }}>
              <Sidebar />
              <main style={mainContentStyle}>
                <MyErrorBoundary innerBoundary key={location}>
                  <Routes>
                    <Route path="*" element={<SiteNotFound />} />
                    {allRoutes.map((route) => (
                      <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                  </Routes>
                </MyErrorBoundary>
              </main>
            </Stack>
          </Stack>
        </HelpProvider>
      </EnvironmentProvider>
    </ContainerProvider>
  );
};

export default App;
