import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { stringifyObjWithBigInts } from "libs/business/utils/objectFunctions";

import style from "./ValueText.module.scss";

interface ValueTextProps {
  text: any;
  success?: boolean;
}

export default function ValueText({ text, success }: ValueTextProps) {
  const preProcessText = (value: any) => {
    if (typeof value === "boolean") {
      return value.toString();
    }
    if (typeof value === "undefined") {
      return "";
    }
    if (value === null || typeof value === "object") {
      if (Array.isArray(value)) return JSON.stringify(value);
      return stringifyObjWithBigInts(value, "\t");
    }

    return value;
  };

  const cleanText = preProcessText(text);

  if (typeof success === "undefined") {
    return (
      <Typography className={style.text} component="pre">
        {cleanText}
      </Typography>
    );
  }

  if (success === true) {
    return (
      <span>
        <FontAwesomeIcon icon={faCircle} className={style.greenLight} />
        <Typography className={style.greenText} component="pre">
          {cleanText}
        </Typography>
      </span>
    );
  }

  return (
    <span>
      <FontAwesomeIcon icon={faCircle} className={style.redLight} />
      <Typography className={style.redText} component="pre">
        {cleanText}
      </Typography>
    </span>
  );
}
