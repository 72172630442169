const generateRandomString = (length: number): string => {
  const pool = "0123456789abcdefghijklmnopqrstuvwxyz";
  let output = "";
  for (let i = 0; i < length; i += 1) {
    output += pool.charAt(Math.floor(Math.random() * pool.length));
  }
  return output;
};

export default generateRandomString;
