import { useState } from "react";
import classes from "./sidebar.module.scss";
import config, { IMainNavElement } from "../../../conf/sidebar_config";
import MainNavLink from "./MainNavLink";
import { IRouteInfo } from "./business";

// const style = {

// };
interface IMainNavProps {
  activeRouteInfo: IRouteInfo;
}

/**
 * The MainNav component
 * @param {IMainNavProps} props
 * @returns a MainNav component
 */
const MainNav = ({ activeRouteInfo }: IMainNavProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const viewClass = open ? classes.open : classes.closed;

  const openNav = () => {
    setOpen(true);
  };
  const closeNav = () => {
    setOpen(false);
  };

  return (
    <nav
      className={`${classes.mainNav} ${viewClass}`}
      onMouseEnter={openNav}
      onFocus={openNav}
      onMouseLeave={closeNav}>
      <ul>
        {config.map((mainNavElement: IMainNavElement, mainNavIndex: number) => {
          return (
            <MainNavLink
              key={mainNavElement.name}
              activeRouteInfo={activeRouteInfo}
              mainNavIndex={mainNavIndex}
              onClick={closeNav}
              onMouseEnter={openNav}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default MainNav;
