import { GET_USER, GetUserQueryDefinition } from "apps/admin/business/user/queries";
import UserServiceInstance from "libs/business/authentication/UserService";
import useParamsId from "libs/business/hooks/useParamId";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import PasswordChange from "../login/PasswordChange";
import UserPermission from "./UserPermission";

export default function UserEdit() {
  const userIdFromParams = useParamsId("id");
  const userIdFromStorage = UserServiceInstance.getUserId();
  const userAccessGroup = UserServiceInstance.getAccessGroup();

  const queryState = useSimpleFetching<GetUserQueryDefinition>({
    query: GET_USER,
    context: "ADMIN",
    queryArguments: { id: userIdFromParams },
  });

  if (queryState === false) return <PageLoadingSpinner />;

  return (
    <>
      <UserPermission config={queryState.user} />
      {userIdFromParams === userIdFromStorage && userAccessGroup !== 1 && <PasswordChange />}
    </>
  );
}
