import { gql } from "@apollo/client";

export const GET_TOKEN = gql`
  query GetToken($email: String!, $password: String!) {
    getToken(email: $email, password: $password) {
      auth
      refresh
      ttlInSeconds
      resetPassword
      accessGroup
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      auth
      refresh
      ttlInSeconds
      resetPassword
      accessGroup
    }
  }
`;

export const REGISTER = gql`
  mutation register($username: String!, $email: String!, $desiredAccessGroup: Int!) {
    register(username: $username, email: $email, desiredAccessGroup: $desiredAccessGroup)
  }
`;

export const PASSWORD_CHANGE = gql`
  mutation ChangePassword(
    $email: String!
    $newPassword: String!
    $token: String
    $oldPassword: String
  ) {
    changePassword(
      email: $email
      newPassword: $newPassword
      token: $token
      oldPassword: $oldPassword
    )
  }
`;

export const PASSWORD_RESET = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export default REGISTER;
