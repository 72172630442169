import dateFormatterDatagrid from "libs/business/utils/timeTransformation";

const defaultColumnProperties = {
  date: {
    width: 140,
    type: "dateTime",
    valueFormatter: dateFormatterDatagrid,
  },
  name: {
    minWidth: 250,
    flex: 1,
  },
};
export default defaultColumnProperties;
