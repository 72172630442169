import { Typography } from "@mui/material";
import ConsentCard, { IConsentCardProps } from "./ConsentCard";
import style from "./ConsentCards.module.scss";

interface IConsentCardsProps {
  consent: IConsentCardProps[];
}

export default function ConsentCards({ consent }: IConsentCardsProps) {
  const noConsents = consent.length === 0;
  const trackedWithoutConsent = consent.length === 1 && consent[0].name === "*";
  if (noConsents || trackedWithoutConsent) {
    return <Typography component="pre">Tracked without consent.</Typography>;
  }

  const cards = consent.map((element) => {
    return (
      <ConsentCard
        key={element.name}
        name={element.name}
        image={element.image}
        status={element.status}
      />
    );
  });
  return <div className={style.list}>{cards}</div>;
}
