import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { Stack } from "@mui/system";

export type CriticalUpdatesObject = {
  [pluginId: string]: {
    name: string;
    criticalLogs: {
      [versionNumber: string]: string;
    };
  };
};

interface SystemUpdatesListProps {
  criticalUpdates: CriticalUpdatesObject;
}

/**
 * The SystemUpdatesList component
 * @param {SystemUpdatesListProps} props
 * @returns a SystemUpdatesList component
 */
const SystemUpdatesList = ({ criticalUpdates }: SystemUpdatesListProps): JSX.Element => {
  const criticalUpdateEntries = Object.entries(criticalUpdates);

  return (
    <>
      {criticalUpdateEntries.map(([pluginId, data]) => {
        return (
          <div>
            <Accordion
              key={pluginId}
              style={{ borderRadius: 0 }}
              sx={{ flex: 1, backgroundColor: "divider", boxShadow: "none" }}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<FontAwesomeIcon icon={faCaretRight} />}
                sx={{
                  minHeight: 35,
                  maxHeight: 35,
                  "&.Mui-expanded": {
                    minHeight: 35,
                    maxHeight: 35,
                  },
                  flexDirection: "row-reverse",
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    marginRight: 2,
                  },
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}>
                <Typography>{data.name}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 4 }}>
                {Object.values(data.criticalLogs).map((log) => (
                  <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                    <Circle sx={{ height: "7px", mt: "7px" }} />
                    <Typography component="pre" sx={{ whiteSpace: "pre-wrap" }}>
                      {log}
                    </Typography>
                  </Stack>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </>
  );
};

export default SystemUpdatesList;
