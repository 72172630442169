import { useContainer } from "apps/admin/components/container/ContainerContext";
import CodeEditor from "../../input/editor/CodeEditor";
import CompareBoolean from "../CompareBoolean";
import CompareMultiSelect from "../CompareMultiSelect";
import CompareSimpleInput from "../CompareSimpleInput";

interface CodeData {
  name?: string;
  js?: string;
  css?: string;
  order?: number;
  before_master?: number;
  container_ids?: number[];
  trigger?: {
    id: number;
    name: string;
  };
}
interface CompareCodeProps {
  old_codeData: CodeData;
  new_codeData: CodeData;
}
/**
 * The CompareCode component
 * @param {CompareCodeProps} props
 * @returns a CompareCode component
 */
const CompareCode = ({
  old_codeData: {
    name: old_name,
    js: old_js,
    css: old_css,
    order: old_order,
    before_master: old_beforeMaster,
    container_ids: old_containerIds,
    trigger: old_trigger,
  },
  new_codeData: {
    name: new_name,
    js: new_js,
    css: new_css,
    order: new_order,
    before_master: new_beforeMaster,
    container_ids: new_containerIds,
    trigger: new_trigger,
  },
}: CompareCodeProps): JSX.Element => {
  const { allContainers } = useContainer();

  const nameShouldBeRendered =
    typeof old_name !== "undefined" && typeof new_name !== "undefined" && old_name !== new_name;

  const triggerShouldBeRendered =
    typeof old_trigger !== "undefined" &&
    typeof new_trigger !== "undefined" &&
    JSON.stringify(old_trigger) !== JSON.stringify(new_trigger);

  const beforeMasterShouldBeRendered =
    typeof old_beforeMaster !== "undefined" &&
    typeof new_beforeMaster !== "undefined" &&
    old_beforeMaster !== new_beforeMaster;

  const orderShouldBeRendered =
    typeof old_order !== "undefined" && typeof new_order !== "undefined" && old_order !== new_order;

  const containerIdsShouldBeRendered =
    typeof old_containerIds !== "undefined" &&
    typeof new_containerIds !== "undefined" &&
    JSON.stringify(old_containerIds) !== JSON.stringify(new_containerIds);

  const jsShouldBeRendered =
    typeof old_js !== "undefined" && typeof new_js !== "undefined" && old_js !== new_js;

  const cssShouldBeRendered =
    typeof old_css !== "undefined" && typeof new_css !== "undefined" && old_css !== new_css;

  return (
    <>
      {nameShouldBeRendered && (
        <CompareSimpleInput
          inputName="name"
          leftLabel="Old Name"
          rightLabel="New Name"
          leftValue={old_name}
          rightValue={new_name}
        />
      )}
      {beforeMasterShouldBeRendered && (
        <CompareBoolean
          inputName="group"
          leftLabel="Old Before Master"
          rightLabel="New Before Master"
          leftValue={!!old_beforeMaster}
          rightValue={!!new_beforeMaster}
        />
      )}
      {orderShouldBeRendered && (
        <CompareSimpleInput
          inputName="scope"
          leftLabel="Old Order"
          rightLabel="New Order"
          leftValue={old_order.toString()}
          rightValue={new_order.toString()}
        />
      )}
      {containerIdsShouldBeRendered && (
        <CompareMultiSelect
          inputName="containers"
          leftLabel="Old Selected Containers"
          rightLabel="New Selected Containers"
          leftValue={allContainers.filter((container) => old_containerIds.includes(container.id))}
          rightValue={allContainers.filter((container) => new_containerIds.includes(container.id))}
        />
      )}
      {jsShouldBeRendered && (
        <CodeEditor
          label="Javascript Code"
          name="js"
          contentOld={decodeURIComponent(old_js)}
          content={decodeURIComponent(new_js)}
          readOnly
        />
      )}
      {triggerShouldBeRendered && (
        <CompareSimpleInput
          inputName="trigger"
          leftLabel="Old Trigger Name"
          rightLabel="New Trigger Name"
          leftValue={old_trigger.name}
          rightValue={new_trigger.name}
        />
      )}
      {cssShouldBeRendered && (
        <CodeEditor
          label="CSS Code"
          name="css"
          contentOld={decodeURIComponent(old_css)}
          content={decodeURIComponent(new_css)}
          language="css"
          readOnly
        />
      )}
    </>
  );
};
export default CompareCode;
