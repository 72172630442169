import { Paper, Typography } from "@mui/material";
import { useWizardData } from "libs/ui/components/surfaces/wizard/WizardDataContext";
import TriggerSelect from "../trigger/TriggerSelect";
import SelectionOverview from "./SelectionOverview";

/**
 * The StepFour component
 * @returns a StepFour component
 */
const StepFour = (): JSX.Element => {
  const wizardData = useWizardData();

  const defaultSelected: number | boolean = (wizardData.tag_types.triggerId as number) || false;
  return (
    <>
      <SelectionOverview
        tagName={wizardData.name}
        toolName={wizardData.tool.name}
        imageUrl={`${wizardData.tool.tool_definition.image_path}medium`}
        templateName={wizardData.tag_types.name}
      />
      <Typography variant="pageTitle" sx={{ mt: 3 }}>
        Trigger Selection
      </Typography>
      <Paper variant="page">
        <TriggerSelect selectedTrigger={defaultSelected} />
      </Paper>
    </>
  );
};

export default StepFour;
