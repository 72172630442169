import { Typography } from "@mui/material";
import style from "./Trigger.module.scss";

export default function TriggerHeader() {
  return (
    <div className={style.triggerHeader}>
      <span />
      <Typography className={style.triggerHeaderElement}>Variable</Typography>
      <Typography className={style.triggerHeaderElement}>Operator</Typography>
      <Typography className={style.triggerHeaderElement}>Condition value</Typography>
      <Typography className={style.triggerHeaderElement}>Variable value</Typography>
      <Typography className={style.triggerHeaderElement}>Executed</Typography>
    </div>
  );
}
