import UserService from "libs/business/authentication/UserService";
import { FC, ReactNode } from "react";

export interface ISwitchStatusProps {
  children: ReactNode;
  allowedAccessGroups: number[];
}

const SwitchStepStatus: FC<ISwitchStatusProps> = ({
  children,
  allowedAccessGroups,
}: ISwitchStatusProps): JSX.Element => {
  const accessGroup = UserService.getAccessGroup();
  const checkAccess = (): boolean => {
    if (allowedAccessGroups.indexOf(accessGroup) === -1) {
      return false;
    }
    return true;
  };
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!Array.isArray(children)) return <>{children}</>;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {checkAccess()
        ? children.find((x: any) => x.props.className === "admin")
        : children.find((x: any) => x.props.className === "user")}
    </>
  );
};

export default SwitchStepStatus;
