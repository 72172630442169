import { Typography } from "@mui/material";
import style from "./Trigger.module.scss";

interface IExcludeTriggerHeaderProps {
  name: string;
}

export default function ExcludeTriggerHeader({ name }: IExcludeTriggerHeaderProps) {
  return (
    <div className={style.excludeTriggerHeader}>
      <Typography className={style.excludeTriggerName}>{name}</Typography>
    </div>
  );
}
