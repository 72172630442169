import { ApolloError } from "@apollo/client";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
  DeleteAssistantQueryDefinition,
  DELETE_ASSISTANT,
  GetAssistantInstancesByTypeQueryDefinition,
} from "apps/jtm/business/assistant/queries";
import colorStyles from "assets/styles/colorPalette.module.scss";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import { dateFormatter } from "libs/business/utils/timeTransformation";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../feedback/modal/ConfirmDialog";

interface AssistantListCardProps {
  assistantData: GetAssistantInstancesByTypeQueryDefinition["dataType"];
  onDeleteComplete: () => void;
}
/**
 * The AssistantListCard component
 * @param {AssistantListCardProps} props
 * @returns a AssistantListCard component
 */
const AssistantListCard = ({
  assistantData,
  onDeleteComplete,
}: AssistantListCardProps): JSX.Element => {
  const navigate = useNavigate();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: `Are you sure you want to delete this assistant instance:`,
    subTitle: `"${assistantData.name}" created at ${dateFormatter(assistantData.create_at)}`,
    onConfirm: () => deleteAssistant(assistantData.id),
  });

  const [deleteAssistantMutation] =
    useMutationWrapper<DeleteAssistantQueryDefinition>(DELETE_ASSISTANT);

  const deleteAssistant = (id: number) => {
    deleteAssistantMutation({
      variables: { id },
      context: { clientName: "JTM" },
      onCompleted: () => {
        onDeleteComplete();
        notifySuccess("Successfully deleted assistant instance!");
      },
      onError: (error: ApolloError) => {
        notifyError(error.message);
      },
    });
  };

  const openConfirmDialog = () => {
    setConfirmDialog((old) => ({ ...old, isOpen: true }));
  };

  return (
    <Paper key={assistantData.id} style={{ background: colorStyles.backgroundDefault }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{ p: 2, cursor: "pointer", flex: 1, overflow: "auto", overflowWrap: "break-word" }}
          onClick={() => navigate(`./${assistantData.id}`)}>
          {assistantData.name}{" "}
          <span
            style={{
              color: colorStyles.grey2,
            }}>{`created at ${dateFormatter(assistantData.create_at)}`}</span>
        </Typography>
        <IconButton sx={{ m: 1 }} onClick={openConfirmDialog}>
          <FontAwesomeIcon icon={faTrashCan} size="xs" />
        </IconButton>
      </Stack>
      <ConfirmDialog
        title={confirmDialog.title}
        isOpen={confirmDialog.isOpen}
        message={confirmDialog.subTitle}
        onConfirm={confirmDialog.onConfirm}
        onCancel={() => {
          setConfirmDialog({ ...confirmDialog, isOpen: false });
        }}
      />
    </Paper>
  );
};

export default AssistantListCard;
