import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Box, Button, Stack, SxProps, TextField, Theme } from "@mui/material";
import { useState } from "react";

interface KeyValue {
  key: string;
  value: any;
}
interface ActionButton {
  name: string;
  icon?: IconDefinition;
  onClick: () => void;
}
interface ListSearchComponentProps {
  placeholderText?: string;
  options: KeyValue[];
  listHeight?: string;
  renderInput: (option: KeyValue) => JSX.Element;
  onChange?: (value: string) => void;
  actionButtonProps?: ActionButton;
  sx?: SxProps<Theme>;
}
/**
 * The ListSearchComponent component
 * @param {ListSearchComponentProps} props
 * @returns a ListSearchComponent component
 */
const ListSearchComponent = ({
  placeholderText,
  options,
  listHeight,
  renderInput,
  onChange,
  actionButtonProps,
  sx,
}: ListSearchComponentProps): JSX.Element => {
  const [searchString, setSearchString] = useState("");
  const filteredOptions = options.filter((option) =>
    option.key.toLowerCase().includes(searchString.toLowerCase()),
  );

  return (
    <Box sx={sx}>
      <Stack direction="row">
        <TextField
          placeholder={placeholderText}
          onChange={(e) => {
            setSearchString(e.target.value);
            if (typeof onChange !== "undefined") onChange(e.target.value);
          }}
          fullWidth
        />
        {actionButtonProps && (
          <Button onClick={actionButtonProps?.onClick} sx={{ minWidth: "max-content" }}>
            {actionButtonProps.name}
          </Button>
        )}
      </Stack>
      <Box sx={{ maxHeight: listHeight || "400px", overflow: "auto" }}>
        {filteredOptions.map((option) => (
          <div key={JSON.stringify(option.value)}>{renderInput(option)}</div>
        ))}
      </Box>
    </Box>
  );
};

export default ListSearchComponent;
