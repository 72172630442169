import { Button, Typography } from "@mui/material";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import TriggerAndConditions from "./TriggerAndConditions";
import { OrConditions } from "./types";

interface TriggerOrConditionsProps {
  conditions: OrConditions;
  readOnly?: boolean;
  nameAppendix?: string;
}
/**
 * The TriggerConditions component
 * @param {TriggerOrConditionsProps} props
 * @param {string} props.nameAppendix needed to use this component more than once on same page (e.g. CompareTriggerConditions)
 * @returns a TriggerConditions component
 */
const TriggerOrConditions = ({
  conditions,
  readOnly = false,
  nameAppendix = "",
}: TriggerOrConditionsProps): JSX.Element => {
  const { setMaintained } = useMaintained();
  const { control } = useFormContext();
  const fieldArrayName = `condition${nameAppendix}`;
  const { fields, insert, replace, remove } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  const addEntry = (index: number) => {
    const newEntry = [
      {
        value: "",
        variable: "",
        operator: "",
      },
    ];
    insert(index, newEntry);
    setMaintained(false);
  };

  const deleteEntryAtIndex = (index: number) => {
    remove(index);
    setMaintained(false);
  };

  useEffect(() => {
    replace(conditions);
  }, [conditions]);

  if (!fields.length && !readOnly) {
    return (
      <Button variant="contained" onClick={() => addEntry(0)} data-cy="addOrCondition">
        Add Condition
      </Button>
    );
  }

  return (
    <div>
      {fields.map((item: any, index) => {
        return (
          <div key={item.id}>
            <TriggerAndConditions
              rootName={`${fieldArrayName}[${index}]`}
              andConditions={conditions[index]}
              addOrCondition={() => addEntry(index + 1)}
              removeOrCondition={() => deleteEntryAtIndex(index)}
              readOnly={readOnly}
            />
            {index !== fields.length - 1 && (
              <Typography
                color="secondary.dark"
                align="center"
                sx={{ p: 3, margin: "2px !important" }}>
                OR
              </Typography>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TriggerOrConditions;
