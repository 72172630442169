import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material/styles";
import CLIENT from "libs/business/apollo";
import MyErrorBoundary from "libs/business/error/MyErrorBoundary";
import theme from "libs/ui/themes";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import NotificationContainer from "libs/business/notification/notification";
import "./index.scss";

import App from "App";
import AuthProvider from "libs/business/authentication/AuthProvider";
import runTrackingCode from "trackingcode";
import reportWebVitals from "./reportWebVitals";

runTrackingCode();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <MyErrorBoundary>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={CLIENT}>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
          <NotificationContainer />
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  </MyErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
