import { AssistantStepFormConfig } from "libs/ui/components/assistant-wizard/types";
import { FieldValues, UseFormReturn } from "react-hook-form";
import ConnectorAssistantStepOne from "../pages/ConnectorAssistantStepOne";
import ConnectorAssistantStepThree from "../pages/ConnectorAssistantStepThree";
import ConnectorAssistantStepTwo from "../pages/ConnectorAssistantStepTwo";
import CA_INITIAL_CONTEXT from "./initialContext";
import {
  CA_INPUT_NAME_CONSTANTS,
  CA_INPUT_NAME_TAG_TYPES_TO_GENERATE,
  CA_INPUT_NAME_TOOL_ID,
} from "./schema";

export const connectorAssistantTitles = ["Container and Tool", "Tool Setup", "Summary"];

const stepTwoReset = (methods: UseFormReturn<FieldValues, object>) => {
  methods.setValue(
    CA_INPUT_NAME_TAG_TYPES_TO_GENERATE,
    CA_INITIAL_CONTEXT.data[CA_INPUT_NAME_TAG_TYPES_TO_GENERATE],
  );

  methods.setValue(CA_INPUT_NAME_CONSTANTS, CA_INITIAL_CONTEXT.data[CA_INPUT_NAME_CONSTANTS]);
};

const connectorAssistantStepConfig: AssistantStepFormConfig[] = [
  {
    title: connectorAssistantTitles[0],
    formInputs: <ConnectorAssistantStepOne />,
  },
  {
    title: connectorAssistantTitles[1],
    formInputs: <ConnectorAssistantStepTwo />,
    stepDependencies: [
      {
        dependentOnStep: 1,
        triggerField: CA_INPUT_NAME_TOOL_ID,
        effect: stepTwoReset,
      },
    ],
  },
  {
    title: connectorAssistantTitles[2],
    formInputs: <ConnectorAssistantStepThree />,
  },
];

export default connectorAssistantStepConfig;
