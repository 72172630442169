import { faInfoCircle, faCircleXmark, faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RowData } from "./type";

interface RowIconProps {
  rowData: RowData;
}
/**
 * The RowIcon component
 * @param {RowIconProps} props
 * @returns a RowIcon component
 */
const RowIcon = ({ rowData }: RowIconProps): JSX.Element => {
  switch (rowData.type) {
    case "info":
      return <FontAwesomeIcon icon={faInfoCircle} />;
    case "error":
      return <FontAwesomeIcon icon={faCircleXmark} />;
    case "tag":
      return (
        <div
          style={{
            margin: 0,
            height: "40px",
            width: "60px",
            backgroundImage: `url(${rowData.image})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      );
    default:
      return <FontAwesomeIcon icon={faCircleQuestion} />;
  }
};

export default RowIcon;
