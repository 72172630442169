import { Stack } from "@mui/material";
import ExcludeTriggerSelect from "apps/jtm/components/trigger/ExludeTriggerSelect";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import MaintainedPage from "apps/jtm/components/shared/maintainedby/MaintainedPage";
import Page from "libs/ui/components/surfaces/page/Page";
import TriggerDetails from "./TriggerDetails";
import TriggerOrConditions from "./TriggerOrConditions";
import { Trigger } from "./types";

interface TriggerFormProps {
  mode: "create" | "update";
  triggerData: Trigger;
}
/**
 * The TriggerForm component
 * @param {TriggerFormProps} props
 * @returns a TriggerForm component
 */
const TriggerForm = ({ mode, triggerData }: TriggerFormProps): JSX.Element => {
  const { maintainedBy } = useMaintained();
  const selectedTrigger = triggerData?.excluded_triggers?.map((element) => element.id) || [];

  return (
    <>
      <MaintainedPage
        title="Trigger Details"
        showMaintainedInfo
        maintainedBy={maintainedBy}
        maintainedTypePlaceholder="trigger">
        <TriggerDetails triggerData={triggerData} mode={mode} />
      </MaintainedPage>
      <Page title="Trigger Conditions" notFirst>
        <TriggerOrConditions conditions={triggerData.condition || []} />
      </Page>
      <Page title="Exclude Trigger" notFirst>
        <Stack>
          <ExcludeTriggerSelect selectedTriggers={selectedTrigger} />
        </Stack>
      </Page>
    </>
  );
};

export default TriggerForm;
