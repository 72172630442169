import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Search } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import useDebounce from "libs/business/hooks/useDebounce";
import useIsFirstRender from "libs/business/hooks/useIsFirstRender";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface SearchBarProps {
  searchParamName?: string;
  debouncedOnChange?: (value: string) => void;
}
/**
 * The SearchInput component
 * @param {SearchBarProps} props
 * @param {string} props.searchParamName the name of search parameter. Default set to "value"
 * @returns a SearchInput component
 */
const SearchBar = ({
  debouncedOnChange,
  searchParamName = "search",
}: SearchBarProps): JSX.Element => {
  const isFirstRender = useIsFirstRender();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get(searchParamName) || "");

  const handleOnChange = () => {
    if (debouncedOnChange) {
      debouncedOnChange(value);
      searchParams.set(searchParamName, value);

      if (!value) searchParams.delete(searchParamName);

      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (value !== "") handleOnChange();
  }, []);

  useDebounce(handleOnChange, 300, [value]);

  return (
    <TextField
      inputRef={(input) => isFirstRender && input && input.focus()}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      placeholder="Search..."
      fullWidth
      sx={{ backgroundColor: "white" }}
      size="small"
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <div style={{ width: 20 }}>
            {value && (
              <IconButton onClick={() => setValue("")}>
                <FontAwesomeIcon icon={faXmark} size="xs" style={{ width: 18 }} />
              </IconButton>
            )}
          </div>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
