import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Paper, Stack } from "@mui/material";
import { useMemo, useReducer, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { AnyObject } from "yup/lib/types";
import { deepCopyObjectWithoutFunction } from "../../../business/utils/objectFunctions";
import {
  AssistantContext,
  AssistantWizardExternalMethods,
  WizardContextType,
  wizardContextReducer,
} from "./AssistantWizardContext";
import AssistantWizardNavigationBar from "./AssistantWizardNavigationBar";
import AssistantWizardProgressBar from "./AssistantWizardProgressBar";
import { notifyIfInputsMissing } from "./devTools";
import useCurrentValidationSchema from "./hooks/useCurrentValidationSchema";
import useRunDependencyEffects from "./hooks/useRunDependencyEffects";
import useUpdateAssistantExternally from "./hooks/useUpdateAssistantExternally";
import { AssistantStepFormConfig } from "./types";

interface AssistantWizardProps<TWizardContextType extends WizardContextType> {
  assistantId: number;
  stepConfig: AssistantStepFormConfig[];
  yupSchemaConfig: OptionalObjectSchema<any, AnyObject, TypeOfShape<any>>[];
  fetchedData: TWizardContextType | null;
  initialContextData: TWizardContextType;
  onFinalSubmit: (data: TWizardContextType["data"]) => void;
  finalSubmitButtonText: string;
}

/**
 * The AssistantWizard component
 * @param {AssistantWizardProps} props
 * @returns a AssistantWizard component
 */
const AssistantWizard = <TWizardContextType extends WizardContextType>({
  assistantId,
  stepConfig,
  yupSchemaConfig,
  fetchedData,
  initialContextData,
  onFinalSubmit,
  finalSubmitButtonText,
}: AssistantWizardProps<TWizardContextType>): JSX.Element | null => {
  // Necessary so that the state will be reset every time a new assistant is opened
  const { current: initAssistantContext } = useRef(
    deepCopyObjectWithoutFunction(initialContextData),
  );
  const [wizardContext, dispatch] = useReducer(
    wizardContextReducer,
    fetchedData || initAssistantContext,
  );

  const navigate = useNavigate();

  const currentValidationSchema = useCurrentValidationSchema(wizardContext, yupSchemaConfig);
  const methods = useForm({ resolver: yupResolver(currentValidationSchema) });

  const { runDependencyEffects } = useRunDependencyEffects(wizardContext, stepConfig, methods);

  const { updateAssistantExternally, updatingAssistantIsLoading } =
    useUpdateAssistantExternally(assistantId);

  const getHookFormValues = () => {
    return methods.getValues();
  };

  const formSubmitHandler = () => {
    const isLastStep = wizardContext.activeStep === stepConfig.length;
    if (!isLastStep) {
      handleNext();
    } else {
      onFinalSubmit(deepCopyObjectWithoutFunction(wizardContext.data));
    }
  };

  const handleNext = () => {
    runDependencyEffects();

    notifyIfInputsMissing(currentValidationSchema, getHookFormValues(), wizardContext.activeStep);

    const updateExternal = (wizardContextData: WizardContextType) =>
      updateAssistantExternally(wizardContextData, {
        onCompleted: () => {
          dispatch({ type: "incrementStep" });
        },
      });

    dispatch({
      type: "next",
      submittedData: getHookFormValues(),
      updateExternal,
    });
  };

  const handlePrev = () => {
    const updateExternal = (wizardContextData: WizardContextType) =>
      updateAssistantExternally(wizardContextData, {
        onCompleted: () => {
          dispatch({ type: "decrementStep" });
        },
      });

    dispatch({
      type: "prev",
      submittedData: getHookFormValues(),
      updateExternal,
    });
  };

  const onPause = () => {
    const updateExternal = (wizardContextData: WizardContextType) =>
      updateAssistantExternally(wizardContextData, {
        onCompleted: () => {
          navigate(`./../..`);
        },
      });

    runDependencyEffects();
    dispatch({ type: "update", submittedData: getHookFormValues(), updateExternal });
  };

  const { formInputs } = stepConfig[wizardContext.activeStep - 1];

  const externalMethods = useMemo(
    () => ({
      goToPrevStep: (step: number) => {
        if (step >= wizardContext.activeStep) return;

        dispatch({
          type: "goToPrevStep",
          step,
          submittedData: getHookFormValues(),
          updateExternal: updateAssistantExternally,
        });
      },
    }),
    [wizardContext.activeStep],
  );

  return (
    <Container style={{ height: "100%", maxHeight: "100%" }}>
      <AssistantContext.Provider value={wizardContext}>
        <AssistantWizardExternalMethods.Provider value={externalMethods}>
          <Stack style={{ height: "100%", maxHeight: "100%" }}>
            <AssistantWizardProgressBar
              stepConfig={stepConfig}
              currentStep={wizardContext.activeStep}
              isLoading={updatingAssistantIsLoading}
            />
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(formSubmitHandler)}
                style={{ height: "100%", maxHeight: "100%", overflow: "auto" }}>
                <Stack justifyContent="space-between" style={{ height: "100%", maxHeight: "100%" }}>
                  {formInputs}
                  <AssistantWizardNavigationBar
                    handlePrev={handlePrev}
                    stepConfig={stepConfig}
                    onPause={onPause}
                    finalSubmitButtonText={finalSubmitButtonText}
                  />
                </Stack>
              </form>
            </FormProvider>
          </Stack>
        </AssistantWizardExternalMethods.Provider>
      </AssistantContext.Provider>
    </Container>
  );
};

export default AssistantWizard;
