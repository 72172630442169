import { Box } from "@mui/material";
import { useContainer } from "apps/admin/components/container/ContainerContext";
import {
  GetContainerStatusByIdQueryDefinition,
  GET_CONTAINER_STATUS_BY_ID,
} from "apps/setup/business/container/queries";
import AdminStepUpdater from "apps/setup/components/steps/adminStepUpdater";
import SwitchStepStatus from "apps/setup/components/steps/SwitchStepStatus";
import TrackingCodeManager from "apps/setup/components/trackingcode/TrackingCodeManager";
import TrackingCodeSpa from "apps/setup/components/trackingcode/TrackingCodeSpa";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import Page from "libs/ui/components/surfaces/page/Page";
import { useParams } from "react-router-dom";

const Trackingcode = (): JSX.Element => {
  const { id } = useParams();
  const { getContainerByID } = useContainer();

  if (typeof id === "undefined") {
    throw new Error("id is not defined as query param");
  }

  const containerId = parseInt(id, 10);
  const container = getContainerByID(containerId);

  if (typeof container === "undefined") {
    throw new Error(`container with id ${id} doesn't exist!`);
  }

  const [getContainerByIdQuery, checkGetContainerByIdQuery] =
    useQueryWrapper<GetContainerStatusByIdQueryDefinition>(GET_CONTAINER_STATUS_BY_ID);

  if (!checkGetContainerByIdQuery.called && container) {
    getContainerByIdQuery({
      context: { clientName: "SETUP" },
      variables: { containerId },
    });
  }

  if (!checkGetContainerByIdQuery.data || container === "LOADING") {
    return <PageLoadingSpinner />;
  }
  return (
    <>
      <Page title="Tracking code">
        <SwitchStepStatus allowedAccessGroups={[1]}>
          <AdminStepUpdater
            className="admin"
            containerId={containerId}
            stepNumber={`2_1_${containerId}`}
            defaultValue={checkGetContainerByIdQuery.data.getContainerStatus.snippet}
            containerName="snippet"
            message="You as SUPERADMIN user can change the status of tracking code. Please make sure that customer really added scripts on their website before pushing the changes."
          />
          <Box className="user" sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <AlertBox
              type="info"
              message={
                "Please implement the following JavaScript code on every page of your website." +
                " We recommend to insert this code as early as possible, at least at the header (<head></head>) of your html structure. " +
                "This JavaScript code needs to be inserted directly (native) on your website - not via Tag-Manager or Injection-Tools"
              }
              title="Important"
              boxStyles={{ display: "flex", flex: "6" }}
            />
            {checkGetContainerByIdQuery.data.getContainerStatus.snippet ? (
              <AlertBox
                type="success"
                message="You have already added scripts on every page of your website"
                title="Success"
                boxStyles={{ display: "flex" }}
              />
            ) : (
              <AlertBox
                type="warning"
                message="Please add this scripts on every page of your website"
                title="Warning"
                boxStyles={{ display: "flex" }}
              />
            )}
          </Box>
        </SwitchStepStatus>
        <TrackingCodeManager container={container} />
      </Page>
      <Box sx={{ m: 2 }} />
      <Page title="[Optional] Single Page Application (SPA)">
        <TrackingCodeSpa />
      </Page>
    </>
  );
};

export default Trackingcode;
