import { gql } from "@apollo/client";
import { GQLTypeDefinition, ListResponse, SingleResponse, SortQueryParams } from "libs/model/types";
import { BackendNexusStateType } from "../state/queries";

type OrConditions = AndConditions[];

type AndConditions = Condition[];
interface Condition {
  variable: string;
  operator: string;
  value: string;
}
export interface BackendNexusTriggerType {
  id: number;
  create_by: number;
  create_at: string;
  edit_by: string;
  edit_at: string;
  short_id: string;
  version: string;
  name: string;
  desc: string;
  condition: OrConditions;
  frontend_trigger_type: "STATE" | "SELECTORACTION";
  selector: string;
  action: string;
  maintained: number;
  pluginID: string;
  excluded_triggers: {
    id: number;
    name: string;
  }[];
  state: BackendNexusStateType;
}

export interface GetTriggerByIdQueryDefinition extends GQLTypeDefinition {
  dataType: Omit<BackendNexusTriggerType, "state"> & Pick<BackendNexusStateType, "id" | "name">;
  response: SingleResponse<"trigger", GetTriggerByIdQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "JTM";
}
export const GET_TRIGGER_BY_ID = gql`
  query trigger($id: Int!) {
    trigger(id: $id) {
      id
      create_by
      create_at
      edit_by
      edit_at
      short_id
      version
      name
      desc
      condition
      state {
        id
        name
      }
      frontend_trigger_type
      selector
      action
      maintained
      pluginID
      excluded_triggers {
        id
        name
      }
    }
  }
`;

export interface GetAllTriggersQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusTriggerType,
    "id" | "short_id" | "desc" | "name" | "create_at" | "edit_at" | "pluginID"
  > & { state: Pick<BackendNexusStateType, "name"> };
  response: ListResponse<"triggers", GetAllTriggersQueryDefinition["dataType"]>;
  arguments?: SortQueryParams;
  context: "JTM";
}

export const GET_ALL_TRIGGERS = gql`
  query triggers($sortParam: String, $sortDirection: String, $withBeforeBundle: Boolean) {
    triggers(
      sortParam: $sortParam
      sortDirection: $sortDirection
      withBeforeBundle: $withBeforeBundle
    ) {
      id
      short_id
      desc
      name
      state {
        name
      }
      create_at
      edit_at
      pluginID
    }
  }
`;

export const GET_TRIGGER_DEFAULT_VALUES = gql`
  query triggerDefaultValues($pluginID: String!) {
    triggerDefaultValues(pluginId: $pluginID) {
      name
      short_id
      desc
      type
      maintained
      frontend_trigger_type
      stateId
      condition
      excluded_triggers
    }
  }
`;

export const COPY_TRIGGER = gql`
  mutation copyTrigger($idToCopy: Int!, $newName: String!) {
    copyTrigger(id_to_copy: $idToCopy, new_name: $newName) {
      id
      short_id
      desc
      name
      state {
        name
      }
      create_at
      edit_at
      pluginID
    }
  }
`;

export const DELETE_TRIGGER = gql`
  mutation deleteTrigger($id: Int!) {
    deleteTrigger(id: $id) {
      ... on Trigger {
        id
        name
      }
      ... on DependenciesOnTrigger {
        dependentCodes {
          id
          name
        }
        dependentTags {
          id
          name
        }
        dependentTriggers {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_TRIGGER = gql`
  mutation updateTrigger(
    $id: Int!
    $name: String!
    $desc: String!
    $excluded_triggers: [Int!]!
    $condition: JSON!
    $frontend_trigger_type: String!
    $maintained: Int!
    $action: String
    $selector: String
    $stateId: Int
  ) {
    updateTrigger(
      id: $id
      name: $name
      desc: $desc
      excluded_triggers: $excluded_triggers
      condition: $condition
      frontend_trigger_type: $frontend_trigger_type
      maintained: $maintained
      action: $action
      selector: $selector
      stateId: $stateId
    ) {
      id
      short_id
      desc
      name
      state {
        name
      }
      create_at
      edit_at
      pluginID
    }
  }
`;

export const CREATE_TRIGGER = gql`
  mutation createTrigger(
    $name: String!
    $desc: String!
    $condition: JSON!
    $frontend_trigger_type: String!
    $excluded_triggers: [Int!]!
    $stateId: Int
    $selector: String
    $action: String
  ) {
    createTrigger(
      name: $name
      desc: $desc
      condition: $condition
      frontend_trigger_type: $frontend_trigger_type
      excluded_triggers: $excluded_triggers
      stateId: $stateId
      selector: $selector
      action: $action
    ) {
      id
      short_id
      desc
      name
      state {
        name
      }
      create_at
      edit_at
      pluginID
    }
  }
`;

export const GET_TRIGGER_SHORT_IDS = gql`
  query triggerShortIds {
    triggerShortIds {
      shortIds
    }
  }
`;

export const GET_TRIGGER_DEPENDENCIES = gql`
  query getTriggerDependencies($id: Int!) {
    getTriggerDependencies(id: $id) {
      dependentTriggers {
        id
        name
      }
      dependentTags {
        id
        name
      }
      dependentCodes {
        id
        name
      }
    }
  }
`;

export default GET_TRIGGER_BY_ID;
