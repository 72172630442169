// /* eslint-disable react/require-default-props */
import { Switch, FormControlLabel, SxProps } from "@mui/material";
import React, { CSSProperties, useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";
import { prototype } from "stream";
import { boolean } from "yup";

interface Props {
  name: string;
  label: string;
  value?: boolean;
  hidden?: boolean;
  color?: "primary" | "error" | "default" | "secondary" | "success";
  disabled?: boolean;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  onChange?: (newValue: boolean | number) => void;
  asNumber?: boolean;
  style?: CSSProperties;
}
/**
 * The InputWrapper component wraps a Mui Switch field with a react-form-hook controller to use it in context.
 * @param {Props} props
 * @returns a InputWrapper component
 */
const SwitchWrapper: React.FC<Props> = ({
  name,
  label,
  value = false,
  hidden = false,
  color = "default",
  disabled = false,
  labelPlacement = "end",
  onChange,
  asNumber = false,
  style,
}: Props) => {
  const { control } = useFormContext();

  const { field } = useController({
    control,
    name,
    defaultValue: value,
  });

  const [checkedState, setCheckedState] = React.useState(value);
  const [colorState, setColorState] = React.useState(color);

  const changeValue = (checked: boolean): void => {
    let newValue: boolean | number = checked;
    if (asNumber) {
      newValue = checked ? 1 : 0;
    }
    setCheckedState(checked);
    setHookFormValue(newValue);
    setUiElementValue(checked);
    runCustomOnChangeFunction(checked);
  };

  const setUiElementValue = (checked: boolean): void => {
    setCheckedState(checked);
    if (checked) {
      setColorState("primary");
    } else {
      setColorState("default");
    }
  };

  const setHookFormValue = (checked: boolean | number) => {
    field.onChange(checked);
  };

  const runCustomOnChangeFunction = (checked: boolean) => {
    if (typeof onChange !== "undefined") onChange(checked);
  };

  // for changes not made by a manual input
  useEffect(() => {
    changeValue(value);
  }, [value]);

  return (
    // the hidden attribute is not working with Switch in combination with react-hook-form
    <div style={{ display: hidden ? "none" : "" }}>
      <FormControlLabel
        sx={{ m: "16px 0px 8px 0px" }}
        style={style}
        value={value}
        control={
          <Switch
            color={colorState}
            checked={checkedState}
            onChange={(e) => changeValue(e.target.checked)}
            disabled={disabled}
            size="medium"
            inputProps={{ "data-cy": name } as any}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        data-cy={`${name}-label`}
      />
    </div>
  );
};

export default SwitchWrapper;
