import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
  GetToolDefinitionByPluginIdQueryDefinition,
  GET_TOOL_DEFINITION_BY_PLUGINID,
} from "apps/jtm/business/tool/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { notifyError } from "libs/business/notification/notification";
import md5 from "md5";
import AssistantValueSummary from "./AssistantValueSummary";

interface AssistantConnectorSummaryProps {
  pluginID: string;
}
/**
 * The AssistantConnectorSummary component
 * @param {AssistantConnectorSummaryProps} props
 * @returns a AssistantConnectorSummary component
 */
const AssistantConnectorSummary = ({ pluginID }: AssistantConnectorSummaryProps): JSX.Element => {
  const getToolDefinitionResponse = useSimpleFetching<GetToolDefinitionByPluginIdQueryDefinition>({
    query: GET_TOOL_DEFINITION_BY_PLUGINID,
    context: "JTM",
    queryArguments: { pluginID },
    customOnError: (e) => {
      notifyError(e);
    },
  });

  let imagePath = `https://pixelstudio.dev.jentis.com/api/images/${md5(pluginID)}/small`;
  let connectorName = pluginID;

  if (getToolDefinitionResponse) {
    imagePath = `${getToolDefinitionResponse.toolDefinition.image_path}small`;
    connectorName = getToolDefinitionResponse.toolDefinition.name;
  }

  return (
    <AssistantValueSummary
      label="Tool"
      value={
        <Stack direction="row" alignItems="center">
          <img style={{ height: "25px", marginRight: "16px" }} src={imagePath} alt="Logo" />
          <Typography variant="bodyMedium16">{connectorName}</Typography>
        </Stack>
      }
    />
  );
};

export default AssistantConnectorSummary;
