import { gql } from "@apollo/client";
import { GQLTypeDefinition, ListResponse, SingleResponse } from "libs/model/types";
import { BackendNexusTriggerType } from "../trigger/queries";

export interface BackendFrontendCodeType {
  id: number;
  createAt: string;
  createBy: number;
  editAt: string;
  editBy: number;
  name: string;
  js: string;
  css: string;
  order: number;
  containerIds: number[];
  triggerId: number;
  trigger: BackendNexusTriggerType;
  // vendorId: number;
  // vendor: any; // TODO change to vendor type after vendor epic is merged
}

export interface CodeCreateArguments {
  id: number;
  name: string;
  js: string;
  containerIds: number[];
  order?: number;
  css?: string;
  triggerId?: number;
  // vendorId?: number;
}

export interface CodeUpdateArguments extends CodeCreateArguments {
  id: number;
}

export interface GetFrontendCodeByIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendFrontendCodeType,
    "id" | "createAt" | "editAt" | "name" | "js" | "css" | "order" | "containerIds" | "triggerId"
  >;
  response: SingleResponse<"frontendCode", GetFrontendCodeByIdQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "JTM";
}

export const GET_FRONTEND_CODE_BY_ID = gql`
  query frontendCode($id: Int!) {
    frontendCode(id: $id) {
      id
      createAt
      editAt
      name
      js
      css
      order
      containerIds
      triggerId
    }
  }
`;

export interface GetAllFrontendCodesQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendFrontendCodeType,
    "id" | "name" | "createAt" | "editAt" | "containerIds" | "triggerId"
  > & {
    trigger: Pick<BackendNexusTriggerType, "id" | "name">;
  };
  response: ListResponse<"frontendCodes", GetAllFrontendCodesQueryDefinition["dataType"]>;
  context: "JTM";
}

export const GET_ALL_FRONTEND_CODES = gql`
  query frontendCodes {
    frontendCodes {
      id
      name
      createAt
      editAt
      containerIds
      triggerId
      trigger {
        id
        name
      }
    }
  }
`;

export interface GetAllFrontendCodeNamesQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendFrontendCodeType, "name">;
  response: ListResponse<"frontendCodes", GetAllFrontendCodeNamesQueryDefinition["dataType"]>;
  context: "JTM";
}

export const GET_ALL_FRONTEND_CODE_NAMES = gql`
  query frontendCodes {
    frontendCodes {
      name
    }
  }
`;

export interface DeleteFrontendCodeMutationDefenition extends GQLTypeDefinition {
  dataType: Pick<BackendFrontendCodeType, "id" | "name" | "createAt" | "editAt">;
  response: SingleResponse<"deleteFrontendCode", DeleteFrontendCodeMutationDefenition["dataType"]>;
  arguments: { id: number };
  context: "JTM";
}

export const DELETE_FRONTEND_CODE = gql`
  mutation deleteFrontendCode($id: Int!) {
    deleteFrontendCode(id: $id) {
      id
      name
      createAt
      editAt
    }
  }
`;

export interface UpdateFrontendCodeMutationDefenition extends GQLTypeDefinition {
  dataType: Pick<
    BackendFrontendCodeType,
    "id" | "createAt" | "editAt" | "name" | "js" | "css" | "order" | "containerIds" | "triggerId"
  > & {
    trigger: Pick<BackendNexusTriggerType, "id" | "name">;
  };
  response: SingleResponse<"updateFrontendCode", UpdateFrontendCodeMutationDefenition["dataType"]>;
  arguments: CodeUpdateArguments;
  context: "JTM";
}

export const UPDATE_FRONTEND_CODE = gql`
  mutation updateFrontendCode(
    $id: Int!
    $name: String!
    $js: String!
    $containerIds: [Int!]!
    $order: Int
    $css: String
    $triggerId: Int
  ) {
    updateFrontendCode(
      id: $id
      name: $name
      js: $js
      containerIds: $containerIds
      order: $order
      css: $css
      triggerId: $triggerId
    ) {
      id
      createAt
      editAt
      name
      js
      css
      order
      containerIds
      triggerId
      trigger {
        id
        name
      }
    }
  }
`;

export interface CreateFrontendCodeMutationDefenition extends GQLTypeDefinition {
  dataType: Pick<
    BackendFrontendCodeType,
    "id" | "createAt" | "editAt" | "name" | "js" | "css" | "order" | "containerIds" | "triggerId"
  > & {
    trigger: Pick<BackendNexusTriggerType, "id" | "name">;
  };
  response: SingleResponse<"createFrontendCode", CreateFrontendCodeMutationDefenition["dataType"]>;
  arguments: CodeCreateArguments;
  context: "JTM";
}

export const CREATE_FRONTEND_CODE = gql`
  mutation createFrontendCode(
    $name: String!
    $js: String!
    $containerIds: [Int!]!
    $order: Int
    $css: String
    $triggerId: Int
  ) {
    createFrontendCode(
      name: $name
      js: $js
      containerIds: $containerIds
      order: $order
      css: $css
      triggerId: $triggerId
    ) {
      id
      name
      createAt
      editAt
      js
      css
      order
      containerIds
      triggerId
      trigger {
        id
        name
      }
    }
  }
`;

export default GET_FRONTEND_CODE_BY_ID;
