import { gql } from "@apollo/client";

const UPDATE_SETUP_STEP = gql`
  mutation updateSetupStep($setupStepNumber: String!, $done: Boolean!) {
    updateSetupStep(setupStepNumber: $setupStepNumber, done: $done) {
      containerId
      dns
      snippet
    }
  }
`;

export default UPDATE_SETUP_STEP;
