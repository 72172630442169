import { Grid, Stack } from "@mui/material";
import KeyValueGroup from "../../../KeyValueGroup/KeyValueGroup";
import { BackendTagDetailData } from "../../type";

interface HttpTabProps {
  detailData: BackendTagDetailData;
}
/**
 * The HttpTabContent component
 * @param {HttpTabContentProps} props
 * @returns a HttpTabContent component
 */
const HttpTabContent = ({ detailData }: HttpTabProps): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Stack spacing={3}>
          <KeyValueGroup name="General" elements={detailData.http?.general?.elements || []} />
          <KeyValueGroup
            name="Request Headers"
            elements={detailData.http?.request_headers?.elements || []}
          />
          <KeyValueGroup
            name="Response Headers"
            elements={detailData.http?.response_headers?.elements || []}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <KeyValueGroup
          name="Query Parameters"
          elements={detailData.http?.query_string_parameters?.elements || []}
        />
      </Grid>
    </Grid>
  );
};

export default HttpTabContent;
