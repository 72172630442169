import CompareMultiSelect from "libs/ui/components/compareview/CompareMultiSelect";

interface CompareAccountProps {
  message: any;
}
/**
 * The CompareAccount component
 * @param {CompareAccountProps} props
 * @returns a CompareAccount component
 */
const CompareAccount = ({ message }: CompareAccountProps): JSX.Element => {
  return (
    <CompareMultiSelect
      inputName="accounts"
      leftLabel="Removed Accounts"
      rightLabel="Added Accounts"
      leftValue={message.old.map((name: string, index: number) => {
        return { name, id: index };
      })}
      rightValue={message.new.map((name: string, index: number) => {
        return { name, id: index };
      })}
      hideArrow
    />
  );
};

export default CompareAccount;
