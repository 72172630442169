import { useLocalStorage } from "libs/business/hooks/useStorage";
import React, { ReactNode, useCallback, useContext } from "react";

const WizardDataContext = React.createContext<any>({});
const WizardDataContextUpdate = React.createContext<(obj: any) => void>(() => {});

export function useWizardData() {
  return useContext(WizardDataContext);
}

export function useWizardDataUpdate() {
  return useContext(WizardDataContextUpdate);
}

interface IWizardDataProviderProps {
  children: ReactNode;
  localStorageDataName: string;
}
export const WizardDataProvider = ({
  children,
  localStorageDataName,
}: IWizardDataProviderProps) => {
  const [data, setData] = useLocalStorage<any>(localStorageDataName, {});

  return (
    <WizardDataContext.Provider value={data}>
      <WizardDataContextUpdate.Provider value={useCallback(setData, [])}>
        {children}
      </WizardDataContextUpdate.Provider>
    </WizardDataContext.Provider>
  );
};

export default WizardDataContext;
