import ValueText from "../ValueText/ValueText";
import style from "./Trigger.module.scss";

interface IConditionLineProps {
  variable: string;
  operator: string;
  conditionValue: string;
  variableValue: string;
  executed: boolean;
}

export default function ConditionLine({
  variable,
  operator,
  conditionValue,
  variableValue,
  executed,
}: IConditionLineProps) {
  return (
    <div className={style.conditionLine}>
      <ValueText text={variable} />
      <ValueText text={operator} />
      <ValueText text={conditionValue} />
      <ValueText text={variableValue} />
      <ValueText text={executed} success={executed} />
    </div>
  );
}
