import { faAngleRight, faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import {
  parseStringWithBigInts,
  stringifyObjWithBigInts,
} from "libs/business/utils/objectFunctions";
import { useState } from "react";
import KeyValueRow from "../KeyValueRow/KeyValueRow";
import style from "./KeyValueGroup.module.scss";

interface IKeyValueGroupProps {
  name: string;
  elements: any[];
  collapsible?: boolean;
}

export interface IElementGroup {
  name: string;
  elements: any;
}

export default function KeyValueGroup({
  name,
  elements,
  collapsible = false,
}: IKeyValueGroupProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleGroup = () => {
    setIsOpen((current: boolean) => !current);
  };

  let elementsDOM;

  const beautifyStringIfIsObject = (str: string) => {
    try {
      const obj = parseStringWithBigInts(str);

      if (typeof obj === "object") {
        return stringifyObjWithBigInts(obj, "\t");
      }

      return str;
    } catch (e) {
      return str;
    }
  };

  if (typeof elements !== "undefined" && elements.length > 0) {
    elementsDOM = elements.map((element: any) => {
      if (typeof element.elements === "undefined") {
        if (typeof element === "string") {
          return <Typography component="pre">{beautifyStringIfIsObject(element)}</Typography>;
        }
        let success: boolean | undefined = element.extra === "success";
        if (!success && (element.extra === "" || element.extra === undefined)) success = undefined;

        let value: string;

        if (typeof element.value === "object" || typeof element.value === "boolean") {
          value = stringifyObjWithBigInts(element.value, "\t");
        } else {
          value = beautifyStringIfIsObject(element.value);
        }

        return (
          <KeyValueRow
            key={`${name}-${element.key}`}
            title={element.key}
            value={value}
            success={success}
          />
        );
      }
      return (
        <KeyValueGroup
          key={element.name}
          name={element.name}
          elements={element.elements}
          collapsible
        />
      );
    });
  } else {
    elementsDOM = <Typography component="pre">No data</Typography>;
  }

  if (collapsible) {
    return (
      <Box sx={{ p: 1 }}>
        <Button onClick={() => toggleGroup()} className={style.button}>
          <Typography className={style.key}>
            {name}
            <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleRight} className={style.icon} />
          </Typography>
        </Button>
        {isOpen ? <div className={style.list}>{elementsDOM}</div> : ""}
      </Box>
    );
  }

  return (
    <div>
      <Typography className={style.title}>{name}</Typography>
      <div className={style.list}>{elementsDOM}</div>
    </div>
  );
}
