// eslint-disable-next-line import/prefer-default-export
export const filterDataOnContainer = <T extends Record<string, any>>(
  data: T[],
  containerIDs: number[],
  dataContainerProperty: string = "containerIDs",
) => {
  const filteredData = data.filter((entry) => {
    const notAssignedToContainer = entry[dataContainerProperty].length === 0;
    if (notAssignedToContainer && containerIDs.length === 0) {
      return true;
    }

    const intersection = entry[dataContainerProperty].filter((containerID: number) => {
      return containerIDs.includes(containerID);
    });
    return intersection.length > 0;
  });
  return filteredData;
};
