import CompareSimpleInput from "libs/ui/components/compareview/CompareSimpleInput";

interface CompareNameProps {
  message: any;
}
/**
 * The CompareName component
 * @param {CompareNameProps} props
 * @returns a CompareName component
 */
const CompareName = ({ message }: CompareNameProps): JSX.Element => {
  return (
    <CompareSimpleInput
      inputName="name"
      leftLabel="Old Name"
      rightLabel="New Name"
      leftValue={message.old.toString()}
      rightValue={message.new.toString()}
    />
  );
};

export default CompareName;
