import { Typography } from "@mui/material";
import { notifyError } from "libs/business/notification/notification";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import LoginScreen from "./LoginScreen";
import PasswordChangeForm from "./PasswordChangeForm";

export default function PasswordChange() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const isPasswordReset = email && token;

  if (!isPasswordReset) {
    notifyError("Token is missing!");

    return (
      <LoginScreen>
        <Typography color="error">An error occurred: Token is missing!</Typography>
      </LoginScreen>
    );
  }
  return (
    <LoginScreen>
      <PasswordChangeForm />
    </LoginScreen>
  );
}
