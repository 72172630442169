import { FieldValues } from "react-hook-form";
import { OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { AnyObject } from "yup/lib/types";

// eslint-disable-next-line import/prefer-default-export
export const notifyIfInputsMissing = (
  validationSchema: OptionalObjectSchema<any, AnyObject, TypeOfShape<any>>,
  hookFormValues: FieldValues,
  step: number,
) => {
  const missingInputFields = Object.keys(validationSchema.fields).filter(
    (fieldName) => !Object.keys(hookFormValues).includes(fieldName),
  );

  if (missingInputFields.length > 0) {
    // eslint-disable-next-line no-console
    console.warn(
      `The following input fields are missing for step ${step}: ${missingInputFields.join(", ")}`,
    );
  }
};
