import config, {
  IGroupNavElement,
  IMainNavElement,
  ISubNavElement,
} from "../../../conf/sidebar_config";

const getLongestMatchedRouteInfo = (pathname: string): IRouteInfo => {
  const allRoutes = getAllRoutesInfo();

  const routeSubStringsMatches = allRoutes.filter((routeInfo) => {
    return pathname.includes(routeInfo.pathname);
  });

  let longestRoute = createRouteInfo("", 0, -1, -1);

  routeSubStringsMatches.forEach((routeInfo) => {
    if (routeInfo.pathname.length > longestRoute.pathname.length) {
      longestRoute = routeInfo;
    }
  });

  return longestRoute;
};

export default getLongestMatchedRouteInfo;

export interface IRouteInfo {
  pathname: string;
  mainNavIndex: number;
  groupNavIndex?: number;
  subNavIndex?: number;
}

const getAllRoutesInfo = (): IRouteInfo[] => {
  let allRoutes: IRouteInfo[] = [];

  config.forEach((mainNavElement: IMainNavElement, mainNavIndex: number) => {
    allRoutes.push(createRouteInfo(mainNavElement.to, mainNavIndex, -1, -1));

    if (mainNavElement.subNav !== undefined) {
      const allSubNavRoutes = getAllRoutesFromSubNav(mainNavElement.subNav, mainNavIndex);
      allRoutes = allRoutes.concat(allSubNavRoutes);
    }
  });

  return allRoutes;
};

const getAllRoutesFromSubNav = (groupNavElement: IGroupNavElement[], mainNavIndex: number) => {
  const allSubNavRoutes: IRouteInfo[] = [];
  groupNavElement.forEach((innerNavGroup, groupNavIndex) => {
    innerNavGroup.elements.forEach((innerNavElement: ISubNavElement, subNavIndex: number) => {
      allSubNavRoutes.push(
        createRouteInfo(innerNavElement.to, mainNavIndex, groupNavIndex, subNavIndex),
      );
    });
  });
  return allSubNavRoutes;
};

const createRouteInfo = (
  pathname: string,
  mainNavIndex: number,
  groupNavIndex: number,
  subNavIndex: number,
): IRouteInfo => ({
  pathname,
  mainNavIndex,
  groupNavIndex,
  subNavIndex,
});
