import React, { ReactNode, useCallback, useContext, useState } from "react";

const NoConsentActive = React.createContext<boolean>(false);
const NoConsentActiveUpdate = React.createContext<(value: boolean) => void>(() => {});

export function useNoConsentActive() {
  return useContext(NoConsentActive);
}

export function useNoConsentActiveUpdate() {
  return useContext(NoConsentActiveUpdate);
}

interface NoConsentActiveProviderProps {
  children: ReactNode;
}
export const NoConsentActiveProvider = ({ children }: NoConsentActiveProviderProps) => {
  const [data, setData] = useState<boolean>(false);

  return (
    <NoConsentActive.Provider value={data}>
      <NoConsentActiveUpdate.Provider value={useCallback(setData, [])}>
        {children}
      </NoConsentActiveUpdate.Provider>
    </NoConsentActive.Provider>
  );
};

export default NoConsentActive;
