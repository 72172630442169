import { Button, Paper, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useAssistantWizardExternalMethods } from "../AssistantWizardContext";

interface AssistantSummaryCardProps {
  children: ReactNode;
  step: number;
}
/**
 * The AssistantSummaryCard component
 * @param {AssistantSummaryCardProps} props
 * @returns a AssistantSummaryCard component
 */
const AssistantSummaryCard = ({ children, step }: AssistantSummaryCardProps): JSX.Element => {
  const methods = useAssistantWizardExternalMethods();
  return (
    <Paper variant="page">
      <Stack direction="row" alignItems="baseline" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="bodyMedium16">{`Overview of Step ${step}`}</Typography>
        <Button
          size="small"
          onClick={() => methods.goToPrevStep(step)}>{`Edit step ${step}`}</Button>
      </Stack>
      {children}
    </Paper>
  );
};

export default AssistantSummaryCard;
