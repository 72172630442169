import { MaintainedProvider } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import { filterEmptyOptions } from "apps/jtm/components/shared/preprocess_constant_values";
import { useHelpContext } from "apps/jtm/components/url/HelpContext";
import {
  GetAllVariablesDocument,
  GetVariableByIdDocument,
  GetVariableDefaultValuesDocument,
  UpdateVariableDocument,
} from "gql/autogeneratedTypes";
import { getUpdateOptions } from "libs/business/apollo/updateOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import useTypedDocumentQueryWrapper from "libs/business/hooks/useTypedDocumentQueryWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { useEffect } from "react";
import { VariableForm } from "../../components/variable/VariableForm";

/**
 * The variable component
 * @returns a variable component
 */
function Variable() {
  const id = useParamsId("id");

  const getVariableResponse = useTypedDocumentQueryWrapper(GetVariableByIdDocument, {
    clientName: "JTM",
    variables: { id },
  });

  const [updateVariable, mutationState] = useMutationWrapper(UpdateVariableDocument);
  const { setHelpParams } = useHelpContext();

  useEffect(() => {
    if (getVariableResponse?.variable) {
      switch (getVariableResponse.variable.source) {
        case "FRONTENDJS":
          setHelpParams("frontend");
          break;
        case "BACKENDJS":
          setHelpParams("backend");
          break;
        case "ACCOUNTCONSTANT":
          setHelpParams("constant");
          break;
        default:
          break;
      }
    }
  }, [getVariableResponse]);

  if (!getVariableResponse || mutationState.loading) {
    return <PageLoadingSpinner />;
  }

  if (!getVariableResponse.variable) throw new Error("Received empty data");

  const variableData = getVariableResponse.variable;
  let isMaintained = variableData.maintained === 1;

  if (variableData.source === "ACCOUNTCONSTANT") isMaintained = false;

  const formSubmitHandler = (submitData: any) => {
    let newData = submitData;
    if (typeof submitData.value === "object") {
      newData = {
        ...submitData,
        value: JSON.stringify({
          value: submitData.value.value,
          options: filterEmptyOptions(submitData.value.options),
        }),
      };
    }
    updateVariable(
      getUpdateOptions("Variable", "JTM", newData, GetAllVariablesDocument, {
        REFETCH_QUERY: GetVariableByIdDocument,
        variables: { id },
        context: "JTM",
      }),
    );
  };

  return (
    <MaintainedProvider
      initialValue={isMaintained}
      elementName="variable"
      id={variableData.id}
      pluginID={variableData.pluginID || undefined}
      formSubmitHandler={formSubmitHandler}
      updateMutationFunction={updateVariable}
      GET_DEFAULT_VALUES={GetVariableDefaultValuesDocument}
      deactivateResetButton={deactivateResetButton(variableData)}>
      <VariableForm data={variableData} mode="update" />
    </MaintainedProvider>
  );
}

export default Variable;

const deactivateResetButton = (variableData: any) => {
  if (variableData.source === "ACCOUNTCONSTANT" || variableData.placeholder) {
    return true;
  }
  return false;
};
