import { faGear } from "@fortawesome/pro-solid-svg-icons";
import HomeIcon from "libs/ui/components/home-icon/HomeIcon";

interface SettingsDefaultPageProps {
  testProp?: any;
}
/**
 * The SettingsDefaultPage component
 * @param {SettingsDefaultPageProps} props
 * @returns a SettingsDefaultPage component
 */
const SettingsDefaultPage = ({ testProp }: SettingsDefaultPageProps): JSX.Element => {
  return <HomeIcon icon={faGear} title="Settings" />;
};

export default SettingsDefaultPage;
