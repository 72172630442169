import { faMask, faMasksTheater, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { faEllipsisVertical, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import React, { memo, useState } from "react";
import FieldSelectionAddMenuContent from "./FieldSelectionAddMenuContent";
import { IFunctionItem, ITextItem, IVariableItem } from "./FieldSelectionTypes";
import classes from "./fieldSelection.module.scss";

interface IFieldSelectionProps {
  initialItems: (IFunctionItem | IVariableItem | ITextItem)[];
  inFunction?: boolean;
  onChange: (data: (IFunctionItem | IVariableItem | ITextItem)[]) => void;
  readOnly?: boolean;
}
/**
 * The FieldSelection component
 * @param {IFieldSelectionProps} props
 * @returns a FieldSelection component
 */
const FieldSelection = ({
  initialItems,
  onChange,
  inFunction = false,
  readOnly = false,
}: IFieldSelectionProps): JSX.Element => {
  const [data, setData] = usePropChangingState(initialItems);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (item: IFunctionItem | IVariableItem | ITextItem): void => {
    setData([...data, item]);
    onChange([...data, item]);
    handleClose();
  };

  const replaceDataAtIndex = (
    index: number,
    items: (IFunctionItem | IVariableItem | ITextItem)[],
  ) => {
    const obj = data[index];
    if (obj.type === "function") {
      obj.children = items;
      setData([...data]);
      onChange([...data]);
    }
  };

  const removeDataAtIndex = (index: number) => {
    data.splice(index, 1);
    setData([...data]);
    onChange([...data]);
  };

  const wrapInAnonymize = (index: number) => {
    // TODO fetch with right id
    const newData = [...data];
    newData[index] = {
      type: "function",
      name: "anonymization",
      short_id: "anonymization",
      children: [data[index]],
    };
    setData([...newData]);
    onChange([...newData]);
  };

  const wrapInPseudoAnonymize = (index: number) => {
    // TODO fetch with right id
    const newData = [...data];
    newData[index] = {
      type: "function",
      name: "pseudonymization",
      short_id: "pseudonymization",
      children: [data[index]],
    };
    setData([...newData]);
    onChange([...newData]);
  };

  const padding = inFunction ? "2px" : 0;
  const addColor = inFunction ? "warning.dark" : "secondary.dark";

  return (
    <Box sx={{ p: padding, m: 0, minHeight: "26px" }}>
      <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
        {data.map((fieldItem, index) => {
          return (
            <FieldItem
              key={fieldItem.name + index.toString()}
              fieldItem={fieldItem}
              onDelete={() => removeDataAtIndex(index)}
              onAnonymize={() => wrapInAnonymize(index)}
              onPseudoAnonymize={() => wrapInPseudoAnonymize(index)}
              onChange={(changedData) => replaceDataAtIndex(index, changedData)}
              readOnly={readOnly}
            />
          );
        })}
        {!readOnly && (
          <>
            <IconButton
              sx={{ borderRadius: 1, height: "25px", width: "25px", color: addColor }}
              onClick={handleClick}>
              <FontAwesomeIcon icon={faPlus} size="sm" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              <FieldSelectionAddMenuContent onSelection={handleSelection} />
            </Menu>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default memo(FieldSelection);

interface IFieldItemProps {
  fieldItem: IFunctionItem | IVariableItem | ITextItem;
  onDelete: () => void;
  onAnonymize: () => void;
  onPseudoAnonymize: () => void;
  onChange: (data: (IFunctionItem | IVariableItem | ITextItem)[]) => void;
  readOnly?: boolean;
}
/**
 * The FieldItem component
 * @param {IFieldItemProps} props
 * @returns a FieldItem component
 */
const FieldItem = ({
  fieldItem,
  onDelete,
  onAnonymize,
  onPseudoAnonymize,
  onChange,
  readOnly = false,
}: IFieldItemProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const styleProps = classes.fieldItem;
  const colorTheme = getColorTheme(fieldItem.type);
  const value = transformValue(fieldItem.name, fieldItem.type);

  return (
    <Stack direction="row" className={`${styleProps} ${colorTheme}`} alignItems="center">
      <Typography variant="body1" sx={{ mr: "5px" }}>
        {value}
      </Typography>
      {fieldItem.type === "function" && (
        <FieldSelection
          initialItems={fieldItem.children || []}
          onChange={onChange}
          inFunction
          readOnly={readOnly}
        />
      )}
      {!readOnly && (
        <>
          <IconButton onClick={handleClick} sx={{ p: "0 5px", fontSize: "14px" }}>
            <FontAwesomeIcon icon={faEllipsisVertical} className={colorTheme} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            <MenuItem onClick={onDelete}>
              <ListItemIcon sx={{ color: "error.main" }}>
                <FontAwesomeIcon icon={faTrashCan} />
              </ListItemIcon>
              <ListItemText sx={{ color: "error.main" }}>Delete</ListItemText>
            </MenuItem>
            <MenuItem onClick={onAnonymize}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faMask} />
              </ListItemIcon>
              <ListItemText>Anonymize</ListItemText>
            </MenuItem>
            <MenuItem onClick={onPseudoAnonymize}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faMasksTheater} />
              </ListItemIcon>
              <ListItemText>Pseudonymise</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </Stack>
  );
};

const getColorTheme = (type: "function" | "variable" | "text"): string => {
  if (type === "function") {
    return classes.functionItem;
  }
  if (type === "variable") {
    return classes.variableItem;
  }
  return "";
};

const transformValue = (
  value: string,
  type: "function" | "variable" | "text",
): string | JSX.Element => {
  if (type === "function") {
    if (value === "anonymization") {
      return <FontAwesomeIcon icon={faMask} style={{ marginRight: "2px" }} />;
    }
    if (value === "pseudonymization") {
      return <FontAwesomeIcon icon={faMasksTheater} style={{ marginRight: "2px" }} />;
    }
  }
  return value;
};
