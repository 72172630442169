import {
  DataGrid,
  GridActionsCellItem,
  GridEnrichedColDef,
  GridRowId,
  GridRowParams,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import {
  ApolloCache,
  DocumentNode,
  FetchResult,
  InternalRefetchQueriesInclude,
} from "@apollo/client";
import { faCopy, faPlugCircleExclamation, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { faClockRotateLeft, faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GET_STATE_DEPENDENCIES } from "apps/jtm/business/state/queries";
import { generateIdOutOfName } from "apps/jtm/components/shared/AutoGenerateNameAndID";
import DependenciesModal, { DependencyData } from "apps/jtm/components/shared/DependenciesModal";
import CLIENT from "libs/business/apollo";
import UserService from "libs/business/authentication/UserService";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import { notifyError, notifyInfo, notifySuccess } from "libs/business/notification/notification";
import getRoutePath from "libs/business/routes/routes";
import getFirstValueOfObject from "libs/business/utils/objectFunctions";
import TService from "libs/model/TService";
import { QueryResponse } from "libs/model/types";
import { useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import LoadingSpinner from "../feedback/loading-spinner/LoadingSpinner";
import ConfirmDialog from "../feedback/modal/ConfirmDialog";
import SimpleInputDialog from "../feedback/modal/SimpleInputDialog";
import getCustomToolBar from "./CustomToolBar";
import IRow from "./IRow";
import { navigateSingleLog } from "./business";
import { LogType, SingleLogNavigationProps } from "./types";

interface ICopyActionCell {
  copyFunction: Function;
  GET_ALL_QUERY: DocumentNode;
  elementTypes: string;
  shortIds?: string[];
}
interface IActionConfig {
  editLink: {
    service: TService;
    routeName: string;
  };
  deleteFunction: Function | false;
  onDeleteCompleted?: () => void;
  onDeleteRefetchQueries?:
    | InternalRefetchQueriesInclude
    | ((
        result: FetchResult<QueryResponse<string, any>, Record<string, any>, Record<string, any>>,
      ) => InternalRefetchQueriesInclude)
    | undefined;
  copy: ICopyActionCell | false;
  log: LogType;
  settings: {
    editable: boolean | ((params: GridRowParams) => boolean);
    deletable: boolean | ((params: GridRowParams) => boolean);
    deleteExcludeIds?: number[];
    copyable: boolean | ((params: GridRowParams) => boolean);
    hasLog: boolean | ((params: GridRowParams) => boolean);
    hasDependencies?: boolean | ((params: GridRowParams) => boolean);
  };
  context?: string;
  onShowDependencies?: {
    query: DocumentNode;
    context: {
      clientName: TService;
    };
  };
}
interface IBasicGridProps {
  columnConfig: GridEnrichedColDef[];
  actionConfig: IActionConfig;
  rowsData: IRow[];
  showContainerFilter?: boolean;
}
/**
 * The BasicGrid component
 * @param {IBasicGridProps} props
 * @returns a BasicGrid component
 */
const BasicGrid: React.FC<IBasicGridProps> = ({
  columnConfig,
  actionConfig,
  rowsData,
  showContainerFilter = false,
}: IBasicGridProps) => {
  const navigate = useNavigate();
  const [rows, setRows] = usePropChangingState<IRow[]>(rowsData);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [idToCopy, setIdToCopy] = useState<GridRowId>();
  const [pageSize, setPageSize] = useState(10);
  const userAccessGroup = UserService.getAccessGroup();
  const [dependenciesLoading, setDependenciesLoading] = useState(false);
  const [dependenies, setDependencies] = useState<DependencyData>({});

  const [modalState, setModalState] = useState(false);
  if (actionConfig.onShowDependencies === undefined) {
    actionConfig!.onShowDependencies = {
      query: GET_STATE_DEPENDENCIES,
      context: { clientName: "jtm" },
    };
  }
  const [getStateDependecies] = useQueryWrapper(actionConfig.onShowDependencies?.query, true);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });
  const [elementName, setElementName] = useState("");

  const userCanEdit = [1, 2, 3, 4, 5, 6].includes(userAccessGroup);

  const onEdit = useCallback(
    (id: GridRowId) => () => {
      navigate(
        getRoutePath(actionConfig.editLink.service, actionConfig.editLink.routeName, { id }),
      );
    },
    [actionConfig.editLink.routeName, actionConfig.editLink.service, navigate],
  );

  const onDelete = useCallback(
    (params: GridRowParams<any>) => async () => {
      const deleteElement = (id: GridRowId, name: string) => {
        if (actionConfig.deleteFunction === false) {
          return;
        }
        const context = actionConfig.context ?? "ADMIN"; // TODO: This is not the right variable check, it is always "ADMIN"
        actionConfig.deleteFunction({
          variables: { id },
          context: { clientName: context },
          update(cache: ApolloCache<any>, mutationResult: FetchResult) {
            if (mutationResult.data) {
              const deletedElement = getFirstValueOfObject(mutationResult.data);
              if (typeof deletedElement.id === "undefined") {
                notifyError(`Element ${name} is in use!`);
              } else {
                // eslint-disable-next-line no-underscore-dangle
                const normalizedId = cache.identify({ id, __typename: deletedElement.__typename });
                cache.evict({ id: normalizedId });
                cache.gc();
                setRows((prevRows) => prevRows.filter((row) => row.id !== deletedElement.id));
                notifySuccess(`Element ${name} deleted`);
                setConfirmDialog((prev) => ({ ...prev, isOpen: false }));
              }
            }
          },
          refetchQueries: actionConfig.onDeleteRefetchQueries,
          awaitRefetchQueries: true,
          onError: (error: any) => {
            notifyError(error.message);
          },
          onCompleted: actionConfig.onDeleteCompleted,
        });
      };
      if (params.row.name) {
        setConfirmDialog((old) => ({
          ...old,
          title: `Are you sure you want to delete ${params.row.name}?`,
          onConfirm: () => {
            deleteElement(params.id, params.row.name);
          },
        }));
      } else if (params.row.username) {
        setConfirmDialog((old) => ({
          ...old,
          title: `Are you sure you want to delete ${params.row.username}?`,
          onConfirm: () => {
            deleteElement(params.id, params.row.username);
          },
        }));
      } else {
        setConfirmDialog((old) => ({
          ...old,
          title: `Are you sure you want to delete ${params.row.id}?`,
          onConfirm: () => {
            deleteElement(params.id, params.row.id);
          },
        }));
      }
      setConfirmDialog((old) => ({
        ...old,
        isOpen: true,
        subTitle: "You can't undo this operation",
      }));
    },
    [actionConfig],
  );

  const onCopy = async (newName: string) => {
    if (actionConfig.copy === false) {
      return;
    }

    const { copyFunction, GET_ALL_QUERY, elementTypes } = actionConfig.copy;

    const context = actionConfig.context ?? "ADMIN";
    copyFunction({
      variables: { idToCopy, newName },
      context: { clientName: context },
      update(cache: ApolloCache<any>, mutationResult: FetchResult) {
        if (mutationResult.data) {
          const copiedData = getFirstValueOfObject(mutationResult.data);

          const data = CLIENT.readQuery({
            query: GET_ALL_QUERY,
          });
          if (data) {
            CLIENT.writeQuery({
              query: GET_ALL_QUERY,
              data: {
                [elementTypes]: [copiedData, ...data[elementTypes]],
              },
            });
          }

          navigate(
            getRoutePath(actionConfig.editLink.service, actionConfig.editLink.routeName, {
              id: copiedData.id,
            }),
          );
        }
      },
    });
  };

  const onLog = useCallback(
    (variableId: GridRowId, row: any) => () => {
      let navigationLogProps: SingleLogNavigationProps;

      if (actionConfig.log === "Variable") {
        navigationLogProps = {
          navigate,
          logType: actionConfig.log,
          id: variableId,
          name: row.name,
          source: row.source,
        };
      } else {
        navigationLogProps = {
          navigate,
          logType: actionConfig.log,
          id: variableId,
          name: row.name,
        };
      }

      navigateSingleLog(navigationLogProps);
    },
    [],
  );

  const onShowDependencies = useCallback(
    (params: any) => async () => {
      if (params.row.name) {
        setElementName(params.row.name);
      } else if (params.row.username) {
        setElementName(params.row.username);
      } else {
        setElementName(params.row.id);
      }
      setDependenciesLoading(true);
      const getDependencies = async (id: number) => {
        const dependenciesQueryData = await getStateDependecies({
          variables: {
            id,
          },
          context: {
            clientName: "JTM",
          },
        });
        if (!dependenciesQueryData.data) {
          return [];
        }
        return getFirstValueOfObject(dependenciesQueryData.data);
      };
      if (actionConfig.settings.hasDependencies && actionConfig.onShowDependencies) {
        const dependencies = await getDependencies(parseInt(params.id as string, 10));
        if (dependencies) {
          setModalState(true);
          const newDependencies: DependencyData = {};
          Object.keys(dependencies).forEach((key) => {
            if (dependencies[key].length > 0 && key !== "__typename") {
              const instanceName = key.split(/(?=[A-Z][a-z]+$)/).pop() || "";
              const linkPrefix = getRoutePath(
                actionConfig.onShowDependencies!.context.clientName,
                instanceName.toLowerCase() || "",
              );
              newDependencies[instanceName] = {
                list: dependencies[key].map((dependency: any) => ({
                  id: dependency.id,
                  name: dependency.name,
                })),
                linkPrefix,
              };
            }
          });
          setDependencies(newDependencies);
          setDependenciesLoading(false);
        } else {
          notifyInfo("No dependencies found");
        }
      }
    },
    [actionConfig.onShowDependencies, actionConfig.settings.hasDependencies, getStateDependecies],
  );

  const ActionArrayDOM = (params: GridRowParams) => {
    const getBoolOrFuncValue = (boolOrFunc: boolean | ((params: GridRowParams) => boolean)) => {
      return typeof boolOrFunc === "function" ? boolOrFunc(params) : boolOrFunc;
    };

    const gridActionArray = [];

    const isEditable = getBoolOrFuncValue(actionConfig.settings.editable);
    if (isEditable) {
      gridActionArray.push(
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faPen} />}
          label="Edit"
          onClick={onEdit(params.id)}
          showInMenu
          data-cy="edit"
        />,
      );
    }

    const isDeleteAble = getBoolOrFuncValue(actionConfig.settings.deletable);
    if (userCanEdit && isDeleteAble) {
      gridActionArray.push(
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faTrashCan} />}
          label="Delete"
          onClick={onDelete(params)}
          disabled={isNotDeletable(params.row, actionConfig.settings.deleteExcludeIds || [])}
          showInMenu
          data-cy="delete"
        />,
      );
    }

    const isCopyAble = getBoolOrFuncValue(actionConfig.settings.copyable);
    if (userCanEdit && isCopyAble) {
      gridActionArray.push(
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faCopy} />}
          label="Copy"
          onClick={() => {
            setCopyModalOpen(true);
            setIdToCopy(params.id);
          }}
          disabled={isNotAbleToCopy(actionConfig.log, params.row.placeholder)}
          showInMenu
          data-cy="copy"
        />,
      );
    }

    const hasLog = getBoolOrFuncValue(actionConfig.settings.hasLog);
    if (hasLog) {
      gridActionArray.push(
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faClockRotateLeft} />}
          label="Log"
          onClick={onLog(params.id, params.row)}
          showInMenu
          data-cy="log"
        />,
      );
    }

    if (actionConfig.settings.hasDependencies) {
      gridActionArray.push(
        <GridActionsCellItem
          icon={<FontAwesomeIcon icon={faPlugCircleExclamation} />}
          label="Show Dependencies"
          onClick={onShowDependencies(params)}
          showInMenu
          data-cy="dependencies"
        />,
      );
    }
    return gridActionArray;
  };

  const columns: GridEnrichedColDef[] = [
    ...columnConfig,
    {
      field: "actions",
      type: "actions",
      width: 5,
      headerName: "",
      getActions: ActionArrayDOM,
      hideable: false,
    },
  ];

  const customToolBar = useMemo(() => getCustomToolBar({ showContainerFilter }), []);

  return (
    <>
      {dependenciesLoading ? <LoadingSpinner /> : ""}
      {actionConfig.settings.hasDependencies && (
        <DependenciesModal
          open={modalState}
          setOpen={setModalState}
          elementName={elementName}
          dependencies={dependenies}
        />
      )}
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onRowDoubleClick={(event) => onEdit(event.id)()}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        disableSelectionOnClick
        autoHeight
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...customToolBar}
      />
      <ConfirmDialog
        title={confirmDialog.title}
        isOpen={confirmDialog.isOpen}
        message={confirmDialog.subTitle}
        onConfirm={confirmDialog.onConfirm}
        onCancel={() => {
          setConfirmDialog({ ...confirmDialog, isOpen: false });
        }}
      />
      <SimpleInputDialog
        title="Give the copy a name"
        inputLabel="Name of copy"
        yupDefinition={yup
          .string()
          .required("This is a required field!")
          .notOneOf(
            actionConfig.copy ? actionConfig.copy.shortIds || [] : [],
            "This Name is already in use!",
          )}
        transformValueBeforeValidation={(value) => generateIdOutOfName(value)}
        open={copyModalOpen}
        onClose={() => setCopyModalOpen(false)}
        asyncOnSubmit={onCopy}
      />
    </>
  );
};

const isNotDeletable = (rowValue: any, deleteExclude: number[]) => {
  return (
    (typeof rowValue.pluginID !== "undefined" &&
      rowValue.pluginID !== null &&
      rowValue.pluginID !== "") ||
    deleteExclude.includes(rowValue.id)
  );
};

const isNotAbleToCopy = (log: LogType, placeholder: any) => {
  return log === "Variable" && placeholder !== null;
};

export default BasicGrid;
