import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import RequiresAccessGroupWrapper from "apps/admin/components/RequiresAccessGroupWrapper";
import { AccessGroups } from "libs/business/authentication/UserStorageManager";
import { useLocalStorage } from "libs/business/hooks/useStorage";
import * as React from "react";
import { Link } from "react-router-dom";

export interface NewInstanceDialogProps {
  instanceName: string;
  newInstanceLink: string;
  newAssistantInstanceLink?: string;
  accessGroups?: AccessGroups | AccessGroups[];
}

const NewInstanceDialog = ({
  instanceName,
  newInstanceLink,
  newAssistantInstanceLink,
  accessGroups = [1, 2, 3, 4, 5, 6],
}: NewInstanceDialogProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [value, setValue] = useLocalStorage(`${instanceName}-mode-preselection`, "assistant");

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getModeLabel = (title: string, subtitle: string) => {
    return (
      <Stack>
        <Typography variant="bodyMedium16">{title} </Typography>
        <Typography variant="bodyRegular12">{subtitle}</Typography>
      </Stack>
    );
  };

  if (!newAssistantInstanceLink) {
    return (
      <RequiresAccessGroupWrapper accessGroups={accessGroups} hideChildren>
        <Link to={newInstanceLink}>
          <Button aria-describedby={id} variant="contained" style={{ width: "max-content" }}>
            Add New {instanceName}
          </Button>
        </Link>
      </RequiresAccessGroupWrapper>
    );
  }

  return (
    <RequiresAccessGroupWrapper accessGroups={accessGroups} hideChildren>
      <>
        <div>
          <Button
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            style={{ width: "max-content" }}>
            Add New {instanceName}
          </Button>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          anchorOrigin={{
            vertical: 42,
            horizontal: "right",
          }}>
          <form>
            <FormControl
              style={{
                padding: "16px",
              }}>
              <FormLabel
                id="create-instance-mode"
                style={{
                  borderBottom: "1px solid #F8F8F8",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "8px",
                  display: "flex",
                  gap: "4px",
                  flexDirection: "column",
                  color: "inherit",
                }}>
                <Typography variant="bodyMedium16">Choose mode type</Typography>
                <Typography variant="bodyRegular14">
                  {`Choose mode type according to your ${instanceName.toLowerCase()} experience`}
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="create-instance-mode"
                defaultValue="assistant"
                value={value}
                onChange={handleRadioChange}
                name="radio-buttons-group">
                <FormControlLabel
                  value="assistant"
                  control={<Radio />}
                  style={{ padding: "8px" }}
                  label={getModeLabel(
                    "Assistant Mode",
                    "This mode will guide you in throughout process",
                  )}
                />
                <FormControlLabel
                  value="manual"
                  control={<Radio />}
                  style={{ padding: "8px" }}
                  label={getModeLabel(
                    "Default Mode",
                    `If you know steps to add new ${instanceName.toLowerCase()} then choose this mode`,
                  )}
                />
              </RadioGroup>
              <div style={{ alignSelf: "end", marginRight: 16, marginTop: 8 }}>
                <Link to={value === "assistant" ? newAssistantInstanceLink : newInstanceLink}>
                  <Button type="submit" variant="outlined">
                    Proceed
                  </Button>
                </Link>
              </div>
            </FormControl>
          </form>
        </Popover>
      </>
    </RequiresAccessGroupWrapper>
  );
};

export default NewInstanceDialog;
