import { gql } from "@apollo/client";

export const GET_LANGUAGES = gql`
  query Languages {
    Languages {
      id
      status
      mid
      original
      create_at
      create_by
      edit_at
      edit_by
      iso
      textcontent_textcontentid
      text_content {
        id
        text
      }
    }
  }
`;

export const GET_USED_LANGUAGES = gql`
  query GetUsedLanguages {
    getUsedLanguages {
      id
      iso
      textcontent_textcontentid
      text_content {
        text
      }
    }
  }
`;

export default GET_LANGUAGES;
