import { ApolloCache, ApolloError, FetchResult } from "@apollo/client";
import { DocumentNode } from "graphql";
import CLIENT from "libs/business/apollo";
import TService from "../../model/TService";

import { notifyError, notifySuccess } from "../notification/notification";

interface IRefetchQueryConfig {
  REFETCH_QUERY: DocumentNode;
  variables: any;
  context: string;
}
// eslint-disable-next-line import/prefer-default-export
export const getUpdateOptions = (
  elementTypeCapitalCase: string,
  clientName: string,
  submitData: any,
  GET_ALL_QUERY: DocumentNode,
  refetchQuery?: IRefetchQueryConfig,
) => {
  const elementTypeLowerCase = elementTypeCapitalCase.toLowerCase(); // eg. function
  const elementListTypeLowerCase = `${elementTypeLowerCase}s`; // eg. functions
  const elementUpdateType = `update${elementTypeCapitalCase}`; //  eg. createFunction

  const options: any = {
    variables: submitData,
    context: { clientName },
    onCompleted: (response: any) => {
      notifySuccess(`${elementTypeCapitalCase} updated`);
    },
    update(cache: ApolloCache<any>, response: FetchResult) {
      if (response.data) {
        const data = CLIENT.readQuery({
          query: GET_ALL_QUERY,
        });
        if (data) {
          const newArrayOfData = data[elementListTypeLowerCase].map((item: any) => {
            if (response.data && item.id === response.data[elementUpdateType].id) {
              return response.data[elementUpdateType];
            }
            return item;
          });

          CLIENT.writeQuery({
            query: GET_ALL_QUERY,
            data: {
              [elementListTypeLowerCase]: newArrayOfData,
            },
          });
        }
      }
    },
    onError: (error: ApolloError) => {
      notifyError(error.message);
    },
  };
  if (refetchQuery) {
    options.refetchQueries = [
      {
        query: refetchQuery.REFETCH_QUERY,
        variables: refetchQuery.variables,
        context: { clientName: refetchQuery.context },
      },
    ];
  }
  return options;
};
