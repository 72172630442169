import { Editor } from "@tinymce/tinymce-react";
import { useCallback, useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

interface TextEditorProps {
  name: string;
  value: string;
  placeholder: string;
  disabled?: boolean;
}
export default function TextEditor({
  name,
  value,
  placeholder,
  disabled = false,
}: TextEditorProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { field } = useController({
    control,
    name,
    defaultValue: value,
  });

  const changeValue = (changedValue: string): void => {
    setHookFormValue(changedValue);
  };

  const setHookFormValue = (changedValue: string | number) => {
    field.onChange(changedValue);
  };

  // for changes not made by a manual input
  useEffect(() => {
    changeValue(value);
  }, [value]);

  const handleEditorChange = (newContent: any) => {
    changeValue(newContent);
  };

  return (
    <div style={{ width: "100%" }}>
      <Editor
        textareaName={name}
        apiKey="cuomsp8f37y94ja55v8qhhism5fpm5tj5ljnzk9rnozrjtrt"
        initialValue={value}
        disabled={disabled}
        init={{
          skin: "snow",
          icons: "thin",
          placeholder,
          height: 300,
          menubar: true,
          plugins: [],
          textcolor_rows: "4",
          toolbar:
            "undo redo | styleselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ",
        }}
        onEditorChange={handleEditorChange}
        // toolbar="code"
      />
    </div>
  );
}
