import { ApolloCache, ApolloError, FetchResult } from "@apollo/client";
import { removesEmptyOptionsOfConstants } from "apps/jtm/components/shared/preprocess_constant_values";
import {
  CREATE_FRONTEND_PLUGIN,
  GET_ALL_FRONTEND_PLUGINS,
  GET_FRONTEND_PLUGIN_DEFINITION,
} from "apps/jtm/business/frontend_plugin/queries";
import FrontendPlugin from "apps/jtm/components/plugin/FrontendPlugin";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import useParamsId from "libs/business/hooks/useParamId";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import CLIENT from "libs/business/apollo";
import getRoutePath from "libs/business/routes/routes";

const FrontendPluginCreate = (): JSX.Element => {
  const navigate = useNavigate();
  const id = useParamsId("id");
  const [searchParams] = useSearchParams();

  const pluginID = searchParams.get("pluginID") ?? "";

  const [getFrontendPluginDefinition, queryState] = useQueryWrapper(GET_FRONTEND_PLUGIN_DEFINITION);
  const [createFrontendPlugin] = useMutationWrapper(CREATE_FRONTEND_PLUGIN);

  if (!queryState.called) {
    getFrontendPluginDefinition({ variables: { pluginID }, context: { clientName: "JTM" } });
  }

  const formSubmitHandler: SubmitHandler<any> = (submitData: any) => {
    submitData.constants = removesEmptyOptionsOfConstants(submitData.constants);
    createFrontendPlugin({
      variables: submitData,
      context: { clientName: "JTM" },
      onCompleted: (response: any) => {
        const newPath = getRoutePath("jtm", "plugin_id", { id: response.createFrontendPlugin.id });
        navigate(newPath, { replace: true });
        notifySuccess("Frontend Plugin created!");
      },
      update(cache: ApolloCache<any>, response: FetchResult) {
        if (response.data) {
          const data = CLIENT.readQuery({
            query: GET_ALL_FRONTEND_PLUGINS,
          });
          if (data) {
            CLIENT.writeQuery({
              query: GET_ALL_FRONTEND_PLUGINS,
              data: {
                frontendPlugins: [response.data.createFrontendPlugin, ...data.frontendPlugins],
              },
            });
          }
        }
      },
      onError: (error: ApolloError) => {
        notifyError(error.message);
      },
    });
  };

  if (queryState.loading || !queryState.data) return <PageLoadingSpinner />;

  const emptyFrontendPluginData = {
    id,
    name: "",
    constants: queryState.data.frontendPluginDefinition,
    containerIDs: [],
    pluginID,
  };

  return (
    <FrontendPlugin
      id={id}
      frontendPluginData={emptyFrontendPluginData}
      formSubmitHandler={formSubmitHandler}
      selectedContainerIdsInit={[]}
    />
  );
};

export default FrontendPluginCreate;
