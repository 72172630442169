import { gql } from "@apollo/client";
import { GQLTypeDefinition, ListResponse, SingleResponse } from "libs/model/types";

export interface BackendNexusFunctionTypes {
  id: number;
  create_by: number;
  create_at: string;
  edit_by: number;
  edit_at: string;
  short_id: string;
  version: string;
  name: string;
  desc: string;
  value: string;
  maintained: number;
  pluginID: string;
}

export interface GetFunctionByIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusFunctionTypes,
    "id" | "short_id" | "name" | "desc" | "value" | "maintained" | "pluginID"
  >;
  response: SingleResponse<"function", GetFunctionByIdQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "JTM";
}

export const GET_FUNCTION_BY_ID = gql`
  query function($id: Int!) {
    function(id: $id) {
      id
      short_id
      name
      desc
      value
      maintained
      pluginID
    }
  }
`;

export interface GetAllFunctionsQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusFunctionTypes,
    "id" | "name" | "short_id" | "create_at" | "edit_at" | "pluginID"
  >;
  response: ListResponse<"functions", GetAllFunctionsQueryDefinition["dataType"]>;
  context: "JTM";
}

export const GET_ALL_FUNCTIONS = gql`
  query functions {
    functions {
      id
      name
      short_id
      create_at
      edit_at
      pluginID
    }
  }
`;

export const UPDATE_FUNCTION = gql`
  mutation updateFunction(
    $id: Int!
    $short_id: String!
    $name: String!
    $desc: String!
    $value: String!
    $maintained: Int!
  ) {
    updateFunction(
      id: $id
      short_id: $short_id
      name: $name
      desc: $desc
      value: $value
      maintained: $maintained
    ) {
      id
      create_at
      edit_at
      short_id
      name
      desc
      value
      maintained
    }
  }
`;

export const CREATE_FUNCTION = gql`
  mutation createFunction(
    $short_id: String!
    $name: String!
    $desc: String!
    $value: String!
    $maintained: Int!
  ) {
    createFunction(
      short_id: $short_id
      name: $name
      desc: $desc
      value: $value
      maintained: $maintained
    ) {
      id
      create_at
      edit_at
      short_id
      name
      desc
      value
      maintained
    }
  }
`;

export const GET_FUNCTION_DEFAULT_VALUES = gql`
  query functionDefaultValues($pluginID: String!) {
    functionDefaultValues(pluginId: $pluginID) {
      name
      short_id
      desc
      value
      maintained
    }
  }
`;

export const COPY_FUNCTION = gql`
  mutation copyFunction($idToCopy: Int!, $newName: String!) {
    copyFunction(id_to_copy: $idToCopy, new_name: $newName) {
      id
      name
      short_id
      create_at
      edit_at
      pluginID
    }
  }
`;

export const DELETE_FUNCTION = gql`
  mutation deleteFunction($id: Int!) {
    deleteFunction(id: $id) {
      ... on Function {
        id
        name
      }
      ... on DependenciesOnFunction {
        dependentTags {
          id
          name
        }
      }
    }
  }
`;

export const GET_FUNCTION_SHORT_IDS = gql`
  query functionShortIds {
    functionShortIds {
      shortIds
    }
  }
`;

export const GET_FUNCTION_DEPENDENCIES = gql`
  query getFunctionDependencies($id: Int!) {
    getFunctionDependencies(id: $id) {
      dependentTags {
        id
        name
      }
    }
  }
`;

export default GET_FUNCTION_BY_ID;
