import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { CSSProperties } from "react";
import classes from "./sidebar.module.scss";

interface IconLinkProps {
  icon?: IconDefinition;
  name: string;
  isActive: boolean;
  style?: CSSProperties;
}
/**
 * The IconLink component
 * @param {IconLinkProps} props
 * @returns a IconLink component
 */
const IconLink = ({ icon, name, isActive, style }: IconLinkProps): JSX.Element => {
  const activeClass = isActive ? classes.mainNavActive : "";

  return (
    <div className={classes.linkWrapper} style={style}>
      <div className={`${classes.link} ${activeClass}`}>
        {icon !== undefined && (
          <div className={classes.iconWrapper}>
            <FontAwesomeIcon icon={icon} style={{ height: "16px" }} />
          </div>
        )}
        <Typography variant="body1">{name}</Typography>
      </div>
    </div>
  );
};

export default IconLink;
