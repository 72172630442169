import { ReactElement } from "react";

export interface ITabWrapperChildProps {
  label?: string | JSX.Element;
  icon?: JSX.Element;
  children: JSX.Element;
  error?: boolean;
}
/**
 * The TabContent component
 * Containts the content  for the tabs
 * @param {ITabWrapperChildProps} props
 * @returns a TabWrapperChild component
 */
const TabContent = ({
  label,
  icon,
  children,
  error = false,
}: ITabWrapperChildProps): ReactElement<ITabWrapperChildProps> => {
  return <div>{children}</div>;
};

export default TabContent;
