import GET_ALL_ENVIRONMENTS from "apps/jtm/business/config_environment/queries";
import { IdAndName } from "libs/model/types";
import React, { ReactNode, useCallback, useContext, useState } from "react";
import useQueryWrapper from "../../../../libs/business/authentication/useQueryWrapper";

const EnvironmentContext = React.createContext<IdAndName[]>([]);
const EnvironmentUpdateContext = React.createContext<(Environments: IdAndName[]) => void>(() => {});

export function useEnvironment() {
  return useContext(EnvironmentContext);
}

export function useEnvironmentUpdate() {
  return useContext(EnvironmentUpdateContext);
}

interface IEnvironmentProviderProps {
  children: ReactNode;
}
/**
 * The EnvironmentContext component provides the context for active Environments.
 * @returns a EnvironmentContext component
 */
export const EnvironmentProvider = ({ children }: IEnvironmentProviderProps) => {
  const [getAllEnvironments, queryState] = useQueryWrapper(GET_ALL_ENVIRONMENTS);
  const [environments, setEnvironment] = useState<IdAndName[]>([]);

  const updateEnvironments = (value: IdAndName[]) => {
    setEnvironment(value);
  };

  if (!queryState.called) {
    getAllEnvironments({
      context: { clientName: "JTM" },
      onCompleted: (response: any) => {
        setEnvironment(response.configEnvironments);
      },
    });
  }

  return (
    <EnvironmentContext.Provider value={environments}>
      <EnvironmentUpdateContext.Provider value={useCallback(updateEnvironments, [])}>
        {children}
      </EnvironmentUpdateContext.Provider>
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentContext;
