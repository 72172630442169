import { Tab, Tabs, Box, SxProps, Theme } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import colorStyles from "assets/styles/colorPalette.module.scss";
import { useHelpContext } from "apps/jtm/components/url/HelpContext";
import { ITabWrapperChildProps } from "./TabContent";

export interface ITabConfig {
  label?: string;
  icon?: JSX.Element;
  content: JSX.Element | string;
}

interface ITabWrapperProps {
  variant?: "fullWidth" | "scrollable" | "standard" | undefined;
  iconPosition?: "start" | "end" | "bottom" | "top";
  children?: ReactElement<ITabWrapperChildProps> | ReactElement<ITabWrapperChildProps>[];
  defaultTab?: number;
  tabsSx?: SxProps<Theme>;
  tabBoxSx?: SxProps<Theme>;
  disablePreRendering?: boolean;
  enableSearchParamsChanging?: boolean;
}

/**
 * The TabWrapper component
 * @param {ITabWrapperProps} props
 * @returns a TabWrapper component
 */
const TabWrapper = ({
  variant,
  iconPosition = "start",
  children,
  defaultTab = 0,
  tabsSx,
  tabBoxSx,
  disablePreRendering = false,
  enableSearchParamsChanging,
}: ITabWrapperProps): JSX.Element => {
  let childrenArray: ReactElement<ITabWrapperChildProps>[] = [];
  const { setHelpParams } = useHelpContext();

  if (typeof children !== "undefined" && !Array.isArray(children)) {
    childrenArray = [children];
  } else if (typeof children !== "undefined") {
    childrenArray = children;
  }

  const [tabIndex, setTabIndex] = React.useState(defaultTab);

  const handleTabSwitch = (event: React.SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  useEffect(() => {
    if (enableSearchParamsChanging) {
      switch (childrenArray[tabIndex].props.label) {
        case "Frontend":
          setHelpParams("frontend");
          break;
        case "Backend":
          setHelpParams("backend");
          break;
        case "Constant":
          setHelpParams("constant");
          break;
        default:
          break;
      }
    }
  }, [enableSearchParamsChanging, tabIndex]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: colorStyles.grey3 }}>
        <Tabs value={tabIndex} onChange={handleTabSwitch} variant={variant} sx={tabsSx}>
          {childrenArray.map((child, index) => {
            return (
              <Tab
                key={typeof child.props.label === "string" ? child.props.label : index}
                label={child.props.label}
                icon={child.props.icon}
                iconPosition={iconPosition}
                id={`tab-${index}`}
                aria-controls={`tabpanel-${index}`}
                sx={{ minHeight: "" }}
              />
            );
          })}
        </Tabs>
      </Box>
      {childrenArray.map((child, index) => {
        return (
          (!disablePreRendering || tabIndex === index) && (
            <Box
              key={typeof child.props.label === "string" ? child.props.label : index}
              hidden={tabIndex !== index}
              role="tabpanel"
              id={`tabpanel-${index}`}
              aria-labelledby={`tab-${index}`}
              sx={tabBoxSx || { overflow: "auto" }}>
              {child}
            </Box>
          )
        );
      })}
    </>
  );
};

export default TabWrapper;
