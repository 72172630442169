import SettingsDefaultPage from "apps/admin/pages/home/SettingsDefaultPage";
import RouteInterface from "libs/business/routes/RouteInterface";

import RequiresAccessGroupWrapper from "apps/admin/components/RequiresAccessGroupWrapper";
import ContainerCreate from "../pages/container/ContainerCreate";
import ContainerGrid from "../pages/container/ContainerGrid";
import ContainerUpdate from "../pages/container/ContainerUpdate";
import Home from "../pages/home/Home";
import Login from "../pages/login/Login";
import PasswordChange from "../pages/login/PasswordChange";
import PasswordForgot from "../pages/login/PasswordForgot";
import PasswordReset from "../pages/login/PasswordReset";
import Consent from "../pages/settings/Consent";
import InitCustomer from "../pages/user/InitCustomer";
import Registration from "../pages/user/Registration";
import UserEdit from "../pages/user/UserEdit";
import UserGrid from "../pages/user/UserGrid";

export const WHITELIST_ROUTES: RouteInterface = {
  login: {
    path: "/login",
    element: <Login />,
  },
  password_forgot: {
    path: "/password-forgot",
    element: <PasswordForgot />,
  },
  password_reset: {
    path: "/password-reset",
    element: <PasswordReset />,
  },
};

const ADMIN_ROUTES: RouteInterface = {
  ...WHITELIST_ROUTES,
  home: {
    path: "/",
    element: <Home />,
  },
  settings: {
    path: "/settings",
    element: <SettingsDefaultPage />,
  },
  init_customer: {
    path: "/init-customer",
    element: <InitCustomer />,
  },
  users: {
    path: "/setting/user",
    element: (
      <RequiresAccessGroupWrapper accessGroups={2}>
        <UserGrid />
      </RequiresAccessGroupWrapper>
    ),
  },
  user_edit: {
    path: "/setting/user/:id",
    element: <UserEdit />,
  },
  register: {
    path: "/setting/user/new",
    element: (
      <RequiresAccessGroupWrapper accessGroups={2}>
        <Registration />
      </RequiresAccessGroupWrapper>
    ),
  },
  container: {
    path: "/setting/container",
    element: <ContainerGrid />,
  },
  container_new: {
    path: "/setting/container/-1",
    element: (
      <RequiresAccessGroupWrapper accessGroups={1}>
        <ContainerCreate />
      </RequiresAccessGroupWrapper>
    ),
  },
  container_id: {
    path: "/setting/container/:id",
    element: (
      <RequiresAccessGroupWrapper accessGroups={3}>
        <ContainerUpdate />
      </RequiresAccessGroupWrapper>
    ),
  },
  consent_administration: {
    path: "/setting/consent",
    element: (
      <RequiresAccessGroupWrapper accessGroups={[1, 2, 3, 4, 5, 6]}>
        <Consent />
      </RequiresAccessGroupWrapper>
    ),
  },
};

export default ADMIN_ROUTES;
