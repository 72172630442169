import { Alert, AlertColor, AlertTitle, Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { FC } from "react";
import colorStyles from "assets/styles/colorPalette.module.scss";

export interface IAlertBoxProps {
  type: AlertColor;
  message: string | JSX.Element;
  title?: string;
  boxStyles?: SxProps<Theme>;
}

const createAlertStyles = (type: AlertColor) => {
  switch (type) {
    case "success":
      return {
        display: "flex",
        alignItems: "center",
        bgcolor: colorStyles.green1,
        color: colorStyles.green9,
        "& .MuiAlert-icon": {
          color: colorStyles.green9,
        },
      };

    case "warning":
      return {
        display: "flex",
        alignItems: "center",
        bgcolor: colorStyles.orange1,
        border: "1px solid",
        borderColor: colorStyles.orange3,
        boxShadow: `0 0 2px ${colorStyles.orange2}`,
        color: colorStyles.orange9,
        "& .MuiAlert-icon": {
          color: colorStyles.orange5,
        },
      };

    case "info":
      return {
        display: "flex",
        alignItems: "center",
        color: colorStyles.primaryDark,
        "& .MuiAlert-icon": {
          color: colorStyles.primaryDark,
        },
      };

    default: {
      return {
        display: "flex",
        alignItems: "center",
        color: colorStyles.primaryDark,
        "& .MuiAlert-icon": {
          color: colorStyles.primaryDark,
        },
      };
    }
  }
};

const AlertBox: FC<IAlertBoxProps> = ({
  type,
  message,
  title,
  boxStyles = {},
}: IAlertBoxProps): JSX.Element | null => {
  const alertStyles: SxProps<Theme> = createAlertStyles(type);

  return (
    <Box sx={boxStyles}>
      <Alert severity={type} sx={alertStyles}>
        {title && (
          <AlertTitle>
            <strong>{title}</strong>
          </AlertTitle>
        )}

        {message}
      </Alert>
    </Box>
  );
};

export default AlertBox;
