import { Paper, Stack, Typography } from "@mui/material";
import {
  BackendNexusToolDetails,
  GetAllToolQueryDefinition,
  GET_ALL_TOOLS,
} from "apps/jtm/business/tool/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import SelectWrapper from "libs/ui/components/input/SelectWrapper";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { useWizardData } from "libs/ui/components/surfaces/wizard/WizardDataContext";
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { TAG_CONFIG_ROOT_KEY } from "./ConfigureTemplate";

/**
 * The StepOne component
 * @returns a StepOne component
 */
const StepOne = (): JSX.Element => {
  const { unregister } = useFormContext();
  const wizardData = useWizardData();
  const defaultNameValue = wizardData.name || "";
  const defaultSelectValue = wizardData?.tool?.id || "";
  const [toolDefinition, setToolDefinition] = useState<
    GetAllToolQueryDefinition["dataType"] | undefined
  >();

  const getUsedTools = useSimpleFetching<GetAllToolQueryDefinition>({
    query: GET_ALL_TOOLS,
    context: "JTM",
    queryArguments: { sortParam: "name", sortDirection: "desc" },
    disableCache: true,
  });
  const handleChangeTool = useCallback(
    (value: string | number) => {
      if (value !== defaultSelectValue) {
        unregister("template");
        unregister(TAG_CONFIG_ROOT_KEY);
      }
      if (getUsedTools) {
        const tool = getUsedTools.tools.find((toolDef) => toolDef.id === value);
        if (tool) setToolDefinition(tool);
      }
    },
    [getUsedTools],
  );

  if (!getUsedTools) return <PageLoadingSpinner />;

  return (
    <>
      <Typography variant="pageTitle">Tool Selection</Typography>
      <Paper variant="page">
        <TextFieldWrapper
          name="tool"
          label="Tool"
          value={toolDefinition as any}
          size="medium"
          hidden
        />
        <TextFieldWrapper
          name="name"
          label="Tag Name"
          value={defaultNameValue}
          size="medium"
          maxLength={256}
        />
        <SelectWrapper
          name="toolId"
          label="Tool"
          options={getUsedTools.tools.map((tool: any) => {
            return {
              key: tool.id,
              value: <SelectOption name={tool.name} imagePath={tool.tool_definition.image_path} />,
            };
          })}
          value={defaultSelectValue}
          onChange={handleChangeTool}
        />
      </Paper>
    </>
  );
};

export default StepOne;

const SelectOption = ({ name, imagePath }: { name: string; imagePath: string }): JSX.Element => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <img
        src={`${imagePath}small`}
        style={{ width: "60px", height: "60px", objectFit: "contain" }}
        alt="logo"
      />
      <Typography variant="h5">{name}</Typography>
    </Stack>
  );
};
