import { Box } from "@mui/material";
import ExcludeTriggers from "../../../Trigger/ExludeTriggers";
import Trigger from "../../../Trigger/Trigger";
import { TagDetailData } from "../../type";

interface TriggerTabContentProps {
  detailData: TagDetailData;
}
/**
 * The TriggerTabContent component
 * @param {TriggerTabContentProps} props
 * @returns a TriggerTabContent component
 */
const TriggerTabContent = ({ detailData }: TriggerTabContentProps): JSX.Element => {
  const excludeTriggers = detailData.trigger["exclude-triggers"];
  return (
    <>
      <Trigger trigger={detailData.trigger.trigger} />
      {excludeTriggers.length > 0 && <ExcludeTriggers triggers={excludeTriggers} />}
    </>
  );
};

export default TriggerTabContent;
