import {
  CREATE_CONTAINER,
  CreateContainerQueryDefinition,
  GET_ALL_CONTAINERS,
} from "apps/admin/business/container/queries";
import { GET_ALL_VERSIONS } from "apps/jtm/business/version/queries";
import CLIENT from "libs/business/apollo";
import { getCreateOptions } from "libs/business/apollo/createOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ContainerForm from "./ContainerForm";

interface ContainerCreateProps {
  isSetupInitialized?: boolean;
}
/**
 * The ContainerCreate component
 * @param {ContainerCreateProps} props
 * @returns a ContainerCreate component
 */
const ContainerCreate = ({ isSetupInitialized = true }: ContainerCreateProps): JSX.Element => {
  const navigate = useNavigate();

  const [createContainer, mutationState] =
    useMutationWrapper<CreateContainerQueryDefinition>(CREATE_CONTAINER);

  const formSubmitHandler: SubmitHandler<CreateContainerQueryDefinition["dataType"]> = (data) => {
    createContainer(
      getCreateOptions("Container", "ADMIN", data, GET_ALL_CONTAINERS, navigate, {
        customOnComplete: () => CLIENT.resetStore(),
        customNavigationPath: isSetupInitialized ? undefined : "/",
        refetchQueriesArg: [
          {
            query: GET_ALL_VERSIONS,
            context: { clientName: "JTM" },
          },
        ],
      }),
    );
  };

  const pageTitle = isSetupInitialized
    ? "Container"
    : "1st Step is the container creation for your setup";

  if (mutationState.loading) return <PageLoadingSpinner />;

  const containerData = mutationState?.data?.createContainer || {
    id: 0,
    name: "",
    short: "",
    live_url: "",
    test_url: "",
    hash: "",
    file_hash: "",
    languages: [],
  };

  return (
    <ContainerForm
      pageTitle={pageTitle}
      containerData={containerData}
      formSubmitHandler={formSubmitHandler}
    />
  );
};

export default ContainerCreate;
