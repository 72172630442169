import AutoGenerateNameAndID from "apps/jtm/components/shared/AutoGenerateNameAndID";
import CodeEditor from "libs/ui/components/input/editor/CodeEditor";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { useMaintained } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import MaintainedPage from "apps/jtm/components/shared/maintainedby/MaintainedPage";

interface IStateFormData {
  id: number;
  short_id: string;
  name: string;
  maintained: number;
  pluginID: string;
  desc: string;
  value: string;
}

interface IStateFormProps {
  mode: "create" | "update";
  data: IStateFormData;
}

export const StateForm: React.FC<IStateFormProps> = ({ mode, data }: IStateFormProps) => {
  const { setMaintained, maintainedBy } = useMaintained();
  const onDescChange = (value: string) => {
    if (value !== data.desc) {
      setMaintained(false);
    }
  };

  const onValueChange = (value: string) => {
    if (value.replace(/[\r\n]/gm, "") !== data.value.replace(/[\r\n]/gm, "")) {
      setMaintained(false);
    }
  };

  return (
    <MaintainedPage
      title="State"
      showMaintainedInfo
      maintainedBy={maintainedBy}
      maintainedTypePlaceholder="state">
      <TextFieldWrapper name="id" label="id" value={data.id} hidden />
      <AutoGenerateNameAndID mode={mode} short_id={data.short_id} name={data.name} />
      <TextFieldWrapper
        name="desc"
        label="Description"
        value={data.desc}
        multiline
        onChange={onDescChange}
      />
      <CodeEditor label="Javascript" name="value" content={data.value} onChange={onValueChange} />
    </MaintainedPage>
  );
};

export default StateForm;
