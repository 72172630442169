import { Box, Card, CardHeader, CardMedia } from "@mui/material";
import { GET_TEMPLATE_VARIABLES_BY_TYPE } from "apps/jtm/business/variable/queries";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import getRoutePath from "libs/business/routes/routes";
import { Link } from "react-router-dom";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { Container } from "@mui/system";
import FrontendJSImg from "assets/images/custom/frontendjs.png";
import BackendJSImg from "assets/images/custom/backendjs.png";

interface IVariableSelectionTabProps {
  type: string;
}

const customVariables: ICustomVariables = {
  FRONTENDJS: {
    pluginID: "",
    image_path: FrontendJSImg,
    name: "Frontend Custom Javascript",
  },
  BACKENDJS: {
    pluginID: "",
    image_path: BackendJSImg,
    name: "Backend Custom Javascript",
  },
};

interface ICustomVariables {
  [key: string]: ICustomVariable;
}

interface ICustomVariable {
  pluginID: string;
  image_path: string;
  name: string;
}

export const VariableSelectionTab: React.FC<IVariableSelectionTabProps> = ({
  type,
}: IVariableSelectionTabProps) => {
  const [getVariables, { called, loading, data }] = useQueryWrapper(GET_TEMPLATE_VARIABLES_BY_TYPE);
  if (!called) {
    getVariables({ variables: { type }, context: { clientName: "JTM" } });
  }

  if (loading || !data) return <PageLoadingSpinner />;

  return (
    <Container>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "grid",
          gridAutoRows: "1fr",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            sm: "repeat(4, 1fr)",
            md: "repeat(5, 1fr)",
          },
          gridRowGap: "20px",
          gridColumnGap: "20px",
        }}>
        {data.templateVariables.map((element: any) => (
          <Link
            key={element.pluginID}
            to={`${getRoutePath("jtm", "variable_new")}?type=${type}&template=${element.pluginID}`}
            state={{ pluginId: element.pluginID }}>
            <Card
              key={`card-${element.pluginID}`}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                "&:hover": {
                  transform: "scale3d(1.02, 1.02, 1)",
                  transition: "all .4s ease-in-out",
                },
              }}>
              <CardHeader title={element.name} />
              <CardMedia
                height="150px"
                image={element.image_path}
                title={element.name}
                component="img"
              />
            </Card>
          </Link>
        ))}
        <Link
          key={customVariables[type].pluginID}
          to={`${getRoutePath("jtm", "variable_new")}?type=${type}`}
          state={{ pluginId: customVariables[type].pluginID }}>
          <Card
            key={`card-${customVariables[type].pluginID}`}
            sx={{
              "&:hover": {
                transform: "scale3d(1.02, 1.02, 1)",
                transition: "all .4s ease-in-out",
              },
            }}>
            <CardHeader title={customVariables[type].name} />
            <CardMedia
              height="150px"
              image={customVariables[type].image_path}
              title={customVariables[type].name}
              component="img"
            />
          </Card>
        </Link>
      </Box>
    </Container>
  );
};

export default IVariableSelectionTabProps;
