import { ApolloCache, ApolloError, FetchResult } from "@apollo/client";
import CLIENT from "libs/business/apollo";
import { notifySuccess, notifyError } from "libs/business/notification/notification";
import { GET_ALL_VERSIONS } from "../../business/version/queries";

// eslint-disable-next-line import/prefer-default-export
export const getPublishMutationOptions = (submitData: any) => {
  return {
    variables: submitData,
    context: { clientName: "JTM" },
    onCompleted: (response: any) => {
      notifySuccess("Publish successfull!");
    },
    refetchQueries: [
      {
        query: GET_ALL_VERSIONS,
        context: { clientName: "JTM" },
      },
    ],
    onError: (error: ApolloError) => {
      notifyError(error.message);
    },
  };
};

/**
 * DEPRECATED But can be used as a schema how to programm it in a readable way
 * @param cache
 * @param response
 * @returns
 */
const updateApolloCachePublish = (cache: ApolloCache<any>, response: FetchResult) => {
  if (!response.data) {
    return;
  }
  const allVersionQueryCache = CLIENT.readQuery({
    query: GET_ALL_VERSIONS,
  });
  if (!allVersionQueryCache) {
    return;
  }

  CLIENT.writeQuery({
    query: GET_ALL_VERSIONS,
    data: {
      deploymentVersions: getNewCacheData(allVersionQueryCache, response),
    },
  });
};

const getNewCacheData = (allVersionQueryCache: any, response: FetchResult) => {
  if (!response.data) {
    throw new Error("response is empty!");
  }
  const newOrUpdatedPuplishID = response.data.publish.id;
  let cacheData = replaceVersionInCacheIfExist(
    allVersionQueryCache,
    response,
    newOrUpdatedPuplishID,
  );

  if (isVersionNotContainingResponseVersion(allVersionQueryCache, newOrUpdatedPuplishID)) {
    cacheData = [response.data.publish, ...cacheData];
  }
  return cacheData;
};

const replaceVersionInCacheIfExist = (
  allVersionQueryCache: any,
  response: FetchResult,
  newOrUpdatedPuplishID: number,
) => {
  return allVersionQueryCache.deploymentVersions.map((item: any) => {
    if (response.data && item.id === newOrUpdatedPuplishID) {
      return response.data.publish;
    }
    return item;
  });
};

const isVersionNotContainingResponseVersion = (
  allVersionQueryCache: any,
  newOrUpdatedPuplishID: number,
) => {
  return (
    typeof allVersionQueryCache.deploymentVersions.find(
      (item: any) => item.id === newOrUpdatedPuplishID,
    ) === "undefined"
  );
};
