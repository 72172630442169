import { gql } from "@apollo/client";

export const GET_ALL_FRONTEND_PLUGIN_DEFINITION = gql`
  query frontendPluginDefinitions {
    frontendPluginDefinitions {
      pluginID
      name
      image_path
    }
  }
`;

export const GET_FRONTEND_PLUGIN_DEFINITION = gql`
  query frontendPluginDefinition($pluginID: String!) {
    frontendPluginDefinition(pluginID: $pluginID) {
      short_id
      name
      desc
      value
      optional
      type
    }
  }
`;

export const GET_FRONTEND_PLUGIN_BY_ID = gql`
  query frontendPlugin($id: Int!) {
    frontendPlugin(id: $id) {
      id
      create_by
      create_at
      edit_by
      edit_at
      short_id
      version
      name
      desc
      pluginID
      constants {
        short_id
        name
        desc
        value
        optional
        type
      }
      container {
        id
      }
    }
  }
`;

export const GET_ALL_FRONTEND_PLUGINS = gql`
  query frontendPlugins {
    frontendPlugins {
      id
      name
      create_at
      edit_at
      containerIDs
      container {
        id
      }
    }
  }
`;

export const CREATE_FRONTEND_PLUGIN = gql`
  mutation createFrontendPlugin(
    $name: String!
    $pluginID: String!
    $containerIDs: [Int]!
    $constants: JSON
  ) {
    createFrontendPlugin(
      name: $name
      pluginID: $pluginID
      containerIds: $containerIDs
      constants: $constants
    ) {
      id
    }
  }
`;

export const UPDATE_FRONTEND_PLUGIN = gql`
  mutation updateFrontendPlugin(
    $id: Int!
    $name: String!
    $pluginID: String!
    $containerIDs: [Int]!
    $constants: JSON
  ) {
    updateFrontendPlugin(
      id: $id
      name: $name
      pluginID: $pluginID
      containerIds: $containerIDs
      constants: $constants
    ) {
      id
    }
  }
`;

export const DELETE_FRONTEND_PLUGIN = gql`
  mutation deleteFrontendPlugin($id: Int!) {
    deleteFrontendPlugin(id: $id) {
      id
      name
    }
  }
`;

export default GET_FRONTEND_PLUGIN_BY_ID;
