import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Paper, Typography } from "@mui/material";
import { REGISTER } from "apps/admin/business/login/queries";
import GET_ALL_USER from "apps/admin/business/user/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import LoadingSpinner from "libs/ui/components/feedback/loading-spinner/LoadingSpinner";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

interface IRegisterProps {
  email: number;
  username: string;
}

const schema = yup.object({
  email: yup.string().required().email(),
  username: yup.string().required().max(256, "The name must be shorter than 256 characters."),
});

export default function LoginForm() {
  const methods = useForm<IRegisterProps>({ resolver: yupResolver(schema) });
  const [register, checkRegister] = useMutationWrapper(REGISTER);
  const navigate = useNavigate();
  const formInput = {
    email: "",
    username: "",
  };

  if (checkRegister.loading) {
    return <LoadingSpinner />;
  }

  const formSubmitHandler: SubmitHandler<IRegisterProps> = (submitData: IRegisterProps) => {
    register({
      variables: {
        email: submitData.email,
        username: submitData.username,
        desiredAccessGroup: 3,
      },
      context: { clientName: "ADMIN" },
      onCompleted: (data) => {
        notifySuccess("Registration successful!");
        navigate("/setting/user");
      },
      refetchQueries: [{ query: GET_ALL_USER, context: { clientName: "ADMIN" } }],
      onError: (error) => {
        notifyError(error.message);
      },
    });
  };

  return (
    <Container>
      <Typography variant="pageTitle">User Registration</Typography>
      <Paper variant="page">
        <FormProvider {...methods} key="registerForm">
          <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
            <TextFieldWrapper name="email" label="E-mail" value={formInput.email} required />
            <TextFieldWrapper
              name="username"
              label="Name"
              value={formInput.username}
              required
              maxLength={256}
            />
            <SaveCancelBar />
          </form>
        </FormProvider>
      </Paper>
    </Container>
  );
}
