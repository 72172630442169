import { useContainer } from "apps/admin/components/container/ContainerContext";
import AssistantListValueSummary from "./AssistantListValueSummary";

interface AssistantContainerSummaryProps {
  containerIDs: number[];
}
/**
 * The AssistantContainerSummaryProps component
 * @param {AssistantContainerSummaryProps} props
 * @returns a AssistantContainerSummary component
 */
const AssistantContainerSummary = ({
  containerIDs,
}: AssistantContainerSummaryProps): JSX.Element => {
  const { allContainers } = useContainer();

  const nameList = allContainers
    .filter((container) => containerIDs.includes(container.id))
    .map((container) => container.name);

  return <AssistantListValueSummary label="Container" values={nameList} variant="green-card" />;
};

export default AssistantContainerSummary;
