import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { TranslationPopUpConfig } from "./types";
import useTranslationForm from "./useTranslationForm";

interface TranslationPopUpProps {
  open: boolean;
  config: TranslationPopUpConfig;
  onClose: () => void;
}

/**
 * The TranslationPopUp component
 * @param {TranslationPopUpProps} props
 * @returns a TranslationPopUp component
 */
const TranslationPopUp = ({ open, config, onClose }: TranslationPopUpProps): JSX.Element => {
  //  HOOKS
  const [translation, setTranslation] = useState(config.translatedTextContent);
  const { setTranslationValue } = useTranslationForm();

  const onSaveHandler = () => {
    setTranslationValue(
      config.languageId,
      config.translationCollectionId,
      translation,
      config.translationId,
    );
    onClose();
  };

  const navigateToGoogleTranslate = () => {
    const encodedText = encodeURIComponent(config.englishTextContent);
    const iso = config.translationLanguageIso.toLowerCase();
    const url = `https://translate.google.com/?sl=en&tl=${iso}&text=${encodedText}&op=translate`;
    window.open(url, "_blank", "noopener noreferrer");
  };

  useEffect(() => {
    setTranslation(config.translatedTextContent);
  }, [config]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Translate</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              aria-readonly
              multiline
              fullWidth
              value={config.englishTextContent}
              label="English"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              margin="normal"
              label={`${config.translationLanguageName} Translation`}
              value={translation}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setTranslation(e.target.value);
              }}
              inputRef={(input) => input && input.focus()}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth onClick={navigateToGoogleTranslate}>
              Open with google translate
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSaveHandler}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TranslationPopUp;
