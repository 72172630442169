import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem } from "@mui/material";
import DocumentationModal from "libs/ui/components/documentation-modal/DocumentationModal";
import React from "react";
import { useHelpContext } from "../../../../jtm/components/url/HelpContext";

/**
 * The HelpMenu component
 * @returns a HelpMenu component
 */
const HelpMenu = (): JSX.Element => {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const { articleId } = useHelpContext();

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElement(null);
  };
  return (
    <>
      <IconButton
        size="medium"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit">
        <FontAwesomeIcon icon={faCircleQuestion} />
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElement)}
        onClose={closeMenu}>
        <a href="https://docs.jentis.com/v3/en" target="_blank" rel="noreferrer">
          <MenuItem onClick={closeMenu}>Documentation</MenuItem>
        </a>

        {anchorElement &&
          articleId &&
          Array.isArray(articleId) &&
          articleId.map((e) => {
            return (
              <DocumentationModal
                articleId={e[1] as string}
                buttonText={`Open in app documentation for ${e[0]} variables`}
              />
            );
          })}
        {articleId && !Array.isArray(articleId) && (
          <DocumentationModal articleId={articleId as string} />
        )}
      </Menu>
    </>
  );
};

export default HelpMenu;
