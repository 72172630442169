import { yupResolver } from "@hookform/resolvers/yup";
import { Container } from "@mui/material";
import GET_ALL_USER, {
  BackendNexusUserGroupType,
  GET_USER,
  GET_USER_GROUPS,
  GetUserGroupsQueryDefinition,
  UPDATE_USER,
  UpdateUserQueryDefinition,
} from "apps/admin/business/user/queries";
import RequiresAccessGroupWrapper from "apps/admin/components/RequiresAccessGroupWrapper";
import { getUpdateOptions } from "libs/business/apollo/updateOptions";
import UserService from "libs/business/authentication/UserService";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import SelectWrapper, { Option } from "libs/ui/components/input/SelectWrapper";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import Page from "libs/ui/components/surfaces/page/Page";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

interface IUserProps {
  config: any;
}

interface IUserEditProps {
  email: number;
  username: string;
}

const schema = yup.object({});

export default function UserPermission({ config }: IUserProps) {
  const methods = useForm<IUserEditProps>({ resolver: yupResolver(schema) });
  const userGroupsResponse = useSimpleFetching<GetUserGroupsQueryDefinition>({
    query: GET_USER_GROUPS,
    context: "ADMIN",
  });
  const [updateUserBeingEdited] = useMutationWrapper<UpdateUserQueryDefinition>(UPDATE_USER);
  const [userBeingEdited] = useState(config);
  const editingUserAccessGroup = UserService.getAccessGroup();

  const canEdit =
    (editingUserAccessGroup === 1 || editingUserAccessGroup === 2) &&
    userBeingEdited.group.id !== 1;

  if (userGroupsResponse === false) return <PageLoadingSpinner />;

  const transformFetchedUserGroups = (fetchedGroups: BackendNexusUserGroupType[]) => {
    const groups: Option[] = [];
    for (const group of fetchedGroups) {
      const optionIsNotSuperadmin = group.id !== 1;
      const userBeingEditIsSuperadmin = userBeingEdited.group.id === 1;

      if (optionIsNotSuperadmin || userBeingEditIsSuperadmin) {
        groups.push({
          key: group.id,
          value: group.name,
        });
      }
    }
    return groups;
  };

  const formSubmitHandler: SubmitHandler<IUserEditProps> = (submitData: IUserEditProps) => {
    updateUserBeingEdited(
      getUpdateOptions(
        "User",
        "ADMIN",
        {
          id: config.id,
          ...submitData,
        },
        GET_ALL_USER,
        { REFETCH_QUERY: GET_USER, context: "ADMIN", variables: { id: config.id } },
      ),
    );
  };

  const transformedGroups = transformFetchedUserGroups(userGroupsResponse.userGroups);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Page title="General Settings">
          <TextFieldWrapper
            name="username"
            label="Name"
            value={userBeingEdited.username}
            readonly={!canEdit}
            maxLength={256}
          />
          <TextFieldWrapper
            name="email"
            label="E-mail"
            value={userBeingEdited.email}
            readonly={!canEdit}
          />
          <SelectWrapper
            name="permissionGroupId"
            label="Permission group"
            value={userBeingEdited.group.id}
            options={transformedGroups}
            readonly={!canEdit}
          />
          <Container>
            {canEdit && (
              <RequiresAccessGroupWrapper accessGroups={[1, 2]} hideChildren>
                <SaveCancelBar />
              </RequiresAccessGroupWrapper>
            )}
          </Container>
        </Page>
      </form>
    </FormProvider>
  );
}
