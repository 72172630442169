import React, { memo, ReactNode, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useController, useFormContext, get } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import { notifyError } from "libs/business/notification/notification";
import { IdAndName, KeyValuePair } from "../../../model/types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IMultiSelectWrapperProps {
  name: string;
  label: string;
  value?: KeyValuePair["key"][] | IdAndName["id"][];
  options: KeyValuePair<string>[] | IdAndName[];
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  helperText?: string;
  maxSelection?: number;
  onChange?: ((newValue: KeyValuePair["key"][]) => void) | ((newValue: IdAndName["id"][]) => void);
}
/**
 * The MultiSelectWrapper component
 * @param {IMultiSelectWrapperProps} props
 * @returns a MultiSelectWrapper component
 */
const MultiSelectWrapper = ({
  name,
  label,
  value = [],
  options = [],
  required = false,
  readonly = false,
  disabled = false,
  hidden = false,
  helperText = "",
  maxSelection = Infinity,
  onChange,
}: IMultiSelectWrapperProps): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { field } = useController({
    control,
    name,
    defaultValue: value,
  });

  const nameErrors = get(errors, name);
  const [uiElementValue, setUiElementValue] = useState(value);

  const changeValue = (changedValue: typeof value): void => {
    if (changedValue.length <= maxSelection) {
      setUiElementValue(changedValue);
      setHookFormValue(changedValue);
      runCustomOnChangeFunction(changedValue);
    } else {
      notifyError(`Only ${maxSelection} items can be selected at once!`);
    }
  };

  const setHookFormValue = (changedValue: typeof value) => {
    field.onChange(changedValue);
  };

  const runCustomOnChangeFunction = (changedValue: any) => {
    if (typeof onChange !== "undefined") {
      onChange(changedValue);
    }
  };

  const renderSelectedValues = (selected: typeof value): ReactNode => {
    const arrayForSort = [...selected];
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {selected.map((selectedKey) => {
          return <Chip key={selectedKey} label={getValueOfOptionByKey(selectedKey)} />;
        })}
      </Box>
    );
  };

  const getValueOfOptionByKey = (key: KeyValuePair["key"] | IdAndName["id"]) => {
    for (const option of options) {
      if ("key" in option && option.key === key) {
        return option.value;
      }
      if ("id" in option && option.id === key) {
        return option.name;
      }
    }
    return "Not found";
  };

  const onChangeHandler = useCallback(
    (e: SelectChangeEvent<KeyValuePair["key"][]>) => changeValue(transformValue(e.target.value)),
    [],
  );

  // for changes not made by a manual input
  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(uiElementValue)) {
      changeValue(value);
    }
  }, [value]);

  const getMenuItem = (option: typeof options[0]) => {
    if ("key" in option) {
      return (
        <MenuItem key={option.key} value={option.key} data-cy={`${name}-option-${option.key}`}>
          {option.value}
        </MenuItem>
      );
    }
    return (
      <MenuItem key={option.id} value={option.id} data-cy={`${name}-option-${option.id}`}>
        {option.name}
      </MenuItem>
    );
  };

  return (
    <FormControl error={!!nameErrors} fullWidth>
      <InputLabel id="MultiSelectLabel">{label}</InputLabel>
      <Select
        labelId="MultiSelectLabel"
        id="MultiSelect"
        multiple
        fullWidth
        error={!!nameErrors}
        required={required}
        readOnly={readonly}
        disabled={disabled}
        hidden={hidden}
        value={uiElementValue}
        onChange={onChangeHandler}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={renderSelectedValues}
        MenuProps={MenuProps}
        data-cy={`${name}-openMenuButton`}
        inputProps={{ "data-cy": name }}>
        {options.map((option) => getMenuItem(option))}
      </Select>
      <FormHelperText data-cy={`${name}-helperText`}>
        {nameErrors?.message ?? helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default memo(MultiSelectWrapper);

const transformValue = (changedValue: KeyValuePair["key"][] | string) => {
  let transformedValue: KeyValuePair["key"][];
  if (typeof changedValue === "string") {
    transformedValue = changedValue.split(",");
    if (Number(transformedValue[0]))
      transformedValue = changedValue.split(",").map((element) => parseInt(element, 10));
  } else {
    transformedValue = changedValue;
  }
  return transformedValue;
};
