import {
  faCode,
  faTag,
  faFunction,
  faHome,
  faTags,
  faBolt,
  faSatelliteDish,
  faCubes,
  faCube,
  IconDefinition,
  faGear,
  faUpload,
  faAddressBook,
  faAddressCard,
  faLanguage,
  faListTree,
  faSection,
} from "@fortawesome/pro-regular-svg-icons";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import getRoutePath from "libs/business/routes/routes";
import { SHOW_TRANSLATION } from "../pages/container/ContainerForm";

export interface IMainNavElement {
  name: string;
  icon: IconDefinition;
  to: string;
  subNav?: IGroupNavElement[];
}

export interface IGroupNavElement {
  groupname?: string;
  elements: ISubNavElement[];
}

export interface ISubNavElement {
  name: string;
  icon?: IconDefinition;
  to: string;
}

const legalManagerConfigElements: ISubNavElement[] = [
  { name: "Consents", icon: faAddressBook, to: getRoutePath("cmp", "consents") },
  { name: "Consentbars", icon: faAddressCard, to: getRoutePath("cmp", "consentbars") },
];

if (SHOW_TRANSLATION) {
  legalManagerConfigElements.push({
    name: "Translation",
    icon: faLanguage,
    to: getRoutePath("cmp", "translation"),
  });
}

const config: IMainNavElement[] = [
  {
    name: "Home",
    icon: faHome,
    to: getRoutePath("admin", "home"),
  },
  {
    name: "Tools",
    icon: faLink,
    to: getRoutePath("jtm", "tools"),
  },
  {
    name: "Server Tag Manager",
    icon: faTag,
    to: getRoutePath("jtm", "server_tag_management"),
    subNav: [
      {
        groupname: "Tagging",
        elements: [{ name: "Tags", icon: faTags, to: getRoutePath("jtm", "tags") }],
      },
      {
        groupname: "Elements",
        elements: [
          { name: "Functions", icon: faFunction, to: getRoutePath("jtm", "functions") },
          { name: "Triggers", icon: faBolt, to: getRoutePath("jtm", "triggers") },
          { name: "States", icon: faSatelliteDish, to: getRoutePath("jtm", "states") },
          { name: "Variables", icon: faCubes, to: getRoutePath("jtm", "variables") },
        ],
      },
      {
        groupname: "Components",
        elements: [
          { name: "Plugins", icon: faCube, to: getRoutePath("jtm", "plugins") },
          { name: "Codes", icon: faCode, to: getRoutePath("jtm", "codes") },
        ],
      },
    ],
  },
  {
    name: "Legal Manager",
    icon: faSection,
    to: getRoutePath("cmp", "home"),
    subNav: [
      {
        groupname: "Consent",
        elements: legalManagerConfigElements,
      },
    ],
  },
  {
    name: "Publish",
    icon: faUpload,
    to: getRoutePath("jtm", "versions"),
  },
  // {
  //   name: "Preview Monitor",
  //   icon: faDesktop,
  //   to: getRoutePath("cmp", "home"),
  //   subNav: [
  //     {
  //       groupname: "Preview",
  //       elements: [{ name: "cmp1", to: "/test/cmp1" }],
  //     },
  //   ],
  // },
  {
    name: "Settings",
    icon: faGear,
    to: getRoutePath("admin", "settings"),
    subNav: [
      {
        groupname: "Basic",
        elements: [{ name: "Containers", to: getRoutePath("admin", "container") }],
      },
      {
        groupname: "User",
        elements: [{ name: "User administration", to: getRoutePath("admin", "users") }],
      },
      {
        groupname: "Consent",
        elements: [
          {
            name: "Consent administration",
            icon: faAddressCard,
            to: getRoutePath("admin", "consent_administration"),
          },
          {
            name: "Category Manager",
            icon: faListTree,
            to: getRoutePath("cmp", "category_manager"),
          },
        ],
      },
      {
        groupname: "Guide",
        elements: [{ name: "Tracking code and DNS ", to: getRoutePath("setup", "home") }],
      },
    ],
  },
];

export default config;
