import { faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import { Link, Tooltip, Typography } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridEnrichedColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useContainer } from "apps/admin/components/container/ContainerContext";
import UPDATE_SETUP_STEP from "apps/setup/business/container/mutations";
import { GET_STATUS_PER_CONTAINER } from "apps/setup/business/container/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import UserService from "libs/business/authentication/UserService";
import customToolBarGridProps from "libs/ui/components/datagrid/CustomToolBar";
import LoadingSpinner from "libs/ui/components/feedback/loading-spinner/LoadingSpinner";
import { FC, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getUpdateStepOptions } from "../steps/getUpdateStepOptions";

interface IContainerStatus {
  id: number;
  url: string;
  dns: boolean;
  snippet: boolean;
  name: string;
}

const ContainersGuideGrid: FC = () => {
  const userHasAccess = UserService.getAccessGroup() === 1;
  const [updateSetupStep] = useMutationWrapper(UPDATE_SETUP_STEP);

  const { allContainers } = useContainer();
  const [getStatusPerContainerQuery, checkGetStatusPerContainerQuery] =
    useQueryWrapper(GET_STATUS_PER_CONTAINER);
  const containersWithStatus: IContainerStatus[] = [];

  const createContainerStatusesData = () => {
    if (checkGetStatusPerContainerQuery.data)
      for (let i = 0; i < allContainers.length; i += 1) {
        containersWithStatus.push({
          id: allContainers[i].id,
          name: allContainers[i].name,
          url: allContainers[i].live_url as string,
          dns: checkGetStatusPerContainerQuery.data.getStatusPerContainer.containerStatuses[i].dns,
          snippet:
            checkGetStatusPerContainerQuery.data.getStatusPerContainer.containerStatuses[i].snippet,
        });
      }
  };
  if (checkGetStatusPerContainerQuery.data && allContainers.length > 0) {
    createContainerStatusesData();
  }

  const onSetupStepChange = useCallback(
    (params: GridRowParams<any>, variant: "snippet" | "dns") => () => {
      const containerId = params.id;
      let variables;
      switch (variant) {
        case "snippet":
          variables = {
            setupStepNumber: `2_1_${containerId}`,
            done: !params.row.snippet,
          };
          break;
        case "dns":
          variables = {
            setupStepNumber: `3_1_${containerId}`,
            done: !params.row.dns,
          };
          break;
        default:
          break;
      }
      updateSetupStep(getUpdateStepOptions(variables));
    },
    [allContainers, updateSetupStep],
  );

  const ActionArrayDOM = (params: GridRowParams) => {
    const gridActionArray = [
      <GridActionsCellItem
        icon={<FontAwesomeIcon icon={faRepeat} />}
        label="Switch tracking code status"
        onClick={onSetupStepChange(params, "snippet")}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<FontAwesomeIcon icon={faRepeat} />}
        label="Switch dns code status"
        onClick={onSetupStepChange(params, "dns")}
        showInMenu
      />,
    ];
    if (userHasAccess) {
      return gridActionArray;
    }
    return [];
  };

  const columns: GridEnrichedColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Container name",
      width: 150,
    },
    {
      field: "url",
      headerName: "Url",
      width: 180,
    },
    {
      field: "snippet",
      headerName: "Tracking code status",
      width: 200,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip title="Click to see the status">
            <Link
              component={RouterLink}
              to={`trackingcode/${params.id}`}
              sx={{
                display: "flex",
                allignItems: "center",
                gap: "5px",
                pt: "5px",
                textDecoration: "underline",
              }}>
              {params.value ? <Typography>Done</Typography> : <Typography>Pending</Typography>}
              {params.value ? (
                <CheckCircleIcon sx={{ color: "success.main" }} />
              ) : (
                <PendingIcon sx={{ color: "warning.main" }} />
              )}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "dns",
      headerName: "DNS Entries Status",
      width: 160,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Tooltip title="Click to see the status">
            <Link
              component={RouterLink}
              to={`dns/${params.id}`}
              sx={{
                display: "flex",
                allignItems: "center",
                gap: "5px",
                pt: "5px",
                textDecoration: "underline",
              }}>
              {params.value ? <Typography>Done</Typography> : <Typography>Pending</Typography>}
              {params.value ? (
                <CheckCircleIcon sx={{ color: "success.main" }} />
              ) : (
                <PendingIcon sx={{ color: "warning.main" }} />
              )}
            </Link>
          </Tooltip>
        );
      },
    },
  ];
  if (userHasAccess) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: ActionArrayDOM,
    });
  }

  if (!checkGetStatusPerContainerQuery.called) {
    getStatusPerContainerQuery({
      context: { clientName: "SETUP" },
    });
  }
  if (!checkGetStatusPerContainerQuery.data && allContainers.length === 0) {
    return <LoadingSpinner />;
  }
  return (
    <DataGrid
      rows={containersWithStatus}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      autoHeight
      {...customToolBarGridProps}
    />
  );
};

export default ContainersGuideGrid;
