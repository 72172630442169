import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import ColoredAccordion from "libs/ui/components/surfaces/accordion/ColoredAccordion";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { GuiListData } from "../tagTypes";
import GuiListItem from "./GuiListItem";

const noBorderRadius = {
  borderRadius: 0,
};

interface GuiListProps {
  guiListData: GuiListData;
  displayNoConsent: boolean;
  rootInputName?: string;
}

/**
 * The GuiList component
 * @param {GuiListProps} props
 * @returns a GuiList component
 */
const GuiList = ({ guiListData, displayNoConsent, rootInputName }: GuiListProps): JSX.Element => {
  const { control } = useFormContext();
  const rootName = rootInputName ? `${rootInputName}.` : "";
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: `${rootName}.${guiListData.id}`,
  });

  const [isChangedByUI, setChangedByUI] = useState<boolean>(false);

  const addEntry = () => {
    const newEntry: any = {};
    guiListData.entryDefinition.forEach((definition) => {
      newEntry[definition.id] = { value: "", noConsentValue: "" };
    });
    append(newEntry);
    setChangedByUI(true);
  };

  const deleteEntryAtIndex = (index: number) => {
    remove(index);
    setChangedByUI(true);
  };

  useEffect(() => {
    if (isChangedByUI === false && guilistDataIsDifferentThenFieldData(guiListData, fields)) {
      replace(guiListData.entries);
    }
  }, [guiListData]);

  return (
    <ColoredAccordion index={0} overrideColor="#444444">
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography>{guiListData.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" spacing={2}>
          {fields.map((item: any, index) => {
            return (
              <GuiListItem
                key={item.id}
                entryDefinition={guiListData.entryDefinition}
                values={item}
                rootInputFieldName={`${rootName}${guiListData.id}.${index}`}
                displayNoConsent={displayNoConsent}
                onDelete={() => deleteEntryAtIndex(index)}
              />
            );
          })}
          <Tooltip title={`Add Element to ${guiListData.name}`}>
            <IconButton style={noBorderRadius} onClick={addEntry}>
              <FontAwesomeIcon icon={faAdd} />
            </IconButton>
          </Tooltip>
        </Stack>
      </AccordionDetails>
    </ColoredAccordion>
  );
};

export default GuiList;

const guilistDataIsDifferentThenFieldData = (guiListData: any, fields: any) => {
  if (guiListData.entries.length !== fields.length) {
    return true;
  }
  for (const entryIndex in guiListData.entries) {
    for (const entryProperty in guiListData.entries[entryIndex]) {
      const guilistProperty = guiListData.entries[entryIndex][entryProperty];
      const fieldsProperty = fields[entryIndex][entryProperty];
      if (JSON.stringify(guilistProperty.value) !== JSON.stringify(fieldsProperty.value)) {
        return true;
      }
      if (
        guilistProperty.noConsentValue &&
        JSON.stringify(guilistProperty.noConsentValue) !==
          JSON.stringify(fieldsProperty.noConsentValue)
      ) {
        return true;
      }
    }
  }
  return false;
};
