import { gql } from "@apollo/client";
import { GQLTypeDefinition, ListResponse, SingleResponse } from "libs/model/types";

export interface BackendNexusContainerDetails {
  id: number;
  name: string;
  short: string;
  live_url: string;
  test_url: string;
  hash: string;
  file_hash: string;
  languages: number[];
}

export interface GetContainerQueryDefinition extends GQLTypeDefinition {
  dataType: BackendNexusContainerDetails;
  response: SingleResponse<"getContainer", GetContainerQueryDefinition["dataType"]>;
  arguments: { id: number };
}

export const GET_CONTAINER = gql`
  query getContainer($id: Int!) {
    getContainer(id: $id) {
      id
      name
      short
      live_url
      test_url
      hash
      file_hash
      languages
    }
  }
`;

export interface UpdateContainerQueryDefinition extends GQLTypeDefinition {
  dataType: BackendNexusContainerDetails;
  response: SingleResponse<"updateContaienr", UpdateContainerQueryDefinition["dataType"]>;
  arguments: BackendNexusContainerDetails;
}

export const UPDATE_CONTAINER = gql`
  mutation updateContainer(
    $id: Int!
    $name: String!
    $short: String!
    $live_url: String!
    $test_url: String!
    $hash: String!
    $file_hash: String!
    $languages: [Int!]!
  ) {
    updateContainer(
      id: $id
      name: $name
      short: $short
      live_url: $live_url
      test_url: $test_url
      hash: $hash
      file_hash: $file_hash
      languages: $languages
    ) {
      id
      name
      short
      live_url
      test_url
      hash
      file_hash
      languages
    }
  }
`;

export interface CreateContainerQueryDefinition extends GQLTypeDefinition {
  dataType: BackendNexusContainerDetails;
  response: SingleResponse<"createContainer", CreateContainerQueryDefinition["dataType"]>;
  arguments: Omit<BackendNexusContainerDetails, "id">;
}

export const CREATE_CONTAINER = gql`
  mutation createContainer(
    $name: String!
    $short: String!
    $live_url: String!
    $test_url: String!
    $hash: String!
    $file_hash: String!
    $languages: [Int!]!
  ) {
    createContainer(
      name: $name
      short: $short
      live_url: $live_url
      test_url: $test_url
      hash: $hash
      file_hash: $file_hash
      languages: $languages
    ) {
      id
      name
      short
      live_url
      test_url
      hash
      file_hash
      languages
    }
  }
`;

export interface GetAllContainerQueryDefinition extends GQLTypeDefinition {
  dataType: BackendNexusContainerDetails;
  response: ListResponse<"containers", GetAllContainerQueryDefinition["dataType"]>;
  context: "ADMIN";
}

export const GET_ALL_CONTAINERS = gql`
  query containers {
    containers {
      id
      name
      short
      live_url
      test_url
      hash
      file_hash
      languages
    }
  }
`;

export interface DeleteContainerQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusContainerDetails, "id">;
  response: SingleResponse<"deleteContainer", DeleteContainerQueryDefinition["dataType"]>;
  arguments: { id: number };
}
export const DELETE_CONTAINER = gql`
  mutation deleteContainer($id: Int!) {
    deleteContainer(id: $id) {
      id
    }
  }
`;
