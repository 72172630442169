import ConsentCards from "../../../ConsentCard/ConsentCards";
import { TagDetailData } from "../../type";

interface ConsentTabContentProps {
  detailData: TagDetailData;
}
/**
 * The ConsentTabContent component
 * @param {ConsentTabContentProps} props
 * @returns a ConsentTabContent component
 */
const ConsentTabContent = ({ detailData }: ConsentTabContentProps): JSX.Element => {
  return <ConsentCards consent={detailData.consent} />;
};

export default ConsentTabContent;
