import { Button, Container, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { jtmPrefix } from "apps/jtm/conf/routes";
import completedPageImage from "assets/images/custom/CompletedPageImage.svg";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import LinkStyledButton from "../buttons/LinkStyledButton";

interface AssistantCompletedPageProps {
  title: string;
  subTitle?: string;
  linkText?: string;
  showPublishButton?: boolean;
  listPath?: string;
  children?: ReactNode;
}
/**
 * The AssistantCompletedPage component
 * @param {AssistantCompletedPageProps} props
 * @returns a AssistantCompletedPage component
 */
const AssistantAssistantCompletedPage = ({
  title,
  subTitle,
  linkText = "to get to the overview",
  listPath,
  showPublishButton = false,
  children,
}: AssistantCompletedPageProps): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Container>
      <Stack alignItems="center" style={{ height: "100%" }}>
        <img alt="completedPage" src={completedPageImage} style={{ height: "400px" }} />
        <Typography variant="h2" fontWeight="500" align="center">
          {title}
        </Typography>
        <Typography variant="bodyRegular16" align="center" sx={{ mt: 1 }}>
          {subTitle}
        </Typography>
        {!!listPath && (
          <Stack alignItems="center" spacing={2} sx={{ mt: 2 }}>
            {showPublishButton && (
              <>
                <Button variant="outlined" onClick={() => navigate(`${jtmPrefix}/versions`)}>
                  Yes, continue
                </Button>
                <Typography variant="bodyRegular16" sx={{ color: "text.secondary" }}>
                  Or
                </Typography>
              </>
            )}
            <Stack justifyContent="center " direction="row" alignItems="baseline">
              <LinkStyledButton onClick={() => navigate(listPath)}>
                <Typography variant="bodyMedium16" component="span">
                  Click here
                </Typography>
              </LinkStyledButton>
              <Typography variant="bodyMedium16" component="span">
                {linkText}
              </Typography>
            </Stack>
          </Stack>
        )}
        {children}
      </Stack>
    </Container>
  );
};

export default AssistantAssistantCompletedPage;
