import { GET_ALL_TOOL_DEFINITIONS } from "apps/jtm/business/tool/queries";
import PluginSelection from "apps/jtm/components/shared/plugin-selection/PluginSelection";
import getRoutePath from "libs/business/routes/routes";

const ToolSelection = (): JSX.Element => {
  return (
    <PluginSelection
      query={GET_ALL_TOOL_DEFINITIONS}
      redirectLinkCard={getRoutePath("jtm", "tool_new")}
    />
  );
};

export default ToolSelection;
