import LoginScreen from "./LoginScreen";
import PasswordForgotForm from "./PasswordForgotForm";

export default function PasswordForgot() {
  return (
    <LoginScreen>
      <PasswordForgotForm />
    </LoginScreen>
  );
}
