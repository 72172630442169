import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContainer } from "apps/admin/components/container/ContainerContext";
import {
  GetContainerStatusByIdQueryDefinition,
  GetCustomerQueryDefinition,
  GET_CONTAINER_STATUS_BY_ID,
  GET_CUSTOMER,
} from "apps/setup/business/container/queries";
import AdminStepUpdater from "apps/setup/components/steps/adminStepUpdater";
import SwitchStepStatus from "apps/setup/components/steps/SwitchStepStatus";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import Page from "libs/ui/components/surfaces/page/Page";
import { useParams } from "react-router-dom";

const DNS = (): JSX.Element => {
  const { id } = useParams();
  const { getContainerByID } = useContainer();

  if (typeof id === "undefined") {
    throw new Error("id is not defined as query param");
  }
  const containerId = parseInt(id, 10);
  const container = getContainerByID(containerId);

  if (typeof container === "undefined") {
    throw new Error(`container with id ${id} doesn't exist!`);
  }

  const createDNSData = (type: string, domain: string, target: string) => {
    return { type, domain, target };
  };

  const [GetCustomerQuery, checkGetCustomerQuery] =
    useQueryWrapper<GetCustomerQueryDefinition>(GET_CUSTOMER);

  if (!checkGetCustomerQuery.called) {
    GetCustomerQuery({
      context: { clientName: "SETUP" },
    });
  }

  const [GetContainerByIdQuery, checkGetContainerByIdQuery] =
    useQueryWrapper<GetContainerStatusByIdQueryDefinition>(GET_CONTAINER_STATUS_BY_ID);

  if (!checkGetContainerByIdQuery.called && container) {
    GetContainerByIdQuery({
      context: { clientName: "SETUP" },
      variables: { containerId },
    });
  }

  if (!checkGetContainerByIdQuery.data || !checkGetCustomerQuery.data || container === "LOADING")
    return <PageLoadingSpinner />;

  const rows = [
    createDNSData(
      container.name,
      `${container.hash}.${container.live_url}`,
      checkGetCustomerQuery.data.getCustomer.ipOfCluster,
    ),
  ];

  return (
    <Page title="DNS">
      <SwitchStepStatus allowedAccessGroups={[1]}>
        <AdminStepUpdater
          className="admin"
          containerId={containerId}
          defaultValue={checkGetContainerByIdQuery.data.getContainerStatus.dns}
          stepNumber={`3_1_${containerId}`}
          message="You as SUPERADMIN user can change the status of DNS settings. Please make sure that customer really made changes to DNS settings before pushing the changes."
          containerName="dns"
        />
        <Box className="user">
          <Box
            sx={{
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "0.5rem",
              gap: "1rem",
              minWidth: "568px",
            }}>
            <>
              <AlertBox
                type="info"
                message="One of the central concepts at JENTIS is that all communication between browser and JENTIS must only take place via 1st party domains. For this purpose it is necessary that you make the followingentries in your DNS."
                title="Important"
                boxStyles={{ display: "flex", flex: "6" }}
              />

              {checkGetContainerByIdQuery.data.getContainerStatus.dns ? (
                <AlertBox
                  type="success"
                  message="You have already added setting to your DNS"
                  title="Success"
                  boxStyles={{ display: "flex" }}
                />
              ) : (
                <AlertBox
                  type="warning"
                  message="Please add this settings in your DNS"
                  title="Warning"
                  boxStyles={{ display: "flex" }}
                />
              )}
            </>
          </Box>

          <AlertBox
            type="warning"
            message="If you are using a Content Delivery Network(CDN), such as Cloudflare, Akamai, etc., please inform your Client Success Manager(CSM)"
            title="Warning"
            boxStyles={{ mb: "0.5rem" }}
          />
        </Box>
      </SwitchStepStatus>

      <TableContainer component={Paper}>
        <TableContainer sx={{ minWidth: 650 }} aria-label="simple table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>DNS Domain</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>DNS Target</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.domain}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell align="left">{row.domain}</TableCell>
                  <TableCell align="left">{row.target}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContainer>
    </Page>
  );
};

export default DNS;
