import { Stack } from "@mui/material";
import GET_LANGUAGES from "apps/cmp/business/languages/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { IdAndName } from "libs/model/types";
import LoadComponentSpinner from "libs/ui/components/feedback/loading-spinner/LoadComponentSpinner";
import MultiSelectWrapper from "libs/ui/components/input/MultiSelectWrapper";

export interface ILanguageSelectorProps {
  selected: number[];
  id: string;
  disabled: number;
  helperText?: string;
}

const LanguageMultiSelect = ({
  selected,
  id,
  disabled,
  helperText = "",
}: ILanguageSelectorProps): JSX.Element => {
  const getLanguagesResponse = useSimpleFetching({
    query: GET_LANGUAGES,
    context: "CMP",
  });
  const allLanguages: IdAndName[] = [];
  const selectedLanguages = selected ?? [];

  if (getLanguagesResponse === false) {
    return <LoadComponentSpinner />;
  }

  for (const language of getLanguagesResponse.Languages) {
    allLanguages.push({
      id: language.id,
      name: language.text_content.text,
    });
  }

  return (
    <Stack>
      <MultiSelectWrapper
        name={id}
        label="Languages"
        options={allLanguages}
        value={[...selectedLanguages]}
        disabled={Boolean(disabled)}
        helperText={helperText}
      />
    </Stack>
  );
};

export default LanguageMultiSelect;
