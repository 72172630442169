import { gql } from "@apollo/client";
import { AccessGroups } from "libs/business/authentication/UserStorageManager";
import { GQLTypeDefinition, ListResponse, SingleResponse } from "libs/model/types";

export interface BackendNexusUserType {
  id: number;
  username: string;
  email: string;
  auth_code: string;
  use_2fa: number;
  group: BackendNexusUserGroupType;
}

export interface BackendNexusUserGroupType {
  id: AccessGroups;
  name: string;
}

export interface GetAllUsersQueryDefinition extends GQLTypeDefinition {
  dataType: Omit<BackendNexusUserType, "auth_code" | "use_2fa">;
  response: ListResponse<"users", GetAllUsersQueryDefinition["dataType"]>;
  context: "ADMIN";
}

export const GET_ALL_USER = gql`
  query users {
    users {
      id
      username
      email
      group {
        id
        name
      }
    }
  }
`;

export interface GetUserQueryDefinition extends GQLTypeDefinition {
  dataType: BackendNexusUserType;
  response: SingleResponse<"user", GetUserQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "ADMIN";
}

export const GET_USER = gql`
  query user($id: Int!) {
    user(id: $id) {
      id
      username
      email
      group {
        id
      }
      auth_code
      use_2fa
    }
  }
`;

export interface UpdateUserQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusUserType, "id" | "username" | "email">;
  response: SingleResponse<"updateUser", UpdateUserQueryDefinition["dataType"]>;
  arguments: {
    id: number;
    username: string;
    email: string;
    permissionGroupId: number;
  };
  context: "ADMIN";
}

export const UPDATE_USER = gql`
  mutation updateUser($id: Int!, $username: String!, $email: String!, $permissionGroupId: Int!) {
    updateUser(id: $id, username: $username, email: $email, permissionGroupId: $permissionGroupId) {
      id
      username
      email
    }
  }
`;

export interface GetUserGroupsQueryDefinition extends GQLTypeDefinition {
  dataType: BackendNexusUserGroupType;
  response: ListResponse<"userGroups", GetUserGroupsQueryDefinition["dataType"]>;
  arguments?: {
    id: number;
  };
  context: "ADMIN";
}

export const GET_USER_GROUPS = gql`
  query userGroups {
    userGroups {
      id
      name
    }
  }
`;

export interface DeleteUserQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusUserGroupType, "id">;
  response: SingleResponse<"deleteUser", DeleteUserQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "ADMIN";
}

export const DELETE_USER_BY_ID = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const SET_2FA = gql`
  mutation set2FA($set2FaId: Int!, $status: Int!) {
    set2FA(id: $set2FaId, status: $status) {
      id
      username
      email
      auth_code
      use_2fa
      group {
        id
      }
    }
  }
`;

export const REGISTER = gql`
  mutation register($username: String!, $email: String!, $desiredAccessGroup: Int!) {
    register(username: $username, email: $email, desiredAccessGroup: $desiredAccessGroup)
  }
`;

export default GET_ALL_USER;
