import { Box, Paper } from "@mui/material";
import { Stack } from "@mui/system";
import AssistantWizardStepNumber from "./AssistantWizardStepNumber";

interface AssistantWizardProgressBarProps {
  stepConfig: { title: string }[];
  currentStep: number;
  isLoading: boolean;
}
/**
 * The AssistantWizardProgressBar component
 * @param {AssistantWizardProgressBarProps} props
 * @returns a AssistantWizardProgressBar component
 */
const AssistantWizardProgressBar = ({
  stepConfig,
  currentStep,
  isLoading,
}: AssistantWizardProgressBarProps): JSX.Element => {
  return (
    <Paper variant="page" sx={{ p: 2, mb: 4 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        {stepConfig.map((step, index) => {
          const stepNumber = index + 1;
          const isLast = stepConfig.length === stepNumber;
          const isCompleted = stepNumber < currentStep;
          return (
            <Stack
              key={step.title}
              direction="row"
              sx={{ flex: isLast ? 0 : 1, minWidth: "max-content" }}
              alignItems="center"
              spacing={1}>
              <AssistantWizardStepNumber
                key={step.title}
                stepNumber={stepNumber}
                isLoading={stepNumber === currentStep && isLoading}
                isCurrent={stepNumber === currentStep}
                isCompleted={stepNumber < currentStep}
                title={step.title}
              />
              {!isLast && (
                <Box
                  sx={{
                    flex: 1,
                    height: "2px",
                    minWidth: 10,
                    backgroundColor: isCompleted ? "primary.main" : "divider",
                  }}
                />
              )}
            </Stack>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default AssistantWizardProgressBar;
