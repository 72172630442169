import { Typography } from "@mui/material";
import { isEmptyObject } from "libs/business/utils/objectFunctions";
import KeyValueGroup from "../../../KeyValueGroup/KeyValueGroup";
import { NameElementsPair } from "../../type";

interface NameElementTabContentProps {
  values: {
    [functionName: string]: NameElementsPair;
  };
  collapsible?: boolean;
}
/**
 * The NameElementTabContent component
 * @param {NameElementTabContentProps} props
 * @returns a NameElementTabContent component
 */
const NameElementTabContent = ({
  values,
  collapsible = true,
}: NameElementTabContentProps): JSX.Element => {
  if (isEmptyObject(values)) {
    return <Typography component="pre">No content.</Typography>;
  }
  return (
    <>
      {Object.keys(values).map((key: string) => {
        const element = values[key];
        return (
          <KeyValueGroup
            key={key}
            name={element.name}
            elements={element.elements || []}
            collapsible={collapsible}
          />
        );
      })}
    </>
  );
};

export default NameElementTabContent;
