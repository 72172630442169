import { Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import SessionRow from "../SessionRow/SessionRow";
import { RowData } from "../SessionRow/type";
import filterRowData from "./filterBusiness";
import ButtonFilters from "./FilterElements/ButtonFilters";
import ConnectorFilter, { ConnectorFilterElement } from "./FilterElements/ConnectorFilter";
import SearchFilter from "./FilterElements/SearchFilter";
import SourceFilter from "./FilterElements/SourceFilter";
import { RowFilterConfig } from "./types";

interface RowFilterProps {
  rowData: RowData[];
  deleteRows: () => void;
}
/**
 * The RowFilter component
 * @param {RowFilterProps} props
 * @returns a RowFilter component
 */
const RowFilter = ({ rowData, deleteRows }: RowFilterProps): JSX.Element => {
  // HOOKS
  const methods = useForm(); // FormProvider necessary to use SelectWrappers
  const [availableConnectors, setAvailableConnectors] = useState<ConnectorFilterElement[]>([]);

  const [filter, setFilter] = useState<RowFilterConfig>({
    source: undefined,
    showInfo: true,
    showErrorsOnly: false,
    showNegatives: false,
    connectorIds: [],
    description: "",
  });

  const filteredData = useMemo(
    () => filterRowData(rowData, filter, availableConnectors),
    [rowData, filter, availableConnectors],
  );

  // RENDERING
  return (
    <FormProvider {...methods}>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <SourceFilter filter={filter} setFilter={setFilter} />
        <SearchFilter filter={filter} setFilter={setFilter} />
        <ButtonFilters filter={filter} setFilter={setFilter} deleteRows={deleteRows} />
      </Stack>
      <ConnectorFilter
        filter={filter}
        setFilter={setFilter}
        rowData={rowData}
        availableConnectors={availableConnectors}
        setAvailableConnectors={setAvailableConnectors}
      />
      <SessionRows rowData={filteredData} />
    </FormProvider>
  );
};

export default RowFilter;

const SessionRows = ({ rowData }: { rowData: RowData[] }): JSX.Element => {
  return (
    <>
      {rowData.map((row) => {
        return <SessionRow key={`${row.id}.${row.index}`} rowData={row} hasDetails={row.details} />;
      })}
    </>
  );
};
