import useIsSetupInitialized from "libs/business/hooks/useIsSetupInitialized";
import { useLocation } from "react-router-dom";
import getLongestMatchedRouteInfo from "./business";
import MainNav from "./MainNav";
import classes from "./sidebar.module.scss";
import SubNav from "./SubNav";

/**
 * The Sidebar component
 * @returns a Sidebar component
 */
const Sidebar = () => {
  const isSetupInitialized = useIsSetupInitialized();
  const { pathname } = useLocation();
  const activeRouteInfo = getLongestMatchedRouteInfo(pathname);

  if (!isSetupInitialized) {
    return null;
  }

  return (
    <>
      <MainNav activeRouteInfo={activeRouteInfo} />
      <div className={classes.collapsedPlaceholder} />
      <SubNav activeRouteInfo={activeRouteInfo} />
    </>
  );
};

export default Sidebar;
