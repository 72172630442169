import { Box, Typography } from "@mui/material";
import { findTranslationInCollection } from "./business";
import scss from "./Translation.module.scss";
import { Language, TranslationCollection, TranslationPopUpConfig } from "./types";
import useTranslationForm from "./useTranslationForm";

interface TranslationFieldProps {
  languagesToTranslate: Language[];
  translationCollection: TranslationCollection;
  onClick: (config: TranslationPopUpConfig) => void;
}
/**
 * The TranslationField component
 * @param {TranslationFieldProps} props
 * @returns a TranslationField component
 */
const TranslationField = ({
  languagesToTranslate,
  translationCollection,
  onClick,
}: TranslationFieldProps): JSX.Element => {
  // HOOKS
  const { setTranslationValue, getTranslationValue } = useTranslationForm();

  // FUNCTIONS
  const renderInputBoxes = () => {
    return languagesToTranslate.map((language) => {
      const currentTranslation = findTranslationInCollection(translationCollection, language.id);

      const fieldValue =
        getTranslationValue(language.id, translationCollection.id) ||
        currentTranslation?.translation ||
        "";

      const onInputBoxClick = () => {
        onClick({
          languageId: language.id,
          translationLanguageName: language.name,
          translationLanguageIso: language.iso,
          translationCollectionId: translationCollection.id,
          englishTextContent: translationCollection.text,
          translatedTextContent: fieldValue || "",
          translationId: currentTranslation?.id || null,
        });
      };

      return (
        <Box
          key={`${language.id}.${translationCollection.id}`}
          onClick={onInputBoxClick}
          className={scss.gridItem}>
          <Typography>{fieldValue}</Typography>
        </Box>
      );
    });
  };

  return (
    <div className={`${scss.gridContainer} ${scss.translationField}`}>
      <Typography>{translationCollection.text}</Typography>
      {renderInputBoxes()}
    </div>
  );
};

export default TranslationField;
