import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { ReactElement, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OptionalObjectSchema } from "yup/lib/object";
import { useWizardDataUpdate, WizardDataProvider } from "./WizardDataContext";

interface IWizardProps {
  children?: ReactElement<IWizardChildProps>[];
  handleFinish: (data: any) => void;
  localStorageDataName: string;
}

const Wizard = ({ children, handleFinish, localStorageDataName }: IWizardProps): JSX.Element => {
  return (
    <WizardDataProvider localStorageDataName={localStorageDataName}>
      <WizardContent handleFinish={handleFinish} localStorageDataName={localStorageDataName}>
        {children}
      </WizardContent>
    </WizardDataProvider>
  );
};

export default Wizard;

const WizardContent = ({ children, handleFinish }: IWizardProps): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0);
  const updateWizardData = useWizardDataUpdate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const childrenArray: ReactElement<IWizardChildProps>[] = children || [];

  const emptyYupSchema = yup.object({});

  const stepContent = childrenArray[activeStep] || <WizardChild label="" schema={emptyYupSchema} />;

  const methods = useForm({
    resolver: yupResolver(stepContent.props.schema),
    // defaultValues: data[activeStep],
  });
  // console.log(data[activeStep]);

  const handleSubmit = (submittedData: any) => {
    if (activeStep === childrenArray.length - 1) {
      handleFinish(submittedData);
    } else {
      updateWizardData(submittedData);
      methods.clearErrors();
      handleNext();
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {childrenArray.map((child, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={child.props.label} {...stepProps}>
              <StepLabel {...labelProps}>{child.props.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={{ p: 2 }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            {stepContent}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep === childrenArray.length - 1 ? (
                <Button type="submit">Finish</Button>
              ) : (
                <Button type="submit">Next</Button>
              )}
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};

interface IWizardChildProps {
  label: string;
  children?: JSX.Element;
  schema: OptionalObjectSchema<any>;
}

export const WizardChild = ({ label, children, schema }: IWizardChildProps): JSX.Element => {
  return <div>{children}</div>;
};
