import { faCheck, faPenToSquare, faTimes, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import GET_CATEGORIES, {
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "apps/cmp/business/categories/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { ChangeEventHandler, useState } from "react";

interface ICategory {
  id: number;
  name: string;
  editable: boolean;
  editing: boolean;
}

export default function CategoryManager() {
  const [newCategoryCounter, setNewCategoriesCounter] = useState<number>(0);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [getCategories, checkGetCategories] = useQueryWrapper(GET_CATEGORIES);
  const [addCategory, checkAddCategory] = useMutationWrapper(ADD_CATEGORY);
  const [updateCategory, checkUpdateCategory] = useMutationWrapper(UPDATE_CATEGORY);
  const [deleteCategory, checkDeleteCategory] = useMutationWrapper(DELETE_CATEGORY);
  const [textFieldErrorMsg, setTextFieldErrorMsg] = useState("");

  if (!checkGetCategories.called) {
    getCategories({
      context: { clientName: "CMP" },
      onCompleted: (data) => {
        setCategories(() => {
          return data.Categories.map((category: any) => {
            return {
              id: category.id,
              name: category.text_content.text,
              editable: category.id > 5,
              editing: false,
            };
          });
        });
      },
    });
  }

  if (
    checkGetCategories.error ||
    checkAddCategory.error ||
    checkUpdateCategory.error ||
    checkDeleteCategory.error
  ) {
    notifyError("Cannot reach the server!");
  }

  if (!checkGetCategories.called || checkGetCategories.loading) {
    return <PageLoadingSpinner />;
  }

  const changeCategoryValueByID = (
    id: number,
    field: "id" | "name" | "editable" | "editing",
    value: number | string | boolean,
  ) => {
    setCategories((prevState) => {
      const newCategories: ICategory[] = [];

      for (const category of prevState) {
        if (category.id !== id) {
          newCategories.push(category);
        } else {
          newCategories.push({
            id: field === "id" ? (value as number) : category.id,
            name: field === "name" ? (value as string) : category.name,
            editable: field === "editable" ? (value as boolean) : category.editable,
            editing: field === "editing" ? (value as boolean) : category.editing,
          });
        }
      }

      return newCategories;
    });
  };

  const deleteCategoryFromStateByID = (id: number) => {
    setCategories((prevCategories) => {
      const newCategories: ICategory[] = [];

      for (const category of prevCategories) {
        if (category.id !== id) {
          newCategories.push(category);
        }
      }

      return newCategories;
    });
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    changeCategoryValueByID(parseInt(event.target.id, 10), "name", event.target.value);
  };

  const listItemsDOM = categories.map((item: ICategory) => {
    let buttons = (
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
        key={`button-group-${item.id}`}
      />
    );
    let text = (
      <Typography sx={{ padding: "15px 20px" }} key={`text-${item.id}`}>
        {item.name}
      </Typography>
    );

    if (item.editable) {
      if (item.editing) {
        text = (
          <TextField
            key={`textfield-${item.id}`}
            sx={{ margin: "10px 10px" }}
            size="small"
            name="name"
            label="Name"
            defaultValue={item.name}
            id={`${item.id}`}
            error={textFieldErrorMsg !== ""}
            helperText={textFieldErrorMsg}
            onChange={handleChange} // TODO: change value
          />
        );
        buttons = (
          <ButtonGroup
            key={`button-group-${item.id}`}
            variant="outlined"
            aria-label="outlined primary button group">
            <IconButton
              key="done"
              aria-label="Example"
              color="success"
              sx={{
                borderRadius: "4px",
                minWidth: "40px",
              }}
              onClick={() => saveCategory(item.id)}>
              <FontAwesomeIcon icon={faCheck} />
            </IconButton>
            <IconButton
              key="cancel"
              aria-label="Example"
              color="error"
              sx={{
                borderRadius: "4px",
                minWidth: "40px",
              }}
              onClick={() => cancelEdit(item.id)}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </ButtonGroup>
        );
      } else {
        buttons = (
          <ButtonGroup
            key={`button-group-${item.id}`}
            variant="outlined"
            aria-label="outlined primary button group">
            <IconButton
              key="edit"
              aria-label="Example"
              sx={{
                borderRadius: "4px",
                minWidth: "40px",
              }}
              onClick={() => makeEditable(item.id)}>
              <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
            <IconButton
              key="delete"
              aria-label="Example"
              sx={{
                borderRadius: "4px",
                minWidth: "40px",
              }}
              onClick={() => deleteCategoryByID(item.id)}>
              <FontAwesomeIcon icon={faTrashCan} />
            </IconButton>
          </ButtonGroup>
        );
      }
    }

    return (
      <Grid key={item.id} item xs={12}>
        <Card className="list-view-item" sx={{ display: "flex", justifyContent: "space-between" }}>
          {text}
          {buttons}
        </Card>
      </Grid>
    );
  });

  const addNewCategory = () => {
    setCategories((prevCategories: ICategory[]) => {
      return [
        ...prevCategories,
        {
          id: 0 - (newCategoryCounter + 1),
          name: "",
          editable: true,
          editing: true,
        },
      ];
    });
    setNewCategoriesCounter((prevValue: number) => prevValue + 1);
  };

  const makeEditable = (id: number) => {
    changeCategoryValueByID(id, "editing", true);
  };

  const deleteCategoryByID = (id: number) => {
    deleteCategory({
      variables: { deleteCategoryId: id },
      context: { clientName: "CMP" },
      onCompleted: (data) => {
        if (data.errors || !data.deleteCategory) {
          notifyError("Delete error!");
        } else {
          notifySuccess("Category deleted!");
          deleteCategoryFromStateByID(id);
        }
      },
    });
  };

  const saveCategory = (id: number) => {
    const selectedCategory = categories.find((category) => category.id === id);

    if (selectedCategory) {
      if (selectedCategory.name === "") {
        setTextFieldErrorMsg("Please insert a name.");
        return;
      }
      setTextFieldErrorMsg("");
      if (selectedCategory.id < 1) {
        addCategory({
          variables: { text: selectedCategory.name },
          context: { clientName: "CMP" },
          onCompleted: (data) => {
            if (data.errors) {
              notifyError(data.errors.message);
            } else {
              notifySuccess("Category added!");
              changeCategoryValueByID(id, "editing", false);
              changeCategoryValueByID(id, "id", data.addCategory.id);
            }
          },
        });
      } else {
        updateCategory({
          variables: { updateCategoryId: selectedCategory.id, text: selectedCategory.name },
          context: { clientName: "CMP" },
          onCompleted: (data) => {
            if (data.errors) {
              notifyError(data.errors.message);
            } else {
              notifySuccess("Category updated!");
              changeCategoryValueByID(id, "editing", false);
              changeCategoryValueByID(id, "id", data.updateCategory.id);
            }
          },
        });
      }
    }
  };

  const cancelEdit = (id: number) => {
    if (id > 0) {
      changeCategoryValueByID(id, "editing", false);
    } else {
      deleteCategoryFromStateByID(id);
    }
  };

  return (
    <Container>
      <Typography variant="pageTitle">Category Manager</Typography>
      <Grid container spacing={2} sx={{ justifyContent: "end" }}>
        {listItemsDOM}
        <Button sx={{ margin: "20px 0" }} variant="contained" onClick={addNewCategory}>
          Add
        </Button>
      </Grid>
    </Container>
  );
}
