import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  GetCriticalUpdatesOfVersionQueryDefinition,
  GET_CRITICAL_UPDATES_OF_VERSION,
} from "apps/jtm/business/version/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import LoadComponentSpinner from "libs/ui/components/feedback/loading-spinner/LoadComponentSpinner";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import SystemUpdatesList from "./SystemUpdatesList";

type ContextType = {
  openModal: boolean;
  handleOpenModal: (id: number) => void;
  handleCloseModal: () => void;
};

export function useSystemUpdateDialog() {
  return useContext(SystemUpdateDialogContext);
}

export const SystemUpdateDialogContext = createContext<ContextType>({
  openModal: false,
  handleOpenModal: () => {},
  handleCloseModal: () => {},
});

interface DialogProviderProps {
  children: ReactNode;
}

export const SystemUpdateDialogProvider = ({ children }: DialogProviderProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [versionId, setVersionId] = useState(0);

  const getCriticalUpdatesResponse = useSimpleFetching<GetCriticalUpdatesOfVersionQueryDefinition>({
    query: GET_CRITICAL_UPDATES_OF_VERSION,
    context: "JTM",
    queryArguments: { id: versionId },
  });

  const handleOpenModal = (id: number): void => {
    setVersionId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const dialogContext = useMemo(() => {
    return { openModal, handleOpenModal, handleCloseModal };
  }, [openModal]);

  let data = {
    critical_updates: {},
  };

  if (getCriticalUpdatesResponse !== false) {
    data = getCriticalUpdatesResponse.deploymentVersion;
  }

  return (
    <SystemUpdateDialogContext.Provider value={dialogContext}>
      {children}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle>Critical system updates</DialogTitle>
        <DialogContent>
          {!data ? (
            <LoadComponentSpinner />
          ) : (
            <div>
              <Typography sx={{ mb: 2 }}>
                Below is the list of the elements, where JENTIS performed a critical update.
              </Typography>
              <SystemUpdatesList criticalUpdates={data.critical_updates} />
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button variant="contained" onClick={handleCloseModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </SystemUpdateDialogContext.Provider>
  );
};

export default SystemUpdateDialogProvider;
