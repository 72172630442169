import { Box } from "@mui/system";
import { GetAllToolIdsQueryDefinition, GET_ALL_TOOLS_IDS } from "apps/jtm/business/tool/queries";
import { jtmPrefix } from "apps/jtm/conf/routes";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import WarningPage from "libs/business/warning/WarningPage";
import LoadingSpinner from "libs/ui/components/feedback/loading-spinner/LoadingSpinner";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface NoToolWarningProps {
  children: ReactNode;
}

const NoToolWarning: FC<NoToolWarningProps> = ({ children }: NoToolWarningProps) => {
  const navigate = useNavigate();
  const getUsedTools = useSimpleFetching<GetAllToolIdsQueryDefinition>({
    query: GET_ALL_TOOLS_IDS,
    context: "JTM",
    disableCache: true,
  });

  const onButtonClick = () => {
    navigate(`${jtmPrefix}/tools/selection`);
  };
  if (!getUsedTools) return <LoadingSpinner />;
  const usedTools = getUsedTools.tools;
  if (usedTools.length === 0) {
    return (
      <WarningPage
        title="There are no tools available"
        message="Please create a tool first "
        buttonText="Open create tool page"
        onButtonClick={onButtonClick}
      />
    );
  }

  return <Box>{children}</Box>;
};

export default NoToolWarning;
