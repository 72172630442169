import { Stack, Typography } from "@mui/material";
import { ErrorDetailData } from "../type";

interface ErrorDetailsProps {
  detailData: ErrorDetailData;
}
/**
 * The ErrorDetails component
 * @param {ErrorDetailsProps} props
 * @returns a ErrorDetails component
 */
const ErrorDetails = ({ detailData }: ErrorDetailsProps): JSX.Element => {
  return (
    <Stack spacing={1}>
      <Typography variant="h3">{detailData.message}</Typography>
      <Typography variant="caption" color="error.main" component="pre">
        {detailData.details}
      </Typography>
    </Stack>
  );
};

export default ErrorDetails;
