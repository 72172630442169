import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Grid } from "@mui/material";
import {
  GetContainerQueryDefinition,
  GET_ALL_CONTAINERS,
} from "apps/admin/business/container/queries";
import LanguageMultiSelect from "apps/cmp/components/languages/LanguageMultiSelect";
import UserService from "libs/business/authentication/UserService";
import useFetchNotOneOfList from "libs/business/hooks/useFetchNotOneOfList";
import generateRandomString from "libs/business/utils/randomization";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import Page from "libs/ui/components/surfaces/page/Page";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

export const SHOW_TRANSLATION = [
  "localhost",
  "muaion.hulk.jentis.com",
  "lsag-qs.weu.jentis.com",
  "humanicv2.weu.jentis.com",
  "satellite.xxxl.jentis.com",
  "controlion.nf.jentis.com",
].some((v) => window.location.origin.includes(v));

interface IContainerFormProps {
  containerData: GetContainerQueryDefinition["dataType"];
  formSubmitHandler: SubmitHandler<any>;
  pageTitle?: string;
}

const useGetSchema = (container: GetContainerQueryDefinition["dataType"]) => {
  const containerShorts = useFetchNotOneOfList({
    query: GET_ALL_CONTAINERS,
    queryName: "containers",
    context: "ADMIN",
    queryProperty: "short",
    exclude: [container.short],
  });

  const containerNames = useFetchNotOneOfList({
    query: GET_ALL_CONTAINERS,
    queryName: "containers",
    context: "ADMIN",
    queryProperty: "name",
    exclude: [container.name],
  });

  const schema = yup.object({
    name: yup
      .string()
      .required()
      .max(256, "The name must be shorter than 256 characters.")
      .notOneOf(containerNames, "This name is already in use!"),
    short: yup.string().required().notOneOf(containerShorts, "This short id is already in use!"),
    live_url: yup.string().required(),
    test_url: yup.string().required(),
    hash: yup.string().required(),
    file_hash: yup.string().required(),
  });

  return schema;
};

export default function ContainerForm({
  containerData,
  formSubmitHandler,
  pageTitle = "Container",
}: IContainerFormProps) {
  const schema = useGetSchema(containerData);
  const methods = useForm<{
    name: string;
    short: string;
    live_url: string;
    test_url: string;
    hash: string;
    file_hash: string;
    languages: number[];
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      languages: [],
    },
  });
  const permissionGroup = UserService.getAccessGroup();

  return (
    <FormProvider {...methods} key="containerForm">
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Page title={pageTitle}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextFieldWrapper
                name="name"
                label="Name"
                value={containerData.name}
                required
                helperText="Name of the container, which can contain any character you want."
                maxLength={256}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWrapper
                name="short"
                label="Short"
                value={containerData.short}
                required
                disabled={containerData.id ? !!1 : !!0}
                helperText="An abbreviation for the domain name with top domain e.g. jentis-at. Upper letters and special characters except '-' are not allowed."
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWrapper
                name="live_url"
                label="Live URL"
                value={containerData.live_url}
                required
                disabled={containerData.id ? !!1 : !!0}
                helperText="The url of your domain without the protocol https:// and the www. For example: jentis.com"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWrapper
                name="test_url"
                label="Test URL"
                value={containerData.test_url}
                required
                helperText="If you want to preview another url instead of the live url (in case of a redirect) you can define a test url."
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWrapper
                name="hash"
                label="Hash"
                value={containerData.hash || generateRandomString(6)}
                required
                disabled={permissionGroup > 3}
                helperText="Automatic generated value (Basictrackingcode)"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWrapper
                name="file_hash"
                label="File Hash"
                value={containerData.file_hash || generateRandomString(6)}
                required={!!1}
                disabled={permissionGroup !== 1}
                helperText="Automatic generated value (Basictrackingcode)"
              />
            </Grid>
            {SHOW_TRANSLATION && (
              <Grid item xs={12}>
                <LanguageMultiSelect
                  selected={containerData.languages}
                  id="languages"
                  disabled={0}
                  helperText='Choose available languages for your Consentbar. All texts of your consentbar will be translated to your chosen languages. "English" is used as a default.'
                />
              </Grid>
            )}
          </Grid>
        </Page>
        <Container>
          <SaveCancelBar />
        </Container>
      </form>
    </FormProvider>
  );
}
