import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { generateIdOutOfName } from "apps/jtm/components/shared/AutoGenerateNameAndID";
import { SyntheticEvent } from "react";
import TextFieldWrapper from "../../input/TextFieldWrapper";

interface SimpleInputDialogProps {
  title: string;
  inputLabel: string;
  open: boolean;
  onClose: () => void;
  asyncOnSubmit: (value: string) => Promise<void>;
  yupDefinition: any;
  transformValueBeforeValidation?: (value: string) => string;
}
/**
 * The SimpleInputDialog component
 * @param {SimpleInputDialogProps} props
 * @returns a SimpleInputDialog component
 */
const SimpleInputDialog = ({
  title,
  inputLabel,
  open,
  onClose,
  asyncOnSubmit,
  yupDefinition,
  transformValueBeforeValidation,
}: SimpleInputDialogProps): JSX.Element => {
  const schema = yup.object({
    inputValue: yupDefinition || yup.string().required("This is a required field!"),
  });
  const methods = useForm({ resolver: yupResolver(schema) });

  const onSubmit = () => {
    if (transformValueBeforeValidation) {
      methods.setValue(
        "inputValue",
        transformValueBeforeValidation(methods.getValues("inputValue")),
      );
    }
    return methods.handleSubmit(formSubmitHandler)();
  };

  const formSubmitHandler = async (data: any) => {
    await asyncOnSubmit(data.inputValue);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <FormProvider {...methods}>
        <DialogContent>
          <TextFieldWrapper name="inputValue" label={inputLabel} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" type="submit" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" onClick={onSubmit}>
            Submit
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default SimpleInputDialog;
