import React, { ReactElement, useEffect } from "react";
import { EditorView, ViewUpdate } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { css } from "@codemirror/lang-css";
import { html } from "@codemirror/lang-html";
import { useController, useFormContext, get } from "react-hook-form";
import classes from "./codeEditor.module.scss";
import { editorSetup } from "./basicEditorSetup";
import * as diff from "./diffBusiness";

interface ICodeEditorProps {
  label: string;
  name: string;
  content: string;
  contentOld?: string | undefined;
  language?: "json" | "html" | "css" | "javascript";
  readOnly?: boolean;
  infoText?: string;
  onChange?: Function;
}
/**
 * This component creates a code-editor
 * @param {ICodeEditorProps} props
 * @returns {ReactElement} void
 */
const CodeEditor: React.FC<ICodeEditorProps> = ({
  label,
  name,
  content,
  contentOld = undefined,
  language = "javascript",
  readOnly = false,
  infoText = "",
  onChange,
}: ICodeEditorProps): ReactElement => {
  const refContainer = React.useRef<HTMLDivElement>(null);

  let value: string;
  let finalInfoText = infoText;

  if (contentOld !== undefined) {
    const diffs = diff.diffLineMode(contentOld, content);
    value = diff.formatDiffs(diffs);
    finalInfoText = readOnly ? diff.diffInfoReadOnly : diff.diffInfo;
  } else {
    value = content;
  }

  const formContext = useFormContext();

  const { field } = useController({
    control: formContext.control,
    name,
    defaultValue: value,
  });

  const updateFormValue = (update: ViewUpdate) => {
    let editorValue = update.state.doc.toString();
    editorValue = diff.removeMarkers(editorValue);
    field.onChange(editorValue);
  };

  const editorState = EditorState.create({
    doc: value,
    extensions: [
      ...editorSetup,
      selectLanguage(language),
      EditorState.readOnly.of(readOnly),
      EditorView.updateListener.of((update) => {
        if (update.docChanged) {
          updateFormValue(update);
          if (typeof onChange !== "undefined") {
            onChange(update.state.doc.toString());
          }
        }
      }),
    ],
  });

  useEffect(() => {
    if (refContainer.current === null) {
      return;
    }

    const view = new EditorView({
      state: editorState,
      parent: refContainer.current,
    });

    // eslint-disable-next-line consistent-return
    return () => {
      view.destroy();
    };
  }, [content]);

  const editorError = get(formContext.formState.errors, name);
  const errorClass = editorError ? classes.error : "";

  return (
    <div>
      <textarea value={formContext.getValues(name) || content} data-cy={name} hidden />
      <div ref={refContainer} className={`${classes.editorBorder} ${errorClass}`}>
        <div className={classes.editorLabel} data-cy={`${name}-label`}>
          {label}
        </div>
      </div>
      <pre className={`${classes.editorInfoText} ${errorClass}`}>{editorError?.message}</pre>
      <pre className={classes.editorInfoText} data-cy={`${name}-helperText`}>
        {finalInfoText}
      </pre>
    </div>
  );
};

export default CodeEditor;

const selectLanguage = (language: string) => {
  switch (language) {
    case "json":
      return json();
    case "css":
      return css();
    case "html":
      return html();
    case "javascript":
      return javascript();
    default:
      return javascript();
  }
};
