import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
  GET_ALL_TOOL_DEFINITIONS,
  GetAllToolDefinitionQueryDefinition,
} from "apps/jtm/business/tool/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import LoadComponentSpinner from "../../feedback/loading-spinner/LoadComponentSpinner";
import SelectWithSearch from "../../search/SelectWithSearcht";

interface ConnectorSelectionProps {
  defaultSelectValue?: string;
  name: string;
}
/**
 * The ConnectorSelection component
 * @param {ConnectorSelectionProps} props
 * @returns a ConnectorSelection component
 */
const ConnectorSelection = ({ defaultSelectValue, name }: ConnectorSelectionProps): JSX.Element => {
  const getAllToolDefinitions = useSimpleFetching<GetAllToolDefinitionQueryDefinition>({
    query: GET_ALL_TOOL_DEFINITIONS,
    context: "JTM",
  });

  if (!getAllToolDefinitions) return <LoadComponentSpinner />;

  const sortedDefinitions = [...getAllToolDefinitions.toolDefinitions].sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
  );

  return (
    <SelectWithSearch
      inputName={name}
      instanceName="Please select a tool"
      defaultValue={defaultSelectValue}
      options={sortedDefinitions.map((toolDefinition) => {
        return {
          key: toolDefinition.pluginID,
          optionLabel: toolDefinition.name,
          value: <SelectOption name={toolDefinition.name} imagePath={toolDefinition.image_path} />,
        };
      })}
    />
  );
};

export default ConnectorSelection;

const SelectOption = ({ name, imagePath }: { name: string; imagePath: string }): JSX.Element => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <img
        src={`${imagePath}small`}
        style={{ width: "60px", height: "60px", objectFit: "contain" }}
        alt="logo"
      />
      <Typography variant="h5">{name}</Typography>
    </Stack>
  );
};
