import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { useWizardData } from "libs/ui/components/surfaces/wizard/WizardDataContext";
import { GET_TAG_DEFINITION } from "../../business/tag/queries";
import ConfigureTemplate from "./ConfigureTemplate";
import SelectionOverview from "./SelectionOverview";

/**
 * The TagNew component
 * @returns a TagNew component
 */
const StepThree = (): JSX.Element => {
  const wizardData = useWizardData();

  const [getTagDefinition, { called, loading, data, error }] = useQueryWrapper(GET_TAG_DEFINITION);
  if (!called) {
    getTagDefinition({
      variables: { toolId: wizardData.toolId, tagType: wizardData.template },
      context: { clientName: "JTM" },
    });
  }

  if (error) return <p>Data posting went wrong: {error ? error.message : ""}</p>;
  if (loading || !data) return <PageLoadingSpinner />;

  return (
    <>
      <SelectionOverview
        tagName={wizardData.name}
        toolName={wizardData.tool.name}
        imageUrl={`${wizardData.tool.tool_definition.image_path}medium`}
        templateName={wizardData.tag_types.name}
      />
      <ConfigureTemplate placeholderDefinition={data.tagDefinition} />
    </>
  );
};

export default StepThree;
