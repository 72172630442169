import { Container } from "@mui/material";
import Page from "libs/ui/components/surfaces/page/Page";
import PasswordChangeForm from "./PasswordChangeForm";

export default function PasswordChange() {
  return (
    <Container>
      <Page title="Change Password" notFirst>
        <PasswordChangeForm />
      </Page>
    </Container>
  );
}
