import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import UserService from "libs/business/authentication/UserService";
import useIsSetupInitialized from "libs/business/hooks/useIsSetupInitialized";
import getRoutePath from "libs/business/routes/routes";
import React from "react";
import { NavLink } from "react-router-dom";

/**
 * The UserMenu component
 * @returns a UserMenu component
 */
const UserMenu = (): JSX.Element => {
  const isSetupInitialized = useIsSetupInitialized();
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit">
        <FontAwesomeIcon icon={faUser} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElement)}
        onClose={closeMenu}>
        {isSetupInitialized && (
          <MenuItem onClick={closeMenu}>
            <Link
              component={NavLink}
              to={getRoutePath("admin", "user_edit", { id: UserService.getUserId() })}>
              My Account
            </Link>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            UserService.logout();
          }}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
