import { GridValueFormatterParams } from "@mui/x-data-grid";
import moment from "moment";

export const dateFormatterWithoutHours = (value: string): string => {
  return moment(value).format("DD/MM/YYYY");
};

export const dateFormatter = (value: string): string => {
  return moment(value).format("DD/MM/YYYY HH:mm");
};

export const dateFormatterDatagrid = (params: GridValueFormatterParams<any>): any => {
  return dateFormatter(params?.value);
};
export default dateFormatterDatagrid;
