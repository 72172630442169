import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  query Countries {
    Countries {
      id
      isocode
      country_type
      textcontent_textcontentid
      text_content {
        text
      }
    }
  }
`;

export default GET_COUNTRIES;
