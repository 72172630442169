import { SxProps, TextField, Typography } from "@mui/material";
import React, {
  CSSProperties,
  FC,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useController, useFormContext, get } from "react-hook-form";

interface ITextfieldWrapperProps {
  name: string;
  label: string;
  value?: string | number;
  type?: string;
  required?: boolean;
  multiline?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  variant?: "outlined" | "filled" | "standard";
  hiddenLabel?: boolean;
  size?: "small" | "medium";
  placeholder?: string;
  helperText?: string;
  onChange?: Function;
  style?: CSSProperties;
  maxLength?: number;
  textFieldSx?: SxProps;
  endAdornment?: React.ReactNode | undefined;
  inputMin?: number;
}
/**
 * The InputWrapper component wraps a Mui TextField with a react-form-hook controller to us it in a context context.
 * @param {ITextfieldWrapperProps} props
 * @returns a InputWrapper component
 */
const TextFieldWrapper: FC<ITextfieldWrapperProps> = forwardRef(
  (
    {
      name,
      label,
      value = "",
      type = "text",
      required = false,
      multiline = false,
      readonly = false,
      disabled = false,
      hidden = false,
      variant = "outlined",
      hiddenLabel = false,
      size = "small",
      placeholder,
      helperText = "",
      onChange,
      style,
      maxLength,
      textFieldSx,
      endAdornment,
      inputMin,
    }: ITextfieldWrapperProps,
    ref: any,
  ) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();

    const { field } = useController({
      control,
      name,
      defaultValue: value,
    });

    const nameErrors = get(errors, name);
    const [uiElementValue, setUiElementValue] = useState(value);

    const changeValue = (changedValue: string | number): void => {
      setUiElementValue(changedValue);
      setHookFormValue(changedValue);
      runCustomOnChangeFunction(changedValue);
    };

    const setHookFormValue = (changedValue: string | number) => {
      field.onChange(changedValue);
    };

    const runCustomOnChangeFunction = (changedValue: string | number) => {
      if (typeof onChange !== "undefined") onChange(changedValue);
    };

    const onChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        changeValue(type === "number" ? parseInt(e.target.value, 10) : e.target.value);
      },
      [],
    );

    const inputProps = useMemo(() => {
      return { readOnly: readonly, maxLength, endAdornment };
    }, [readonly, maxLength, endAdornment]);

    // for changes not made by a manual input
    useEffect(() => {
      changeValue(value);
    }, [value]);

    return (
      // the hidden attribute is not working with TextFields in combination with react-hook-form
      <div style={{ display: hidden ? "none" : "", width: "100%" }}>
        <TextField
          sx={textFieldSx}
          style={style}
          label={hiddenLabel ? undefined : <span data-cy={`${name}-label`}>{label}</span>}
          required={required}
          multiline={multiline}
          type={maxLength ? "text" : type}
          margin="normal"
          size={size}
          fullWidth
          value={uiElementValue}
          onChange={onChangeHandler}
          error={!!nameErrors}
          helperText={
            <span data-cy={`${name}-helperText`}>{nameErrors?.message ?? helperText}</span>
          }
          disabled={disabled}
          variant={variant}
          hiddenLabel={hiddenLabel}
          placeholder={placeholder}
          InputProps={inputProps}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ "data-cy": name, min: inputMin }}
          {...ref}
        />
      </div>
    );
  },
);

export default memo(TextFieldWrapper);
