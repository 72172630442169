import { Container } from "@mui/material";
import { useActiveContainer } from "apps/admin/components/container/ContainerContext";
import { DELETE_TAG, GET_ALL_TAGS } from "apps/jtm/business/tag/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import getRoutePath from "libs/business/routes/routes";
import { filterDataOnContainer } from "libs/business/utils/filterDataOnContainer";
import BasicGrid from "libs/ui/components/datagrid/BasicGrid";
import IRow from "libs/ui/components/datagrid/IRow";
import defaultColumnProperties from "libs/ui/components/datagrid/defaultColumnProperties";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";

/**
 * The FunctionGrid component displays a grid containing all functions in the db.
 * @returns a FunctionGrid component
 */
const TagGrid = (): JSX.Element => {
  const activeContainerIds = useActiveContainer();
  const [getAllTags, queryState] = useQueryWrapper(GET_ALL_TAGS);
  const [deleteFunction] = useMutationWrapper(DELETE_TAG);

  if (!queryState.called) {
    getAllTags({ context: { clientName: "JTM" } });
  }
  if (queryState.loading || !queryState.data) return <PageLoadingSpinner />;

  const filteredTagData: IRow[] = filterDataOnContainer(queryState.data.tags, activeContainerIds);

  return (
    <Container>
      <Page
        title="Tags"
        newButtonConfig={{
          instanceName: "Tag",
          newInstanceLink: getRoutePath("jtm", "tag_new"),
        }}>
        <BasicGrid
          showContainerFilter
          columnConfig={[
            {
              field: "tool",
              headerName: "Tool",
              valueGetter: ({ value }) => value?.name,
              renderCell: (params) => (
                <img
                  src={`${params.row.tool.tool_definition.image_path}small`}
                  style={{ width: "48px", height: "48px", objectFit: "contain" }}
                  alt="logo"
                />
              ),
            },
            {
              field: "edit_at",
              headerName: "Edited",
              ...defaultColumnProperties.date,
            },
            {
              field: "name",
              headerName: "Name",
              ...defaultColumnProperties.name,
            },
            {
              field: "template_name",
              headerName: "Template",
              width: 250,
            },
            {
              field: "trigger",
              headerName: "Trigger",
              width: 250,
              valueGetter: ({ value }) => value?.name || "-",
            },
          ]}
          actionConfig={{
            editLink: {
              service: "jtm",
              routeName: "tag_id",
            },
            context: "JTM",
            deleteFunction,
            onDeleteRefetchQueries: [GET_ALL_TAGS],
            copy: false,
            log: "Tag",
            settings: {
              editable: true,
              deletable: true,
              copyable: false,
              hasLog: true,
            },
          }}
          rowsData={filteredTagData}
        />
      </Page>
    </Container>
  );
};

export default TagGrid;
