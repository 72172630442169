import { DocumentNode, FetchResult, MutationFunctionOptions } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container } from "@mui/material";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import { notifyError } from "libs/business/notification/notification";
import { getValidationSchema } from "libs/business/utils/getValidationSchema";
import { getFirstValueOfObject } from "libs/business/utils/objectFunctions";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import ContinueCancelDialog from "libs/ui/components/feedback/modal/ContinueCancelDialog";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import React, { ReactNode, useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import MaintainedDialog from "./MaintainedDialog";
import getMaintainedStatus, { MaintainedStatus } from "./maintainedStatus";

type ContextType = {
  maintained: boolean;
  setMaintained: (b: boolean) => void;
  maintainedBy: MaintainedStatus;
  showResetButton: boolean;
};

export function useMaintained() {
  return useContext(MaintainedContext);
}

export const MaintainedContext = React.createContext<ContextType>({
  maintained: false,
  setMaintained: () => {},
  maintainedBy: "custom",
  showResetButton: false,
});

interface IMaintainedProviderProps {
  initialValue: boolean;
  elementName: string;
  id?: number;
  pluginID?: string;
  shortIds?: string[];
  formSubmitHandler: (data: any) => void;
  updateMutationFunction?: (
    options?: MutationFunctionOptions<any, any, any, any> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  GET_DEFAULT_VALUES?: DocumentNode;
  deactivateResetButton?: boolean;
  children: ReactNode;
}

/**
 * The MaintainedContext
 * @returns a MaintainedContext component
 */
export const MaintainedProvider = ({
  initialValue,
  elementName,
  id,
  pluginID,
  formSubmitHandler,
  updateMutationFunction,
  GET_DEFAULT_VALUES,
  shortIds,
  deactivateResetButton,
  children,
}: IMaintainedProviderProps) => {
  const [maintained, setMaintained] = usePropChangingState<boolean>(initialValue);

  const maintainedBy = getMaintainedStatus(maintained, pluginID);
  const showResetButton = maintainedBy === "user" && deactivateResetButton !== true;
  const memo = useMemo(() => {
    return { maintained, setMaintained, maintainedBy, showResetButton };
  }, [maintained, setMaintained, maintainedBy, showResetButton]);

  if (maintainedBy === "custom") {
    return (
      <MaintainedContext.Provider value={memo}>
        <MaintainedDialog open={maintained} />
        <MaintainedCustomForm formSubmitHandler={formSubmitHandler} shortIds={shortIds}>
          {children}
        </MaintainedCustomForm>
      </MaintainedContext.Provider>
    );
  }
  if (
    typeof pluginID === "undefined" ||
    typeof id === "undefined" ||
    typeof updateMutationFunction === "undefined" ||
    typeof GET_DEFAULT_VALUES === "undefined"
  ) {
    return <p>Wrong Usage of the component!</p>;
  }
  return (
    <MaintainedContext.Provider value={memo}>
      <MaintainedDialog open={maintained} />
      <MaintainedResetForm
        elementName={elementName}
        id={id}
        pluginID={pluginID}
        formSubmitHandler={formSubmitHandler}
        updateMutationFunction={updateMutationFunction}
        GET_DEFAULT_VALUES={GET_DEFAULT_VALUES}
        shortIds={shortIds}>
        {children}
      </MaintainedResetForm>
    </MaintainedContext.Provider>
  );
};

export default MaintainedContext;

interface IMaintainedResetFormProps {
  elementName: string;
  id: number;
  pluginID: string;
  shortIds?: string[];
  formSubmitHandler: (data: any) => void;
  updateMutationFunction: (
    options?: MutationFunctionOptions<any, any, any, any> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  GET_DEFAULT_VALUES: DocumentNode;
  children: ReactNode;
}

interface MaintainedFormProps {
  shortIds?: string[];
  formSubmitHandler: (data: any) => void;
  resetMaintainedHandler: any;
  children: ReactNode;
}

export const MaintainedResetForm = ({
  elementName,
  id,
  pluginID,
  shortIds,
  formSubmitHandler,
  updateMutationFunction,
  GET_DEFAULT_VALUES,
  children,
}: IMaintainedResetFormProps) => {
  const [getDefault, defaultQueryState] = useQueryWrapper(GET_DEFAULT_VALUES);

  if (!defaultQueryState.called) {
    getDefault({
      variables: { pluginID },
      context: { clientName: "JTM" },
      onError: (error) => {
        notifyError(error.message);
      },
    });
  }

  const resetMaintained = () => {
    if (!defaultQueryState.data) return;
    const defaultData = getFirstValueOfObject(defaultQueryState.data);
    const resetData = {
      id,
      ...defaultData,
    };
    updateMutationFunction({
      variables: resetData,
      context: { clientName: "JTM" },
      onCompleted: () => {
        window.location.reload();
      },
      onError: (error: any) => {
        notifyError(error.message);
      },
    });
  };

  if (defaultQueryState.loading) {
    return <PageLoadingSpinner />;
  }

  return (
    <MaintainedForm
      shortIds={shortIds}
      formSubmitHandler={formSubmitHandler}
      resetMaintainedHandler={resetMaintained}>
      {children}
    </MaintainedForm>
  );
};

export const MaintainedCustomForm = ({ shortIds, formSubmitHandler, children }: any) => {
  return (
    <MaintainedForm
      shortIds={shortIds}
      formSubmitHandler={formSubmitHandler}
      resetMaintainedHandler={() => {}}>
      {children}
    </MaintainedForm>
  );
};

export const MaintainedForm = ({
  shortIds,
  formSubmitHandler,
  resetMaintainedHandler,
  children,
}: MaintainedFormProps) => {
  const schema = getValidationSchema(shortIds);
  const methods = useForm({ resolver: yupResolver(schema), reValidateMode: "onSubmit" });
  const { maintained, showResetButton } = useMaintained();
  const [warningModalOpen, setWarningModalOpen] = useState(false);

  return (
    <>
      <FormProvider {...methods} key="maintainedform">
        <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <TextFieldWrapper
            name="maintained"
            label="Maintained"
            hidden
            value={maintained ? 1 : 0}
          />
          {children}
          <Container>
            <SaveCancelBar
              showResetButton={showResetButton}
              resetMaintainedHandler={() => setWarningModalOpen(true)}
            />
          </Container>
        </form>
      </FormProvider>
      <ContinueCancelDialog
        open={warningModalOpen}
        title="Attention! You are about to reset this element."
        bodyText="By clicking on Reset, you will lose all the customizations you have made."
        onClose={() => setWarningModalOpen(false)}
        onSubmit={resetMaintainedHandler}
        primaryButtonText="Reset"
      />
    </>
  );
};
