export const getFirstValueOfObject = (obj: object): any => {
  const firstKey = Object.keys(obj)[0];
  return obj[firstKey as keyof typeof obj];
};

export const getFirstKeyOfObject = (obj: object): any => {
  return Object.keys(obj)[0];
};

export const getNextObjectProperty = (obj: object, currentItem: any): any => {
  const keys = Object.keys(obj);
  const lengthOBj = keys.length;
  const nextIndex = keys.indexOf(currentItem) + 1;
  return keys[nextIndex % lengthOBj];
};

export const getPreviousObjectProperty = (obj: object, currentItem: any): any => {
  const keys = Object.keys(obj);
  const lengthOBj = keys.length;
  let previousIndex = keys.indexOf(currentItem) - 1;
  if (previousIndex < 0) {
    previousIndex += lengthOBj;
  }
  return keys[previousIndex];
};

export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

export function removeDuplicateObjectsFromArray<T extends object>(
  arr: T[],
  comparisonKey: string,
): T[] {
  const uniqueValues: any = [];

  const uniqueArray = arr.filter((obj) => {
    const isDuplicate = uniqueValues.includes(JSON.stringify(obj[comparisonKey as keyof T]));

    if (isDuplicate) {
      return false;
    }

    uniqueValues.push(JSON.stringify(obj[comparisonKey as keyof T]));
    return true;
  });

  return uniqueArray;
}

export const deepCopyObjectWithoutFunction = <T extends object>(obj: T): T =>
  JSON.parse(JSON.stringify(obj));

export const parseStringWithBigInts = (str: string) => {
  // from: gist.github.com/jarrodek/24d32cb6a03c40b11fe77651ba223257
  function reviver(key: any, value: any) {
    if (typeof value !== "number" || Number.MAX_SAFE_INTEGER > value) {
      return value;
    }
    const maxLen = Number.MAX_SAFE_INTEGER.toString().length - 1;
    const needle = String(value).slice(0, maxLen);
    const re = new RegExp(`${needle}\\d+`);
    const matches = str.match(re);
    if (matches) {
      return BigInt(matches[0]);
    }
    return value;
  }

  return JSON.parse(str, reviver);
};

/**
 *
 * @param obj
 * stringifies the object by converting bigints into strings
 * converts big ints into string
 */
export const stringifyObjWithBigInts = (obj: object, space: string | number): string => {
  function replacer(key: any, value: any) {
    if (typeof value === "bigint") {
      return value.toString();
    }
    return value;
  }
  return JSON.stringify(obj, replacer, space);
};
export default getFirstValueOfObject;

/**
 *
 * @param obj an object
 * @param dottedString as sting like "key1.key2"
 * returns the value of obj.key1.key2
 */
export const getValueByDottedStringValue = (
  obj: { [key: string]: any },
  dottedString: string,
): unknown => {
  const exploded = dottedString.split(".");
  let currentValue = obj;
  for (const key of exploded) {
    currentValue = currentValue?.[key];
  }
  return currentValue;
};
