import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import logoBlack from "assets/images/logos/logo_black.png";
import colorStyles from "assets/styles/colorPalette.module.scss";
import { Link } from "react-router-dom";
import HelpMenu from "./HelpMenu";
import UserMenu from "./UserMenu";

const imageStyle = {
  height: "20px",
};

const zIndex = {
  zIndex: 100,
  borderBottom: "1px solid",
  borderColor: colorStyles.grey3,
};

const Header = (): JSX.Element => {
  return (
    <AppBar sx={zIndex}>
      <Toolbar>
        <Link to="/">
          <img alt="logo" src={logoBlack} style={imageStyle} />
        </Link>
        <div style={{ flex: 1 }} />
        <div>
          <HelpMenu />
          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
