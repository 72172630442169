import { faCheck, faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Stack, Typography } from "@mui/material";
import colorStyles from "assets/styles/colorPalette.module.scss";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import { ReactNode } from "react";

interface TaskWrapperProps {
  taskDescription: string;
  children?: ReactNode;
  done?: boolean;
  showCheckbox?: boolean;
  hideTask?: boolean;
}
/**
 * The TaskWrapper component
 * @param {TaskWrapperProps} props
 * @returns a TaskWrapper component
 */
const TaskWrapper = ({
  taskDescription,
  children,
  done = false,
  showCheckbox = false,
  hideTask = false,
}: TaskWrapperProps): JSX.Element => {
  const [doneState, setDoneState] = usePropChangingState(done);

  if (hideTask) return <div>{children}</div>;

  return (
    <div>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          p: 2,
          mb: 2,
          backgroundColor: doneState ? colorStyles.successLight : colorStyles.yellow1,
          borderRadius: 1,
          color: doneState ? colorStyles.successDark : colorStyles.yellow9,
        }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: "20px", height: "20px" }}>
          <FontAwesomeIcon icon={doneState ? faCheck : faPen} />
        </Stack>
        <div>
          <Typography>{taskDescription}</Typography>
          {showCheckbox && (
            <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
              <Checkbox
                size="small"
                sx={{
                  "&.MuiButtonBase-root": { p: 0 },
                  "&.Mui-checked": {
                    color: doneState ? colorStyles.successDark : colorStyles.yellow9,
                  },
                }}
                onChange={() => setDoneState((prev) => !prev)}
              />
              <Typography>Mark as done</Typography>
            </Stack>
          )}
        </div>
      </Stack>
      {children}
    </div>
  );
};

export default TaskWrapper;
