import { faInfoCircle, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tooltip } from "@mui/material";
import ConfirmDialog from "libs/ui/components/feedback/modal/ConfirmDialog";
import TabContent from "libs/ui/components/surfaces/tabs/TabContent";
import VerticalTabWrapper from "libs/ui/components/surfaces/tabs/VerticalTabWrapper";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
// import { get, useFormContext } from "react-hook-form";
import { IConstantData } from "./ConstantContainerSetting";
import ConstantEditTable from "./ConstantEditTable";

interface IConstantsTabConfiguration {
  constantData: IConstantData[];
  setConstantData?: React.Dispatch<IConstantData[]>;
  selectedContainerIds: string[] | number[];
  submitName: string;
  onPlusClick?: Function;
  environmentColumnsNeeded?: boolean;
  defaultReadOnly?: boolean;
}
/**
 * The ConstantEditTable component
 * @param {IConstantsTabConfiguration} props
 * @returns a ConstantEditTable component
 */
const ConstantsTabConfiguration = ({
  constantData,
  selectedContainerIds,
  setConstantData,
  submitName,
  onPlusClick,
  environmentColumnsNeeded,
  defaultReadOnly = false,
}: IConstantsTabConfiguration): JSX.Element => {
  const [deleteModalState, setDeleteModalState] = useState({ open: false, id: 0 });
  const formContext = useFormContext();
  // const {
  //   formState: { errors },
  // } = useFormContext();

  const iconWithToolTip = (
    <Tooltip title="This tool constant is mandatory">
      <FontAwesomeIcon icon={faInfoCircle} />
    </Tooltip>
  );

  const onDelete = () => {
    if (deleteModalState.id < 0 && setConstantData) {
      const name = constantData.find((constant) => constant.id === deleteModalState.id)?.name;
      if (name) {
        formContext.unregister(`${submitName}.${name}`);
        setConstantData(constantData.filter((constant) => constant.id !== deleteModalState.id));
      }
    }
    setDeleteModalState((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <VerticalTabWrapper onPlusClick={onPlusClick}>
        {constantData.map((constant) => {
          const isMandatory = !constant.optional;
          let label: string | JSX.Element = constant.name;
          const { id } = constant;

          if (id && id < 0) {
            label = (
              <>
                <IconButton
                  onClick={() => setDeleteModalState({ open: true, id })}
                  sx={{ color: "inherit" }}>
                  <FontAwesomeIcon icon={faTrashCan} size="xs" />
                </IconButton>
                {constant.name}
              </>
            );
          }

          // const nameErrors = get(errors, `constants.${constant.short_id}.value`);
          return (
            <TabContent
              label={label}
              key={constant.name}
              icon={isMandatory ? iconWithToolTip : undefined}
              // error={!!nameErrors}
            >
              <Box sx={{ p: 2 }}>
                <ConstantEditTable
                  constantData={constant}
                  submitName={submitName}
                  selectedContainerIds={selectedContainerIds}
                  environmentColumnsNeeded={!!environmentColumnsNeeded}
                  defaultReadOnly={defaultReadOnly}
                />
              </Box>
            </TabContent>
          );
        })}
      </VerticalTabWrapper>
      <ConfirmDialog
        title="Are you sure you want to delete this custom constant?"
        isOpen={deleteModalState.open}
        onConfirm={() => onDelete()}
        onCancel={() => setDeleteModalState({ open: false, id: 0 })}
        message=""
      />
    </>
  );
};

export default ConstantsTabConfiguration;
