import RouteInterface from "libs/business/routes/RouteInterface";
import AssistantList from "libs/ui/components/assistant-list/AssistantList";
import AssistantCompletedPage from "libs/ui/components/feedback/AssistantCompletedPage";
import FrontendCodeGrid from "../pages/code/FrontendCodeGrid";
import FrontendCodeNew from "../pages/code/FrontendCodeNew";
import FrontendCodeUpdate from "../pages/code/FrontendCodeUpdate";
import FunctionGrid from "../pages/function/FunctionGrid";
import FunctionNew from "../pages/function/FunctionNew";
import FunctionUpdate from "../pages/function/FunctionUpdate";
import ServerTagManagementDefaultPage from "../pages/home/ServerTagManagementDefaultPage";
import SingleLogGrid from "../pages/log/SingleLogGrid";
import VersionLogGrid from "../pages/log/VersionLogGrid";
import FrontendPluginCreate from "../pages/plugin/FrontendPluginCreate";
import FrontendPluginGrid from "../pages/plugin/FrontendPluginGrid";
import FrontendPluginSelection from "../pages/plugin/FrontendPluginSelection";
import FrontendPluginUpdate from "../pages/plugin/FrontendPluginUpdate";
import StateGrid from "../pages/state/StateGrid";
import StateNew from "../pages/state/StateNew";
import StateUpdate from "../pages/state/StateUpdate";
import TagGrid from "../pages/tag/TagGrid";
import TagNew from "../pages/tag/TagNew";
import TagUpdate from "../pages/tag/TagUpdate";
import ToolCreate from "../pages/tool/ToolCreate";
import ToolList from "../pages/tool/ToolList";
import ToolSelection from "../pages/tool/ToolSelection";
import ToolUpdate from "../pages/tool/ToolUpdate";
import ConnectorAssistant from "../pages/tool/assistant/ConnectorAssistant";
import { connectorAssistantTitles } from "../pages/tool/assistant/conf/stepConfig";
import TriggerGrid from "../pages/trigger/TriggerGrid";
import TriggerNew from "../pages/trigger/TriggerNew";
import TriggerUpdate from "../pages/trigger/TriggerUpdate";
import VariableGrid from "../pages/variable/VariableGrid";
import { VariableNew } from "../pages/variable/VariableNew";
import VariableSelection from "../pages/variable/VariableSelection";
import VariableUpdate from "../pages/variable/VariableUpdate";
import Versions from "../pages/version/Versions";

export const jtmPrefix = "/app/jtm";

const routes: RouteInterface = {
  server_tag_management: {
    path: `${jtmPrefix}/servertagmanagement`,
    element: <ServerTagManagementDefaultPage />,
  },
  functions: {
    path: `${jtmPrefix}/functions`,
    element: <FunctionGrid />,
    documentation: "6bb30939-eba1-410c-95da-1b1a1e4738ee",
  },
  function_id: {
    path: `${jtmPrefix}/functions/:id`,
    element: <FunctionUpdate />,
    documentation: "6bb30939-eba1-410c-95da-1b1a1e4738ee",
  },
  function_new: {
    path: `${jtmPrefix}/functions/new`,
    element: <FunctionNew />,
    documentation: "6bb30939-eba1-410c-95da-1b1a1e4738ee",
  },
  triggers: {
    path: `${jtmPrefix}/triggers`,
    element: <TriggerGrid />,
    documentation: "02bdc740-6fde-4e73-8593-946806b1eb8c",
  },
  trigger_id: {
    path: `${jtmPrefix}/triggers/:id`,
    element: <TriggerUpdate />,
    documentation: "02bdc740-6fde-4e73-8593-946806b1eb8c",
  },
  trigger_new: {
    path: `${jtmPrefix}/triggers/new`,
    element: <TriggerNew />,
    documentation: "02bdc740-6fde-4e73-8593-946806b1eb8c",
  },
  variables: {
    path: `${jtmPrefix}/variables`,
    element: <VariableGrid />,
    documentation: {
      constant: "49c52b0a-3ac4-4638-988e-c955c96df91e",
      frontend: "b279c59e-c15b-4453-bfbc-4daef243f9ed",
      backend: "77a874b5-4c1e-4579-af26-cc507c369d93",
    },
  },
  variable_id: {
    path: `${jtmPrefix}/variables/:id`,
    element: <VariableUpdate />,
    documentation: {
      constant: "49c52b0a-3ac4-4638-988e-c955c96df91e",
      frontend: "b279c59e-c15b-4453-bfbc-4daef243f9ed",
      backend: "77a874b5-4c1e-4579-af26-cc507c369d93",
    },
  },
  variable_new: {
    path: `${jtmPrefix}/variables/new`,
    element: <VariableNew />,
  },
  variable_selection: {
    path: `${jtmPrefix}/variables/selection`,
    element: <VariableSelection />,
    documentation: {
      constant: "49c52b0a-3ac4-4638-988e-c955c96df91e",
      frontend: "b279c59e-c15b-4453-bfbc-4daef243f9ed",
      backend: "77a874b5-4c1e-4579-af26-cc507c369d93",
    },
  },
  states: {
    path: `${jtmPrefix}/states`,
    element: <StateGrid />,
    documentation: "981a5190-b599-4ffc-be1e-a5d76017c2f6",
  },
  state_id: {
    path: `${jtmPrefix}/states/:id`,
    element: <StateUpdate />,
    documentation: "981a5190-b599-4ffc-be1e-a5d76017c2f6",
  },
  state_new: {
    path: `${jtmPrefix}/states/new`,
    element: <StateNew />,
    documentation: "981a5190-b599-4ffc-be1e-a5d76017c2f6",
  },
  tools: {
    path: `${jtmPrefix}/tools`,
    element: <ToolList />,
    documentation: "39227b0b-f7da-4f2d-be2f-72d0d11446ca",
  },
  tool_id: {
    path: `${jtmPrefix}/tools/:id`,
    element: <ToolUpdate />,
    documentation: "39227b0b-f7da-4f2d-be2f-72d0d11446ca",
  },
  tool_new: {
    path: `${jtmPrefix}/tools/new`,
    element: <ToolCreate />,
    documentation: "39227b0b-f7da-4f2d-be2f-72d0d11446ca",
  },
  tags: {
    path: `${jtmPrefix}/tags`,
    element: <TagGrid />,
    documentation: "d5a0b22b-2acb-4d9d-8a11-e866d08c5951",
  },
  tag_id: {
    path: `${jtmPrefix}/tags/:id`,
    element: <TagUpdate />,
    documentation: "d5a0b22b-2acb-4d9d-8a11-e866d08c5951",
  },
  tag_new: {
    path: `${jtmPrefix}/tags/new`,
    element: <TagNew />,
    documentation: "d5a0b22b-2acb-4d9d-8a11-e866d08c5951",
  },
  codes: {
    path: `${jtmPrefix}/codes`,
    element: <FrontendCodeGrid />,
    documentation: "0fade7e1-3282-48b8-b5fc-2b9a2b03de5c",
  },
  code_id: {
    path: `${jtmPrefix}/codes/:id`,
    element: <FrontendCodeUpdate />,
    documentation: "0fade7e1-3282-48b8-b5fc-2b9a2b03de5c",
  },
  code_new: {
    path: `${jtmPrefix}/codes/new`,
    element: <FrontendCodeNew />,
    documentation: "0fade7e1-3282-48b8-b5fc-2b9a2b03de5c",
  },
  plugins: {
    path: `${jtmPrefix}/plugins`,
    element: <FrontendPluginGrid />,
    documentation: "a7b88817-1e53-4700-bf9d-7e5c6dc422ea",
  },
  plugin_id: {
    path: `${jtmPrefix}/plugins/:id`,
    element: <FrontendPluginUpdate />,
    documentation: "a7b88817-1e53-4700-bf9d-7e5c6dc422ea",
  },
  plugin_new: {
    path: `${jtmPrefix}/plugins/new`,
    element: <FrontendPluginCreate />,
    documentation: "a7b88817-1e53-4700-bf9d-7e5c6dc422ea",
  },
  plugin_selection: {
    path: `${jtmPrefix}/plugins/selection`,
    element: <FrontendPluginSelection />,
    documentation: "a7b88817-1e53-4700-bf9d-7e5c6dc422ea",
  },
  tool_selection: {
    path: `${jtmPrefix}/tools/selection`,
    element: <ToolSelection />,
    documentation: "39227b0b-f7da-4f2d-be2f-72d0d11446ca",
  },
  versions: {
    path: `${jtmPrefix}/versions`,
    element: <Versions />,
    documentation: "c66e79cd-1d74-4da7-a926-3383a1a891e0",
  },
  changelog: {
    path: `${jtmPrefix}/versions/log`,
    element: <VersionLogGrid />,
  },
  singlelog: {
    path: `${jtmPrefix}/:urlParamType/log/:id`,
    element: <SingleLogGrid />,
  },
  tool_assistant: {
    path: `${jtmPrefix}/tools/assistant/`,
    element: (
      <AssistantList type="CONNECTOR" instanceName="tool" titles={connectorAssistantTitles} />
    ),
  },
  tool_assistant_id: {
    path: `${jtmPrefix}/tools/assistant/:id`,
    element: <ConnectorAssistant />,
  },
  tool_assistant_completed: {
    path: `${jtmPrefix}/tools/assistant/:id/completed`,
    element: (
      <AssistantCompletedPage
        title="Congratulations, the tool has been created!"
        subTitle="Would you like to continue with the publishing process?"
        linkText="to navigate to the tool overview page"
        listPath={`${jtmPrefix}/tools`}
        showPublishButton
      />
    ),
  },
};

export default routes;
