import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@mui/material";
import FieldSelectionInputWrapper from "apps/jtm/components/tag/field_selection/FieldSelectionInputWrapper";

interface CompareSimplePlaceholderProps {
  inputName: string;
  label: string;
  leftValue: string;
  rightValue: string;
}

/**
 * The CompareSimplePlaceholder component
 * @param {CompareSimplePlaceholderProps} props
 * @returns a CompareSimplePlaceholder component
 */
const CompareSimplePlaceholder = ({
  inputName,
  label,
  leftValue,
  rightValue,
}: CompareSimplePlaceholderProps): JSX.Element => {
  return (
    <Stack
      direction="row"
      spacing={4}
      alignItems="center"
      justifyContent="space-evenly"
      sx={{ mt: 2 }}>
      <FieldSelectionInputWrapper
        inputFieldName={`left.${inputName}`}
        label={label}
        desc=""
        value={leftValue}
        optional
        hideDesc
        readOnly
      />
      <FontAwesomeIcon style={{ marginTop: "8px" }} icon={faArrowRight} />
      <FieldSelectionInputWrapper
        inputFieldName={`${inputName}`}
        label={label}
        desc=""
        value={rightValue}
        optional
        hideDesc
        readOnly
      />
    </Stack>
  );
};

export default CompareSimplePlaceholder;
