import GET_TRIGGER_BY_ID, {
  GetTriggerByIdQueryDefinition,
  GET_ALL_TRIGGERS,
  GET_TRIGGER_DEFAULT_VALUES,
  UPDATE_TRIGGER,
} from "apps/jtm/business/trigger/queries";
import { MaintainedProvider } from "apps/jtm/components/shared/maintainedby/MaintainedContext";
import TriggerForm from "apps/jtm/components/trigger/TriggerForm";
import { getUpdateOptions } from "libs/business/apollo/updateOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";

/**
 * The TriggerUpdate component
 * @returns a TriggerUpdate component
 */
const TriggerUpdate = (): JSX.Element => {
  const id = useParamsId("id");
  const getTriggerResponse = useSimpleFetching<GetTriggerByIdQueryDefinition>({
    query: GET_TRIGGER_BY_ID,
    context: "JTM",
    queryArguments: { id },
  });
  const [updateTrigger] = useMutationWrapper(UPDATE_TRIGGER);

  if (getTriggerResponse === false) {
    return <PageLoadingSpinner />;
  }

  const triggerData = getTriggerResponse.trigger;

  const handleSubmit = (submitData: any) => {
    updateTrigger(
      getUpdateOptions("Trigger", "JTM", submitData, GET_ALL_TRIGGERS, {
        REFETCH_QUERY: GET_TRIGGER_BY_ID,
        variables: { id },
        context: "JTM",
      }),
    );
  };

  const isMaintained = triggerData.maintained === 1;

  return (
    <MaintainedProvider
      initialValue={isMaintained}
      elementName="trigger"
      id={triggerData.id}
      pluginID={triggerData.pluginID}
      formSubmitHandler={handleSubmit}
      updateMutationFunction={updateTrigger}
      GET_DEFAULT_VALUES={GET_TRIGGER_DEFAULT_VALUES}>
      <TriggerForm mode="update" triggerData={triggerData} />
    </MaintainedProvider>
  );
};

export default TriggerUpdate;
