import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@mui/material";
import { IdAndName } from "libs/model/types";
import MultiSelectWrapper from "../input/MultiSelectWrapper";

interface CompareMultiSelectProps {
  inputName: string;
  leftLabel: string;
  rightLabel: string;
  leftValue: IdAndName[];
  rightValue: IdAndName[];
  hideArrow?: boolean;
}
/**
 * The CompareMultiSelect component
 * @param {CompareMultiSelectProps} props
 * @returns a CompareMultiSelect component
 */
const CompareMultiSelect = (props: CompareMultiSelectProps): JSX.Element => {
  const { inputName, leftLabel, rightLabel, leftValue, rightValue, hideArrow = false } = props;

  // const { deletedValues, newValues, identicalValues } = getSeparatedValues(leftValue, rightValue);

  return (
    <Stack direction="row" spacing={2} sx={{ mt: 2, minWidth: 500 }} alignItems="center">
      <Stack sx={{ width: "100%" }}>
        <MultiSelectWrapper
          name={`left_${inputName}`}
          label={leftLabel}
          disabled
          value={leftValue.map((element) => element.id)}
          options={leftValue}
          readonly
        />
      </Stack>
      {hideArrow ? (
        <div style={{ minWidth: "16px" }} />
      ) : (
        <FontAwesomeIcon style={{ marginTop: "8px" }} icon={faArrowRight} />
      )}
      <Stack sx={{ width: "100%" }}>
        <MultiSelectWrapper
          name={`${inputName}`}
          label={rightLabel}
          disabled
          value={rightValue.map((element) => element.id)}
          options={rightValue}
          readonly
        />
      </Stack>
    </Stack>
  );
};

export default CompareMultiSelect;

/**
 * Created for lated to display deleted and added chips differently
 */
// const getSeparatedValues = (leftValue: IdAndName[], rightValue: IdAndName[]) => {
//   return {
//     deletedValues: getDeletedValues(leftValue, rightValue),
//     newValues: getNewValues(leftValue, rightValue),
//     identicalValues: getIdentiaclValues(leftValue, rightValue),
//   };
// };

// const getDeletedValues = (leftValue: IdAndName[], rightValue: IdAndName[]): IdAndName[] => {
//   const deletedValues: IdAndName[] = [];

//   leftValue.forEach((old_value) => {
//     const valueNotFoundInNew = !rightValue.find((new_value) => {
//       return old_value.id === new_value.id;
//     });

//     if (valueNotFoundInNew) {
//       deletedValues.push(old_value);
//     }
//   });

//   return deletedValues;
// };

// const getNewValues = (leftValue: IdAndName[], rightValue: IdAndName[]): IdAndName[] => {
//   const newValues: IdAndName[] = [];

//   rightValue.forEach((new_value) => {
//     const valuesNotFoundInOld = !leftValue.find((old_value) => {
//       return old_value.id === new_value.id;
//     });

//     if (valuesNotFoundInOld) {
//       newValues.push(new_value);
//     }
//   });

//   return newValues;
// };

// const getIdentiaclValues = (leftValue: IdAndName[], rightValue: IdAndName[]): IdAndName[] => {
//   const identicalValues: IdAndName[] = [];

//   rightValue.forEach((new_value) => {
//     leftValue.forEach((old_value) => {
//       if (old_value.id === new_value.id) {
//         identicalValues.push(new_value);
//       }
//     });
//   });

//   return identicalValues;
// };
