import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import ErrorPage from "./ErrorPage";
import GlobalErrorPage from "./GlobalErrorPage";

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const onClick = () => {
    resetErrorBoundary();
  };

  return (
    <ErrorPage
      variant="oops"
      errorMsg={error.message}
      buttonText="Try Again"
      onButtonClick={onClick}
    />
  );
}

const myErrorHandler = (error: Error, info: React.ErrorInfo) => {
  console.error("Logging:", error, info);
};

export function MyErrorBoundary({ innerBoundary, children }: any) {
  return (
    <ErrorBoundary
      FallbackComponent={(fallbackProps) => {
        if (!innerBoundary) return GlobalErrorPage(fallbackProps);
        return ErrorFallback(fallbackProps);
      }}
      onError={myErrorHandler}
      key={window.location.pathname}>
      {children}
    </ErrorBoundary>
  );
}

export default MyErrorBoundary;
