import { Box, Grid, Stack, Typography } from "@mui/material";

const PublishedCardContent = ({ data }: any): JSX.Element => {
  if (data === undefined) {
    return (
      <Stack direction="column" justifyContent="center" sx={{ height: "100%" }}>
        <Typography align="center" variant="h6">
          Not published yet!
        </Typography>
      </Stack>
    );
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <Box sx={{ m: 1 }}>
          <Typography variant="subtitle2">Published On</Typography>
          <Typography variant="body2"> {data.published_on}</Typography>
          <Box sx={{ m: 1 }} />
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2"> {data.description}</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ m: 1 }}>
          <Typography variant="subtitle2">Created On</Typography>
          <Typography variant="body2"> {data.created_on}</Typography>
          <Box sx={{ m: 1 }} />
          <Typography variant="subtitle2">Published By</Typography>
          <Typography variant="body2" overflow="hidden" textOverflow="ellipsis">
            {data.published_by}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PublishedCardContent;
