/* eslint-disable prefer-destructuring */
import { IFunctionItem, ITextItem, IVariableItem } from "./FieldSelectionTypes";

export const transformToFieldItemArray = (
  value: string,
  mappingTable: { [shortId: string]: string },
): (IFunctionItem | IVariableItem | ITextItem)[] => {
  const itemArray = [];
  let textValue = "";

  const addTextValue = () => {
    if (textValue !== "") {
      itemArray.push(createTextItem(textValue));
      textValue = "";
    }
  };

  let i = 0;
  while (i < value.length) {
    const currentChar = value[i];
    const remainder = value.slice(i + 1, value.length);

    if (isVariable(currentChar, remainder)) {
      addTextValue();

      itemArray.push(createVariableItemFromRemainder(remainder, mappingTable));

      const closingBrackets = "}}";
      i += remainder.indexOf(closingBrackets) + closingBrackets.length;
    } else if (isFunction(currentChar, remainder)) {
      addTextValue();

      itemArray.push(createFunctionItemFromRemainder(remainder, mappingTable));

      i += getIndexOfClosingFunctionBracket(remainder) + 1;
    } else {
      textValue += currentChar;

      const endOfValue = remainder === "";
      if (endOfValue) {
        addTextValue();
      }
    }

    i += 1;
  }
  return itemArray;
};

const isFunction = (char: string, remainder: string): boolean => {
  const indexOfNextRoundOpenBracket =
    remainder.indexOf("(") < 0 ? Infinity : remainder.indexOf("(");
  const indexOfNextRoundClosingBracket =
    remainder.indexOf(")") < 0 ? Infinity : remainder.indexOf(")");
  if (
    char === "(" &&
    remainder.indexOf(",") < indexOfNextRoundOpenBracket &&
    remainder.indexOf(",") < indexOfNextRoundClosingBracket &&
    remainder.match(/.,.*?\)/gm)
  )
    return true;
  return false;
};

const isVariable = (char: string, remainder: string): boolean => {
  if (
    char === "{" &&
    typeof remainder[0] !== "undefined" &&
    remainder[0] === "{" &&
    typeof remainder[1] !== "undefined" &&
    remainder[1] !== "{" &&
    remainder.match("}}")
  )
    return true;
  return false;
};

const createFunctionItemFromRemainder = (
  remainder: string,
  mappingTable: { [shortId: string]: string },
): IFunctionItem => {
  const closingFunctionIndex = getIndexOfClosingFunctionBracket(remainder);
  const match = remainder.match(/(.*?),/m);
  let shortId = "";
  if (match !== null && typeof match[1] === "string") {
    shortId = match[1];
  }
  const sliceFrom = shortId.length + 1; // +1 to slice the ","-char away
  const innerText = remainder.slice(sliceFrom, closingFunctionIndex);

  return {
    short_id: shortId,
    name: mappingTable[shortId] || shortId,
    type: "function",
    children: transformToFieldItemArray(innerText, mappingTable),
  };
};

const createVariableItemFromRemainder = (
  remainder: string,
  mappingTable: { [shortId: string]: string },
): IVariableItem => {
  const match = remainder.match(/{(.*?)}}/m);
  let shortId = "";

  if (match !== null && typeof match[1] === "string") {
    shortId = match[1];
  }

  return {
    short_id: shortId,
    name: mappingTable[shortId] || shortId,
    type: "variable",
  };
};

const getIndexOfClosingFunctionBracket = (remainder: string): number => {
  let count = 0;
  for (let i = 0; i < remainder.length; i += 1) {
    const char = remainder[i];

    if (char === "(") {
      count += 1;
    }
    if (count === 0 && char === ")") {
      return i;
    }
    if (char === ")") {
      count -= 1;
    }
  }
  return remainder.length - 1;
};

const createTextItem = (name: string): ITextItem => {
  return {
    name,
    type: "text",
  };
};

export const transformToString = (value: (IFunctionItem | IVariableItem | ITextItem)[]): string => {
  let finalString = "";
  value.forEach((item) => {
    if (item.type === "text") {
      finalString += item.name;
    }

    if (item.type === "variable") {
      finalString += `{{${item.short_id}}}`;
    }

    if (item.type === "function") {
      finalString += `(${item.short_id},${transformToString(item.children || [])})`;
    }
  });
  return finalString;
};
