/* eslint-disable class-methods-use-this */
import { GET_TOKEN } from "apps/admin/business/login/queries";
import GET_ALL_USER from "apps/admin/business/user/queries";
import CLIENT, { logout } from "../apollo";
import EmptyDataError from "../apollo/EmptyDataError";
import { notifyError } from "../notification/notification";
import TokenStorageManager from "./TokenStorageManager";
import UserStorageManager, { User } from "./UserStorageManager";

class UserService {
  private static instance: UserService;

  private user: User;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
    this.user = {
      id: -1,
      username: "",
      email: "",
      group: { id: 7, name: "READACCESS" },
    };

    try {
      this.user = UserStorageManager.getUser();
    } catch (e) {
      this.removeOldAuth();

      this.logout();
    }
  }

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }

    return UserService.instance;
  }

  private removeOldAuth() {
    // for deployment to remove old auth token and to reload app to get new version
    if (localStorage.getItem("refreshTokenSession")) {
      localStorage.removeItem("refreshTokenSession");
      localStorage.removeItem("userSession");
      window.location.reload();
    }
  }

  public async login(email: string, password: string) {
    const payload = await UserService.fetchToken(email, password);

    TokenStorageManager.setAuthToken(payload.auth);
    TokenStorageManager.setRefreshToken(payload.refresh);

    const user = await this.getUserByEmail(email);
    this.user = user;

    UserStorageManager.setUser(user);

    TokenStorageManager.dispatchTokenChanged();
  }

  private static async fetchToken(email: string, password: string) {
    try {
      const apolloQueryResult = await CLIENT.query({
        query: GET_TOKEN,
        variables: { email, password },
        context: { clientName: "ADMIN" },
      });

      if (!apolloQueryResult.data.getToken) throw new EmptyDataError();

      return apolloQueryResult.data.getToken;
    } catch (e) {
      if (e instanceof Error) notifyError(e.message);
      throw e;
    }
  }

  private async getUserByEmail(email: string) {
    const response = await CLIENT.query({
      query: GET_ALL_USER,
      fetchPolicy: "no-cache",
      context: { clientName: "ADMIN" },
    });

    const { users } = response.data;

    let user = users.find((u: any) => u.email === email);

    if (!user) {
      user = users.find((u: any) => u.username === email);
    }

    if (!user) {
      throw new Error("User not found");
    }

    return user;
  }

  public logout() {
    logout();
  }

  public getUser() {
    return this.user;
  }

  public getUserId() {
    return this.user.id;
  }

  public getAccessGroup() {
    return this.user.group.id;
  }
}

const UserServiceInstance = UserService.getInstance();

export default UserServiceInstance;
