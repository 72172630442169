import { Container, Stack, Typography } from "@mui/material";
import ContainerMultiSelect from "apps/admin/components/container/ContainerMultiSelect";
import {
  GET_CONNECTED_CONSENTBAR_DATA,
  GET_CONSENTBAR_CONFIG,
  SAVE_CONSENTBAR_CONFIG,
  SAVE_CONSENTBAR_CONNECTIONS,
} from "apps/cmp/business/consentbars/queries";
import {
  IConstantData,
  IConstantOption,
} from "apps/jtm/components/constants/ConstantContainerSetting";
import ConstantsTabConfiguration from "apps/jtm/components/constants/ConstantsTabConfiguration";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import { FormProvider, useForm } from "react-hook-form";

interface ConsentBarConfigElement {
  key: string;
  description: string;
  name: string;
  default: string;
  type: "textarea" | "color" | "input" | "select" | "editor" | "multiselect" | undefined;
  value: string;
  options?: { [key: string]: string };
}

interface FetchedConsentBarData {
  config_js: string;
  id: number;
  name: string;
  template_consentbar_selected: {
    config_json: string;
    containerid: number;
  }[];
}
interface FormData {
  selectedContainerIds: number[];
  config: ConfigFormData;
}

interface ConfigFormData {
  [key: string]: {
    id: string;
    name: string;
    options?: { account?: number; value: any }[];
    value: String;
  };
}

/**
 * The ConsentBar component
 * @returns a ConsentBar component
 */
const ConsentBar = (): JSX.Element => {
  // HOOKS
  const methods = useForm<any>({});
  const consentbarId = useParamsId("id");

  // QUERIES
  const getConsentBarConfigResponse = useSimpleFetching({
    query: GET_CONSENTBAR_CONFIG,
    context: "CMP",
    queryArguments: { consentbarId },
  });

  const getConnectionsResponse = useSimpleFetching({
    query: GET_CONNECTED_CONSENTBAR_DATA,
    context: "CMP",
    queryArguments: { consentbarId },
  });

  const [saveConfig] = useMutationWrapper(SAVE_CONSENTBAR_CONFIG);
  const [saveConnection] = useMutationWrapper(SAVE_CONSENTBAR_CONNECTIONS);

  // FUNCTIONS
  const formSubmitHandler = (data: FormData) => {
    saveConnection({
      variables: { containerids: data.selectedContainerIds, consentbarConsentbarid: consentbarId },
      context: { clientName: "CMP" },
      onCompleted: () => {
        saveConfig({
          variables: {
            consentbarId,
            containers: data.selectedContainerIds,
            config: transformConfig(data.config),
          },
          context: { clientName: "CMP" },
          onCompleted: () => {
            notifySuccess("Configuration updated successful!");
          },
          onError: (error) => {
            notifyError(error.message);
          },
          refetchQueries: [
            {
              query: GET_CONSENTBAR_CONFIG,
              context: { clientName: "CMP" },
              variables: { consentbarId },
            },
            {
              query: GET_CONNECTED_CONSENTBAR_DATA,
              context: { clientName: "CMP" },
              variables: { consentbarId },
            },
          ],
        });
      },
      onError: (error) => {
        notifyError(error.message);
      },
    });
  };

  if (getConsentBarConfigResponse === false || getConnectionsResponse === false) {
    return <PageLoadingSpinner />;
  }

  const fetchedData: FetchedConsentBarData = getConsentBarConfigResponse.ConsentbarData;
  const constantData = transformFetchedData(fetchedData);

  const initialSelectedContainerIds =
    getConnectionsResponse.ActiveConsentbarData.template_consentbar_selected.map(
      (connection: any) => connection.containerid,
    );

  return (
    <FormProvider {...methods} key="frontendPluginForm">
      <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Page title="Consentbar">
          <Stack spacing={5}>
            <Typography variant="h2">{fetchedData.name}</Typography>
            <ContainerMultiSelect
              inputName="selectedContainerIds"
              idsOfSelectedContainers={initialSelectedContainerIds}
              // onChange={handleMultiSelectChange}
              label="Connected Containers"
            />
            {constantData.length > 0 && (
              <ConstantsTabConfiguration
                constantData={constantData}
                selectedContainerIds={
                  methods.watch("selectedContainerIds") || initialSelectedContainerIds
                }
                submitName="config"
                defaultReadOnly
              />
            )}
          </Stack>
        </Page>
        <Container>
          <SaveCancelBar />
        </Container>
      </form>
    </FormProvider>
  );
};

export default ConsentBar;

const transformFetchedData = (fetchedData: FetchedConsentBarData): IConstantData[] => {
  const config: { [key: string]: ConsentBarConfigElement } = JSON.parse(fetchedData.config_js);
  const constantDataArray: IConstantData[] = [];

  for (const key in config) {
    if (Object.prototype.hasOwnProperty.call(config, key)) {
      const defaultConfigElement = config[key];
      const options: IConstantOption[] = [];

      fetchedData.template_consentbar_selected.forEach((configForContainer) => {
        if (!configForContainer.config_json) return;
        const containerConfigs: { [key: string]: string } = JSON.parse(
          configForContainer.config_json,
        );
        if (typeof containerConfigs[key] !== "undefined") {
          options.push({
            account: configForContainer.containerid,
            environment: 1,
            value: containerConfigs[key],
          });
        }
      });

      let typeOptions: { key: string; value: string }[] | undefined;

      if (defaultConfigElement.options) {
        const o = defaultConfigElement.options;
        typeOptions = Object.keys(o).map((optionKey: string) => {
          return { key: optionKey, value: o[optionKey] };
        });
      }

      constantDataArray.push({
        short_id: key,
        name: defaultConfigElement.name,
        value: {
          value: defaultConfigElement.default,
          description: defaultConfigElement.description,
          type: defaultConfigElement.type === "textarea" ? "editor" : defaultConfigElement.type,
          type_options: typeOptions,
          options,
        },
      });
    }
  }

  return constantDataArray;
};

const transformConfig = (config: ConfigFormData): string => {
  const newConfigObject: { [key: number]: { [key: string]: string } } = {};

  for (const key in config) {
    if (Object.prototype.hasOwnProperty.call(config, key)) {
      const configElement = config[key];
      if (configElement.options) {
        configElement.options.forEach((configElementOption) => {
          if (typeof configElementOption.account !== "undefined") {
            newConfigObject[configElementOption.account] =
              newConfigObject[configElementOption.account] || {};
            if (configElementOption.value !== "")
              newConfigObject[configElementOption.account][key] = configElementOption.value;
          }
        });
      }
    }
  }

  return JSON.stringify(newConfigObject);
};
