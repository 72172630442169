import { faCircleQuestion, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, InputLabel, Stack, Tooltip, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { ContainerEnvironmentValues } from "libs/model/types";
import PaginatedInputDialog from "libs/ui/components/feedback/modal/PaginatedInputModal";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { FC, useState } from "react";
import continerIcon from "../../../../assets/icons/containerIcon.svg";

interface ContainerValueSelectionProps {
  rootInputName: string;
  labelText: string;
  description?: string;
  containerEnvironmentValues: ContainerEnvironmentValues<string>;
  isMandatory?: boolean;
  shortPluginId: string;
  selectedContainerIds: number[];
  onDelete?: (shortId: string) => void;
}

const ContainerValueSelection: FC<ContainerValueSelectionProps> = ({
  rootInputName,
  labelText,
  description,
  containerEnvironmentValues,
  isMandatory,
  selectedContainerIds,
  shortPluginId,
  onDelete,
}: ContainerValueSelectionProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const onIconClick = () => {
    setModalOpen(true);
  };
  const onModalClose = () => {
    setModalOpen(false);
  };

  const prefixName = shortPluginId ? `${rootInputName}.${shortPluginId}` : rootInputName;

  return (
    <div>
      <PaginatedInputDialog
        defaultValues={containerEnvironmentValues}
        rootInputName={prefixName}
        open={modalOpen}
        onClose={onModalClose}
        title={labelText}
        subtitle={description || "No description available"}
        selectedContainerIds={selectedContainerIds}
      />

      <InputLabel sx={{ mb: 1, overflowWrap: "break-word", whiteSpace: "normal" }}>
        <Typography variant="bodyMedium14" data-cy={`${prefixName}-label`}>
          {labelText}
          {isMandatory && (
            <Tooltip
              title="This is mandatory field, please provide a default value"
              placement="top-start">
              <span> *</span>
            </Tooltip>
          )}
          {description && (
            <Tooltip title={description} placement="top-start">
              <FontAwesomeIcon icon={faCircleQuestion} style={{ marginLeft: "6px" }} />
            </Tooltip>
          )}
        </Typography>
      </InputLabel>
      <TextFieldWrapper
        name={`${prefixName}.name`}
        label="Name"
        value={containerEnvironmentValues.name || labelText || shortPluginId}
        hidden
      />
      <Stack direction="row" alignItems="center">
        <TextFieldWrapper
          name={`${prefixName}.value`}
          hiddenLabel
          placeholder="Please enter a default value"
          endAdornment={
            selectedContainerIds.length > 0 ? (
              <InputAdornment
                position="end"
                onClick={onIconClick}
                style={{ cursor: "pointer" }}
                data-cy={`${prefixName}-openContainerValues`}>
                <Tooltip title="Click here to add individual values for specific containers and environments">
                  <img src={continerIcon} alt="" />
                </Tooltip>
              </InputAdornment>
            ) : undefined
          }
          size="medium"
          value={containerEnvironmentValues.value}
          label={labelText}
          textFieldSx={{ m: 0 }}
        />
        {onDelete && (
          <IconButton
            sx={{ mb: "20px" }}
            onClick={() => onDelete(shortPluginId)}
            data-cy={`${prefixName}-deleteButton`}>
            <FontAwesomeIcon icon={faTrashCan} size="xs" />
          </IconButton>
        )}
      </Stack>
    </div>
  );
};

export default ContainerValueSelection;
