import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { ISubNavElement } from "apps/admin/conf/sidebar_config";
import { NavLink } from "react-router-dom";
import IconLink from "./IconLink";
import classes from "./sidebar.module.scss";

interface ISubNavLinkProps {
  subNavElement: ISubNavElement;
  isActive: boolean;
}
/**
 * The SubNavLink component
 * @param {ISubNavLinkProps} props
 * @returns a SubNavLink component
 */
const SubNavLink = ({ subNavElement, isActive }: ISubNavLinkProps): JSX.Element => {
  const isActiveClass = isActive ? classes.subNavActive : "";
  return (
    <li>
      <NavLink to={subNavElement.to}>
        <div className={`${isActiveClass} ${classes.subNavLink}`}>
          <Typography variant="body1">{subNavElement.name}</Typography>
        </div>
      </NavLink>
    </li>
  );
};

export default SubNavLink;
