const getMaintainedStatus = (
  maintained: boolean,
  pluginId: string | undefined,
): MaintainedStatus => {
  if (pluginId === null || pluginId === undefined || pluginId === "") {
    return "custom";
  }
  if (maintained) {
    return "jentis";
  }
  return "user";
};

export default getMaintainedStatus;

export type MaintainedStatus = "jentis" | "user" | "custom";
