import CodeEditor from "../../input/editor/CodeEditor";
import CompareBoolean from "../CompareBoolean";
import CompareSimpleInput from "../CompareSimpleInput";

interface FrontendVariableData {
  __typename?: string;
  id?: number;
  name?: string;
  value?: string;
  maintained?: number;
  pluginID?: string;
  personal_data?: boolean;
  group?: string;
  scope?: string;
}

interface ICompareFrontendVariableProps {
  old_variableData: FrontendVariableData;
  new_variableData: FrontendVariableData;
  allowCompareEdit?: boolean;
}
/**
 * The CompareFrontendVariable component
 * @param {ICompareFrontendVariableProps} props
 * @returns a CompareFrontendVariable component
 */
const CompareFrontendVariable = ({
  old_variableData: {
    name: old_name,
    value: old_js,
    group: old_group,
    personal_data: old_pii,
    scope: old_scope,
  },
  new_variableData: {
    name: new_name,
    value: new_js,
    group: new_group,
    personal_data: new_pii,
    scope: new_scope,
  },
  allowCompareEdit = false,
}: ICompareFrontendVariableProps): JSX.Element | null => {
  const nameShouldBeRendered =
    typeof old_name !== "undefined" && typeof new_name !== "undefined" && old_name !== new_name;

  const groupShouldBeRendered =
    typeof old_group !== "undefined" && typeof new_group !== "undefined" && old_group !== new_group;

  const scopeShouldBeRendered =
    typeof old_scope !== "undefined" && typeof new_scope !== "undefined" && old_scope !== new_scope;

  const piiShouldBeRendered =
    typeof old_pii !== "undefined" && typeof new_pii !== "undefined" && old_pii !== new_pii;

  const jsShouldBeRendered =
    typeof old_js !== "undefined" && typeof new_js !== "undefined" && old_js !== new_js;

  return (
    <>
      {nameShouldBeRendered && (
        <CompareSimpleInput
          inputName="name"
          leftLabel="Old Name"
          rightLabel="New Name"
          leftValue={old_name}
          rightValue={new_name}
          allowCompareEdit={allowCompareEdit}
        />
      )}
      {groupShouldBeRendered && (
        <CompareSimpleInput
          inputName="group"
          leftLabel="Old Group"
          rightLabel="New Group"
          leftValue={old_group}
          rightValue={new_group}
          allowCompareEdit={allowCompareEdit}
        />
      )}
      {scopeShouldBeRendered && (
        <CompareSimpleInput
          inputName="scope"
          leftLabel="Old Scope"
          rightLabel="New Scope"
          leftValue={old_scope}
          rightValue={new_scope}
          allowCompareEdit={allowCompareEdit}
        />
      )}
      {piiShouldBeRendered && (
        <CompareBoolean
          inputName="pii"
          leftLabel="Old Personal Identifiable Information"
          rightLabel="New Personal Identifiable Information"
          leftValue={old_pii}
          rightValue={new_pii}
          allowCompareEdit={allowCompareEdit}
        />
      )}
      {jsShouldBeRendered && (
        <CodeEditor
          label="Javascript Code"
          name="value"
          contentOld={decodeURIComponent(old_js)}
          content={decodeURIComponent(new_js)}
          readOnly={!allowCompareEdit}
        />
      )}
    </>
  );
};

export default CompareFrontendVariable;
