import { BackendNexusUserGroupType } from "apps/admin/business/user/queries";
import ls from "localstorage-slim";

export type AccessGroups = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface User {
  id: number;
  username: string;
  email: string;
  group: BackendNexusUserGroupType;
}

const USER_STORAGE_KEY = "user-session";

class UserStorageManager {
  public static setUser(user: User) {
    ls.set(USER_STORAGE_KEY, JSON.stringify(user), { encrypt: true });
  }

  public static getUser() {
    const storageItem: string | null = ls.get(USER_STORAGE_KEY, { decrypt: true });

    if (storageItem === null) throw new Error("Malformed user object");

    let user: User;
    try {
      user = JSON.parse(storageItem);
    } catch (e) {
      ls.remove(USER_STORAGE_KEY);
      throw new Error("Malformed user object");
    }

    return user;
  }

  public static removeUser() {
    ls.remove(USER_STORAGE_KEY);
  }
}

export default UserStorageManager;
