/* eslint-disable react/jsx-props-no-spreading */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useContainer } from "apps/admin/components/container/ContainerContext";
import { useEnvironment } from "apps/jtm/components/environment/EnvironmentContext";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

interface InputTableProps {
  rootInputName: string;
  selectedContainerIds: number[];
}

const InputTable = ({ rootInputName, selectedContainerIds }: InputTableProps) => {
  const formContext = useFormContext();
  const { allContainers } = useContainer();
  const environments = useEnvironment();
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const pageContainerIds = () => {
    return selectedContainerIds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="bodyMedium16">Container list</Typography>
              </TableCell>
              {environments.map((env) => (
                <TableCell key={env.id}>
                  <Typography variant="bodyMedium16">{env.name} environment</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pageContainerIds().map((selectedContainerId) => {
              return (
                <TableRow key={selectedContainerId}>
                  <TableCell style={{ maxWidth: "200px", overflowWrap: "break-word" }}>
                    {allContainers.find((c) => c.id === selectedContainerId)!.name}
                  </TableCell>
                  {environments.map((env) => {
                    return (
                      <TableCell key={env.id}>
                        <TextField
                          {...formContext.register(
                            `${rootInputName}.options.${selectedContainerId}${env.id}.value`,
                          )}
                          placeholder={formContext.getValues(`${rootInputName}.value`)}
                          size="small"
                          fullWidth
                        />
                        <input
                          {...formContext.register(
                            `${rootInputName}.options.${selectedContainerId}${env.id}.environment`,
                            { valueAsNumber: true },
                          )}
                          value={env.id}
                          hidden
                        />
                        <input
                          {...formContext.register(
                            `${rootInputName}.options.${selectedContainerId}${env.id}.account`,
                            { valueAsNumber: true },
                          )}
                          value={selectedContainerId}
                          hidden
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedContainerIds.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={selectedContainerIds.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default InputTable;
