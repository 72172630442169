import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Stack, Typography } from "@mui/material";
import colorStyles from "assets/styles/colorPalette.module.scss";

interface AssistantWizardStepNumberProps {
  stepNumber: number;
  isCurrent: boolean;
  isCompleted: boolean;
  isLoading: boolean;
  title: string;
}
/**
 * The AssistantWizardStepNumber component
 * @param {AssistantWizardStepNumberProps} props
 * @returns a AssistantWizardStepNumber component
 */
const AssistantWizardStepNumber = ({
  stepNumber,
  isCurrent,
  isCompleted,
  isLoading,
  title,
}: AssistantWizardStepNumberProps): JSX.Element => {
  const color = isCurrent || isCompleted ? "primary.main" : colorStyles.grey2;
  return (
    <Stack direction="row" sx={{ color }} alignItems="center" spacing={1}>
      <Stack
        sx={{
          backgroundColor: isCompleted ? "primary.main" : "",
          color: isCompleted ? "primary.contrastText" : "",
          border: 2,
          width: 30,
          height: 30,
          borderRadius: 1,
        }}
        justifyContent="center"
        alignItems="center">
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Typography>{isCompleted ? <FontAwesomeIcon icon={faCheck} /> : stepNumber}</Typography>
        )}
      </Stack>
      <Typography component="pre">{title}</Typography>
    </Stack>
  );
};

export default AssistantWizardStepNumber;
