import { Box, Stack, Typography } from "@mui/material";
import AssistantValueSummary from "./AssistantValueSummary";

interface AssistantListValueSummaryProps {
  values: string[];
  label: string;
  variant?: "green-card";
  emptyListMessage?: string;
}
/**
 * The AssistantListValueSummary component
 * @param {AssistantListValueSummaryProps} props
 * @returns a AssistantListValueSummary component
 */
const AssistantListValueSummary = ({
  values,
  label,
  variant,
  emptyListMessage = "Nothing selected",
}: AssistantListValueSummaryProps): JSX.Element => {
  let value;

  switch (variant) {
    case "green-card": {
      value = (
        <Stack direction="row" gap={1} flexWrap="wrap">
          {values.map((str, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={`${str}${index}`}
              sx={{ px: 1, bgcolor: "success.light", borderRadius: 1 }}>
              <Typography variant="bodyMedium16">{str}</Typography>
            </Box>
          ))}
        </Stack>
      );
      break;
    }
    default: {
      if (values.length === 0) {
        value = emptyListMessage;
      } else {
        value = values.join(", ");
      }
      break;
    }
  }

  return (
    <div>
      <AssistantValueSummary label={label} value={value} />
    </div>
  );
};

export default AssistantListValueSummary;
