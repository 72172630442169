import { Box } from "@mui/system";
import useDebounce from "libs/business/hooks/useDebounce";
import { deepCopyObjectWithoutFunction } from "libs/business/utils/objectFunctions";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import React, { useCallback, useState } from "react";
import { RowFilterConfig } from "../types";

interface SearchFilterProps {
  filter: RowFilterConfig;
  setFilter: React.Dispatch<React.SetStateAction<RowFilterConfig>>;
}
/**
 * The SearchFilter component
 * @param {SearchFilterProps} props
 * @returns a SearchFilter component
 */
const SearchFilter = ({ filter, setFilter }: SearchFilterProps): JSX.Element => {
  const [descriptionSearch, setDescriptionSearch] = useState("");

  const onSearchChange = useCallback(() => {
    setFilter((currentFilter) => {
      const newFilter: RowFilterConfig = deepCopyObjectWithoutFunction(currentFilter);
      newFilter.description = descriptionSearch;
      return newFilter;
    });
  }, [descriptionSearch, setFilter]);

  useDebounce(onSearchChange, 300, [descriptionSearch]);

  return (
    <Box style={{ marginTop: "-16px", flex: 1, maxHeight: "50px" }}>
      <TextFieldWrapper
        name="string"
        label="Search"
        size="medium"
        value={filter.description}
        onChange={setDescriptionSearch}
      />
    </Box>
  );
};

export default SearchFilter;
