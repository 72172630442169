import { getValueByDottedStringValue } from "libs/business/utils/objectFunctions";
import { useCallback } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { WizardContextType } from "../AssistantWizardContext";
import { AssistantStepFormConfig, StepDependency } from "../types";

const useRunDependencyEffects = (
  wizardContext: WizardContextType,
  stepConfig: AssistantStepFormConfig[],
  hookFormMethods: UseFormReturn<FieldValues, object>,
) => {
  const runDependencyEffects = () => {
    const dependencies = getDependenciesOfCurrentStep();
    dependencies.forEach((dependency) => {
      if (triggerFieldHasChanged(dependency) && dependency.effect !== undefined) {
        dependency.effect(hookFormMethods);
      }
    });
  };

  const triggerFieldHasChanged = (dependency: StepDependency) =>
    getValueByDottedStringValue(wizardContext.data, dependency.triggerField) !==
    hookFormMethods.getValues(dependency.triggerField);

  const getDependenciesOfCurrentStep = useCallback(() => {
    const stepDependencies: StepDependency[] = [];

    stepConfig.forEach((stepConfigElement, index) => {
      stepConfigElement.stepDependencies?.forEach((stepDependency) => {
        const currentStepAffectsOtherStep =
          stepDependency.dependentOnStep === wizardContext.activeStep &&
          index + 1 <= wizardContext.highestVisited;

        if (currentStepAffectsOtherStep) {
          stepDependencies.push(stepDependency);
        }
      });
    });
    return stepDependencies;
  }, [stepConfig, wizardContext.activeStep, wizardContext.highestVisited]);

  return { runDependencyEffects };
};

export default useRunDependencyEffects;
