import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, Box, Fade, IconButton, Modal, Stack, Typography } from "@mui/material";

const ModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "background.default",
  borderRadius: "4px",
  boxShadow: 24,
  maxHeight: "90%",
  overflow: "hidden",
};

const contentStyle = {
  p: 4,
  maxHeight: "100%",
  overflow: "auto",
};

interface IPopUpWindowProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children?: JSX.Element;
}
/**
 * The PopUpWindow component
 * @param {IPopUpWindowProps} props
 * @returns a PopUpWindow component
 */
const PopUpWindow = ({ open, handleClose, title, children }: IPopUpWindowProps): JSX.Element => {
  return (
    <Modal
      sx={{ overflow: "auto" }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Stack sx={ModalStyle}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "background.paper",
              zIndex: 2,
              p: 2,
            }}>
            <Typography
              id="transition-modal-title"
              variant="h3"
              color="secondary.contrastText"
              component="h2">
              {renderTitle(title)}
            </Typography>
            <IconButton onClick={handleClose} sx={{ borderRadius: 1 }}>
              <FontAwesomeIcon size="sm" icon={faXmark} />
            </IconButton>
          </Stack>
          <Box sx={contentStyle}>{children}</Box>
        </Stack>
      </Fade>
    </Modal>
  );
};

export default PopUpWindow;

const renderTitle = (title: string | undefined): string => {
  if (typeof title === "undefined") return "";
  return title;
};
