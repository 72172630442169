import { ApolloCache, FetchResult, useSubscription } from "@apollo/client";
import { faSatelliteDish } from "@fortawesome/pro-solid-svg-icons";
import CLIENT from "libs/business/apollo";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { useSessionStorage } from "libs/business/hooks/useStorage";
import useTimeout from "libs/business/hooks/useTimeout";
import { notifyInfo, notifySuccess } from "libs/business/notification/notification";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import Page from "libs/ui/components/surfaces/page/Page";
import { useEffect, useState } from "react";
import { DELETE_REQUESTS_BY_SESSION_ID } from "../../business/mutations";
import { GET_REQUEST_ROWS, SUBSCRIBTION_TO_NEW_ROWS } from "../../business/queries";
import RowFilter from "../RowFilter/RowFilter";

interface SessionDataProps {
  sessionID: string;
  subscriptionWasSuccessful: boolean;
  setSubscriptionWasSuccessful: (b: boolean) => void;
}

const SessionData = ({
  sessionID,
  subscriptionWasSuccessful,
  setSubscriptionWasSuccessful,
}: SessionDataProps): JSX.Element => {
  // HOOKS
  const [getFirstRows, queryState] = useQueryWrapper(GET_REQUEST_ROWS);
  const [lastRecordId, setLastRecordId] = useSessionStorage(sessionID, 0);
  const [rowData, setRowData] = useState<any[]>([]);
  const [lastFetchedData, setLastFetchedData] = useState<any[]>([]);
  const { data, loading, error } = useSubscription(SUBSCRIBTION_TO_NEW_ROWS, {
    variables: { sessionId: sessionID },
    context: { clientName: "MONITOR", isSubscription: true },
  });
  const [deleteRequestsBySessionId, deleteRequestsBySessionIdState] = useMutationWrapper(
    DELETE_REQUESTS_BY_SESSION_ID,
  );

  const setIsNewDataFalse = useTimeout(() => {
    setRowData((old) => enrichWithIsNew(old, false));
  }, 2000);

  useEffect(() => {
    const dataExists = !!queryState.data?.findRequestRows?.requestRows;
    if (queryState.data && dataExists) {
      setRowData(queryState.data.findRequestRows.requestRows);
    }
  }, [queryState.data]);

  // FUNCTIONS
  const enrichWithIsNew = (arr: any[], isNew: boolean) => {
    return arr.map((rowDataElement) => {
      return { ...rowDataElement, isNew };
    });
  };

  const deleteRows = () => {
    deleteRequestsBySessionId({
      variables: { sessionId: sessionID },
      context: { clientName: "MONITOR" },
      onCompleted: () => {
        notifySuccess(`Rows successfully deleted`);
        setRowData([]);
      },
      update(cache: ApolloCache<any>, response: FetchResult) {
        if (response.data) {
          const queryData = CLIENT.readQuery({
            query: GET_REQUEST_ROWS,
          });
          if (queryData) {
            CLIENT.writeQuery({
              query: GET_REQUEST_ROWS,
              data: queryData,
            });
          }
        }
      },
    });
    if (deleteRequestsBySessionIdState.error) {
      notifyInfo("Error while deleting rows");
    }
  };
  // FETCHING
  if (!loading && data) {
    const newData = data.subscribeToNewRows;
    const newDataWasFetched = data.subscribeToNewRows !== lastFetchedData;
    if (newDataWasFetched) {
      setSubscriptionWasSuccessful(true);
      setRowData((old) => [...enrichWithIsNew(newData, true), ...enrichWithIsNew(old, false)]);
      setLastFetchedData(newData);
      if (newData.length > 0) setLastRecordId(newData.at(-1)?.id);
      notifyInfo("New data received");
      setIsNewDataFalse.reset();
    }
  }

  if (error) {
    setSubscriptionWasSuccessful(false);
  }

  if (!queryState.called) {
    getFirstRows({
      variables: { sessionID, limit: 50, cursorId: lastRecordId },
      context: { clientName: "MONITOR" },
    });
  }

  if (!queryState.called || queryState.loading) {
    return <PageLoadingSpinner />;
  }

  // RENDERING
  return (
    <Page
      title="Live Data"
      notificationText={
        subscriptionWasSuccessful
          ? "Successfully connected to the debug session!"
          : "You are not connected to the debug session!"
      }
      notificationType={subscriptionWasSuccessful ? "SUCCESS" : "ERROR"}
      notificationIcon={faSatelliteDish}>
      <RowFilter rowData={rowData} deleteRows={deleteRows} />
    </Page>
  );
};

export default SessionData;
