import { gql } from "@apollo/client";
import {
  ContainerEnvironmentValues,
  GQLTypeDefinition,
  ListResponse,
  SingleResponse,
} from "libs/model/types";

export interface BackendNexusToolConstantDetails {
  short_id: string;
  name: string;
  desc: string;
  value: ContainerEnvironmentValues;
  optional: boolean;
  type: string;
}

interface IConsentBarConfigs {
  id: number;
  name: string;
  image_path: string;
  defaultConfig: IConsentData;
  data: {
    [key: number]: IConsentData;
  };
}

interface IConsentData {
  justification: number | null;
  name: string;
  street: string;
  zip: string;
  country: number | null;
  category: number | null;
  purposes: number[];
  description: string;
}
export interface BackendNexusCmpInformationDetails {
  no_consent_mode: boolean;
  essential_mode_available: boolean;
  consentbar_configs: IConsentBarConfigs[];
}

export interface TagTypeWithKey {
  [pluginId: string]: {
    name: string;
    description: string;
    triggerId?: number;
  };
}

export interface BackendNexusToolDefinitionDetails {
  pluginID: string;
  name: string;
  image_path: string;
  tag_types: TagTypeWithKey;
  constants: BackendNexusToolConstantDetails[];
  cmpInformation: BackendNexusCmpInformationDetails;
}

export interface BackendNexusToolDetails {
  id: number;
  create_by: number;
  create_at: string;
  edit_by: number;
  edit_at: string;
  name: string;
  version: string;
  no_consent_mode: boolean;
  pluginID: string;
  containerIDs: number[];
  justification_id: number;
  category_id: number;
  tool_definition: BackendNexusToolDefinitionDetails;
  constants: BackendNexusToolConstantDetails[];
}

export interface GetAllToolDefinitionQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusToolDefinitionDetails, "pluginID" | "name" | "image_path">;
  response: ListResponse<"toolDefinitions", GetAllToolDefinitionQueryDefinition["dataType"]>;
}

export const GET_ALL_TOOL_DEFINITIONS = gql`
  query toolDefinitions {
    toolDefinitions {
      pluginID
      name
      image_path
    }
  }
`;

export interface GetTagTypesByPluginIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusToolDefinitionDetails, "tag_types">;
  response: SingleResponse<"toolDefinition", GetTagTypesByPluginIdQueryDefinition["dataType"]>;
  arguments: { pluginID: string };
}

export const GET_TAG_TYPE_DEFINITION_BY_PLUGINID = gql`
  query toolDefinition($pluginID: String!) {
    toolDefinition(pluginID: $pluginID) {
      tag_types
    }
  }
`;

export interface GetToolDefinitionByPluginIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusToolDefinitionDetails,
    "name" | "image_path" | "pluginID" | "cmpInformation" | "constants"
  >;
  response: SingleResponse<
    "toolDefinition",
    GetToolDefinitionByPluginIdQueryDefinition["dataType"]
  >;
  arguments: { pluginID: string };
}

export const GET_TOOL_DEFINITION_BY_PLUGINID = gql`
  query toolDefinition($pluginID: String!) {
    toolDefinition(pluginID: $pluginID) {
      name
      image_path
      pluginID
      constants {
        short_id
        name
        desc
        value
        optional
        type
      }
      cmpInformation {
        no_consent_mode
        essential_mode_available
        consentbar_configs
      }
    }
  }
`;

export interface GetAllToolQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusToolDetails,
    "id" | "create_at" | "edit_at" | "name" | "pluginID" | "containerIDs"
  > & {
    tool_definition: Pick<
      BackendNexusToolDefinitionDetails,
      "name" | "image_path" | "pluginID" | "tag_types"
    >;
  };
  response: ListResponse<"tools", GetAllToolQueryDefinition["dataType"]>;
  arguments: { sortParam?: String; sortDirection?: String };
}

export const GET_ALL_TOOLS = gql`
  query tools($sortParam: String, $sortDirection: String) {
    tools(sortParam: $sortParam, sortDirection: $sortDirection) {
      id
      create_at
      edit_at
      name
      pluginID
      containerIDs
      tool_definition {
        pluginID
        name
        image_path
        tag_types
      }
    }
  }
`;

export interface GetToolByIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<
    BackendNexusToolDetails,
    | "id"
    | "create_at"
    | "edit_at"
    | "name"
    | "pluginID"
    | "containerIDs"
    | "version"
    | "no_consent_mode"
    | "pluginID"
    | "containerIDs"
    | "justification_id"
    | "category_id"
    | "constants"
  > & {
    tool_definition: Pick<
      BackendNexusToolDefinitionDetails,
      "name" | "image_path" | "pluginID" | "tag_types" | "cmpInformation" | "constants"
    >;
  };
  response: SingleResponse<"tool", GetToolByIdQueryDefinition["dataType"]>;
  arguments: { toolId: number };
}

export const GET_TOOL_BY_ID = gql`
  query tool($toolId: Int!) {
    tool(id: $toolId) {
      id
      create_by
      create_at
      edit_by
      edit_at
      name
      version
      no_consent_mode
      pluginID
      containerIDs
      justification_id
      category_id
      tool_definition {
        pluginID
        name
        image_path
        tag_types
        cmpInformation {
          no_consent_mode
          essential_mode_available
          consentbar_configs
        }
      }
      constants {
        short_id
        name
        desc
        value
        optional
        type
      }
    }
  }
`;

export interface GetAllToolIdsQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusToolDetails, "id">;
  response: ListResponse<"tools", GetAllToolIdsQueryDefinition["dataType"]>;
}

export const GET_ALL_TOOLS_IDS = gql`
  query tools {
    tools {
      id
    }
  }
`;

export const CREATE_TOOL = gql`
  mutation createTool(
    $name: String!
    $no_consent_mode: Boolean
    $containerIDs: [Int!]!
    $pluginID: String!
    $constants: JSON
    $cmpInformation: JSON
    $createTags: Boolean!
    $tagTypesToGenerate: [String!]
  ) {
    createTool(
      name: $name
      no_consent_mode: $no_consent_mode
      containerIds: $containerIDs
      pluginID: $pluginID
      constants: $constants
      cmpInformation: $cmpInformation
      createTags: $createTags
      tagTypesToGenerate: $tagTypesToGenerate
    ) {
      id
      create_at
      edit_at
      name
      pluginID
      containerIDs
      tool_definition {
        pluginID
        name
        image_path
      }
    }
  }
`;

export const UPDATE_TOOL = gql`
  mutation updateTool(
    $id: Int!
    $name: String!
    $no_consent_mode: Boolean
    $containerIDs: [Int!]!
    $constants: JSON
    $cmpInformation: JSON
  ) {
    updateTool(
      id: $id
      name: $name
      no_consent_mode: $no_consent_mode
      containerIds: $containerIDs
      constants: $constants
      cmpInformation: $cmpInformation
    ) {
      id
      create_at
      edit_at
      name
      pluginID
      containerIDs
      tool_definition {
        pluginID
        name
        image_path
      }
    }
  }
`;

export const GET_ALL_TOOL_NAMES = gql`
  query tools {
    tools {
      name
    }
  }
`;
