import { Container, Paper, Stack, Switch, Typography } from "@mui/material";
import GET_CONSENT_CONFIG, { SAVE_CONSENT_CONFIG } from "apps/admin/business/consent/queries";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import { notifyError, notifySuccess } from "libs/business/notification/notification";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import { useState } from "react";

export default function Consent() {
  const [isChecked, setChecked] = useState(true);
  const [getChecked, checkChecked] = useQueryWrapper(GET_CONSENT_CONFIG);
  const [saveChange, checkSaveChange] = useMutationWrapper(SAVE_CONSENT_CONFIG);

  if (!checkChecked.called) {
    getChecked({
      context: { clientName: "CMP" },
      onCompleted: (data: any) => {
        setChecked(() => {
          return data.findConfigs.strict;
        });
      },
    });
  }

  if (checkChecked.error || checkSaveChange.error) {
    notifyError("Server error");
  }

  if (!checkChecked.called || checkChecked.loading) {
    return <PageLoadingSpinner />;
  }

  const handleSwitchChange = () => {
    saveChange({
      variables: { strict: !isChecked ? 1 : 0 },
      context: { clientName: "CMP" },
      onCompleted: (data: any) => {
        notifySuccess("Success!");
      },
    });
    setChecked((prevValue: boolean) => {
      return !prevValue;
    });
  };

  return (
    <Container>
      <Typography variant="pageTitle">Consent administration</Typography>
      <Paper variant="page">
        <Stack spacing={2}>
          <Switch defaultChecked={!!isChecked} onChange={handleSwitchChange} />
          <Typography variant="body1">
            JENTIS follows a Legal Assessment Document which describes how to configure and how to
            proceed with a CMP. Turning this off means, you are able to track without consent, but
            you are taking the responsibility for further legal consequences.
          </Typography>
        </Stack>
      </Paper>
    </Container>
  );
}
