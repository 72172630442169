import { Typography } from "@mui/material";
import ValueText from "../ValueText/ValueText";
import style from "./KeyValueRow.module.scss";

interface KeyValueRowProps {
  title: string;
  value: any;
  success?: boolean;
}

export default function KeyValueRow({ title, value, success }: KeyValueRowProps) {
  return (
    <div style={{ display: "flex" }}>
      <Typography className={style.key} component="pre">
        {title} :
      </Typography>
      <ValueText text={value} success={success} />
    </div>
  );
}
