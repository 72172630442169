import routes from "apps/jtm/conf/routes";
import React, { ReactNode, useContext, useMemo } from "react";
import { matchPath, useLocation, useSearchParams } from "react-router-dom";

type HelpContextType = {
  articleId: string | boolean | string[][];
  setHelpParams: (help_param: string) => void;
};

export function useHelpContext() {
  return useContext(HelpContext);
}

export const HelpContext = React.createContext<HelpContextType>({
  articleId: false,
  setHelpParams: () => {},
});

interface ISearchParamsProviderProps {
  children: ReactNode;
}

/**
 * The SearchParamsContext
 * @returns a SearchParamsContext component
 */
export const HelpProvider = ({ children }: ISearchParamsProviderProps) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const memo = useMemo(() => {
    const setHelpParams = (help_param: string) => {
      setSearchParams({ help_param });
    };
    const getDocumentationWithSearchParam = (
      documentation:
        | string
        | {
            [key: string]: string;
          }
        | undefined,
      helpParam: string,
    ) => {
      if (documentation && typeof documentation !== "string") {
        return documentation[helpParam];
      }
      return false;
    };
    const getDocumentationWithoutSearchParam = (
      documentation:
        | string
        | {
            [key: string]: string;
          }
        | undefined,
    ) => {
      let documentationId: string | false | string[][] = false;
      if (typeof documentation === "string") {
        documentationId = documentation;
      }
      if (typeof documentation === "object") {
        documentationId = Object.keys(documentation).map((key) => {
          return [String(key), documentation[key]];
        });
      }
      return documentationId;
    };
    const getArticleId = () => {
      const helpParam = searchParams.get("help_param");
      let documentationId: string | boolean | string[][] = false;
      const routeWithDocumentation = Object.keys(routes).find((e) => {
        return matchPath({ path: routes[e].path }, location.pathname as string);
      });
      if (!routeWithDocumentation) return false;
      if (helpParam) {
        documentationId = getDocumentationWithSearchParam(
          routes[routeWithDocumentation].documentation,
          helpParam,
        );
      } else {
        documentationId = getDocumentationWithoutSearchParam(
          routes[routeWithDocumentation].documentation,
        );
      }
      return documentationId;
    };
    return { articleId: getArticleId(), setHelpParams };
  }, [location, searchParams, setSearchParams]);

  return <HelpContext.Provider value={memo}>{children}</HelpContext.Provider>;
};

export default HelpContext;
