/* eslint-disable react/no-danger */
import { MenuItem, Modal, Typography } from "@mui/material";
import { Box, SxProps, Theme } from "@mui/system";
import useFetch from "libs/business/hooks/useFetch";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import { FC, useState } from "react";
import { notifyInfo } from "../../../business/notification/notification";
import LoadingSpinner from "../feedback/loading-spinner/LoadingSpinner";
import "../../../../assets/styles/documentationModalFonts.css";

interface IDocumentationModalProps {
  articleId: string;
  buttonText?: string;
}

const style: SxProps<Theme> = {
  position: "absolute",
  top: "15%",
  left: "15%",
  width: "70%",
  height: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  p: 4,
};

// Token for https://apidocs.document360.com/
// If expired, please contact Christoph Seidl
const TOKEN =
  "6u5guwqLsbNPn8cqFNzEjnLWzuVcuUM0i9w7nP3xYJubDynGWlicSVvINIWk/PnRcvfj35KkNwGYUKGIxyjqVAi6PlehLxtlEtCXVJCerN3Wr+XhHibpEruHxSuMhK8BzFx/+JYZ/lscHEQ07VHYAQ==";

const DocumentationModal: FC<IDocumentationModalProps> = ({
  articleId,
  buttonText = "Open in app documentation",
}: IDocumentationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [url] = usePropChangingState(`https://apihub.document360.io/v2/articles/${articleId}/en`);

  const { loading, error, value } = useFetch(
    url,
    {
      api_token: TOKEN,
    },
    [url],
  );
  if (!value && !loading) {
    return null;
  }
  if (error) {
    notifyInfo("Something went wrong");
    // eslint-disable-next-line no-console
    console.warn(error);
  }

  if (loading) return <LoadingSpinner />;

  return (
    <Box>
      <MenuItem onClick={handleOpen}>{buttonText}</MenuItem>

      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h2" component="h2">
            <strong> {value.data.title} </strong>
          </Typography>
          <Typography>
            <Box
              className="documentationModal"
              dangerouslySetInnerHTML={{ __html: value.data.html_content }}
            />
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default DocumentationModal;
