import { FormControl, FormHelperText } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useController, useFormContext, get } from "react-hook-form";

interface ColorSelectWrapperProps {
  name: string;
  value?: string;
  disabled?: boolean;
  helperText?: string;
}
/**
 * The ColorSelectWrapper component
 * @param {ColorSelectWrapperProps} props
 * @returns a ColorSelectWrapper component
 */
const ColorSelectWrapper = ({
  name,
  value = "",
  disabled = false,
  helperText,
}: ColorSelectWrapperProps): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { field } = useController({
    control,
    name,
    defaultValue: value,
  });

  const nameErrors = get(errors, name);
  const [uiElementValue, setUiElementValue] = useState(value);

  const changeValue = (changedValue: string): void => {
    setUiElementValue(changedValue);
    setHookFormValue(changedValue);
  };

  const setHookFormValue = (changedValue: string) => {
    field.onChange(changedValue);
  };

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      changeValue(e.target.value);
    },
    [],
  );

  return (
    <FormControl error={!!nameErrors} fullWidth disabled={disabled}>
      <input
        type="color"
        name={name}
        value={uiElementValue}
        disabled={disabled}
        onChange={onChangeHandler}
        style={{
          width: "100%",
          height: "100%",
          minHeight: "40px",
          borderRadius: 4,
          cursor: "pointer",
        }}
        data-cy={name}
      />
      <FormHelperText data-cy={`${name}-helperText`}>
        {nameErrors?.message ?? helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default ColorSelectWrapper;
