import {
  GetManifestToolPluginIdNameMappingsQueryDefinition,
  GET_MANIFEST_TOOL_PLUGINID_NAME_MAPPINGS,
} from "apps/jtm/business/log/queries";
import useSimpleFetching from "libs/business/hooks/useSimpleFetching";
import { deepCopyObjectWithoutFunction } from "libs/business/utils/objectFunctions";
import { IdAndName } from "libs/model/types";
import MultiSelectWrapper from "libs/ui/components/input/MultiSelectWrapper";
import React, { useCallback, useEffect } from "react";
import getAllUniqueConnectorShortIdsFromData from "../../Session/getAllConnectorShortIdsFromData";
import { RowData } from "../../SessionRow/type";
import { RowFilterConfig } from "../types";

export interface ConnectorFilterElement extends IdAndName {
  shortId: string;
}
interface ConnectorFilterProps {
  rowData: RowData[];
  filter: RowFilterConfig;
  setFilter: React.Dispatch<React.SetStateAction<RowFilterConfig>>;
  availableConnectors: ConnectorFilterElement[];
  setAvailableConnectors: React.Dispatch<React.SetStateAction<ConnectorFilterElement[]>>;
}
/**
 * The ConnectorFilter component
 * @param {ConnectorFilterProps} props
 * @returns a ConnectorFilter component
 */
const ConnectorFilter = ({
  rowData,
  filter,
  setFilter,
  availableConnectors,
  setAvailableConnectors,
}: ConnectorFilterProps): JSX.Element => {
  const mappingTable = useSimpleFetching<GetManifestToolPluginIdNameMappingsQueryDefinition>({
    query: GET_MANIFEST_TOOL_PLUGINID_NAME_MAPPINGS,
    context: "JTM",
  });

  // HOOKS
  useEffect(() => {
    const allConnectorShortIds = getAllUniqueConnectorShortIdsFromData(rowData);

    setAvailableConnectors((currentConnectors) => {
      if (mappingTable === false) return [];
      const newConnectors = getNewConnectors(
        currentConnectors,
        allConnectorShortIds,
        mappingTable.manifestToolPluginIdNameMappings,
      );
      return newConnectors;
    });
  }, [rowData, setAvailableConnectors, mappingTable]);

  // FUNCTIONS
  const onConnectorSelect = useCallback(
    (newConnectors: number[]) => {
      setFilter((currentFilter) => {
        const newFilter: RowFilterConfig = deepCopyObjectWithoutFunction(currentFilter);
        newFilter.connectorIds = newConnectors;
        return newFilter;
      });
    },
    [setFilter],
  );

  return (
    <MultiSelectWrapper
      name="connectors"
      label="Connector Filter"
      options={availableConnectors}
      value={filter.connectorIds}
      onChange={onConnectorSelect}
    />
  );
};

export default ConnectorFilter;

const getNewConnectors = (
  currentConnectors: ConnectorFilterElement[],
  allConnectorShortIds: string[],
  mappingTable: { [shortId: string]: string },
) => {
  const newConnectors = deepCopyObjectWithoutFunction(currentConnectors);

  const currentConnectorsIncludes = (shortId: string) =>
    !!currentConnectors.find((oC) => oC.shortId === shortId);

  const notIncludedConnectorShortIds = allConnectorShortIds.filter(
    (shortId) => !currentConnectorsIncludes(shortId),
  );

  notIncludedConnectorShortIds.forEach((connectorShortId) => {
    const name = mappingTable[connectorShortId] || connectorShortId;

    const newConnector = { id: newConnectors.length, shortId: connectorShortId, name };

    newConnectors.push(newConnector);
  });
  return newConnectors;
};
