type TokenType = "REFRESH" | "AUTH";

const REFRESH_TOKEN_STORAGE_NAME = "refresh-token";

const AUTH_TOKEN_STORAGE_NAME = "auth-token";
class TokenStorageManager {
  public static getAuthToken() {
    return this.getToken("AUTH");
  }

  public static getRefreshToken() {
    return this.getToken("REFRESH");
  }

  private static getToken(tokenType: TokenType) {
    let token: string | null;

    if (tokenType === "REFRESH") {
      token = localStorage.getItem(REFRESH_TOKEN_STORAGE_NAME);
    } else {
      token = sessionStorage.getItem(AUTH_TOKEN_STORAGE_NAME);
    }

    if (token === null) return undefined;

    return token;
  }

  public static setAuthToken(token: string) {
    sessionStorage.setItem(AUTH_TOKEN_STORAGE_NAME, token);
  }

  public static setRefreshToken(token: string) {
    localStorage.setItem(REFRESH_TOKEN_STORAGE_NAME, token);
  }

  public static removeAllToken() {
    this.removeAuthToken();
    this.removeRefreshToken();
  }

  private static removeAuthToken() {
    sessionStorage.removeItem(AUTH_TOKEN_STORAGE_NAME);
  }

  private static removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_NAME);
  }

  public static dispatchTokenChanged() {
    window.dispatchEvent(new Event("TOKEN_CHANGED"));
  }

  /**
   * @param cb the callback, which should be called on event
   * @returns a function which removes the listener.
   */
  public static addOnTokenChangeListener(cb: () => void) {
    window.addEventListener("TOKEN_CHANGED", cb);
    return () => window.removeEventListener("TOKEN_CHANGED", cb);
  }
}

export default TokenStorageManager;
