import { Paper } from "@mui/material";
import { ReactNode } from "react";
import styles from "./LoginScreen.module.scss";

export default function LoginScreen({ children }: { children: ReactNode }) {
  return (
    <div className={styles.login_screen}>
      <Paper className={styles.login_container} variant="page">
        {children}
      </Paper>
    </div>
  );
}
