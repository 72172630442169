import { Box, Grid, Stack, Typography } from "@mui/material";
import { IContainer } from "apps/admin/components/container/ContainerContext";
import SelectWrapper from "libs/ui/components/input/SelectWrapper";
import TextEditor from "libs/ui/components/input/text-editor/TextEditor";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { memo } from "react";
import MultiSelectWrapper from "libs/ui/components/input/MultiSelectWrapper";
import ColorSelectWrapper from "libs/ui/components/input/ColorSelectWrapper";

export interface IConstantData {
  id?: number;
  short_id: string;
  name: string;
  desc?: string;
  value: IConstantValue;
  optional?: boolean;
}

export interface IConstantValue {
  value: any;
  description?: string;
  type?: "select" | "editor" | "multiselect" | "input" | "color";
  type_options?: any[];
  options?: IConstantOption[];
}

export interface IConstantOption {
  account: number;
  environment?: number;
  value: any;
}

interface IEnvironmentInfo {
  id: number;
  name: string;
}

interface IConstantContainerSettingProps {
  constant: IConstantData;
  containerInfo: IContainer;
  allEnvironments?: IEnvironmentInfo[];
  submitName: string;
  defaultValue: string;
  readOnly?: boolean;
}
/**
 * The ContainerSettingsInput component
 * @param {IConstantContainerSettingProps} props
 * @returns a ContainerSettingsInput component
 */
const ConstantContainerSetting = ({
  constant,
  containerInfo,
  allEnvironments,
  submitName,
  defaultValue,
  readOnly = false,
}: IConstantContainerSettingProps): JSX.Element => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={3} justifyContent="flex-end">
        <div style={{ overflow: "hidden", marginTop: "12px" }}>
          <Typography variant="body1">{containerInfo.name}</Typography>
        </div>
        {/* Hidden inputs to get the right object structure on submit */}
        <TextFieldWrapper
          name={`${submitName}.${constant.short_id}.name`}
          label={`${submitName}.${constant.short_id}.name`}
          value={constant.name}
          hidden
        />
        <TextFieldWrapper
          name={`${submitName}.${constant.short_id}.id`}
          label={`${submitName}.${constant.short_id}.id`}
          value={constant.short_id}
          hidden
        />
        <TextFieldWrapper
          name={`${submitName}.${constant.short_id}.value`}
          label={`${submitName}.${constant.short_id}.value`}
          value={defaultValue}
          hidden
        />
      </Grid>
      <Grid item xs={9}>
        <Stack direction="row" spacing={2} justifyContent="space-around">
          {typeof allEnvironments !== "undefined"
            ? renderEnvironmentInputs(
                constant,
                containerInfo,
                allEnvironments,
                defaultValue,
                submitName,
                readOnly,
              )
            : renderBaseInput(constant, containerInfo, defaultValue, submitName)}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default memo(ConstantContainerSetting);

const renderEnvironmentInputs = (
  constant: IConstantData,
  containerInfo: IContainer,
  allEnvironments: IEnvironmentInfo[],
  defaultValue: string,
  submitName: string,
  readOnly: boolean,
): JSX.Element[] => {
  const containerOptions =
    constant.value?.options?.filter((option) => option && option.account === containerInfo.id) ||
    [];

  return allEnvironments.map((environment) => {
    const filteredOptions: IConstantOption[] = [];
    const objectPosition = `${submitName}.${constant.short_id}.options.${containerInfo.id}${environment.id}`;

    containerOptions.forEach((option) => {
      if (option.environment === environment.id) {
        filteredOptions[option.environment] = option;
      }
    });

    return (
      <div key={`${objectPosition}`}>
        <TextFieldWrapper
          name={`${objectPosition}.value`}
          label={environment.name}
          hiddenLabel
          variant="filled"
          value={filteredOptions[environment.id]?.value as string}
          placeholder={defaultValue}
          readonly={readOnly}
        />
        {/* Hidden inputs to get the right object structure on submit */}
        <TextFieldWrapper
          name={`${objectPosition}.account`}
          label={`${objectPosition}.account`}
          hidden
          value={containerInfo.id}
        />
        <TextFieldWrapper
          name={`${objectPosition}.environment`}
          label={`${objectPosition}.environment`}
          hidden
          value={environment.id}
        />
      </div>
    );
  });
};

const renderBaseInput = (
  constant: IConstantData,
  containerInfo: IContainer,
  defaultValue: string,
  submitName: string,
): JSX.Element => {
  const containerOptions =
    constant.value.options?.filter(
      (option) => option && String(option.account) === String(containerInfo.id),
    ) || [];
  const objectPosition = `${submitName}.${constant.short_id}.options.${containerInfo.id}`;

  const inputField = () => {
    if (constant.value.type) {
      switch (constant.value.type) {
        case "select":
          if (constant.value.type_options) {
            return (
              <SelectWrapper
                key={objectPosition}
                name={`${objectPosition}.value`}
                label="Value"
                value={containerOptions[0]?.value as string}
                options={constant.value.type_options}
              />
            );
          }
          break;
        case "multiselect":
          if (constant.value.type_options) {
            return (
              <MultiSelectWrapper
                key={objectPosition}
                name={`${objectPosition}.value`}
                label="Value"
                value={containerOptions[0]?.value}
                options={constant.value.type_options}
              />
            );
          }
          break;
        case "editor":
          return (
            <TextEditor
              key={objectPosition}
              name={`${objectPosition}.value`}
              placeholder="Description"
              value={containerOptions[0]?.value as string}
            />
          );
        case "color":
          return (
            <ColorSelectWrapper
              name={`${objectPosition}.value`}
              value={containerOptions[0]?.value}
            />
          );
        default:
          return (
            <TextFieldWrapper
              name={`${objectPosition}.value`}
              label="Value"
              hiddenLabel
              variant="filled"
              value={containerOptions[0]?.value as string}
              placeholder={defaultValue}
            />
          );
      }
    }

    return (
      <TextFieldWrapper
        name={`${objectPosition}.value`}
        label="Value"
        hiddenLabel
        variant="filled"
        value={containerOptions[0]?.value as string}
        placeholder={defaultValue}
      />
    );
  };
  return (
    <>
      {inputField()}
      {/* Hidden inputs to get the right object structure on submit */}
      <TextFieldWrapper
        name={`${objectPosition}.account`}
        label={`${objectPosition}.account`}
        hidden
        value={containerInfo.id}
      />
    </>
  );
};
