import { ApolloCache, ApolloError, FetchResult } from "@apollo/client";
import CLIENT from "libs/business/apollo";
import { notifySuccess, notifyError } from "libs/business/notification/notification";
import {
  GET_CONTAINER_STATUS_BY_ID,
  GET_STATUS_PER_CONTAINER,
} from "../../business/container/queries";

// eslint-disable-next-line import/prefer-default-export
export const getUpdateStepOptions = (variables: any) => {
  return {
    variables,
    context: { clientName: "SETUP" },
    onCompleted: () => {
      notifySuccess("Updated!");
    },
    update(cache: ApolloCache<any>, response: FetchResult) {
      let statusById: any | undefined;
      if (response.data) {
        const data = CLIENT.readQuery({
          query: GET_STATUS_PER_CONTAINER,
        });
        if (data) {
          const newArrayOfData = data.getStatusPerContainer.containerStatuses.map((item: any) => {
            if (response.data && item.containerId === response.data.updateSetupStep.containerId) {
              statusById = response.data.updateSetupStep;
              return response.data.updateSetupStep;
            }
            return item;
          });
          CLIENT.writeQuery({
            query: GET_STATUS_PER_CONTAINER,
            data: {
              getStatusPerContainer: { containerStatuses: newArrayOfData },
            },
          });
          if (statusById) {
            CLIENT.writeQuery({
              query: GET_CONTAINER_STATUS_BY_ID,
              variables: { containerId: statusById.containerId },
              data: {
                getContainerStatus: statusById,
              },
            });
          }
        }
      }
    },
    onError: (error: ApolloError) => {
      notifyError(error.message);
    },
  };
};
