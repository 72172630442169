import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  FetchResult,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { setContext } from "@apollo/link-context";
import { REFRESH_TOKEN } from "apps/admin/business/login/queries";
import { createClient } from "graphql-ws";
import TokenStorageManager from "../authentication/TokenStorageManager";
import UserStorageManager from "../authentication/UserStorageManager";
import { getDevelopServiceURL, getProductionServiceURL } from "./config";
import ErrorCodes from "./ErrorCodes";
import RefreshTokenError from "./RefreshTokenError";

const getHTTPLink = (service: string, subscription?: boolean) => {
  const uri =
    process.env.REACT_APP_ENVIRONMENT !== "dev"
      ? getProductionServiceURL(service, subscription)
      : getDevelopServiceURL(service, subscription);

  // Creating websocket link if the operation is subscription
  if (subscription) {
    return new GraphQLWsLink(
      createClient({
        url: uri,
        connectionParams: () => ({
          Authorization: `Bearer ${TokenStorageManager.getAuthToken()}`,
        }),
      }),
    );
  }

  return createHttpLink({ uri });
};

export type ClientName = "ADMIN" | "JTM" | "CMP" | "SETUP" | "MONITOR";

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().clientName === "ADMIN",
  getHTTPLink("admin"),
  ApolloLink.split(
    (operation) => operation.getContext().clientName === "JTM",
    getHTTPLink("jtm"),
    ApolloLink.split(
      (operation) => operation.getContext().clientName === "CMP",
      getHTTPLink("cmp"),
      ApolloLink.split(
        (operation) => operation.getContext().clientName === "SETUP",
        getHTTPLink("setup"),
        ApolloLink.split(
          (operation) => operation.getContext().isSubscription,
          getHTTPLink("monitor", true),
          getHTTPLink("monitor", false),
        ),
      ),
    ),
  ),
);

const authLink = setContext((_, { headers }) => {
  const token = TokenStorageManager.getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      // eslint-disable-next-line default-case
      switch (error.extensions.code) {
        case ErrorCodes.TOKEN_EXPIRED:
        case ErrorCodes.BAD_TOKEN: {
          // ignore 401 error for a refresh request
          if (operation.operationName === "RefreshToken") return;

          const observable = new Observable<FetchResult<Record<string, any>>>((observer) => {
            // used an anonymous function for using an async function
            (async () => {
              try {
                await refreshAuthToken();

                // Retry the failed request
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              } catch (e) {
                observer.error(e);
              }
            })();
          });
          // eslint-disable-next-line consistent-return
          return observable;
        }
      }
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const refreshAuthToken = async () => {
  try {
    const refreshToken = TokenStorageManager.getRefreshToken() || "";

    if (refreshToken === undefined) {
      logout();
      throw new RefreshTokenError();
    }

    const authenticationPayload = await tryToFetchNewAuthAndRefreshToken(refreshToken);

    TokenStorageManager.setAuthToken(authenticationPayload.auth);
    TokenStorageManager.setRefreshToken(authenticationPayload.refresh);
  } catch (err) {
    logout();
    throw new RefreshTokenError();
  }
};

const tryToFetchNewAuthAndRefreshToken = async (refreshToken: string) => {
  const refreshResolverResponse = await CLIENT.query({
    query: REFRESH_TOKEN,
    context: { clientName: "ADMIN" },
    variables: { refreshToken },
    fetchPolicy: "no-cache",
  });

  if (!refreshResolverResponse.data.refreshToken) {
    throw new RefreshTokenError();
  }

  return refreshResolverResponse.data.refreshToken;
};

export const logout = () => {
  TokenStorageManager.removeAllToken();
  UserStorageManager.removeUser();
  TokenStorageManager.dispatchTokenChanged();
  CLIENT.resetStore();
};

const CLIENT = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authLink, httpLink]),
});

export default CLIENT;
