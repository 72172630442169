import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import AlertBox from "libs/ui/components/surfaces/alertbox/alertBox";
import { PUBLISH } from "../../business/output/queries";
import { getPublishMutationOptions } from "./get_publish_mutation_options";
import EnvironmentMultiSelect from "../environment/EnvironmentSelect";
import { ReleaseLogClassification } from "../log/ReleaseLogs";
import { useSystemUpdateDialog } from "./SystemUpdatesPopUp";

type ContextType = {
  openModal: boolean;
  handleOpenModal: (versionData: IVersionData) => void;
  handleCloseModal: () => void;
};

export function usePublishDialog() {
  return useContext(PublishDialogContext);
}

export const PublishDialogContext = createContext<ContextType>({} as ContextType);

interface DialogProviderProps {
  initModalOpen?: boolean;
  children: ReactNode;
}
interface IVersionData {
  containerName: string;
  versionNumber: number;
  versionId: number;
  classification: ReleaseLogClassification;
}

const schema = yup.object({
  description: yup.string().required(),
  environmentIds: yup
    .array()
    .of(yup.number())
    .min(1, "you have to select at least one environment!"),
});

export const PublishDialogProvider = ({ initModalOpen = false, children }: DialogProviderProps) => {
  const systemUpdateDialog = useSystemUpdateDialog();
  const [openModal, setOpenModal] = useState<boolean>(initModalOpen);
  const [version, setVersion] = useState<IVersionData>({
    containerName: "No Container",
    versionNumber: 0,
    versionId: 0,
    classification: "normal",
  });

  const methods = useForm<any>({ resolver: yupResolver(schema) });

  const [publish, mutationState] = useMutationWrapper(PUBLISH);

  const handleOpenModal = (versionData: IVersionData): void => {
    setVersion(versionData);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const formSubmitHandler: SubmitHandler<any> = (submitData: any) => {
    publish(getPublishMutationOptions(submitData));
    handleCloseModal();
  };

  const dialogContext = useMemo(() => {
    methods.reset();
    return { openModal, handleOpenModal, handleCloseModal };
  }, [openModal]);

  if (mutationState.loading) return <PageLoadingSpinner />;

  const showCriticalUpdateWarning =
    version.classification === "critical" || version.classification === "emergency";

  return (
    <PublishDialogContext.Provider value={dialogContext}>
      {children}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle>Publish</DialogTitle>
        <FormProvider {...methods} key="publishForm">
          <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(formSubmitHandler)}>
            <TextFieldWrapper name="versionId" label="versionId" value={version.versionId} hidden />
            <DialogContent>
              <Grid container sx={{ mb: 4 }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Container</Typography>
                  <Typography variant="body2"> {version.containerName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Version number</Typography>
                  <Typography variant="body2"> {version.versionNumber}</Typography>
                </Grid>
              </Grid>
              <Stack spacing={2}>
                {showCriticalUpdateWarning && (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => systemUpdateDialog.handleOpenModal(version.versionId)}>
                    <AlertBox
                      type="warning"
                      message="This version includes critical system updates performed by JENTIS."
                    />
                  </div>
                )}
                <EnvironmentMultiSelect />
              </Stack>
              <TextFieldWrapper name="description" label="Description" value="" multiline />
            </DialogContent>
            <DialogActions>
              <SaveCancelBar cancelHandler={handleCloseModal} mainButtonText="Publish" />
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </PublishDialogContext.Provider>
  );
};

export default PublishDialogProvider;
