import { useContainer } from "apps/admin/components/container/ContainerContext";
import ContainerValueSelection from "libs/ui/components/input/ContainerValueSelection";
import { useMemo } from "react";

interface IConstantProps {
  data?: any;
}

/**
 * The Constant component
 * @param {IConstantProps} props
 * @returns a Constant component
 */
const Constant = ({ data }: IConstantProps): JSX.Element => {
  const { allContainers } = useContainer();

  const getContainerIds = useMemo(
    () => allContainers.map((containerEntity) => containerEntity.id),
    [allContainers],
  );

  return (
    <ContainerValueSelection
      rootInputName="value"
      labelText="Default Value"
      description="Use this value for all the containers which do not have a specified value."
      containerEnvironmentValues={data.value}
      shortPluginId=""
      selectedContainerIds={getContainerIds}
    />
  );
};

export default Constant;
