import { DocumentNode } from "@apollo/client";
import { Box, Card, CardHeader, CardMedia, Container } from "@mui/material";
import useQueryWrapper from "libs/business/authentication/useQueryWrapper";
import getFirstValueOfObject from "libs/business/utils/objectFunctions";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import SearchBar from "libs/ui/components/input/SearchBar";
import { useState } from "react";
import { Link } from "react-router-dom";

interface IElementData {
  pluginID: string;
  name: string;
  imagePath: string;
}

interface IPluginSelectionProps {
  query: DocumentNode;
  redirectLinkCard: string;
}

const PluginSelection = ({ query, redirectLinkCard }: IPluginSelectionProps): JSX.Element => {
  const [data, setData] = useState<IElementData[]>([
    {
      pluginID: "",
      name: "",
      imagePath: "",
    },
  ]);

  const [getPlugins, { called, loading, data: queryData }] = useQueryWrapper(query);

  if (!called) {
    getPlugins({
      context: { clientName: "JTM" },
      onCompleted: (result: any) => {
        const allData: IElementData[] = getFirstValueOfObject(result);
        setData(allData);
      },
    });
  }

  const filterListBySearch = (searchKey: string, allData: IElementData[]) => {
    if (searchKey !== "") {
      return allData.filter((element) => {
        return element.name.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    return allData;
  };

  const onSearchInputChange = (newSearchValue: string) => {
    if (!queryData) return;
    const allData = getFirstValueOfObject(queryData);
    const filteredData = filterListBySearch(newSearchValue, allData);
    setData(filteredData);
  };

  if (!called || loading) return <PageLoadingSpinner />;

  return (
    <Container>
      <Box sx={{ mb: 2 }}>
        <SearchBar debouncedOnChange={onSearchInputChange} />
      </Box>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "grid",
          gridAutoRows: "1fr",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            sm: "repeat(4, 1fr)",
            md: "repeat(5, 1fr)",
          },
          gridRowGap: "20px",
          gridColumnGap: "20px",
        }}>
        {data.map((element: any) => (
          <Link
            key={element.pluginID}
            to={`${redirectLinkCard}?pluginID=${element.pluginID}`}
            state={{ pluginId: element.pluginID }}>
            <Card
              key={`card-${element.pluginID}`}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                "&:hover": {
                  transform: "scale3d(1.02, 1.02, 1)",
                  transition: "all .4s ease-in-out",
                },
              }}>
              <CardHeader title={element.name} />
              <CardMedia
                height="150px"
                image={`${element.image_path}medium`}
                title={element.name}
                component="img"
              />
            </Card>
          </Link>
        ))}
      </Box>
    </Container>
  );
};

export default PluginSelection;
