/* eslint-disable prefer-destructuring */
import { yupResolver } from "@hookform/resolvers/yup";
import { Paper, TextField, Typography } from "@mui/material";
import GET_ALL_TAGS, { UPDATE_TAG } from "apps/jtm/business/tag/queries";
import { GetTagByIdDocument } from "gql/autogeneratedTypes";
import { getUpdateOptions } from "libs/business/apollo/updateOptions";
import useMutationWrapper from "libs/business/authentication/useMutationWrapper";
import useParamsId from "libs/business/hooks/useParamId";
import useTypedDocumentQueryWrapper from "libs/business/hooks/useTypedDocumentQueryWrapper";
import checkForNullOrUndefined from "libs/business/utils/checkForNullOrUndefined";
import PageLoadingSpinner from "libs/ui/components/feedback/loading-spinner/PageLoadingSpinner";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import SaveCancelBar from "libs/ui/components/surfaces/save-cancel-bar/SaveCancelBar";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useLocalStorage } from "libs/business/hooks/useStorage";
import ConfigureTemplate from "../../components/tag/ConfigureTemplate";
import TriggerSelect from "../../components/trigger/TriggerSelect";

const schema = yup.object({
  name: yup.string().required().min(4).max(255, "The name must be shorter than 255 characters."),
  triggerId: yup.number().required(),
});

const TagUpdate = (): JSX.Element => {
  const id = useParamsId("id");
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const [essentialModeValue, setEssentialModeValue] = useLocalStorage(
    "essentialModePerTag",
    [] as number[],
  );

  const persistEssentialMode = (value: boolean) => {
    if (value && !essentialModeValue.includes(id))
      setEssentialModeValue([...essentialModeValue, id]);
    if (!value && essentialModeValue.includes(id))
      setEssentialModeValue(essentialModeValue.filter((e) => e !== id));
  };

  const getTagByIdResponse = useTypedDocumentQueryWrapper(GetTagByIdDocument, {
    clientName: "JTM",
    variables: { id },
    disableCache: true,
  });

  const [updateTag, updateTagMutationState] = useMutationWrapper(UPDATE_TAG);

  const formSubmitHandler = (submitData: any) => {
    submitData.arraylistVariables = submitData.arraylistVariables || null;
    submitData.hitType = submitData.hitType || null;
    persistEssentialMode(submitData.consentmode);
    updateTag({
      ...getUpdateOptions("Tag", "JTM", submitData, GET_ALL_TAGS, {
        REFETCH_QUERY: GetTagByIdDocument,
        variables: { id },
        context: "JTM",
      }),
      refetchQueries: [GetTagByIdDocument],
      awaitRefetchQueries: true,
    });
  };

  if (!getTagByIdResponse || updateTagMutationState.loading) {
    return <PageLoadingSpinner />;
  }

  if (!getTagByIdResponse.tag) throw new Error("Received empty data");

  const tagData = getTagByIdResponse.tag;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Typography variant="pageTitle">General Tag Settings</Typography>
        <Paper variant="page">
          <TextField
            defaultValue={tagData.template_name}
            disabled
            size="small"
            label="Template"
            margin="normal"
            fullWidth
          />
          <TextFieldWrapper name="id" label="id" value={id} hidden />
          <TextFieldWrapper
            name="name"
            label="Name"
            value={checkForNullOrUndefined(tagData.name)}
            maxLength={256}
          />
          <TriggerSelect selectedTrigger={tagData.trigger?.id || false} />
        </Paper>
        <ConfigureTemplate
          placeholderDefinition={tagData.placeholder_definition}
          essentialMode={essentialModeValue.includes(id)}
        />
        <SaveCancelBar />
      </form>
    </FormProvider>
  );
};

export default TagUpdate;
