import Condition from "./Condition";
import ExcludeTriggerHeader from "./ExcludeTriggerHeader";
import style from "./Trigger.module.scss";
import { TriggerCondition } from "./types";

interface IExcludeTriggerProps {
  name: string;
  conditions: TriggerCondition[][];
}

export default function ExcludeTrigger({ name, conditions }: IExcludeTriggerProps) {
  return (
    <div className={style.excludeTrigger}>
      <ExcludeTriggerHeader name={name} />
      <Condition condition={conditions} />
    </div>
  );
}
