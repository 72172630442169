import { Typography } from "@mui/material";
import { IContainer, useContainer } from "apps/admin/components/container/ContainerContext";
import MultiSelectWithSearch from "libs/ui/components/search/MultiSelectWithSearch";

interface IContainerMultiSelectProps {
  idsOfSelectedContainers: number[];
  onChange?: (newValues: number[]) => void;
  label?: string;
  inputName?: string;
}

const ContainerMultiSelect = ({
  idsOfSelectedContainers,
  onChange,
  label = "Containers",
  inputName = "containerIDs",
}: IContainerMultiSelectProps): JSX.Element => {
  const { allContainers } = useContainer();

  // Following function was implemented because of corrupt database
  idsOfSelectedContainers = removeContainersWhichAreNotInDatabase(
    idsOfSelectedContainers,
    allContainers,
  );

  const options = allContainers.map((container) => ({
    key: container.id,
    value: <Typography>{container.name}</Typography>,
    optionLabel: container.name,
  }));

  const onChangeAdapter = (newValues: (number | string)[] | undefined) => {
    // TODO: fix generics of MultiSelectWithSearch
    if (onChange) onChange((newValues as number[])?.filter((val) => val) || []);
  };

  return (
    <MultiSelectWithSearch
      inputName={inputName}
      instanceName={label}
      defaultValue={idsOfSelectedContainers}
      options={options}
      onChange={onChangeAdapter}
    />
  );
};

export default ContainerMultiSelect;

const removeContainersWhichAreNotInDatabase = (
  idsOfSelectedContainers: number[],
  allDatabaseContainers: IContainer[],
): number[] => {
  const finalContainerSelection: number[] = [];
  idsOfSelectedContainers.forEach((containerId) => {
    if (allDatabaseContainers.find((container) => container.id === containerId)) {
      finalContainerSelection.push(containerId);
    }
  });
  return finalContainerSelection;
};
