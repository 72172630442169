import { Button, Stack, Typography } from "@mui/material";
import ContainerMultiSelect from "apps/admin/components/container/ContainerMultiSelect";
import { BackendNexusToolConstantDetails } from "apps/jtm/business/tool/queries";
import usePropChangingState from "libs/business/hooks/usePropChangingState";
import ConfirmDialog from "libs/ui/components/feedback/modal/ConfirmDialog";
import SimpleInputDialog from "libs/ui/components/feedback/modal/SimpleInputDialog";
import SwitchWrapper from "libs/ui/components/input/SwitchWrapper";
import TextFieldWrapper from "libs/ui/components/input/TextFieldWrapper";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import ConstantGrid from "./ConstantGrid";

interface ITrackingSettings {
  name: string;
  constants: BackendNexusToolConstantDetails[];
  createTags: boolean;
  selectedContainerIds: number[];
  setContainerSelection: React.Dispatch<any>;
}
/**
 * The TrackingSettings component
 * @returns a TrackingSettings component
 */
const TrackingSettings = ({
  name,
  constants,
  createTags,
  selectedContainerIds,
  setContainerSelection,
}: ITrackingSettings): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [newConstantId, setNewConstantId] = useState(-1);
  const [constantData, setConstantData] =
    usePropChangingState<(BackendNexusToolConstantDetails & { isNew?: boolean })[]>(constants);
  const [deleteModalState, setDeleteModalState] = useState({ open: false, short_id: "" });
  const formContext = useFormContext();

  const handleMultiSelectChange = (newValues: number[]) => {
    setContainerSelection(newValues);
  };

  const handleOpenModal = (): void => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const names = constantData.map((constant) => constant.name);

  const handleAddConstant = (value: string) => {
    const newConstant: BackendNexusToolConstantDetails & { isNew?: boolean } = {
      isNew: true,
      short_id: value,
      desc: "",
      name: value,
      value: { value: "" },
      optional: true,
      type: "text",
    };
    setConstantData([...constantData, newConstant]);
    setNewConstantId(newConstantId - 1);
    handleCloseModal();
  };

  const handleDelete = () => {
    const fieldName = constantData.find(
      (constant) => constant.short_id === deleteModalState.short_id,
    )?.name;
    if (fieldName) {
      formContext.unregister(`constants.${fieldName}`);
      setConstantData(
        constantData.filter((constant) => constant.short_id !== deleteModalState.short_id),
      );
    }

    setDeleteModalState((prev) => ({ ...prev, open: false }));
  };

  const onDeleteClick = (shortId: string) => {
    setDeleteModalState({ open: true, short_id: shortId });
  };

  return (
    <>
      <Stack spacing={5}>
        <TextFieldWrapper name="name" label="Name" value={name} size="medium" maxLength={256} />
        <ContainerMultiSelect
          idsOfSelectedContainers={selectedContainerIds}
          onChange={handleMultiSelectChange}
        />
        {createTags && (
          <SwitchWrapper
            name="createTags"
            label="Automatically create Tags"
            value
            labelPlacement="start"
          />
        )}
        <Typography variant="h6">Constant Settings</Typography>
        <ConstantGrid
          rootInputName="constants"
          constants={constantData}
          selectedContainerIds={selectedContainerIds}
          onDelete={onDeleteClick}
        />
        <div>
          <Button variant="outlined" onClick={handleOpenModal}>
            Add Constant
          </Button>
        </div>
      </Stack>
      <SimpleInputDialog
        open={openModal}
        onClose={handleCloseModal}
        asyncOnSubmit={async (value) => handleAddConstant(value)}
        yupDefinition={yup.string().required().notOneOf(names, "This Name is already in use!")}
        title="Enter the name of the new constant."
        inputLabel="Constant Name"
      />
      <ConfirmDialog
        title="Are you sure you want to delete this custom constant?"
        isOpen={deleteModalState.open}
        onConfirm={() => handleDelete()}
        onCancel={() => setDeleteModalState({ open: false, short_id: "" })}
        message=""
      />
    </>
  );
};

export default TrackingSettings;
