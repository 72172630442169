import { gql } from "@apollo/client";
import { BackendNexusUserType } from "apps/admin/business/user/queries";
import { GQLTypeDefinition, ListResponse, SingleResponse } from "libs/model/types";
import { WizardContextType } from "libs/ui/components/assistant-wizard/AssistantWizardContext";

export type AssistantType = "CONNECTOR";

export interface BackendNexusAssistantTypes {
  id: number;
  create_by: BackendNexusUserType;
  create_at: string;
  edit_by: BackendNexusUserType;
  edit_at: string;
  short_id: string;
  name: string;
  type: AssistantType;
  invitedUsers: BackendNexusUserType[];
  data: WizardContextType | null;
}

export interface GetAssistantByIdQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusAssistantTypes, "data">;
  response: SingleResponse<"assistant", GetAssistantByIdQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "JTM";
}

export const GET_ASSISTANT_BY_ID = gql`
  query assistant($id: Int!) {
    assistant(id: $id) {
      data
    }
  }
`;

export interface GetAssistantInstancesByTypeQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusAssistantTypes, "id" | "name" | "create_at" | "create_by" | "type">;
  response: ListResponse<
    "assistantsByType",
    GetAssistantInstancesByTypeQueryDefinition["dataType"]
  >;
  arguments: { type: AssistantType }; // Delete row if there are not args
  context: "JTM";
}

export const GET_ASSISTANT_INSTANCES_BY_TYPE = gql`
  query assistantsByType($type: String!) {
    assistantsByType(type: $type) {
      id
      type
      create_at
      name
    }
  }
`;

export interface CreateAssistantQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusAssistantTypes, "id">;
  response: SingleResponse<"createAssistant", CreateAssistantQueryDefinition["dataType"]>;
  arguments: { type: AssistantType; name: string };
  context: "JTM";
}
export const CREATE_ASSISTANT = gql`
  mutation createAssistant($type: String!, $name: String!) {
    createAssistant(type: $type, name: $name) {
      id
      name
    }
  }
`;

export interface UpdateAssistantQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusAssistantTypes, "data" | "id" | "type">;
  response: SingleResponse<"updateAssistant", UpdateAssistantQueryDefinition["dataType"]>;
  arguments: { id: number; data: BackendNexusAssistantTypes["data"]; name?: string };
  context: "JTM";
}

export const UPDATE_ASSISTANT = gql`
  mutation updateAssistant($id: Int!, $name: String, $data: JSON!) {
    updateAssistant(id: $id, data: $data, name: $name) {
      id
      type
      data
    }
  }
`;

export interface DeleteAssistantQueryDefinition extends GQLTypeDefinition {
  dataType: Pick<BackendNexusAssistantTypes, "id">;
  response: SingleResponse<"deleteAssistant", DeleteAssistantQueryDefinition["dataType"]>;
  arguments: { id: number };
  context: "JTM";
}

export const DELETE_ASSISTANT = gql`
  mutation deleteAssistant($id: Int!) {
    deleteAssistant(id: $id) {
      id
    }
  }
`;
